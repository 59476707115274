import { call, put } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import portfoliosAction from '../actions/Portfolios';

const {
  PORTFOLIOS_PENDING,
  PORTFOLIOS_FULFILLED,
  PORTFOLIOS_REJECTED
} = portfoliosAction;

export default function* getPortfolios(request) {
  yield put({ type: PORTFOLIOS_PENDING });

  try {
    const { customerId } = request;

    const query = QueryBuilder()
      .eq('customer.id', customerId, { type: 'string' })
      .eq('portfolioKind.id', 'portfolioKind-7')
      .getQueryString();

    const url = `/account/group/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: PORTFOLIOS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PORTFOLIOS_REJECTED, error });

    return error;
  }
}
