import axios from 'axios';

import {
  RequestStartTimeInterceptor,
  ResponseBpfErrorInterceptor,
  ResponsePerformanceRuntimeInterceptor,
  ResponseUnauthorizedOrForbiddenInterceptor
} from './Interceptors/index';

export default function initInterceptors(instance = axios) {
  const requestStartTimeInterceptor = RequestStartTimeInterceptor();
  const responseBpfErrorInterceptor = ResponseBpfErrorInterceptor();
  const responsePerformanceRuntimeInterceptor = ResponsePerformanceRuntimeInterceptor();
  const responseUnauthorizedOrForbiddenInterceptor = ResponseUnauthorizedOrForbiddenInterceptor();

  requestStartTimeInterceptor.register(instance);
  responseBpfErrorInterceptor.register(instance);
  responsePerformanceRuntimeInterceptor.register(instance);
  responseUnauthorizedOrForbiddenInterceptor.register(instance);

  return instance;
}
