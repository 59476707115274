import { get } from 'lodash';

/**
 * get the customer id from state.
 * Try to get it from url, else get if from powersearch
 *
 * @param  {Object} [state={}] State object
 *
 * @return {String} customerId Customer id
 */
export default function getCustomerIdFromState(state = {}, match = {}) {
  return get(
    match,
    'params.customerNumber',
    get(state, 'powersearch.customer.id')
  );
}
