import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import { get } from 'lodash';
import salariesAction from '../../actions/Salary/Salaries';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';
import getIdFromRef from '../../../../lib/Utils/getIdFromRef';

const {
  SALARIES_PENDING,
  SALARIES_FULFILLED,
  SALARIES_REJECTED
} = salariesAction;

function updateTransactionsWithAccounts(transactions, accounts) {
  return transactions.map((tran) => {
    const accountId = getIdFromRef(get(tran, 'account.$ref'), 'account');
    const account = accounts.find((acc) => get(acc, 'id') === accountId);

    return {
      ...tran,
      account
    };
  });
}

export default function* getSalaries(request) {
  const { dataKey } = request;
  
  yield put({ type: SALARIES_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('customerId', dataKey, { type: 'string' })
      .eq('isSalary', true)
      .limit(1000)
      .getQueryString();

    const url = `/account/transaction/${query}`;
    const transactionPayload = yield call(handleNextHeaders, url);

    const accounts = yield call(getCustomerAccounts, { dataKey });

    const payload = updateTransactionsWithAccounts(transactionPayload, accounts);

    yield put({ type: SALARIES_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: SALARIES_REJECTED, dataKey, error });

    return error;
  }
}
