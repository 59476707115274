import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, upperFirst, noop, isUndefined } from 'lodash';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actions as codeActions, utils as codeUtils } from '@evoja-web/entity-code';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import {
  withWorkguideInstanceData,
  WorkguideCustomerAware
} from '../../../Workguide/hocs';
import {
  ContractList,
  Documents
} from './Unlock/index';

import { Formalities } from '../../../BpfCms/index';
import securitiesAccountActions from '../../actions/Actions';
import { InputField } from '../../../General/index';
import { ProcessComments } from '../../../Bpf/index';

class SecuritiesAccountUnlock extends React.Component {
  constructor(props) {
    super(props);
    const { codeActions } = this.props;

    this.state = {
      comment: ''
    };

    codeActions.groupsRequest({ groups: ['contractStatus', 'contractType'] });

    this.onSubmit = this.onSubmit.bind(this);
    this.resetProcess = this.resetProcess.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.canRejectProcess = this.canRejectProcess.bind(this);
  }

  componentDidMount() {
    const {
      bpfTask,
      securitiesAccountActions
    } = this.props;

    const originId = get(bpfTask, 'data.task.processInstanceId');
    securitiesAccountActions.workbenchRecordRequest({ dataKey: originId, originId });
  }

  onSubmit() {
    const { comment } = this.state;
    const {
      bpfTask,
      form,
      redirect,
      securitiesAccountActions,
      workbenchData
    } = this.props;
    const { data } = form;

    const comments = [
      ...get(bpfTask, 'data.process.comments', []),
      { value: comment, editor: get(workbenchData, 'assigneeDesignation'), timestamp: new Date() }
    ];

    securitiesAccountActions.unlockFormSaveRequest({
      data,
      taskId: get(bpfTask, 'data.task.id'),
      comments,
      rejectTask: false,
      canReject: this.canRejectProcess()
    });

    redirect();
  }

  onCustomerChange() {
    const {
      bpfTask,
      securitiesAccountActions
    } = this.props;

    const customerIds = get(bpfTask, 'data.process.contracts', [])
      .map((contract) => get(contract, 'clientKey'));

    securitiesAccountActions.customersRequest({ customerIds });
  }

  canRejectProcess() {
    const { bpfTask } = this.props;

    if (get(bpfTask, 'data.process.subStatusKey', '') === 'depot-activity-unlock-qms') {
      return true;
    }
    return false;
  }

  resetProcess() {
    const { comment } = this.state;
    const {
      bpfTask,
      form,
      redirect,
      securitiesAccountActions,
      workbenchData
    } = this.props;
    const { data } = form;

    const comments = [
      ...get(bpfTask, 'data.process.comments', []),
      { value: comment, editor: get(workbenchData, 'assigneeDesignation'), timestamp: new Date() }
    ];
    securitiesAccountActions.unlockFormSaveRequest({ data, taskId: get(bpfTask, 'data.task.id'), comments, rejectTask: true });

    redirect();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { comment } = this.state;
    const {
      bpfContracts,
      bpfTask,
      codes,
      customer,
      customers,
      environment,
      language,
      redirect,
      requesting,
      intl
    } = this.props;

    const productName = get(bpfTask, `data.process.productGroup.productDescription${upperFirst(language)}`);
    const depotNumber = get(bpfTask, 'data.process.depotToOpenEditNumber');
    const portfolioId = get(bpfTask, 'data.process.portfolioId');

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
        requesting={requesting}
        showLoadIndicator
      >
        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="SecuritiesAccount.Unlock.Form.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '40px' }}>
          <Col lg={12}>
            <FormattedMessage id="SecuritiesAccount.Unlock.Form.Description" />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <FormattedMessage
              id="SecuritiesAccount.Unlock.Form.Product"
              values={{ product: productName }}
              tagName="strong"
            />
          </Col>
        </Row>

        {!isUndefined(depotNumber) && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12}>
              <FormattedMessage
                id="SecuritiesAccount.Unlock.Form.DepotNumber"
                values={{ number: depotNumber }}
                tagName="strong"
              />
            </Col>
          </Row>
        )}

        {isUndefined(depotNumber) && !isUndefined(portfolioId) && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12}>
              <FormattedMessage
                id="SecuritiesAccount.Unlock.Form.PortfolioId"
                values={{ id: portfolioId }}
                tagName="strong"
              />
            </Col>
          </Row>
        )}

        <Row style={{ paddingBottom: '20px' }}>
          <Col>
            <Formalities customer={customer} />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <ContractList
              codes={codes}
              bpfContracts={bpfContracts}
              bpfTask={bpfTask}
              customers={customers}
              language={language}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <Documents
              customers={customers}
              environment={environment}
            />
          </Col>
        </Row>

        {!isUndefined(get(bpfTask, 'data.process.comments')) && (
          <Row style={{ paddingBottom: '20px' }}>
            <div style={{ marginLeft: 15 }}>
              <FormattedMessage
                id="SecuritiesAccount.Unlock.Form.Comment"
                tagName="strong"
              />
            </div>

            <Col lg={12}>
              <ProcessComments comments={get(bpfTask, 'data.process.comments', [])} />
            </Col>
          </Row>
        )}

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <FormattedMessage
              id="SecuritiesAccount.Unlock.Form.CreateComment"
              tagName="strong"
            />
            <FormGroup>
              <InputField
                id="comment"
                name="comment"
                type="textarea"
                value={comment}
                onChange={(id, value) => this.setState({ comment: value })}
                rows={4}
                limit={2000}
                placeholder={intl.formatMessage({ id: 'Workguide.Approval.Comment' })}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <Alert type="warning">
              <AlertContent>
                <FormattedMessage
                  id="SecuritiesAccount.Unlock.Form.Hint1"
                />
              </AlertContent>
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              onClick={this.onSubmit}
            >
              <FormattedMessage id="Activity.Form.Submit" />
            </Button>
            <span style={{ paddingRight: '20px' }} />
            {this.canRejectProcess() && (
              <Button
                color="primary"
                onClick={this.resetProcess}
              >
                <FormattedMessage id="Activity.Form.RejectProcess" />
              </Button>
            )}

            <span style={{ paddingRight: '20px' }} />

            <Button
              color="primary"
              onClick={() => redirect()}
              outline
            >
              <FormattedMessage id="General.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </WorkguideCustomerAware>
    );
  }
}

SecuritiesAccountUnlock.propTypes = {
  bpfContracts: PropTypes.array,
  bpfTask: PropTypes.object.isRequired,
  codeActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  customers: PropTypes.object,
  environment: PropTypes.object.isRequired,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  redirect: PropTypes.func,
  requesting: PropTypes.bool,
  securitiesAccountActions: PropTypes.object.isRequired,
  workbenchData: PropTypes.object
};

SecuritiesAccountUnlock.defaultProps = {
  bpfContracts: [],
  codes: { groups: {} },
  customer: undefined,
  customers: {},
  form: {},
  language: 'de',
  redirect: noop,
  requesting: false,
  workbenchData: {}
};

function isRequesting(state) {
  return (
    get(state, 'securitiesAccount.customers.requesting', false)
    || !codeUtils.allGroupsFulfilledOrRejected(state.codes)
  );
}

function mapStateToProps(state, ownProps) {
  const {
    customers,
    unlockForm,
    workbenchRecord
  } = state.securitiesAccount;
  const { bpfTask } = ownProps;

  const originId = get(bpfTask, 'data.task.processInstanceId');
  const workbenchData = get(workbenchRecord, `${originId}.data`);
  const bpfContracts = get(bpfTask, 'data.process.contracts', [])
    .map((c) => ({ ...c, id: get(c, 'contractId') }));

  return {
    bpfContracts,
    codes: state.codes,
    customers,
    environment: state.environment,
    form: unlockForm,
    language: state.login.language,
    requesting: isRequesting(state),
    workbenchData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    codeActions: bindActionCreators(codeActions, dispatch),
    securitiesAccountActions: bindActionCreators(securitiesAccountActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(SecuritiesAccountUnlock)
  )
);
