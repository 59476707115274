import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Import is required as it registers the chartjs plugins... Do not remove!
// eslint-disable-next-line
import ChartJS from 'chart.js/auto'

import { configureStore } from './Store';
import App from './App';
import rootReducer from './reducers';
import sagas from './sagas';
import init from './init';

init();

function preparePersistedState() {
  return {};
}

const store = configureStore(rootReducer, sagas, preparePersistedState);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
