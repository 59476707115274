import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import checkExistingOpeningAction from '../actions/CheckExistingOpening';

const {
  CHECK_EXISTING_OPENING_PENDING,
  CHECK_EXISTING_OPENING_FULFILLED,
  CHECK_EXISTING_OPENING_REJECTED
} = checkExistingOpeningAction;

export default function* checkExistingOpening(request) {
  yield put({ type: CHECK_EXISTING_OPENING_PENDING });

  try {
    const { customerId, product, tenant = 6300 } = request;
    const businessKey = `${customerId}-${product}`;
    const baseUrl = '/sif/bpf/public/api/v1/history/process-instance';
    const params = `tenantId=${tenant}&processDefinitionKey=valiant-depot-open&processInstanceBusinessKey=${businessKey}&unfinished=true`;
    const url = `${baseUrl}?${params}`;

    const { data: payload } = yield call(axios, {
      url,
      method: 'get'
    });

    yield put({ type: CHECK_EXISTING_OPENING_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CHECK_EXISTING_OPENING_REJECTED, error });

    return error;
  }
}
