import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import fetchUiNotesBanners from '../UiNotes/fetchUiNotesBanners';

export default function* bootstrapUiNotesBanners() {
  const { fulfilled = false, data = {} } = yield select((state) => get(state, 'uiNotesBanners', {}));

  if (fulfilled) return data;

  return yield call(fetchUiNotesBanners);
}
