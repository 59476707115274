import { call, put } from 'redux-saga/effects';

import accountActions from '../../actions/SetAdvisor/Account';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';

const {
  ACCOUNTS_PENDING,
  ACCOUNTS_FULFILLED,
  ACCOUNTS_REJECTED
} = accountActions;

export default function* getAccounts(request) {
  const { customerId } = request;

  yield put({ type: ACCOUNTS_PENDING });
  try {
    const payload = yield call(getCustomerAccounts, { dataKey: customerId });

    yield put({ type: ACCOUNTS_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: ACCOUNTS_REJECTED, error });

    return error;
  }
}
