import { call, put } from 'redux-saga/effects';

import moment from 'moment';
import { get, groupBy, sortBy } from 'lodash';

import getOutlookCalendar from './getOutlookCalendar';
import getBirthdays from './getBirthdays';
import outlookCalendarAndBirthdayAction from '../actions/OutlookCalendarAndBirthday';
import { BIRTHDAY_TYPE } from '../components/Birthday/Birthday';
import { OUTLOOK_CALENDAR_TYPE } from '../components/OutlookCalendar/OutlookCalendar';

const {
  OUTLOOK_CALENDAR_AND_BIRTHDAYS_PENDING,
  OUTLOOK_CALENDAR_AND_BIRTHDAYS_FULFILLED,
  OUTLOOK_CALENDAR_AND_BIRTHDAYS_REJECTED
} = outlookCalendarAndBirthdayAction;

/**
 * Create List of Birthdays and Outlook Calendar entrys.
 *
 *
 * @param {Array} birthdayData
 * @param {Array} outlookCalendarData
 * @returns List of Birthdays and OutlookCalendars
 */
const createOutlookCalendar = (birthdayData, outlookCalendarData) => {
  const mappedbirthdayData = birthdayData.map((birthday) => {
    const currentYear = moment().year();

    let date = moment(get(birthday, 'birthDate')).set({ hour: 23, minute: 59, second: 59, year: currentYear });
    if (date.isBefore(moment())) {
      date = date.add(1, 'y');
    }

    return {
      date,
      type: BIRTHDAY_TYPE,
      data: birthday
    };
  });

  const mappedOutlookCalendarData = get(outlookCalendarData, 'items', []).map((outlookCalendar) => {
    return {
      date: moment(get(outlookCalendar, 'start.dateTime')).set({ hour: 23, minute: 59, second: 59 }),
      type: OUTLOOK_CALENDAR_TYPE,
      data: outlookCalendar
    };
  });

  const dataArray = [...mappedbirthdayData, ...mappedOutlookCalendarData];

  const groupedData = Object.keys(groupBy(dataArray, 'date')).map((k) => {
    const grouped = groupBy(dataArray, 'date')[k];
    const date = moment(grouped[0].date);
    const birthday = { dateFormatted: date.format('L'), date, items: grouped };
    return birthday;
  });

  return {
    items: sortBy(groupedData, (obj) => obj.date),
    lastRefresh: get(outlookCalendarData, 'lastRefresh'),
    userId: get(outlookCalendarData, 'userId')
  };
};

export default function* getOutlookCalendarAndBirthdays(request) {
  yield put({ type: OUTLOOK_CALENDAR_AND_BIRTHDAYS_PENDING });

  try {
    const {
      consultantId,
      consultantUserId,
      daysForward = 7,
      clearCache,
      mail
    } = request;

    const outlookCalendarData = yield call(getOutlookCalendar, { clearCache, userId: consultantUserId, mail });
    const birthdaysData = yield call(getBirthdays, { consultantId, daysForward });

    const data = createOutlookCalendar(birthdaysData, outlookCalendarData);

    yield put({ type: OUTLOOK_CALENDAR_AND_BIRTHDAYS_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: OUTLOOK_CALENDAR_AND_BIRTHDAYS_REJECTED, error });

    return error;
  }
}
