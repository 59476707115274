import { takeLatest } from 'redux-saga/effects';

import {
  FINANCING_ESTIMATION_LOAD_REQUEST,
} from '../actions/FinancingEstimation';

import { getFinancingEstimation } from './FinancingEstimation/index';

export default function* root() {
  yield takeLatest(FINANCING_ESTIMATION_LOAD_REQUEST, getFinancingEstimation);
}
