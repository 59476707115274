import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get, chain } from 'lodash';

import portfoliosAction from '../../actions/Portfolio/Portfolios';
import getCustomerAccounts from '../../../Account/sagas/getCustomerAccounts';

const {
  PORTFOLIOS_PENDING,
  PORTFOLIOS_FULFILLED,
  PORTFOLIOS_REJECTED
} = portfoliosAction;

function* getAccountGroups(request) {
  const { customerId } = request;

  const query = QueryBuilder()
    .eq('customer.id', customerId, { type: 'string' })
    .eq('isActive', true)
    .out('portfolioKind.id', ['portfolioKind-1', 'portfolioKind-9'])
    .limit(200)
    .getQueryString();

  const result = yield call(handleNextHeaders, `/account/group/${query}`);

  return result;
}

function* getAccountsByGroups(customerId, groups = []) {
  // Do nothing if groups is empty as this will lead to an error when trying to fetch with an empty in() statement
  if (get(groups, 'length', 0) === 0) {
    return [];
  }

  const ids = groups.map((group) => get(group, 'id'));
  const accounts = yield call(getCustomerAccounts, { dataKey: customerId });

  return chain(accounts)
    .filter((acc) => get(acc, 'accountType.number') !== '6610' && ids.includes(get(acc, 'accountGroup.id')))
    .groupBy('accountGroup.id')
    .value();
}

export default function* getPortfolios(request) {
  const { customerId } = request;
  yield put({ type: PORTFOLIOS_PENDING });

  try {
    const groups = yield call(getAccountGroups, request);
    const accounts = yield call(getAccountsByGroups, customerId, groups);

    const payload = groups.map((group) => ({
      ...group,
      accounts: get(accounts, get(group, 'id'), [])
    }));

    yield put({ type: PORTFOLIOS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PORTFOLIOS_REJECTED, error });

    return error;
  }
}
