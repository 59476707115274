import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import moment from 'moment';
import { isNil } from 'lodash';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import bannersAction from '../actions/Banners';

const {
  BANNERS_PENDING,
  BANNERS_FULFILLED,
  BANNERS_REJECTED
} = bannersAction;

function* updateIndexDb({ data = [] }) {
  const db = getDbInstance({ id: 'map' });
  const records = yield call(db.getAllRecords, { table: 'uiNote' });

  const updated = data.reduce((result, banner) => {
    const current = result.find((r) => r.id === banner.id);

    return isNil(current)
      ? [...result, banner]
      : [...result.filter((r) => r.id !== current.id), { ...current, ...banner }];
  }, records);

  yield call(db.bulkUpdate, { table: 'uiNote', data: updated, clear: true });

  return updated;
}

export default function* getBanners(request) {
  yield put({ type: BANNERS_PENDING });

  try {
    const startDate = moment().startOf('day').utc().format();
    const endDate = moment().endOf('day').utc().format();
    const query = QueryBuilder()
      .eq('enabled', true)
      .eq('type.id', 'userInterfaceNoteType-2')
      .ge('publishDate', startDate)
      .lt('publishDate', endDate)
      .getQueryString();

    const url = `/work/uinotes/${query}`;
    const data = yield call(handleNextHeaders, url);

    const payload = yield call(updateIndexDb, { data });

    yield put({ type: BANNERS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: BANNERS_REJECTED, error });

    return error;
  }
}
