import { TYPE_STRING } from '@metrichor/jmespath-plus';
/**
 * Javascript substr function
 * Usegage: substr(<<PATH TO VALUE>>, start, length )
 *
 * @param  {Array} resolvedArgs Array of resolved args
 *
 * @return {String} str Substring of the given string
 */
export function substr(resolvedArgs) {
  const [str = '', start = 0, length] = resolvedArgs;

  return str.substr(start, length);
}

export default {
  key: 'substr',
  func: substr,
  types: [{ types: [TYPE_STRING] }, { types: [TYPE_STRING] }, { types: [TYPE_STRING] }]
};
