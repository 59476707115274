/**
 * Check if the given maritial status code is married or registered partnership
 *
 * @param  {String} id Code id
 *
 * @return {Boolean}
 */
export default function maritalStatusIsTogetherCheck(id) {
  return ['customerMaritalStatus-10', 'customerMaritalStatus-2'].includes(id);
}
