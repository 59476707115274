import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import './ContractListItem.css';

const ContractListItem = React.memo(({
  codes,
  customers,
  data,
  language
}) => {
  const {
    contractStatus,
    contractType
  } = data;
  const customer = get(customers, 'data', []).find((c) => get(c, 'id') === get(data, 'clientKey', '').toString());
  const status = get(codes, 'groups.contractStatus', []).find((c) => get(c, 'number') === contractStatus.toString());
  const type = get(codes, 'groups.contractType', []).find((c) => get(c, 'number') === contractType.toString());

  return (
    <Row className="securities-account-unlock-contract-list-item">
      <Col lg={5} md={5} sm={12}>
        <FormattedMessage id="SecuritiesAccount.Unlock.Form.Customer" tagName="strong" />
        <p>
          {get(customer, 'designation', '')}
          {' '}
          {`(${get(customer, 'customerNumberAlternate')})`}
        </p>
      </Col>

      <Col lg={5} md={5} sm={12}>
        <FormattedMessage id="SecuritiesAccount.Unlock.Form.Documents" tagName="strong" />
        <p>
          {get(type, `text.${language}`)}
          {` (${contractType})`}
        </p>
      </Col>

      <Col lg={2} md={2} sm={12}>
        <FormattedMessage id="SecuritiesAccount.Unlock.Form.Status" tagName="strong" />
        <p>
          {get(status, `text.${language}`, '')}
        </p>
      </Col>
    </Row>
  );
});

ContractListItem.propTypes = {
  codes: PropTypes.object,
  customers: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string
};

ContractListItem.defaultProps = {
  codes: { groups: {} },
  customers: {},
  data: {},
  language: 'de'
};

export default ContractListItem;
