import { Actions } from '@evoja-web/redaction';

import { authorizationsAction } from './Authorizations';
import { cancelOpeningAction } from './CancelOpening';
import { checkExistingOpeningAction } from './CheckExistingOpening';
import { contractsAction } from './Contracts';
import { customersAction } from './Customers';
import { fundsAction } from './Funds';
import { openingFormAction } from './OpeningForm';
import { paymentAccountsAction } from './PaymentAccounts';
import { portfoliosAction } from './Portfolios';
import { productsAction } from './Products';
import { qiDeclarationAction } from './QiDeclaration';
import { unlockFormAction } from './UnlockForm';
import { workbenchRecordAction } from './WorkbenchRecord';

export const actions = Actions([
  authorizationsAction,
  cancelOpeningAction,
  checkExistingOpeningAction,
  contractsAction,
  customersAction,
  fundsAction,
  openingFormAction,
  paymentAccountsAction,
  portfoliosAction,
  productsAction,
  qiDeclarationAction,
  unlockFormAction,
  workbenchRecordAction
]);

export default actions.get();
