import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, DropdownItem, FormGroup, Label, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';

import './FeatureFlagMenuItem.css';
import { get } from 'lodash';

class FeatureFlagMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDescriptionOpen: false
    };

    this.toggleDescription = this.toggleDescription.bind(this);
  }

  toggleDescription(ev) {
    const { isDescriptionOpen } = this.state;

    ev.preventDefault();
    ev.stopPropagation();

    this.setState({ isDescriptionOpen: !isDescriptionOpen });
  }

  render() {
    const { isDescriptionOpen } = this.state;
    const { featureFlag, language } = this.props;

    return (
      <DropdownItem
        toggle={false}
        className="feature-flag-menu-item"
      >
        <FormGroup className="feature-flag-menu-item--form-group">
          <Row onClick={this.toggleDescription}>
            <Col md={10}>
              <i
                className={`mdi feature-flag-status-icon ${get(featureFlag, 'active') ? 'mdi-check-circle-outline' : 'mdi-minus-circle-outline'}`}
                onClick={this.onClear}
              />
              <Label check>
                {get(featureFlag, `name.${language}`)}
                {' '}
                (
                {get(featureFlag, 'identifier')}
                )
              </Label>
            </Col>
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 0 }}>
              <span
                className={`mdi ${isDescriptionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'} feature-flag-menu-item--actions`}
                onClick={this.toggleDescription}
              />
            </Col>
          </Row>
          <Collapse isOpen={isDescriptionOpen}>
            <div className="feature-flag-menu-item-collapse-description--divider" />
            <span className="feature-flag-menu-item-collapse-description--span">
              {get(featureFlag, `description.${language}`)}
            </span>
          </Collapse>
        </FormGroup>
      </DropdownItem>
    );
  }
}

FeatureFlagMenuItem.propTypes = {
  featureFlag: PropTypes.object,
  language: PropTypes.string
};

FeatureFlagMenuItem.defaultProps = {
  featureFlag: {},
  language: 'de'
};

export default injectIntl(FeatureFlagMenuItem);
