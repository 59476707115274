import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Collapse, DropdownItem } from 'reactstrap';
import { get, isNil } from 'lodash';

import './UiTheme.css';

const themes = [{
  id: 'default',
  title: <FormattedMessage id="General.UiTheme.Default.Title" />,
  classNames: ['theme--default']
}, {
  id: 'relaxingBlue',
  title: <FormattedMessage id="General.UiTheme.RelaxingBlue.Title" />,
  classNames: ['theme--relaxing-blue']
}, {
  id: 'refreshingLime',
  title: <FormattedMessage id="General.UiTheme.RefreshingLime.Title" />,
  classNames: ['theme--refreshing-lime']
}, {
  id: 'inspiringSunset',
  title: <FormattedMessage id="General.UiTheme.InspiringSunset.Title" />,
  classNames: ['theme--inspiring-sunset']
}, {
  id: 'bananaParty',
  title: <FormattedMessage id="General.UiTheme.BananaParty.Title" />,
  classNames: ['theme--banana-party']
}, {
  id: 'oldSchool',
  title: <FormattedMessage id="General.UiTheme.OldSchool.Title" />,
  classNames: ['theme--old-school']
}, {
  id: 'showMoar',
  title: <FormattedMessage id="General.UiTheme.ShowMoar.Title" />,
  classNames: ['theme--show-moar']
}];

/**
   * Remove current theme classes and add new
   *
   * @return  void
   */
function onThemeChange(theme) {
  const { classNames } = theme;

  Array.from(document.body.classList)
    .filter((c) => c.startsWith('theme--'))
    .forEach((c) => document.body.classList.remove(c));

  classNames.forEach((c) => document.body.classList.add(c));

  localStorage.setItem('selectedTheme', theme.id);
}

class UiTheme extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true
    };

    const selectedTheme = localStorage.getItem('selectedTheme');
    if (!isNil(selectedTheme)) {
      const theme = themes.find((t) => t.id === selectedTheme);
      onThemeChange(theme);
    }

    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * Expand / collapse themes
   *
   * @return  void
   */
  onToggle() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { collapsed } = this.state;

    const children = themes.map((theme) => {
      const { title } = theme;

      return (
        <div
          className="layout-header-user-profile--dropdown--themes--theme"
          onClick={() => onThemeChange(theme)}
          key={get(theme, 'id')}
        >
          {title}
        </div>
      );
    });

    return (
      <>
        <DropdownItem
          onClick={this.onToggle}
          toggle={false}
        >
          <span className="mdi mdi-theme-light-dark" />
          <FormattedMessage id="General.Header.Dropdown.UiTheme" />
        </DropdownItem>

        <Collapse isOpen={!collapsed}>
          {children}
        </Collapse>
      </>
    );
  }
}

export default UiTheme;
