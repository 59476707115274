import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import contractActions from '../../actions/SetAdvisor/Contract';

const {
  CONTRACTS_PENDING,
  CONTRACTS_FULFILLED,
  CONTRACTS_REJECTED
} = contractActions;

export default function* getContracts(request) {
  const { customerId } = request;

  yield put({ type: CONTRACTS_PENDING });
  try {
    const payload = yield call(handleNextHeaders, `/entity/contract/?eq(customer.id,string:${customerId})&limit(9999)`);

    yield put({ type: CONTRACTS_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: CONTRACTS_REJECTED, error });

    return error;
  }
}
