import { call } from 'redux-saga/effects';

import getAllWorkguides from '../../packages/Workguide/sagas/Workguide/getAllWorkguides';
import getActiveWorkguides from '../../packages/Workguide/sagas/Workguide/getActiveWorkguides';

export default function* bootstrapWorkguides() {
  const allWorkguides = yield call(getAllWorkguides);
  const activeWorkguies = yield call(getActiveWorkguides);

  return {
    allWorkguides,
    activeWorkguies
  };
}
