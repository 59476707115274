import { call, put } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get, uniq, isArray } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import customersAction from '../actions/Customers';

const {
  CUSTOMERS_PENDING,
  CUSTOMERS_FULFILLED,
  CUSTOMERS_REJECTED
} = customersAction;

export default function* getCustomers(request) {
  yield put({ type: CUSTOMERS_PENDING });

  try {
    const { customerIds } = request;
    const query = QueryBuilder()
      .in('id', uniq(customerIds), { type: 'string' })
      .getQueryString();

    const url = `/person/customer/${query}`;
    const payload = !isArray(customerIds) || get(customerIds, 'length', 0) === 0
      ? []
      : yield call(handleNextHeaders, url);

    yield put({ type: CUSTOMERS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMERS_REJECTED, error });

    return error;
  }
}
