import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import fetchLatestReleaseNote from '../../packages/ReleaseNote/sagas/fetchLatestReleaseNote';

export default function* bootstrapReleaseNotes() {
  const { fulfilled = false, data = {} } = yield select((state) => get(state, 'releaseNote.fetchLatestReleaseNote', {}));

  if (fulfilled) return data;

  return yield call(fetchLatestReleaseNote);
}
