import update from 'immutability-helper';
import { DefaultReducer } from '@evoja-web/redaction';
import { get } from 'lodash';

import action, { outlookCalendarAndBirthdayAction } from '../actions/OutlookCalendarAndBirthday';

const { OUTLOOK_CALENDAR_AND_BIRTHDAYS_REJECTED } = action;

function onOutlookCalendarAndBirthdayRejected(state, action) {
  return update(state, {
    outlookCalendarAndBirthday: {
      fulfilled: { $set: true },
      data: { $set: get(action, 'payload', {}) }
    }
  });
}

export const reducer = DefaultReducer('outlookCalendarAndBirthday', outlookCalendarAndBirthdayAction);
reducer.after(OUTLOOK_CALENDAR_AND_BIRTHDAYS_REJECTED, onOutlookCalendarAndBirthdayRejected);

export default reducer.get();
