import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { get, has, isEqual, isNil, noop, sum, values } from 'lodash';
import { Col, Row } from 'reactstrap';
import update from 'immutability-helper';
import { withCodeGroups } from '@evoja-web/entity-code';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BeatLoader, InputField, Select } from '../../../General';
import QIDeclaration from '../../components/DepotOpening/QIDeclaration';
import securitiesAccountActions from '../../../BpfSecuritiesAccount/actions/Actions';

function getInvestmentStrategyOptions(intl) {
  return [
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.InvestmentStrategy.Strategies.ValuePreserving' }), value: 1 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.InvestmentStrategy.Strategies.Conservative' }), value: 10 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.InvestmentStrategy.Strategies.Balanced' }), value: 3 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.InvestmentStrategy.Strategies.Dynamic' }), value: 9 },
    { label: intl.formatMessage({ id: 'Provision.Form.DepotOpening.InvestmentStrategy.Strategies.CapitalGainsOriented' }), value: 5 }
  ];
}

function getFundSelectOptions() {
  return [
    { label: '0%', value: 0 },
    { label: '25%', value: 25 },
    { label: '30%', value: 30 },
    { label: '35%', value: 35 },
    { label: '40%', value: 40 },
    { label: '45%', value: 45 },
    { label: '50%', value: 50 },
    { label: '55%', value: 55 },
    { label: '60%', value: 60 },
    { label: '65%', value: 65 },
    { label: '70%', value: 70 },
    { label: '75%', value: 75 },
    { label: '80%', value: 80 },
    { label: '85%', value: 85 },
    { label: '90%', value: 90 },
    { label: '95%', value: 95 },
    { label: '100%', value: 100 },
  ];
}

class FundSelection extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeFondSelection = this.onChangeFondSelection.bind(this);

    const { customer, securitiesAccountActions } = props;

    securitiesAccountActions.qiDeclarationRequest({ dataKey: get(customer, 'id') });
  }

  componentDidMount() {
    this.loadQiDeclaration();
  }

  componentDidUpdate(prevProps) {
    const { customer } = this.props;
    const { customer: prevCustomer } = prevProps;

    if (!isEqual(get(customer, 'id'), get(prevCustomer, 'id'))) { this.loadQiDeclaration(); }
  }

  onChangeFondSelection(id, value) {
    const { data, onChange } = this.props;
    const updatedFunds = update(get(data, 'funds', {}), {
      [id]: { $set: value }
    });

    onChange('funds', updatedFunds);
    onChange('fundsTotal', sum(values(updatedFunds)));
  }

  loadQiDeclaration() {
    const { customer, securitiesAccountActions, qiDeclaration } = this.props;

    if (isNil(qiDeclaration)) {
      securitiesAccountActions.qiDeclarationRequest({ dataKey: get(customer, 'id') });
    }
  }

  /**
   * Check if we have to render the qi declaration form for the given customer or its partners
   *
   * @param  {Object} validations Validation result
   *
   * @return {ReactElement} markup
   */
  renderQiDeclaration(validations) {
    const {
      codes,
      data,
      onChange,
      language,
      qiDeclaration
    } = this.props;

    if (has(qiDeclaration, 'data.resultVariables.contract')) return null;

    if (get(qiDeclaration, 'requesting')) return <BeatLoader />;

    return (
      <QIDeclaration
        codes={codes}
        data={data}
        language={language}
        onChange={onChange}
        validations={validations}
      />
    );
  }

  renderFondElements() {
    const { data, language, codes } = this.props;

    const pensionFundCodes = get(codes, 'groups.pensionFund', []);

    const fundSelectOptions = getFundSelectOptions();

    return pensionFundCodes.map((fund) => {
      return (
        <Row className="provision-depot-opening--space--top">
          <Col md={2}>
            <Select
              id={get(fund, 'number')}
              options={fundSelectOptions}
              value={get(data, `funds.${get(fund, 'number')}`, 0)}
              onChange={this.onChangeFondSelection}
            />
          </Col>
          <Col md={10} className="provision-depot-opening--label--center">
            <span>{get(fund, `text.${language}`)}</span>
          </Col>
        </Row>
      );
    });
  }

  render() {
    const {
      intl, data, onChange, validations, showValidations
    } = this.props;

    return (
      <>
        <Row className="provision-depot-opening--space--top-large">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.InvestmentStrategy.Title" />
          </Col>
          <Col md={3}>
            <Select
              id="investmentObjective"
              options={getInvestmentStrategyOptions(intl)}
              value={get(data, 'investmentObjective')}
              onChange={onChange}
              validations={get(validations, 'investmentObjective')}
              showValidations={showValidations}
            />
          </Col>
        </Row>
        {this.renderQiDeclaration()}
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.FundSelection.Title" tagName="h3" />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.FundSelection.Select2Valor" tagName="span" />
          </Col>
        </Row>
        {this.renderFondElements()}
        <Row className="provision-depot-opening--space--top">
          <Col md={2}>
            <InputField
              onChange={noop}
              value={`${get(data, 'fundsTotal', 0)}%`}
              validations={get(validations, 'fundsTotal')}
              showValidations={showValidations}
            />
          </Col>
          <Col className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.FundSelection.Total" />
          </Col>
        </Row>
      </>
    );
  }
}

FundSelection.propTypes = {
  validations: PropTypes.object.isRequired,
  securitiesAccountActions: PropTypes.object.isRequired,
  qiDeclaration: PropTypes.object,
  intl: PropTypes.object.isRequired,
  codes: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  showValidations: PropTypes.bool,
  language: PropTypes.string,
  customer: PropTypes.object
};

FundSelection.defaultProps = {
  codes: { groups: {} },
  qiDeclaration: {},
  data: {},
  onChange: noop,
  showValidations: true,
  language: 'de',
  customer: {}
};

function mapStateToProps(state, ownProps) {
  const { customer } = ownProps;

  return {
    qiDeclaration: get(state, `securitiesAccount.qiDeclaration.${get(customer, 'id')}`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    securitiesAccountActions: bindActionCreators(securitiesAccountActions, dispatch)
  };
}

export default withCodeGroups({ groups: ['pensionFund', 'country'] })(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(FundSelection)
  )
);
