import { Reducers } from '@evoja-web/redaction';

import { reducer as customerFinancingCollateralReducer } from './Customer/FinancingCollateral';
import { reducer as customerFinancingTrancheReducer } from './Customer/FinancingTranche';
import { reducer as consultationFormReducer } from './ConsultationForm';
import { reducer as edossierUploadReducer } from './EdossierUpload';

export const reducers = Reducers([
  customerFinancingCollateralReducer,
  customerFinancingTrancheReducer,
  consultationFormReducer,
  edossierUploadReducer
]);

export default reducers.get();
