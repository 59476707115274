import moment from 'moment';
import { get } from 'lodash';

/**
 * Get the expire date based on the given dueDate and orderOrigin
 * Return the current value if the given dueDate is not valid or the given orderOrigin is not a task or potential
 *
 * @return  {String} expireDate Expire date
 */
export default function getExpireDateByDueDate({
  dueDate,
  expireDate,
  mapper,
  orderOrigin
}) {
  // Return current value if dueDate is not a valid date
  if (!moment(dueDate, moment.ISO_8601, true).isValid()) {
    return expireDate;
  }

  return mapper.getCombined('leadCategory', ['codeMapping-task', 'codeMapping-potential']).includes(get(orderOrigin, 'id'))
    // Add 1 month to the dueDate if orderOrigin in a task or potential
    ? moment(dueDate).startOf('day').add(1, 'months').format()
    // expireDate must not before dueDate
    : moment(expireDate).isBefore(moment(dueDate))
      ? dueDate
      : expireDate;
}
