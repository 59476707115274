import { TYPE_STRING, TYPE_ARRAY, TYPE_NUMBER, TYPE_NULL } from '@metrichor/jmespath-plus';
import { get, isNull } from 'lodash';

/**
 * Try to find the code id by the given text
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} id Code id
 */
export function codeIdByNumber(resolvedArgs) {
  let [codes = [], number = '', language = 'de'] = resolvedArgs;

  if (isNull(codes)) codes = [];
  if (isNull(number)) number = '';
  if (isNull(language)) language = 'de';

  // eslint-disable-next-line
  const code = codes.find((c) => get(c, 'number') == number);

  return get(code, 'id');
}

export default {
  key: 'codeIdByNumber',
  func: codeIdByNumber,
  types: [{ types: [TYPE_ARRAY, TYPE_NULL] }, { types: [TYPE_STRING, TYPE_NUMBER, TYPE_NULL] }]
};
