import { put } from 'redux-saga/effects';

import permissionsAction from '../../actions/Acl/Permissions';

import { permissions } from '../../fakes/acl';

const {
  PERMISSIONS_PENDING,
  PERMISSIONS_FULFILLED,
  PERMISSIONS_REJECTED
} = permissionsAction;

export default function* getPermissions() {
  yield put({ type: PERMISSIONS_PENDING });

  try {
    yield put({ type: PERMISSIONS_FULFILLED, payload: permissions });

    return permissions;
  } catch (error) {
    yield put({ type: PERMISSIONS_REJECTED, error });

    return error;
  }
}
