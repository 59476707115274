import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';

import './Fund.css';
import { Select } from '../../../../General/index';

const Fund = React.memo(({
  fund,
  language,
  onChange,
  value
}) => {
  const { number } = fund;

  function getOptions() {
    const options = [{
      value: 0,
      label: '0%'
    }];
    for (let i = 20; i <= 100; i += 5) {
      options.push({
        value: i,
        label: `${i}%`
      });
    }
    return options;
  }

  return (
    <div className="securities-account-opening-fund">
      <div className="securities-account-opening-fund--input">
        <Select
          id={number}
          value={value}
          options={getOptions()}
          onChange={onChange}
        />
      </div>

      <div className="securities-account-opening-fund--title">
        {get(fund, `text.${language}`)}
        ,
        {'  Valor '}
        {number}
      </div>
    </div>
  );
});

Fund.propTypes = {
  fund: PropTypes.object.isRequired,
  language: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number
};

Fund.defaultProps = {
  language: 'de',
  onChange: noop,
  value: 0
};

export default Fund;
