import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

class Img extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imgUrl: URL.createObjectURL(get(props, 'blob'))
    };
  }

  componentWillUnmount() {
    const { imgUrl } = this.state;

    URL.revokeObjectURL(imgUrl);
  }

  render() {
    const { imgUrl } = this.state;
    const { blob, ...rest } = this.props;

    return <img alt="foo" {...rest} src={imgUrl} />;
  }
}

Img.propTypes = {
  blob: PropTypes.object.isRequired,
};

export default Img;
