import moment from 'moment';
import { set } from 'lodash';

import AxiosRequestInterceptor from './Proto';

export default function RequestStartTimeInterceptor() {
  function onResolve(config) {
    set(config, 'metadata.startTime', moment().format());

    return config;
  }

  return AxiosRequestInterceptor({ onResolve });
}
