import { put } from 'redux-saga/effects';

import temporaryUserRolesAction from '../../actions/Acl/TemporaryUserRoles';

const {
  TEMPORARY_USER_ROLES_PENDING,
  TEMPORARY_USER_ROLES_FULFILLED,
  TEMPORARY_USER_ROLES_REJECTED
} = temporaryUserRolesAction;

export default function* setTemporaryUserRoles(request) {
  yield put({ type: TEMPORARY_USER_ROLES_PENDING });

  try {
    const { roles } = request;

    yield put({ type: TEMPORARY_USER_ROLES_FULFILLED, payload: roles });

    return roles;
  } catch (error) {
    yield put({ type: TEMPORARY_USER_ROLES_REJECTED, error });

    return error;
  }
}
