import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Redirect, Route } from 'react-router';
import UserSwitch from '../../containers/UserSwitch';

import Bootstrap from '../Bootstrap/Bootstrap';

const UserSwitchRoute = ({
  component,
  path,
  login,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        return login.session
          ? (
            <Bootstrap path={path}>
              <Suspense fallback={<div />}>
                <UserSwitch component={component} {...props} />
              </Suspense>
            </Bootstrap>
          )
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: get(props, 'location') }
              }}
            />
          );
      }}
    />
  );
};

UserSwitchRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  login: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    login: state.login
  };
}

export default connect(mapStateToProps)(UserSwitchRoute);
