import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import profileCompletionAction from '../../actions/ProfileCompletion/Company';

const {
  PROFILE_COMPLETION_COMPANY_PENDING,
  PROFILE_COMPLETION_COMPANY_FULFILLED,
  PROFILE_COMPLETION_COMPANY_REJECTED
} = profileCompletionAction;

export default function* getProfileCompletion(request) {
  const { customerId } = request;

  yield put({ type: PROFILE_COMPLETION_COMPANY_PENDING });
  try {
    const url = `analytics/customer-profile-completion?customerId=${customerId}`;
    const data = yield call(handleNextHeaders, { url, noCache: true });

    const payload = get(data, 0, {});

    yield put({ type: PROFILE_COMPLETION_COMPANY_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PROFILE_COMPLETION_COMPANY_REJECTED, error });

    return error;
  }
}
