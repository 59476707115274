import { TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import moment from 'moment';
import { isNull } from 'lodash';

/**
 * Subtract the given amount of <<day, month, year>> to the given date.
 * @see: https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/02-subtract/
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} formatted Formatted number
 */
export function dateStringDiff(resolvedArgs = []) {
  const [ds1, ds2, key = 'days'] = resolvedArgs;

  if (isNull(ds1) || isNull(ds2)) return 0;

  const date1 = moment(ds1);
  const date2 = moment(ds2);

  if (!date1.isValid() || !date2.isValid()) return 0;

  return date1.diff(date2, key);
}

export default {
  key: 'dateStringDiff',
  func: dateStringDiff,
  types: [
    { types: [TYPE_STRING, TYPE_NULL] },
    { types: [TYPE_STRING, TYPE_NULL] },
    { types: [TYPE_STRING], optional: true }
  ]
};
