import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import './MenuFooter.css';
import { withModalHandler } from '@evoja-web/react-modal';
import { MODAL_IDENTIFIER as MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL } from '../../../../../Activity/containers/ActivitiesOverviewModal';

const ShowMoreLabel = React.memo(({
  clearValue,
  history,
  selectProps,
  modalHandler
}) => {
  const onShowMore = get(selectProps, 'onShowMore', noop);

  return (
    <div className="layout-header-powersearch--menu-footer">
      <div
        className="layout-header-powersearch--menu-footer--link react-anchor layout-header-powersearch--menu-footer--link--showMore"
        onClick={onShowMore}
      >
        <FormattedMessage id="CustomerWorkguideSearch.Menu.ShowMore" />
      </div>

      <div
        className="layout-header-powersearch--menu-footer--link react-anchor"
        onClick={() => {
          modalHandler.show({
            id: MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL,
            componentProps: {
              onCancel: () => modalHandler.hide(MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL),
            },
            modalProps: {
              size: 'lg',
              className: 'activity-overview-modal'
            }
          });

          clearValue();
          selectProps.selectRef.current.blur();
        }}
      >
        <FormattedMessage id="CustomerWorkguideSearch.Menu.AllWorkguides" />
      </div>
    </div>
  );
});

ShowMoreLabel.propTypes = {
  clearValue: PropTypes.func,
  history: PropTypes.object.isRequired,
  selectProps: PropTypes.object,
  modalHandler: PropTypes.object.isRequired
};

ShowMoreLabel.defaultProps = {
  clearValue: noop,
  selectProps: {}
};

export default withRouter(withModalHandler()(ShowMoreLabel));
