import { Acl } from '@evoja-web/client-acl';
import { TYPE_STRING } from '@metrichor/jmespath-plus';
import { getValueFromStore } from '../../../Store';

export const hasPermission = (resolvedArgs) => {
  const [permission = ''] = resolvedArgs;
  const permissions = getValueFromStore('acl.permissions.data', []);
  const userRoles = getValueFromStore('acl.userRoles.data', []);
  const session = getValueFromStore('login.session');
  const temporaryUserRoles = getValueFromStore('acl.temporaryUserRoles.data', []);
  const acl = Acl(session, permissions, userRoles);

  if (temporaryUserRoles.length > 0) {
    acl.setTemporaryUserRoles(temporaryUserRoles);
  }

  return acl.isAllowed(permission);
};

export default {
  key: 'hasPermission',
  func: hasPermission,
  types: [{ types: [TYPE_STRING] }]
};
