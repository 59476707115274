import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import actions from '../../actions/UserSettings';

const {
  SAVE_USERSETTINGS_PENDING,
  SAVE_USERSETTINGS_FULFILLED,
  SAVE_USERSETTINGS_REJECTED
} = actions;

export default function* saveUserSettings(request) {
  const { userId, settings } = request;
  yield put({ type: SAVE_USERSETTINGS_PENDING });

  try {
    yield call(axios, {
      url: `/person/usersettings/${userId}`,
      method: 'put',
      data: settings
    });

    yield put({ type: SAVE_USERSETTINGS_FULFILLED, payload: settings });

    return {
      userId,
      settings
    };
  } catch (error) {
    yield put({ type: SAVE_USERSETTINGS_REJECTED, error });

    return error;
  }
}
