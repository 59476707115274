import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, upperFirst } from 'lodash';
import { Col, Row } from 'reactstrap';

import { connect } from 'react-redux';
import moment from 'moment';
import { CustomerDetail } from '../../../../General/index';
import { withWorkguideInstanceData } from '../../../../Workguide/index';

const CustomerInfo = React.memo(({
  bpfTask, codes, language, customer
}) => {
  const documentTyp = get(codes, 'groups.documentIdType', []).find(
    (c) => Number(c.number) === get(bpfTask, 'data.process.documentTypeCode', 0)
  );
  function getCountryText(country) {
    const code = get(codes, 'groups.country', []).find((c) => get(c, `textShort.${language}`) === country);

    return get(code, `text.${language}`, '-');
  }
  const birthDate = moment(get(bpfTask, 'data.process.customerInformation.personalInformation.dateOfBirth')).format('DD.MM.YYYY');

  return (
    <div>
      <Row style={{ paddingTop: '25px' }}>
        <Col lg={12}>
          <h5>
            <FormattedMessage id="Onboarding.CustomerInfo.Title" />
          </h5>
          <hr />
        </Col>
      </Row>

      <CustomerDetail id="Onboarding.CustomerInfo.PartnerNr" value={customer.customerNumberAlternate} />

      <CustomerDetail
        id="Onboarding.CustomerInfo.FirstLastName"
        value={`${get(bpfTask, 'data.process.customerInformation.personalInformation.firstName', '-')} ${get(
          bpfTask,
          'data.process.customerInformation.personalInformation.lastName',
          '-'
        )}`}
      />

      <CustomerDetail
        id="Onboarding.CustomerInfo.DateOfBirth"
        value={birthDate}
      />

      <CustomerDetail
        id="Onboarding.CustomerInfo.Address"
        value={`${get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.street', '-')} ${get(
          bpfTask,
          'data.process.customerInformation.addressInformation.domicileAddress.houseNumber',
          ''
        )}, ${get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.postcode', '-')} ${get(
          bpfTask,
          'data.process.customerInformation.addressInformation.domicileAddress.place',
          '-'
        )}`}
      />

      <CustomerDetail
        id="Onboarding.CustomerInfo.Country"
        value={getCountryText(get(bpfTask, 'data.process.customerInformation.addressInformation.domicileAddress.country'))}
      />

      <CustomerDetail
        id="Onboarding.CustomerInfo.Nationality"
        value={getCountryText(get(bpfTask, 'data.process.customerInformation.personalInformation.nationality'))}
      />

      <CustomerDetail
        id="Onboarding.CustomerInfo.MobileNr"
        value={get(bpfTask, 'data.process.customerInformation.contactInformation.mobileNr')}
      />

      <CustomerDetail id="Onboarding.CustomerInfo.Mail" value={get(bpfTask, 'data.process.customerInformation.contactInformation.mail')} />

      <CustomerDetail id="Onboarding.CustomerInfo.Product" value={get(bpfTask, `data.process.productDescription${upperFirst(language)}`, '-')} />

      <Row style={{ paddingTop: '10px', paddingBottom: '25px' }}>
        <Col sm={4}>
          <p>
            <FormattedMessage id="Onboarding.CustomerInfo.Identification.Title" />
          </p>
        </Col>
        <Col sm={8}>
          <CustomerDetail id="Onboarding.CustomerInfo.Identification.DocumentTyp" value={get(documentTyp, `text.${language}`)} />

          <CustomerDetail
            id="Onboarding.CustomerInfo.Identification.CountryOfIssue"
            value={getCountryText(get(bpfTask, 'data.process.countryKey'))}
          />
        </Col>
      </Row>
    </div>
  );
});

CustomerInfo.propTypes = {
  bpfTask: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  language: PropTypes.string
};

CustomerInfo.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  customer: {}
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CustomerInfo)
  )
);
