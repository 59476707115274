import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getCustomerFinancingCollateral from './Customer/getFinancingCollateral';
import getCustomerFinancingTranche from './Customer/getFinancingTranche';
import getConsultationFormData from './getConsultationFormData';
import uploadToEdossier from './uploadToEdossier';

const {
  CUSTOMER_FINANCING_COLLATERAL_REQUEST,
  CUSTOMER_FINANCING_TRANCHE_REQUEST,
  CONSULTATION_FORM_REQUEST,
  CONSULTATION_FORM_EDOSSIER_UPLOAD_REQUEST
} = actions;

export default function* consultationFormRootSaga() {
  yield takeLatest(CUSTOMER_FINANCING_COLLATERAL_REQUEST, getCustomerFinancingCollateral);
  yield takeLatest(CUSTOMER_FINANCING_TRANCHE_REQUEST, getCustomerFinancingTranche);
  yield takeLatest(CONSULTATION_FORM_REQUEST, getConsultationFormData);
  yield takeLatest(CONSULTATION_FORM_EDOSSIER_UPLOAD_REQUEST, uploadToEdossier);
}
