import { all, call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import kpiAction from '../../actions/Kpi/Kpi';

const {
  KPI_PENDING,
  KPI_FULFILLED,
  KPI_REJECTED
} = kpiAction;

/**
 * Get data from /customer/kpi
 *
 * @param  {Object} request Action request
 *
 * @return {Generator}
 */
function* getCustomerKpi(request) {
  const { customerId } = request;

  const query = QueryBuilder()
    .eq('id', customerId, { type: 'string' })
    .select(['id', 'dbiiRollingLast12Month'])
    .getQueryString();

  const url = `/customer/kpi/${query}`;

  const data = yield call(handleNextHeaders, url);

  return get(data, 0, {});
}

/**
 * Get data from /person/regulatoryclassification
 *
 * @param  {Object} request Action request
 *
 * @return {Generator}
 */
function* getCustomerBalance(request) {
  const { customerId } = request;

  const query = QueryBuilder()
    .eq('customerId', customerId, { type: 'string' })
    .in('balanceSubType.id', ['assetType-4930', 'assetType-4931', 'assetType-4932', 'assetType-4933', 'liabilityType-4934'])
    .select(['id', 'amountDue', 'amount', 'balanceSubType', 'valueCurrencyIso'])
    .getQueryString();

  const url = `/person/customer-balance/${query}`;

  const data = yield call(handleNextHeaders, url);

  return data;
}

export default function* getKpi(request) {
  yield put({ type: KPI_PENDING });

  try {
    const [kpi, balance] = yield all([
      call(getCustomerKpi, request),
      call(getCustomerBalance, request)
    ]);

    const payload = {
      dbiiRollingLast12Month: get(kpi, 'dbiiRollingLast12Month'),
      payment: balance.find((b) => get(b, 'balanceSubType.id') === 'assetType-4930'),
      financing: balance.find((b) => get(b, 'balanceSubType.id') === 'liabilityType-4934'),
      investment: balance.find((b) => get(b, 'balanceSubType.id') === 'assetType-4932'),
      saving: balance.find((b) => get(b, 'balanceSubType.id') === 'assetType-4931'),
      provision: balance.find((b) => get(b, 'balanceSubType.id') === 'assetType-4933'),
    };

    yield put({ type: KPI_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: KPI_REJECTED, error });

    return error;
  }
}
