import { takeLatest } from 'redux-saga/effects';

import releaseNotesActions from '../actions/Actions';

import fetchReleaseNotes from './fetchReleaseNotes';

const { RELEASE_NOTES_REQUEST } = releaseNotesActions;

export default function* relationRootSaga() {
  yield takeLatest(RELEASE_NOTES_REQUEST, fetchReleaseNotes);
}
