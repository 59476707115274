import { put, call, all } from 'redux-saga/effects';
import { get, isEmpty, isNil } from 'lodash';

import { removeHost } from '@evoja-web/redux-saga-utils';
import { createIntl } from 'react-intl';
import actions from '../../actions/Actions';
import SpsCoverLetter from '../../../../components/Pdf/Letters/SpsCoverLetter';
import SpsReplyEnvelopeLetter from '../../../../components/Pdf/Letters/SpsReplyEnvelopeLetter';
import { generatePdf } from '../../../../lib/Pdf/Utils';
import mergeDocuments from '../../../File/sagas/mergeDocuments';

// Intl stuff
import messages from '../../../../intl/messages';
import { flattenMessages } from '../../../../utils';
import prepareCoverLetterDescription from '../../utils/prepareCoverLetterDescription';
import uploadFiles from '../uploadFiles';

const {
  CREATE_COVER_LETTER_FORM_SAVE_PENDING,
  CREATE_COVER_LETTER_FORM_SAVE_FULFILLED,
  CREATE_COVER_LETTER_FORM_SAVE_REJECTED
} = actions;

export default function* saveForm(request) {
  const {
    customer, data, documents, consultant
  } = request;

  yield put({ type: CREATE_COVER_LETTER_FORM_SAVE_PENDING });

  try {
    // Prepare Description for Cover Letter
    const description = get(data, 'description');
    if (!isNil(description) && !isEmpty(description)) {
      data.descriptionHtmlElement = prepareCoverLetterDescription({ description });
    }

    const filesToUpload = [];

    const language = get(customer, 'languageIso', 'de');
    const intl = createIntl({
      locale: language,
      messages: flattenMessages(messages[language])
    });

    // Cover Letter generation
    const spsCoverLetter = SpsCoverLetter({ customer, formData: data, language, intl, consultant });
    const spsCoverLetterResult = yield call(generatePdf, spsCoverLetter);
    filesToUpload.push(get(spsCoverLetterResult, 'blob'));

    // // TODO Remove (only for Test in Code)
    // const url = `/preview/pdf/${window.btoa(spsCoverLetterResult.url)}`;
    // window.open(url, '_blank');

    // Repley Letter generation
    if (get(data, 'replyEnvelope')) {
      const spsReplyEnvelopeLetter = SpsReplyEnvelopeLetter({ customer, intl });
      const spsReplyEnvelopeLetterResult = yield call(generatePdf, spsReplyEnvelopeLetter);
      filesToUpload.push(get(spsReplyEnvelopeLetterResult, 'blob'));

      // // TODO Remove (only for Test in Code)
      // const url = `/preview/pdf/${window.btoa(spsReplyEnvelopeLetterResult.url)}`;
      // window.open(url, '_blank');
    }

    // Upload generated Files
    const files = yield call(uploadFiles, filesToUpload);
    // Generate Merged CoverLetter and Documents evtl. with Reply Letter, to one big PDF
    const documentIds = documents.map((doc) => {
      const path = removeHost(get(doc, 'docLink', ''));
      const gravtionUrlReplace = path.replace(/^\/graviton\/api\/file\//, '');
      const gravitonAndFileReplace = gravtionUrlReplace.replace(/\/file\//, '');
      return gravitonAndFileReplace;
    });

    // create Array with FileIds of Files to merge.
    const documentIdsToMerge = [get(files, '0.file').getId(), ...documentIds];
    if (get(files, 'length') > 1 && get(data, 'replyEnvelope')) documentIdsToMerge.push(get(files, '1.file').getId()); // Adds Reply Letter

    yield call(mergeDocuments, { fileIds: documentIdsToMerge, withDocumentValidation: true });

    yield put({ type: CREATE_COVER_LETTER_FORM_SAVE_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: CREATE_COVER_LETTER_FORM_SAVE_REJECTED, error });

    return error;
  }
}
