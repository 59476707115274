import { ReleaseActionConfig } from '@evoja-web/react-application-release';

import DestroyTarzanInstances from './lib/ReleaseAction/DestroyTarzanInstances';

const config = ReleaseActionConfig();
config.addAction(DestroyTarzanInstances());

config
  .getAction('clearIndexDbTables')
  .setOrder(0)
  .addVersion('develop', { tables: [{ database: 'map', name: 'workguide' }] });

config
  .getAction('deleteIndexDbDatabases')
  .setOrder(1)
  .addVersion('develop', { databases: ['map', 'customerWorkguideSearch'] })
  .addVersion('v3.4.x', { databases: ['map'] })
  .addVersion('v3.5.x', { databases: ['map', 'customerWorkguideSearch'] });

config
  .getAction('destroyTarzanInstances')
  .setOrder(2)
  .addVersion('*')
  .addVersion('develop');

config
  .getAction('logout')
  .setOrder(9999);

config
  .getAction('reload')
  .setOrder(9999);

export default config;
