import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { get, has, head, isEqual, isNil, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { BeatLoader, RadioSelectWrapper } from '../../../General';
import provisionActions from '../../actions/Actions';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import './AccountSelect.css';
import AccountPledgeAlert from '../../components/Alerts/AccountPledgeAlert';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

// Returns options for Account Select with new account option.
function getNewAccountOption() {
  return [{
    label: (
      <Row>
        <Col md={3} lg={3}>
          <FormattedMessage id="Provision.Form.AccountSelection.NewAccount" />
        </Col>
      </Row>
    ),
    value: 0
  }];
}

class AccountSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.getAccountOptions = this.getAccountOptions.bind(this);
    this.onChangeAccount = this.onChangeAccount.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      identifier, customer, actions, fulfilled, provisionAccounts, onChange
    } = this.props;
    const { customer: prevCustomer, fulfilled: prevFulfilled } = prevProps;
    const customerId = get(customer, 'id');
    const prevCustomerId = get(prevCustomer, 'id');

    if (customerId !== prevCustomerId) {
      actions.provisionAccountsRequest({ dataKey: customerId });
    }

    // Set default values, if provisionAccounts returns some accounts
    if (fulfilled && fulfilled !== prevFulfilled && get(provisionAccounts, 'data.resultVariables.pensionProducts.length', 0) > 0) {
      const firstElement = head(get(provisionAccounts, 'data.resultVariables.pensionProducts', []));
      onChange(identifier, firstElement);
    }
  }

  onChangeAccount(id, value) {
    const { onChange, provisionAccounts } = this.props;

    let account = get(provisionAccounts, 'data.resultVariables.pensionProducts', []).find((acc) => get(acc, 'accountKey') === value);

    if (value === 0) {
      account = {
        accountKey: 0
      };
    }

    onChange(id, account);
  }

  /**
   * Get account options for the account selection.
   *
   * @return {Array} options - Array of account options with labels and values
   */
  getAccountOptions() {
    const { provisionAccounts, intl } = this.props;
    return get(provisionAccounts, 'data.resultVariables.pensionProducts', []).map((account) => ({
      label: (
        <Row>
          <Col md={3} lg={3}>
            {get(account, 'accountCategory', '-')}
          </Col>
          <Col md={3} lg={3}>
            {get(account, 'editAccountNumber', '-')}
          </Col>
          <Col md={2} lg={2}>
            {has(account, 'balance') ? `${get(account, 'balance.amount', '-')} ${get(account, 'balance.currency', '-')}` : '-'}
          </Col>
          <Col md={2} lg={2}>
            {intl.formatMessage({ id: !hasProvisionAccounWithdrawalLimit(account) ? 'General.Yes' : 'General.No' })}
          </Col>
          <Col md={2} lg={2}>
            {!isNil(get(account, 'withdrawalPeriodStart')) ? moment(get(account, 'withdrawalPeriodStart')).format('L') : '-'}
          </Col>
        </Row>
      ),
      value: account.accountKey
    }));
  }

  /**
   * Renders errors or a Loader if its still requesting the account data
   * @returns
   */
  renderErrorOrLoader() {
    const { requesting, error } = this.props;

    if (!requesting && !isEqual(error, false)) {
      return (
        <Alert type="error">
          <AlertContent>
            <FormattedMessage id="Provision.Form.AccountSelection.AccountLoadError" values={{ message: get(error, 'message') }} />
          </AlertContent>
        </Alert>
      );
    }

    return <BeatLoader />;
  }

  renderExistingAccountSelection() {
    const {
      identifier, selectedAccount, validations, disabled, showValidations, provisionAccounts
    } = this.props;

    if (get(provisionAccounts, 'data.resultVariables.pensionProducts.length', 0) <= 0) {
      return null;
    }

    return (
      <>
        <Row className="provision-account-select--existing-account--row">
          <Col md={3} lg={3}>
            <FormattedMessage id="Provision.Form.AccountSelection.ExistingAccounts" tagName="b" />
          </Col>
          <Col md={3} lg={3} />
          <Col md={2} lg={2}>
            <FormattedMessage id="Provision.Form.AccountSelection.Balance" tagName="b" />
          </Col>
          <Col md={2} lg={2}>
            <FormattedMessage id="Provision.Form.AccountSelection.CancellationRequired" tagName="b" />
          </Col>
          <Col md={2} lg={2}>
            <FormattedMessage id="Provision.Form.AccountSelection.CancellationDue" tagName="b" />
          </Col>
        </Row>
        {
        get(selectedAccount, 'pledged', false) && (
          <Row className="provision-account-select--existing-account-pledge-alert--row">
            <AccountPledgeAlert />
          </Row>
        )
      }
        <RadioSelectWrapper
          onChange={this.onChangeAccount}
          value={get(selectedAccount, 'accountKey')}
          id={identifier}
          disabled={disabled}
          options={this.getAccountOptions()}
          validations={get(validations, identifier)}
          showValidations={showValidations}
        />
      </>
    );
  }

  renderNewAccountSelection() {
    const {
      identifier, selectedAccount, validations, disabled, showValidations
    } = this.props;

    return (
      <>
        <Row className="provision-account-select--new-account--row">
          <Col md={3} lg={3}>
            <FormattedMessage id="Provision.Form.AccountSelection.NewAccount" tagName="b" />
          </Col>
          <Col md={9} lg={9} />
        </Row>
        <RadioSelectWrapper
          onChange={this.onChangeAccount}
          value={get(selectedAccount, 'accountKey')}
          id={identifier}
          disabled={disabled}
          options={getNewAccountOption()}
          validations={get(validations, identifier)}
          showValidations={showValidations}
        />
      </>
    );
  }

  render() {
    const { fulfilled, error, withNewAccount } = this.props;
    return (
      <Section>
        <SectionTitle>
          <FormattedMessage id="Provision.Form.AccountSelection.SelectAccount" />
        </SectionTitle>
        <SectionContent>
          {
            fulfilled && isEqual(error, false) ? (
              /* Existing Privor Accounts */
              this.renderExistingAccountSelection()
            ) : (
              this.renderErrorOrLoader()
            )
          }
          {/* Create new Privor Account */}
          {withNewAccount && this.renderNewAccountSelection()}
        </SectionContent>
      </Section>
    );
  }
}

AccountSelect.propTypes = {
  actions: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  provisionAccounts: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  identifier: PropTypes.string,
  selectedAccount: PropTypes.object,
  fulfilled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  requesting: PropTypes.bool,
  disabled: PropTypes.bool,
  showValidations: PropTypes.bool,
  withNewAccount: PropTypes.bool
};
AccountSelect.defaultProps = {
  identifier: 'selectedAccount',
  onChange: noop,
  selectedAccount: undefined,
  fulfilled: false,
  error: [],
  requesting: true,
  disabled: false,
  showValidations: true,
  withNewAccount: false
};

function mapStateToProps(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');

  return {
    provisionAccounts: get(state, `provision.provisionAccounts.${customerId}`),
    fulfilled: get(state, `provision.provisionAccounts.${customerId}.fulfilled`),
    error: get(state, `provision.provisionAccounts.${customerId}.error`),
    requesting: get(state, `provision.provisionAccounts.${customerId}.requesting`),
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(provisionActions, dispatch) };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountSelect));
