import { registerCustomWorkguide } from '../Workguide';
import CardSuspension from './containers/Workguide/Suspensions';
import CardClaim from './containers/Workguide/CreditCardClaim';
import PinLetter from './containers/Workguide/PinLetter';
import Replacement from './containers/Workguide/Replacement';

export default function initCardPackage() {
  registerCustomWorkguide({ key: 'CardSuspensionForm', component: CardSuspension });
  registerCustomWorkguide({ key: 'CardClaimForm', component: CardClaim });
  registerCustomWorkguide({ key: 'PinLetterForm', component: PinLetter });
  registerCustomWorkguide({ key: 'ReplacementForm', component: Replacement });
}
