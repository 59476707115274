import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isUndefined } from 'lodash';
import { withRouter } from 'react-router';

import SearchField from '../../components/SearchField';
import activityActions from '../../actions/Actions';
import getCustomerIdFromState from '../../lib/Utils/getCustomerIdFromState';

class CustomerActivitiesSearchField extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    const { search } = this.props;

    if (!isUndefined(get(search, 'data'))) {
      activityActions.customerActivitiesSearchReset();

      // Call onChange whitout searchTerm on unmount to get
      // reset leads on customer overview without it
      this.onChange();
    }
  }

  onChange(searchTerm) {
    const {
      activityActions,
      activities,
      customerId,
      filters
    } = this.props;

    activityActions.customerActivitiesSearchRequest({ searchTerm });
    activityActions.customerActivitiesReset();
    activityActions.customerActivitiesRequest({
      customerId,
      filters: get(filters, 'data', {}),
      limit: get(activities, 'limit', 20),
      offset: 0,
      searchTerm
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { search } = this.props;

    return (
      <SearchField
        onChange={this.onChange}
        searchTerm={get(search, 'data')}
      />
    );
  }
}

CustomerActivitiesSearchField.propTypes = {
  activities: PropTypes.object,
  activityActions: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  search: PropTypes.object
};

CustomerActivitiesSearchField.defaultProps = {
  activities: {},
  filters: {},
  search: {}
};

function mapStateToProps(state, ownProps) {
  const { match } = ownProps;

  return {
    activities: state.customerActivities,
    customerId: getCustomerIdFromState(state, match),
    filters: state.activity.customerActivitiesFilter,
    search: state.activity.customerActivitiesSearch
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerActivitiesSearchField)
);
