import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment/moment';
import customerBaseAnalysisAction from '../actions/CalculateManyCapitalTaxes';

const {
  CALCULATE_MANY_CAPITAL_TAXES_PENDING,
  CALCULATE_MANY_CAPITAL_TAXES_FULFILLED,
  CALCULATE_MANY_CAPITAL_TAXES_REJECTED
} = customerBaseAnalysisAction;

export default function* calculateManyCapitalTaxes(request) {
  const { data } = request;

  yield put({ type: CALCULATE_MANY_CAPITAL_TAXES_PENDING });

  try {
    const requestPayload = {
      TaxYear: get(data, 'taxYear', moment().year()), // ---> aktuelles Jahr
      TaxGroupID: get(data, 'taxLocationId'), // ---> TaxLocationID aus vorherigem Request
      Relationship: get(data, 'relationship'), // ---> 1 = Alleinstehend, 2 = Verheiratet, 3 = Konkubinat, 4 = Eingetragene Partnerschaft
      Confession1: get(data, 'confession1'), // ---> 1 = Reformiert, 2 = Römisch-katholisch, 3 = Christkatholisch, 5 = Andere/keine
      Confession2: get(data, 'confession2'), // ---> Nur wenn nicht alleinstehend, muss in Dropdown ausgewählt werden 1 = Reformiert, 2 = Römisch-katholisch, 3 = Christkatholisch, 5 = Andere/keine
      NumberOfChildren: get(data, 'numberOfChildren', 0), // ---> Anzahl Kinder
      Gender: get(data, 'gender'), // ---> 1 = Männlich, 2 = Weiblich
      AgeAtPayment: get(data, 'ageAtPayment', 0), // ---> Aktuelles Alter des Kunden
      Capital: get(data, 'capital', 0) // 40000 //---> Aktueller Wert des Vorsorgekontos
    };

    const { data: calculatedTaxData } = yield call(axios, {
      url: '/tax/API_calculateManyCapitalTaxes',
      method: 'post',
      data: requestPayload
    });

    const payload = get(calculatedTaxData, 'response.0');

    yield put({ type: CALCULATE_MANY_CAPITAL_TAXES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CALCULATE_MANY_CAPITAL_TAXES_REJECTED, error });

    return error;
  }
}
