import { call, put } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import authorizationsAction from '../actions/Authorizations';

const {
  AUTHORIZATIONS_PENDING,
  AUTHORIZATIONS_FULFILLED,
  AUTHORIZATIONS_REJECTED
} = authorizationsAction;

export default function* getAuthorizations(request) {
  yield put({ type: AUTHORIZATIONS_PENDING });

  try {
    const { customerId } = request;
    const query = QueryBuilder()
      .eq('attorneyDonor.id', customerId, { type: 'string' })
      .eq('powerOfAttorney', true)
      .limit(100)
      .getQueryString();

    const url = `/entity/contract/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: AUTHORIZATIONS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: AUTHORIZATIONS_REJECTED, error });

    return error;
  }
}
