import { takeLatest } from 'redux-saga/effects';

import {
  getCustomers
} from './CustomerSearch/index';
import customerSearchActions from '../actions/CustomerSearch';

const { SEARCH_REQUEST } = customerSearchActions;

export default function* customerSearchRootSaga() {
  yield takeLatest(SEARCH_REQUEST, getCustomers);
}
