import { takeEvery, takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import cancelOpening from './cancelOpening';
import checkExistingOpening from './checkExistingOpening';
import getAuthorizations from './getAuthorizations';
import getContracts from './getContracts';
import getCustomers from './getCustomers';
import getFunds from './getFunds';
import getPaymentAccounts from './getPaymentAccounts';
import getPortfolios from './getPortfolios';
import getProducts from './getProducts';
import getQiDeclaration from './getQiDeclaration';
import getWorkbenchRecord from './getWorkbenchRecord';
import saveOpeningForm from './saveOpeningForm';
import saveUnlockForm from './saveUnlockForm';

import securitiesAccountActions from '../actions/Actions';

const {
  AUTHORIZATIONS_REQUEST,
  CANCEL_OPENING_REQUEST,
  CHECK_EXISTING_OPENING_REQUEST,
  CONTRACTS_REQUEST,
  CUSTOMERS_REQUEST,
  FUNDS_REQUEST,
  OPENING_FORM_SAVE_REQUEST,
  PAYMENT_ACCOUNTS_REQUEST,
  PORTFOLIOS_REQUEST,
  PRODUCTS_REQUEST,
  QI_DECLARATION_REQUEST,
  UNLOCK_FORM_SAVE_REQUEST,
  WORKBENCH_RECORD_REQUEST
} = securitiesAccountActions;

export default function* bpfSecuritiesAccountsRootSaga() {
  yield takeEvery(CANCEL_OPENING_REQUEST, cancelOpening);
  yield takeEvery(QI_DECLARATION_REQUEST, getQiDeclaration);
  yield takeLatest(AUTHORIZATIONS_REQUEST, getAuthorizations);
  yield takeLatest(CHECK_EXISTING_OPENING_REQUEST, checkExistingOpening);
  yield takeLatest(CONTRACTS_REQUEST, getContracts);
  yield takeLatest(CUSTOMERS_REQUEST, getCustomers);
  yield takeLatest(FUNDS_REQUEST, getFunds);
  yield takeLatest(OPENING_FORM_SAVE_REQUEST, saveOpeningForm);
  yield takeLatest(PAYMENT_ACCOUNTS_REQUEST, getPaymentAccounts);
  yield takeLatest(PORTFOLIOS_REQUEST, getPortfolios);
  yield takeLatest(PRODUCTS_REQUEST, getProducts);
  yield takeLatest(UNLOCK_FORM_SAVE_REQUEST, saveUnlockForm);
  yield takeLatestByDataKey(WORKBENCH_RECORD_REQUEST, getWorkbenchRecord);
}
