import { put, call } from 'redux-saga/effects';
import { get, some } from 'lodash';
import { AxiosError } from 'axios';

import { ADD_TOAST } from '../../actions/Toast';

// List of paths (url.includes) that should be ignored
// https://issue.swisscom.ch/browse/MAP-8463
const ignorePaths = [
  '/veseca',
  '/visecadmc',
  '/airlock',
  '/valiant-cms-getVariables',
  '/valiant-fil-is-genericGet',
  '/valiant-nnk-duplicate-check',
  '/valiant-product-loadAccount',
  '/valiant-product-getVariables',
  '/valiant-product-getCards',
  '/valiant-product-loadPoas',
  '/valiant-vollmacht-ebanking',
  'valiant-product-orderEBankingContract'
];

function* handleThirdPartySystemError(action) {
  yield put({
    type: ADD_TOAST,
    kind: 'error',
    title: get(action, 'error.title', 'Error.3rdPartyService.Title'),
    description: get(action, 'error.description', 'Error.3rdPartyService.Description'),
    values: { ...get(action, 'values', {}), message: get(action, 'error.message') }
  });
}

export default function* handleError(action) {
  const { error, suppressToast = true, values = {} } = action;

  // Temporary fix for https://issue.swisscom.ch/browse/MAP-8463
  if (error instanceof AxiosError) {
    const method = get(error, 'response.config.method', '');
    const url = get(error, 'response.config.url', '');

    if (method.toLowerCase() === 'get' || some(ignorePaths, (p) => url.includes(p))) {
      return undefined;
    }
  }

  // Do nothing if toast should be supressed
  if (suppressToast) return undefined;

  // Show third party error instead of general error if request was proxied
  if (get(error, 'response.headers.x-gateway-proxied') === 'YES') {
    return yield call(handleThirdPartySystemError, action);
  }

  yield put({
    type: ADD_TOAST,
    kind: 'error',
    title: error.title || 'Error.General.Title',
    description: error.description || action.type,
    values: { ...values, message: get(action, 'error.message') }
  });

  return undefined;
}
