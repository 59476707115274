import axios from 'axios';

export default function setDefaults(instance = axios) {
  instance.defaults.headers.common = {};
  instance.defaults.headers.common['Accept-Language'] = 'de,en,fr';
  instance.defaults.timeout = 60000;
  instance.defaults.withCredentials = false;

  return instance;
}
