import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { isNil } from 'lodash';
import { removeUnwantedValues } from '@evoja-web/redux-saga-utils';

import completeTaskAction from '../actions/CompleteTask';

const {
  COMPLETE_TASK_PENDING,
  COMPLETE_TASK_FULFILLED,
  COMPLETE_TASK_REJECTED
} = completeTaskAction;

export default function* completeTask(request) {
  const {
    dataKey,
    skipUserCheck = false,
    variables
  } = request;

  const data = !isNil(variables)
    ? { variables }
    : undefined;

  yield put({ type: COMPLETE_TASK_PENDING, dataKey });

  try {
    const url = `/sif/bpf/public/api/v1/task/${dataKey}/complete?skipUserCheck=${skipUserCheck}`;
    const payload = yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(data, [undefined, null])
    });

    yield put({ type: COMPLETE_TASK_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: COMPLETE_TASK_REJECTED, dataKey, error });

    return error;
  }
}
