import update from 'immutability-helper';
import { get } from 'lodash';

import userSettingsActions from '../actions/UserSettings';
import {
  GUIDED_TOUR_PENDING,
  GUIDED_TOUR_GET_TEXT_PENDING,
  GUIDED_TOUR_GET_TEXT_FULFILLED,
  GUIDED_TOUR_SHOW,
  GUIDED_TOUR_HIDE,
  GUIDED_TOUR_REJECTED
} from '../actions/GuidedTour';

const { USERSETTINGS_FULFILLED } = userSettingsActions;

const defaultState = {
  dashboard: {
    run: true
  },
  kundenUeberblick: {
    run: true
  },
  grad360: {
    run: true
  },
  leadManagement: {
    run: true
  },
  text: ''
};

export const methods = {
  default: (state) => state
};

methods[GUIDED_TOUR_PENDING] = (state, action) => ({
  ...state,
  [action.name]: {
    pending: true
  }
});

methods[GUIDED_TOUR_GET_TEXT_PENDING] = (state) => ({ ...state });

methods[GUIDED_TOUR_GET_TEXT_FULFILLED] = (state, action) => ({ ...state, text: action.data });

methods[GUIDED_TOUR_REJECTED] = (state) => ({ ...state });

methods[GUIDED_TOUR_SHOW] = (state, action) => {
  return { ...state, [action.name]: { run: action.run } };
};

methods[GUIDED_TOUR_HIDE] = (state, action) => {
  return { ...state, [action.name]: { run: action.run } };
};

methods[USERSETTINGS_FULFILLED] = (state, action) => update(state, {
  dashboard: { run: { $set: get(action, 'payload.guidedTour.dashboard', true) } },
  kundenUeberblick: { run: { $set: get(action, 'payload.guidedTour.workguide', true) } },
  grad360: { run: { $set: get(action, 'payload.guidedTour.customer', true) } },
  leadManagement: { run: { $set: get(action, 'payload.guidedTour.leadManagement', true) } }
});

export default function guidedTourReducer(state = defaultState, action) {
  const func = methods[action.type] || methods.default;

  return func(state, action);
}
