import { isNull } from 'lodash';

import {
  USERSWITCH_PENDING,
  USERSWITCH_FULFILLED,
  USERSWITCH_REJECTED,
  USERSWITCH_CLEAR
} from '../actions/UserSwitch';

const selected = JSON.parse(sessionStorage.getItem('userSwitch'));

const defaultState = {
  selected: isNull(selected) ? undefined : selected
};

const methods = {
  default: (state) => state
};

methods[USERSWITCH_PENDING] = (state) => ({ ...state, requesting: true, error: false });

methods[USERSWITCH_FULFILLED] = (state, action) => {
  // Put result into session storage, else the user won'b be available when reladoing the tab
  sessionStorage.setItem('userSwitch', JSON.stringify(action.data));

  return { ...state, requesting: false, error: false, selected: action.data };
};

methods[USERSWITCH_REJECTED] = (state, action) => ({ ...state, requesting: false, error: action.error });

methods[USERSWITCH_CLEAR] = (state) => {
  sessionStorage.removeItem('userSwitch');

  return { ...state, requesting: false, error: false, selected: null };
};

export default function userSwitchReducer(state = defaultState, action) {
  const func = methods[action.type] || methods.default;

  return func(state, action);
}
