import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, noop, sum, compact } from 'lodash';
import update from 'immutability-helper';
import { Label, InputGroup, InputGroupText } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ValidationResult } from '@evoja-web/react-form';

import './Funds.css';
import Fund from './Fund';
import { NumberField } from '../../../../General/index';

const Funds = React.memo(({
  funds,
  language,
  onChange,
  validations,
  value
}) => {
  const onFundChange = useCallback((number, v) => {
    const updated = update(value, { [number]: { $set: v } });
    onChange('funds', updated);
  }, [value, onChange]);

  const children = get(funds, 'data', []).map((fund) => {
    const { number } = fund;
    const v = get(value, number, 0);

    return (
      <Fund
        key={get(fund, 'id', number)}
        fund={fund}
        language={language}
        onChange={onFundChange}
        value={v}
      />
    );
  });

  return (
    <div className="securities-account-opening-funds">
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.Funds.Title" />
      </Label>

      {children}

      <div style={{ paddingTop: '10px' }} />

      <div className="securities-account-opening-funds-total">
        <InputGroup>
          <NumberField
            disabled
            id="total"
            value={sum(compact(Object.values(value)))}
            allowNegative={false}
          />

          <InputGroupText>%</InputGroupText>
        </InputGroup>
      </div>

      <ValidationResult
        validations={validations}
        show
      />
    </div>
  );
});

Funds.propTypes = {
  funds: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.object
};

Funds.defaultProps = {
  funds: {},
  language: 'de',
  onChange: noop,
  validations: {},
  value: {}
};

export default Funds;
