import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getExternalLinks from './getExternalLinks';

const { EXTERNAL_LINK_REQUEST } = actions;

export default function* externalLinkRootSaga() {
  yield takeLatest(EXTERNAL_LINK_REQUEST, getExternalLinks);
}
