import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isUndefined } from 'lodash';
import { withRouter } from 'react-router';
import { ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BeatLoader } from 'react-spinners';

import './ActivitiesModal.css';
import { withModalHandler } from '@evoja-web/react-modal';
import List from '../../components/List/ScrollableActivitiesList';
import FilterMenu from './FilterMenu';
import SearchField from './SearchField';
import { ACTIVITY_EDIT_MODAL_IDENTIFIER } from '../../constants';
import activityActions from '../../actions/Actions';
import getCustomerIdFromState from '../../lib/Utils/getCustomerIdFromState';
// RESOLVE DEPENDENCY: Modal handler should be moved to own package

class CustomerActivitiesModal extends React.Component {
  constructor(props) {
    super(props);

    this.onNextChunkRequest = this.onNextChunkRequest.bind(this);
    this.onActivityDoubleClick = this.onActivityDoubleClick.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
  }

  /**
   * Load next chunk of data
   *
   * @return void
   */
  onNextChunkRequest() {
    const {
      activities,
      activityActions,
      customerId,
      filters,
      search
    } = this.props;

    const limit = get(activities, 'limit', 20);
    const offset = isUndefined(get(activities, 'data'))
      ? 0
      : get(activities, 'offset', 0) + limit;

    activityActions.customerActivitiesRequest({
      customerId,
      filters: get(filters, 'data', {}),
      limit,
      offset,
      searchTerm: get(search, 'data')
    });
  }

  /**
   * Show edit modal on double click
   *
   * @param  {Object} activity Activity to edit
   *
   * @return void
   */
  onActivityDoubleClick(activity) {
    const {
      consultants,
      modalHandler
    } = this.props;

    const componentProps = {
      activity,
      consultants,
      onDeleteCallback: this.closeEditModal,
      onSubmit: this.closeEditModal,
      onCancel: this.closeEditModal
    };

    const modalProps = {
      size: 'lg',
      className: 'activity-modal'
    };

    modalHandler.show({
      id: ACTIVITY_EDIT_MODAL_IDENTIFIER,
      componentProps,
      modalProps
    });
  }

  /**
   * Close the edit modal
   *
   * @return void
   */
  closeEditModal() {
    const { modalHandler } = this.props;

    modalHandler.hide(ACTIVITY_EDIT_MODAL_IDENTIFIER);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { activities } = this.props;

    return (
      <React.Fragment>
        <ModalHeader className="customer-activities-modal-header">
          <FormattedMessage id="Activity.CustomerActivities.Modal.Title" />

          <div className="customer-activities-modal-search-and-filter">
            <div className="customer-activities-modal-search-and-filter--search">
              <SearchField />
            </div>

            <div className="customer-activities-modal-search-and-filter--filter">
              <FilterMenu />
            </div>
          </div>

          <div className="customer-activities-modal-search-and-filter--loader">
            {get(activities, 'requesting', false) && (
              <BeatLoader color="#49A3D0" size={12} />
            )}
          </div>
        </ModalHeader>

        <ModalBody>
          <div
            id="customer-activities-modal-content"
            style={{ height: '75vh', overflow: 'auto' }}
          >
            <List
              activities={activities}
              className="customer-activities-modal-activity-list"
              onActivityDoubleClick={this.onActivityDoubleClick}
              onNextChunkRequest={this.onNextChunkRequest}
              scrollableTarget="customer-activities-modal-content"
              scope="customer"
            />
          </div>
        </ModalBody>
      </React.Fragment>
    );
  }
}

CustomerActivitiesModal.propTypes = {
  activities: PropTypes.object,
  activityActions: PropTypes.object.isRequired,
  consultants: PropTypes.array,
  customerId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  modalHandler: PropTypes.object.isRequired,
  search: PropTypes.object
};

CustomerActivitiesModal.defaultProps = {
  activities: {},
  consultants: [],
  filters: {},
  search: {}
};

function mapStateToProps(state, ownProps) {
  const { match } = ownProps;

  return {
    activities: state.activity.customerActivities,
    customerId: getCustomerIdFromState(state, match),
    filters: state.activity.customerActivitiesFilter,
    search: state.activity.customerActivitiesSearch
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withModalHandler()(CustomerActivitiesModal)
  )
);
