import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

const NoMatchRoute = React.memo(({ location }) => {
  return (
    <Redirect
      to={{
        pathname: '/dashboard',
        state: { from: location }
      }}
    />
  );
});

NoMatchRoute.propTypes = {
  location: PropTypes.object
};

NoMatchRoute.defaultProps = {
  location: undefined
};

export default NoMatchRoute;
