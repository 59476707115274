import React from 'react';
import { isFunction, camelCase, upperFirst, isNil } from 'lodash';
import { Button, Modal, ModalBody, ModalHeader, DropdownItem, ModalFooter } from 'reactstrap';
import Dexie from 'dexie';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@evoja-web/react-form';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';


const defaultDatabases = ['codes', 'map', 'react-table', '__dbnames', 'customerWorkguideSearch'];

class DeleteIndexDB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      databases: [],
      selected: [],
      isModalOpen: false
    };

    this.loadDatabases = this.loadDatabases.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onToggleModal = this.onToggleModal.bind(this);
  }

  /**
   * Submit the form.
   * Close db connections and delete the selected databases
   *
   * @return  void
   */
  async onSubmit() {
    const { selected } = this.state;

    // Make sure connections are closed before delete
    await Promise.all(selected.map(async (name) => {
      const db = getDbInstance({ id: name });

      if (!isNil(db)) {
        await db.close();
      }

      return true;
    }));

    await Promise.all(selected.map(async (name) => Dexie.delete(name)));

    window.location.reload();
  }

  /**
   * Toggle the modal on click
   *
   * @return  void
   */
  onToggleModal() {
    const { isModalOpen } = this.state;

    this.setState({ isModalOpen: !isModalOpen });
  }

  /**
   * Select / deselect the given db
   *
   * @param   {String}  dbName  Database name
   *
   * @return void
   */
  onSelectionChange(database) {
    const { selected } = this.state;

    const updated = selected.includes(database)
      ? selected.filter((s) => s !== database)
      : [...selected, database];

    this.setState({ selected: updated });
  }

  /**
   * Load the local db names via dexie and set them in state.
   *
   * @return  void
   */
  async loadDatabases() {
    const databases = isFunction(indexedDB.databases)
      ? await Dexie.getDatabaseNames()
      : defaultDatabases;

    this.setState({ databases });
  }

  /**
   * Render the children (checkboxes with db names / descriptions)
   *
   * @return  {Array} children Array of react nodes
   */
  renderChildren() {
    const { databases, selected } = this.state;

    return databases.map((database) => {
      const descriptionIdentifier = `General.DeleteIndexDB.Modal.Description.${upperFirst(camelCase(database))}`;

      return (
        <Checkbox
          id={database}
          name={database}
          value={selected.includes(database)}
          onChange={() => this.onSelectionChange(database)}
        >
          {database}
          {': '}
          <FormattedMessage id={descriptionIdentifier} />
        </Checkbox>
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isModalOpen } = this.state;

    return (
      <React.Fragment>
        <DropdownItem
          className="layout-header-user-profile--dropdown-item layout-header-user-profile--dropdown-item--no-icon"
          onClick={this.onToggleModal}
          toggle={false}
        >
          <FormattedMessage id="General.Header.Dropdown.DeleteIndexDB" />
        </DropdownItem>

        <Modal
          isOpen={isModalOpen}
          onOpened={this.loadDatabases}
          size="lg"
          toggle={this.onToggleModal}
          unmountOnClose
        >
          <ModalHeader toggle={this.onToggleModal}>
            <FormattedMessage id="General.DeleteIndexDB.Modal.Title" />
          </ModalHeader>

          <ModalBody>
            {this.renderChildren()}
          </ModalBody>

          <ModalFooter style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              outline
              onClick={this.onToggleModal}
            >
              <FormattedMessage id="General.Cancel" />
            </Button>

            <Button
              color="primary"
              onClick={this.onSubmit}
            >
              <FormattedMessage id="General.Submit" />
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeleteIndexDB;
