import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { DateField } from '../../../General';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

class DateSelection extends PureComponent {
  constructor(props) {
    super(props);

    this.onDateFromChanged = this.onDateFromChanged.bind(this);
  }

  /**
  * Event handler for when the 'dateFrom' field changes.
  * Updates the form state with new date value.
  * Also calculates and updates validFrom
  *
  * @param {string} key - The key of the date field
  * @param {string} value - The new date value
  */
  onDateFromChanged(key, value) {
    const { onChange } = this.props;
    onChange(key, value);
    onChange('validUntil', moment(value).add(3, 'month').subtract(1, 'day').format());
  }

  /**
   * Returns min Date for DateFiled to limit selectable Date range
   * @returns 
   */
  getMinDates() {
    const { data } = this.props;

    if (get(data, 'selectedAccount.accountKey') !== 0) {
      if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
        const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
        return isAfterToday ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')) : moment();
      }
      return moment().add(31, 'days');
    }

    return moment();
  }

  render() {
    const { data, validations, showValidations } = this.props;

    const validFromMinDate = this.getMinDates();

    return (
      <>
        <Row className="provision-depot-opening--space--top-large">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.SecuritiesSolutionCancellation.ValidFrom" />
          </Col>
          <Col md={3}>
            <DateField
              id="validFrom"
              name="validFrom"
              value={get(data, 'validFrom')}
              minDate={validFromMinDate}
              onChange={this.onDateFromChanged}
              onInputChange={this.onDateFromChanged}
              showValidations={showValidations}
              validations={get(validations, 'validFrom')}
            />
          </Col>
        </Row>
        <Row className="provision-depot-opening--space--top">
          <Col md={3} className="provision-depot-opening--label--center">
            <FormattedMessage id="Provision.Form.DepotOpening.SecuritiesSolutionCancellation.ValidTo" />
          </Col>
          <Col md={3}>
            <DateField
              id="validUntil"
              name="validUntil"
              value={get(data, 'validUntil')}
              validations={get(validations, 'validUntil')}
              showValidations={showValidations}
            />
          </Col>
        </Row>
      </>
    );
  }
}

DateSelection.propTypes = {
  validations: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  showValidations: PropTypes.bool
};

DateSelection.defaultProps = {
  onChange: noop,
  data: {},
  showValidations: true
};

export default DateSelection;
