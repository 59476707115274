import { DefaultReducer } from '@evoja-web/redaction';
import { userSettingsAction } from '../../actions/UserSettings';

export const defaultState = {
  data: {
    guidedTour: {},
    collapsed: [],
    agGridColDefs: []
  }
};

export const reducer = DefaultReducer('settings', userSettingsAction, defaultState);

export default reducer.get();
