import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop, upperFirst } from 'lodash';

import { Select } from '../../../../General/index';

const ProductSelect = React.memo(({
  language,
  products,
  onChange,
  validations,
  value
}) => {
  const options = products.map((product) => ({
    value: get(product, 'productGroups'),
    label: get(product, `productDescription${upperFirst(language)}`)
  }));

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.ProductSelect" />
      </Label>

      <Select
        id="product"
        isLoading={get(products, 'requesting', false)}
        options={options}
        onChange={onChange}
        validations={validations}
        value={value}
        showValidations
      />
    </FormGroup>
  );
});

ProductSelect.propTypes = {
  language: PropTypes.string,
  products: PropTypes.array,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.number
};

ProductSelect.defaultProps = {
  language: 'de',
  products: [],
  onChange: noop,
  validations: undefined,
  value: undefined
};

export default ProductSelect;
