import { takeLatest, takeEvery } from 'redux-saga/effects';

import actions from '../actions/UserSettings';
import {
  fetchUserSettings,
  saveUserSettings
} from './UserSettings/index';

const {
  USERSETTINGS_REQUEST,
  SAVE_USERSETTINGS_REQUEST
} = actions;

export default function* root() {
  yield takeLatest(USERSETTINGS_REQUEST, fetchUserSettings);
  yield takeEvery(SAVE_USERSETTINGS_REQUEST, saveUserSettings);
}
