import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { chain, get, has } from 'lodash';

import moment from 'moment';
import consultantCustomersAction from '../actions/ConsultantCustomers';
import { getCodeMapper } from '../../../globals';

const {
  CONSULTANT_CUSTOMERS_PENDING,
  CONSULTANT_CUSTOMERS_FULFILLED,
  CONSULTANT_CUSTOMERS_REJECTED
} = consultantCustomersAction;

function sumBalanceBySubType({ data, subType }) {
  const codeMapper = getCodeMapper();

  return chain(data)
    .get('customerBalance', [])
    .filter((b) => codeMapper.get('balanceSubType', subType).includes(get(b, 'balanceSubType.id')))
    .sumBy('amount')
    .value();
}

export default function* getConsultantCustomers(request) {
  const { dataKey } = request;

  yield put({ type: CONSULTANT_CUSTOMERS_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('customer.consultant.id', dataKey, { type: 'string' })
      .limit(300)
      .getQueryString();

    const url = `/customer/customerbook/${query}`;
    const customers = yield call(handleNextHeaders, url);

    const payload = customers.map((data) => {
      return {
        ...data,
        customer: {
          ...data.customer,
          age: has(data, 'customer.age')
            ? get(data, 'customer.age')
            : get(data, 'customer.birthDate')
              ? moment().diff(moment(get(data, 'customer.birthDate')), 'years')
              : undefined,
          agePartner: has(data, 'customer.agePartner')
            ? get(data, 'customer.agePartner')
            : get(data, 'customer.birthDatePartner')
              ? moment().diff(moment(get(data, 'customer.birthDatePartner')), 'years')
              : undefined
        },
        investments: sumBalanceBySubType({ data, subType: 'investments' }),
        payments: sumBalanceBySubType({ data, subType: 'payments' }),
        savings: sumBalanceBySubType({ data, subType: 'savings' }),
        pensions: sumBalanceBySubType({ data, subType: 'pensions' })
      };
    });

    yield put({ type: CONSULTANT_CUSTOMERS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONSULTANT_CUSTOMERS_REJECTED, dataKey, error });

    return error;
  }
}
