import { call, all } from 'redux-saga/effects';
import { get } from 'lodash';
import { GravitonFile } from '@evoja-web/react-file-upload';
import { upload } from '@evoja-web/react-file-upload/sagas';

export default function* uploadFiles(files) {
  try {
    const gravitonFiles = files.map((file) => {
      const gravitonFile = GravitonFile();
      gravitonFile.fromFile(file);
      return gravitonFile;
    });

    const operations = gravitonFiles.map((file) => call(upload, { file }));
    return yield all(operations);
  } catch (error) {
    const errorText = get(files, 'length', 0) > 1 ? 'Cover Letter' : 'Cover Letter and Reply Letter';
    console.error(`Could not save the ${errorText}. Error: `, error);
    throw new Error(`Could not save the ${errorText}.`);
  }
}
