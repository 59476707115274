import { TYPE_STRING, TYPE_BOOLEAN, TYPE_NULL, TYPE_ANY } from '@metrichor/jmespath-plus';
import { isNull } from 'lodash';

/**
 * Check if the given value is false (boolean) or 'false' (string)
 * and return the given "then" value if so.
 * Return undefined if not
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {Mixed} value "then" value or undefined
 */
export function ifFalse(resolvedArgs = []) {
  const [value, then, other = undefined] = resolvedArgs;

  if (!isNull(value) && (value === false || value === 'false')) return then;

  return isNull(other)
    ? undefined
    : other;
}

export default {
  key: 'ifFalse',
  func: ifFalse,
  types: [
    { types: [TYPE_BOOLEAN, TYPE_STRING, TYPE_NULL] },
    { types: [TYPE_ANY] },
    { types: [TYPE_ANY], optional: true }
  ]
};
