import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import ratingsAction from '../../actions/Rating/Ratings';

const {
  RATINGS_PENDING,
  RATINGS_FULFILLED,
  RATINGS_REJECTED
} = ratingsAction;

export default function* getRatings(request) {
  yield put({ type: RATINGS_PENDING });

  try {
    const { customerId } = request;

    const query = QueryBuilder()
      .eq('customer.ref.$id', customerId, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/person/rating/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: RATINGS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: RATINGS_REJECTED, error });

    return error;
  }
}
