import update from 'immutability-helper';
import { DefaultReducer } from '@evoja-web/redaction';
import { isUndefined, isNull } from 'lodash';
import { logoutActions } from '@evoja-web/react-login';

import action, { temporaryUserRolesAction } from '../../actions/Acl/TemporaryUserRoles';

const {
  GATEWAY_LOGOUT_FULFILLED,
  OIDC_LOGOUT_FULFILLED
} = logoutActions;

const {
  TEMPORARY_USER_ROLES_FULFILLED,
  TEMPORARY_USER_ROLES_RESET
} = action;

function afterTemporaryUserRolesFulfilled(state, action) {
  const { payload } = action;

  if (!isUndefined(payload)) {
    localStorage.setItem('temporaryUserRoles', JSON.stringify(payload));
  }

  return state;
}

function afterTemporaryUserRolesReset(state) {
  localStorage.removeItem('temporaryUserRoles');

  return state;
}

function aclOnLogoutFulfilled(state) {
  return update(state, {
    data: { $set: undefined }
  });
}

// Load temporary user roles from localStorage and set them as default state
const localStorageData = localStorage.getItem('temporaryUserRoles');
const defaultState = {
  data: isNull(localStorageData) ? undefined : JSON.parse(localStorageData)
};

export const reducer = DefaultReducer('temporaryUserRoles', temporaryUserRolesAction, defaultState);
reducer.addMethod(GATEWAY_LOGOUT_FULFILLED, aclOnLogoutFulfilled);
reducer.addMethod(OIDC_LOGOUT_FULFILLED, aclOnLogoutFulfilled);
reducer.after(TEMPORARY_USER_ROLES_FULFILLED, afterTemporaryUserRolesFulfilled);
reducer.after(TEMPORARY_USER_ROLES_RESET, afterTemporaryUserRolesReset);

export default reducer.get();
