import { TYPE_STRING, TYPE_ARRAY, TYPE_NULL } from '@metrichor/jmespath-plus';
import { get, isNull } from 'lodash';

/**
 * Try to find the code id by the given text
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} id Code id
 */
export function codeIdByTextShort(resolvedArgs) {
  let [codes = [], text = '', language = 'de'] = resolvedArgs;

  if (isNull(codes)) codes = [];
  if (isNull(text)) text = '';
  if (isNull(language)) language = 'de';

  const code = codes.find((c) => get(c, `textShort.${language}`) === text);

  return get(code, 'id');
}

export default {
  key: 'codeIdByTextShort',
  func: codeIdByTextShort,
  types: [{ types: [TYPE_ARRAY, TYPE_NULL] }, { types: [TYPE_STRING, TYPE_NULL] }]
};
