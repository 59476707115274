import React from 'react';
import { get, random, isNil } from 'lodash';

import './MostImportantComponent.css';

const effects = ['shake', 'wiggle', 'blink', 'roll'];
const speed = ['slow', 'fast'];

let inti;

function party({
  collection,
  depth = 8
}) {
  Array.from(collection).forEach((node) => {
    const className = get(node, 'children.length', 0) > 0
      ? `party-${get(effects, random(0, 1))}-${get(speed, random(0, 1))}`
      : `party-${get(effects, random(0, 3))}-${get(speed, random(0, 1))}`;

    node.classList.add(className);

    if (depth > 0) {
      party({
        collection: node.children,
        depth: depth - 1
      });
    }
  });
}

function removeParty() {
  Array.from(document.querySelectorAll('[class*="party"]')).forEach((node) => {
    node.classList.forEach((c) => {
      if (c.includes('party')) {
        node.classList.remove(c);
      }
    });
  });
}

function partyhard() {
  removeParty();

  document.getElementById('layout-content').classList.add('party-rainbow-fast', 'party-remove-child-background');

  Array.from(document.getElementsByClassName('customer-detail')).forEach((node) => {
    node.classList.add('party-remove-background');
  });

  Array.from(document.getElementsByClassName('card-container')).forEach((node) => {
    node.classList.add('party-shake-fast');
    party({ collection: node.children });
  });

  Array.from(document.getElementsByClassName('layout-header-navbar')).forEach((node) => {
    node.classList.add('party-rainbow-horizontal-fast');
    party({ collection: node.children });
  });

  Array.from(document.getElementsByClassName('customer-detail-navigation')).forEach((node) => {
    // node.classList.add('party-rainbow-horizontal-slow');
    party({ collection: node.children });
  });

  Array.from(document.getElementsByClassName('customer-detail-header')).forEach((node) => {
    node.classList.add('party-shake-fast');
    party({ collection: node.children });
  });

  Array.from(document.getElementsByClassName('customer-detail-header-kpi')).forEach((node) => {
    node.classList.add('party-shake-fast');
    party({ collection: node.children, depth: 8 });
  });

  // inti = setInterval(partyhard, 5000);

  inti = setTimeout(() => {
    partyhard();
  }, 4000);
}

function partyslow(howMuchIsTheFish = 14500, isFirst = false) {
  removeParty();

  if (!isNil(inti)) {
    // inti = clearInterval(inti);
    inti = clearTimeout(inti);
  }

  Array.from(document.getElementsByClassName('card-container')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  Array.from(document.getElementsByClassName('navbar-collapse')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  Array.from(document.getElementsByClassName('customer-detail-header')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  Array.from(document.getElementsByClassName('customer-detail-header-kpi')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  Array.from(document.getElementsByClassName('customer-detail-navigation--link--icon')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  Array.from(document.getElementsByClassName('layout-header-navbar-logo')).forEach((node) => {
    node.classList.add('party-shake-slow');
  });

  setTimeout(partyhard, howMuchIsTheFish);
  if (isFirst) setTimeout(() => partyslow(24800), 42000);
}

const PartyHard = React.memo(() => {
  return (
    <div>
      <audio
        autoPlay
        onPlay={() => partyslow(14500, true)}
        onEnded={() => window.location.reload(true)}
      >
        <source src="/boring.mp3" type="audio/mpeg" />
      </audio>
    </div>
  );
});

PartyHard.propTypes = {

};

PartyHard.defaultProps = {

};

export default PartyHard;
