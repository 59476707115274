import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { set } from 'lodash';

// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import removeUnwantedValues from '../../../lib/Utils/removeUnwantedValues';
import unlockFormAction from '../actions/UnlockForm';

const {
  UNLOCK_FORM_SAVE_PENDING,
  UNLOCK_FORM_SAVE_FULFILLED,
  UNLOCK_FORM_SAVE_REJECTED
} = unlockFormAction;

export default function* saveUnlockForm(request) {
  yield put({ type: UNLOCK_FORM_SAVE_PENDING });

  try {
    const {
      taskId,
      comments,
      rejectTask,
      canReject
    } = request;
    const payload = {
      variables: {
        unlockRejected: null,
        comments: {
          type: 'Json',
          value: JSON.stringify(comments)
        }
      }
    };

    // If is rejected, add property to payload
    if (rejectTask) set(payload, 'variables.unlockRejected', { value: true });
    // Check if was rejected before
    if (canReject) set(payload, 'variables.unlockRejected', { value: false });

    const url = `/sif/bpf/camunda/v7/task/${taskId}/complete`;
    yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(payload, [undefined, null])
    });

    yield put({ type: UNLOCK_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: UNLOCK_FORM_SAVE_REJECTED, error });

    return error;
  }
}
