import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import fetchUserSettings from '../UserSettings/fetchUserSettings';

export default function* bootstrapUserSettings() {
  const session = yield select((state) => get(state, 'login.session', {}));
  const { fulfilled = false, data = {} } = yield select((state) => get(state, 'userSettings.settings', {}));

  if (fulfilled) return data;

  return yield call(fetchUserSettings, { userId: get(session, 'id') });
}
