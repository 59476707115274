import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, uniq } from 'lodash';

import userRolesAction from '../../actions/Acl/UserRoles';
import { userExceptions, developAdmins } from '../../fakes/acl';
import { isLocalhost } from '../../lib/Utils';

const {
  USER_ROLES_PENDING,
  USER_ROLES_FULFILLED,
  USER_ROLES_REJECTED
} = userRolesAction;

export function* getPermission(consultantId) {
  let permission = [];

  try {
    const { data } = yield call(axios, {
      url: `/person/consultant/${consultantId}?select(permission)`,
      method: 'get'
    });

    permission = get(data, 'permission', []);

    // Overwrite permission for local dev as they're not always set on dev-pub
    if (isLocalhost() && developAdmins.includes(consultantId)) {
      permission = ['ADMINISTRATOR'];
    }
  } catch (error) {
    permission = [];
  }

  return permission;
}

export default function* getUserRoles(request) {
  yield put({ type: USER_ROLES_PENDING });

  try {
    const { consultantId } = request;
    const roles = yield call(getPermission, consultantId);

    // Well... there are some users that don't have an ad account (and no assigned groups to map the roles to).
    // We need to add an exception for all these users as they must have the backoffice role as well
    // https://issue.swisscom.ch/browse/MAP-2810
    const exception = get(userExceptions, consultantId, []);
    const payload = uniq([...roles, ...exception, 'ALL_USERS']);

    yield put({ type: USER_ROLES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: USER_ROLES_REJECTED, error });

    return error;
  }
}
