import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { get } from 'lodash';

const AccountHolder = React.memo(({ process }) => {
  const { accountHolder } = process;

  const yes = <FormattedMessage id="General.Yes" />;
  const no = <FormattedMessage id="General.No" />;

  return (
    <div className="workguide-onboarding-card--account-holder">
      <Row>
        <Col lg={12} md={12}>
          <strong>
            <FormattedMessage id="Onboarding.ProcessInfo.AccountHolder.Title" />
          </strong>
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.AccountHolder.Designation" />
        </Col>

        <Col lg={8} md={8}>
          {`${get(accountHolder, 'clientDesignation', '')} (${get(accountHolder, 'editClientNumber', '')})`}
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.AccountHolder.Account" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'editAccountNumber', '')}
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.BaseContract" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'clientKeyBasisContract', false) ? yes : no}
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.AiaFatca" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'clientKeyAiaFatcaDeclaration', false) ? yes : no}
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.Identification" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'clientKeyIsIdentityOk', false) ? yes : no}
        </Col>
      </Row>
    </div>
  );
});

AccountHolder.propTypes = {
  process: PropTypes.object
};

AccountHolder.defaultProps = {
  process: {}
};

export default AccountHolder;
