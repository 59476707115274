import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';

import './Links.css';

const HeaderLinks = React.memo(() => {
  return (
    <React.Fragment>
      <NavItem>
        <NavLink
          className="layout-header-navbar-links--link"
          tag={RouterLink}
          to="/dashboard"
        >
          <FormattedMessage id="Header.HeaderLinks.Dashboard" />
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          className="layout-header-navbar-links--link"
          tag={RouterLink}
          to="/leadmanagement"
        >
          <FormattedMessage id="Header.HeaderLinks.Lead" />
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          className="layout-header-navbar-links--link"
          tag={RouterLink}
          to="/workbench/overview"
        >
          <FormattedMessage id="Header.HeaderLinks.Workbench" />
        </NavLink>
      </NavItem>
    </React.Fragment>
  );
});

export default HeaderLinks;
