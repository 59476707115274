import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { get } from 'lodash';

import actions, { saveUserSettingsAction } from '../../actions/UserSettings';

const { SAVE_USERSETTINGS_FULFILLED } = actions;

export const reducer = DefaultReducer('save', saveUserSettingsAction);
reducer.replaceMethod(
  SAVE_USERSETTINGS_FULFILLED,
  (state, action) => update(state, {
    save: {
      requesting: { $set: false },
      error: { $set: false }
    },
    settings: {
      data: { $set: get(action, 'payload', reducer.defaultState.settings) }
    }
  })
);

export default reducer.get();
