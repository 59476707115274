import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import './Image.css';
import moment from 'moment';
import { get, isNil, noop, omit } from 'lodash';
import ProfileImage from './ProfileImage';

const UserProfileImage = React.memo((props) => {
  const {
    className,
    consultant,
    disabled,
    itemProp: { latestReleaseNote, readAt, readAtFulfilled },
    fileActions,
    profilePictures,
    onToggle,
    ...rest
  } = props;

  const classNames = cl({
    'layout-header-user-profile--image': true,
    'layout-header-user-profile--image--disabled': disabled
  }, className);

  const hasNewReleaseNotes = (readAtFulfilled && isNil(readAt)) || (
    !isNil(readAt)
    && readAtFulfilled
    && latestReleaseNote
    && moment(readAt) <= moment(get(latestReleaseNote, 'publishDate'))
  );

  return (
    <div
      {...omit(rest, 'userSwitch')}
      className={classNames}
      onClick={onToggle}
    >
      {hasNewReleaseNotes && (
        <span className="user-profile--news-badge">
          <span className="mdi mdi-bell-outline" style={{ color: 'white' }} />
        </span>
      )}
      <div className="layout-header-user-profile--image--container">
        <ProfileImage consultant={consultant} profilePictures={profilePictures} fileActions={fileActions} withAlternativeUserImage />
      </div>
    </div>
  );
});

UserProfileImage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  itemProp: PropTypes.shape({
    readAt: PropTypes.string,
    readAtFulfilled: PropTypes.bool,
    latestReleaseNote: PropTypes.object
  }),
  consultant: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  profilePictures: PropTypes.object,
  fileActions: PropTypes.object.isRequired,
  onToggle: PropTypes.func
};

UserProfileImage.defaultProps = {
  className: undefined,
  disabled: false,
  itemProp: {
    readAt: undefined,
    readAtFulfilled: false,
    latestReleaseNote: {
      publishDate: undefined,
      id: undefined
    }
  },
  profilePictures: {},
  onToggle: noop
};

export default UserProfileImage;
