import { DefaultReducer } from '@evoja-web/redaction';
import action, { externalLinkAction } from '../actions/ExternalLink';
import update from 'immutability-helper';
import { get, has, last, set } from 'lodash';

function getLinksByCategories(payload) {
  return payload.reduce((result, item) => {
    const assignedCategories = get(item, 'categories', [])
    .map((c) => last(get(c, '$ref', '').split('/')));

    assignedCategories.forEach((cat) => {
      if (!has(result, cat)) set(result, cat, []);
      result[cat].push(item);
    });

    if(get(assignedCategories, 'length', 0) <= 0) {
      if (!has(result, 'uncategorised')) set(result, 'uncategorised', []);
      result.uncategorised.push(item);
    }

    return result;
  }, {});
}

const { EXTERNAL_LINK_FULFILLED } = action;
function afterExternalLinkFulfilled(state, action) {
  const { payload = [] } = action;

  return update(state, {
    allLinks: { $set: payload },
    categories: { $set: getLinksByCategories(payload) },
  });
}

export const reducer = DefaultReducer('externalLinks', externalLinkAction);
reducer.after(EXTERNAL_LINK_FULFILLED, afterExternalLinkFulfilled);

export default reducer.get();
