import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import moment from 'moment';
import kpiHistoryAction from '../../actions/Kpi/KpiHistory';

const {
  KPI_HISTORY_PENDING,
  KPI_HISTORY_FULFILLED,
  KPI_HISTORY_REJECTED
} = kpiHistoryAction;

export default function* getKpiHistory(request) {
  const { dataKey } = request;
  yield put({ type: KPI_HISTORY_PENDING, dataKey });

  try {
    const currentDate = moment();

    // Subtract 3 months
    const date3MonthsBefore = currentDate.subtract(3, 'months');

    const query = QueryBuilder()
      .eq('customerId', dataKey, { type: 'string' })
      .or()
      .and()
      .eq('balanceSubType.id', 'assetType-5998', { type: 'string' })
      .gt('entryDate', date3MonthsBefore.subtract(1, 'days').utc().format(), { type: 'string' })
      .end()
      .and()
      .eq('balanceSubType.id', 'assetType-5999', { type: 'string' })
      .lt('entryDate', date3MonthsBefore.add(1, 'days').utc().format(), { type: 'string' })
      .end()
      .end()
      .sort('+entryDate')
      .limit(999)
      .getQueryString();

    const url = `/person/customer-balance/history/${query}`;

    const data = yield call(handleNextHeaders, url);
    
    yield put({ type: KPI_HISTORY_FULFILLED, dataKey, payload: data });

    return data;
  } catch (error) {
    yield put({ type: KPI_HISTORY_REJECTED, dataKey, error });

    return error;
  }
}
