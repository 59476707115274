import { Reducers } from '@evoja-web/redaction';

import { reducer as searchAccountReducer } from './SearchAccount';
import { reducer as searchCustomerReducer } from './SearchCustomer';
import { reducer as searchByCustomerReducer } from './SearchByCustomer';

export const reducers = Reducers([
  searchAccountReducer,
  searchCustomerReducer,
  searchByCustomerReducer
]);

export default reducers.get();
