import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { head } from 'lodash';

import actions from '../../actions/UserSettings';

const {
  USERSETTINGS_PENDING,
  USERSETTINGS_FULFILLED,
  USERSETTINGS_REJECTED
} = actions;

export default function* fetchUserSettings(request) {
  const { userId } = request;
  yield put({ type: USERSETTINGS_PENDING });

  try {
    const { data } = yield call(axios, {
      url: `/person/usersettings/?eq(id,string:${userId})`,
      method: 'get'
    });

    yield put({ type: USERSETTINGS_FULFILLED, payload: head(data) });

    return head(data);
  } catch (error) {
    yield put({ type: USERSETTINGS_REJECTED, error });

    return error;
  }
}
