import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { withEnvironmentCheck } from '@evoja-web/react-environment-check';
import { Modal, ModalBody } from 'reactstrap';

import './EnvironmentCheck.css';
import clientInfo from '../../../clientInfo';

class EnvironmentCheck extends React.Component {
  constructor(props) {
    super(props);

    const {
      clientInfo,
      environment,
      onEnvironmentChange
    } = props;

    this.state = {
      isOpen: false
    };

    this.onChange = this.onChange.bind(this);

    onEnvironmentChange({
      callback: this.onChange,
      clientInfo,
      environment
    });
  }

  onChange(...args) {
    this.setState({ isOpen: true });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return (
      <div className="environment-change">
        <Modal
          className="environment-change-modal"
          fullscreen="xl"
          isOpen={isOpen}
          size="xl"
          backdrop={false}
        >
          <ModalBody>
            YAY
          </ModalBody>
        </Modal>
        {children}
      </div>
    );
  }
}

EnvironmentCheck.propTypes = {
  children: PropTypes.node,
  clientInfo: PropTypes.object.isRequired,
  environment: PropTypes.object.isRequired,
  onEnvironmentChange: PropTypes.func.isRequired
};

EnvironmentCheck.defaultProps = {
  children: []
};

function mapStateToProps(state, ownProps) {
  return {
    clientInfo,
    environment: get(state, 'environment.config')
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withEnvironmentCheck()(EnvironmentCheck)
);
