import { Reducers } from '@evoja-web/redaction';

import { reducer as existingContractReducer } from './BaseContract/ExistingContract';
import { reducer as existingOpeningReducer } from './BaseContract/ExistingOpening';
import { reducer as openingFormReducer } from './BaseContract/OpeningForm';

export const reducers = Reducers([
  existingContractReducer,
  existingOpeningReducer,
  openingFormReducer
]);

export default reducers.get();
