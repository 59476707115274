import { takeLatest } from 'redux-saga/effects';

import {
  getAccounts,
  getContracts,
  getCustomerBalance,
  getPricing,
  getProductbundleBundle,
  getProductbundleProduct,
  getProductbundleUsage,
  getProductbundleUsageDetail,
  getTextblocks
} from './SetAdvisor/index';

import setadvisorActions from '../actions/SetAdvisor';

const {
  ACCOUNTS_REQUEST,
  CONTRACTS_REQUEST,
  CUSTOMER_BALANCE_REQUEST,
  PRICING_REQUEST,
  PRODUCTBUNDLE_BUNDLE_REQUEST,
  PRODUCTBUNDLE_PRODUCT_REQUEST,
  PRODUCTBUNDLE_USAGE_REQUEST,
  PRODUCTBUNDLE_USAGE_DETAIL_REQUEST,
  TEXTBLOCKS_REQUEST
} = setadvisorActions;

export default function* root() {
  yield takeLatest(ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(CUSTOMER_BALANCE_REQUEST, getCustomerBalance);
  yield takeLatest(CONTRACTS_REQUEST, getContracts);
  yield takeLatest(PRODUCTBUNDLE_BUNDLE_REQUEST, getProductbundleBundle);
  yield takeLatest(PRODUCTBUNDLE_PRODUCT_REQUEST, getProductbundleProduct);
  yield takeLatest(PRODUCTBUNDLE_USAGE_REQUEST, getProductbundleUsage);
  yield takeLatest(TEXTBLOCKS_REQUEST, getTextblocks);
  yield takeLatest(PRICING_REQUEST, getPricing);
  yield takeLatest(PRODUCTBUNDLE_USAGE_DETAIL_REQUEST, getProductbundleUsageDetail);
}
