import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import _, { get } from 'lodash';
import { encodeRqlString } from '@evoja-web/redux-saga-utils';

import searchAccountAction from '../actions/SearchAccount';

const {
  SEARCH_ACCOUNT_PENDING,
  SEARCH_ACCOUNT_FULFILLED,
  SEARCH_ACCOUNT_REJECTED
} = searchAccountAction;

function* fetchRelatedCustomers(relatedCustomerNumbers) {
  const uniqueRelatedCustomerNumbers = _(relatedCustomerNumbers).compact().uniq().value();

  if (get(uniqueRelatedCustomerNumbers, 'length', 0) === 0) return {};

  const urlParts = [
    `in(customerNumber,(${uniqueRelatedCustomerNumbers.map((id) => `string:${encodeRqlString(id)}`)}))`,
    `limit(${uniqueRelatedCustomerNumbers.length},0)`
  ].join('&');

  const { data } = yield call(axios.get, `/person/customer/?${urlParts}`);

  // searching of not founded customers
  const notFoundedCustomerNumbers = uniqueRelatedCustomerNumbers.filter((customerNumber) => {
    return !data.find((foundedCustomer) => {
      return foundedCustomer.customerNumber === customerNumber;
    });
  });

  let dataMigration = [];
  if (notFoundedCustomerNumbers.length > 0) {
    const urlPartsMigration = [
      `in(customerNumber,(${notFoundedCustomerNumbers.map((customerNumber) => `string:${encodeRqlString(customerNumber)}`)}))`,
      `limit(${notFoundedCustomerNumbers.length},0)`
    ].join('&');

    const response = yield call(axios.get, `/person/customer-migration/?${urlPartsMigration}`);
    dataMigration = response.data.map((record) => ({
      ...record,
      customerNumberAlternate: get(record, 'customerNumber'),
      designation: get(record, 'lastName'),
      legalAddress: { city: get(record, 'city') },
      birthDate: get(record, 'birthDate'),
    }));
  }

  return _.keyBy([
    ...data,
    ...dataMigration.reverse(),
  ], 'customerNumber');
}

/**
 * Do the search
 *
 * @param  {Object} request Request object from throttle
 *
 * @return {Generator}
 */
export default function* migrationSearchAccount(request) {
  yield put({ type: SEARCH_ACCOUNT_PENDING });

  try {
    let term = get(request, 'accountTerm', '');

    term = term.replace(/[^\d,]+/g, '');

    const result = yield call(axios, {
      url: `/account/account-migration/?or(like(migrations.keyOrigin,${term}*),like(migrations.keyTarget,${term}*))`,
      method: 'get'
    });
    const accountMigrations = result.data;

    const customerNumbers = accountMigrations.map((row) => row.customerNumber);

    const customers = yield call(fetchRelatedCustomers, customerNumbers);

    const accountMigrationsWithCustomer = accountMigrations
      .map((row) => ({
        ...row,
        customer: customers[row.customerNumber]
          || {
            customerNumberAlternate: '-',
            designation: '-',
            legalAddress: { city: '-' },
            birthDate: '-',
          }
      }));

    accountMigrationsWithCustomer.forEach((row) => {
      // eslint-disable-next-line no-param-reassign
      row.migrations = row.migrations.filter((migration) => {
        if (migration.keyOrigin.indexOf(term) !== -1) {
          // eslint-disable-next-line no-param-reassign
          migration.keyOriginFound = true;
        }
        if (migration.keyTarget.indexOf(term) !== -1) {
          // eslint-disable-next-line no-param-reassign
          migration.keyTargetFound = true;
        }
        return migration.keyOriginFound || migration.keyTargetFound;
      });
    });

    yield put({
      type: SEARCH_ACCOUNT_FULFILLED,
      payload: accountMigrationsWithCustomer,
    });

    return accountMigrationsWithCustomer;
  } catch (error) {
    yield put({ type: SEARCH_ACCOUNT_REJECTED, error });
    return error;
  }
}
