import { Reducer } from '@evoja-web/redaction';
import { get } from 'lodash';
import update from 'immutability-helper';

import { actions as accountActions } from '../../../Account/index';
import updateAccountInPortfolios from './updateAccountInPortfolios';

const { RUBRIC_DELETE_FULFILLED } = accountActions;

function onAccountRubricDelete(state, action) {
  const { account } = get(action, 'payload', {});

  const updated = updateAccountInPortfolios(account, get(state, 'portfolios.data', []));

  return update(state, {
    portfolios: {
      data: { $set: updated }
    }
  });
}

export const reducer = Reducer('portfolios', { [RUBRIC_DELETE_FULFILLED]: onAccountRubricDelete });

export default reducer.get();
