import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';

const Documents = React.memo(({
  environment,
  customers
}) => {
  const children = get(customers, 'data', []).map((customer) => {
    const url = `${get(environment, 'config.edossier_url')}${get(customer, 'customerNumber')}`;

    return (
      <div key={get(customer, 'id')}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="react-anchor"
          href={url}
        >
          <FormattedMessage
            id="SecuritiesAccount.Unlock.Form.CustomerDocuments"
            values={{ designation: get(customer, 'designation') }}
          />
        </a>
      </div>
    );
  });

  return (
    <div>
      <Label>
        <strong>
          <FormattedMessage id="SecuritiesAccount.Unlock.Form.Documents" />
        </strong>
      </Label>

      {children}
    </div>
  );
});

Documents.propTypes = {
  customers: PropTypes.object,
  environment: PropTypes.object.isRequired
};

Documents.defaultProps = {
  customers: {}
};

export default Documents;
