function onResolveDefault(response) {
  return response;
}

function onRejectDefault(error) {
  return Promise.reject(error);
}

export default function AxiosResponseInterceptor({
  onResolve = onResolveDefault,
  onReject = onRejectDefault
}) {
  function register(axios) {
    axios.interceptors.response.use(onResolve, onReject);
  }

  return Object.freeze({
    register
  });
}
