import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import fundsAction from '../actions/Funds';

const {
  FUNDS_PENDING,
  FUNDS_FULFILLED,
  FUNDS_REJECTED
} = fundsAction;

export default function* getFunds() {
  yield put({ type: FUNDS_PENDING });

  try {
    const url = '/entity/code/?eq(group,fundinvestFund)&sort(+order)';
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: FUNDS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: FUNDS_REJECTED, error });

    return error;
  }
}
