import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import deleteAction from '../../actions/ForeignAsset/Delete';

const {
  FOREIGN_ASSET_DELETE_PENDING,
  FOREIGN_ASSET_DELETE_FULFILLED,
  FOREIGN_ASSET_DELETE_REJECTED
} = deleteAction;

export default function* deleteForeignAsset(request) {
  yield put({ type: FOREIGN_ASSET_DELETE_PENDING });

  try {
    const { id, balanceType } = request;

    const action = balanceType === 'asset' ? 'customerBalanceasset' : 'customerBalanceliability';

    yield call(axios, {
      url: '/ssp/action',
      method: 'post',
      data: {
        action,
        operation: 'delete',
        parameters: {
          recordId: id
        }
      }
    });

    const url = `/person/customer-balance/${id}`;

    const payload = yield call(axios, {
      url,
      method: 'delete'
    });

    yield put({ type: FOREIGN_ASSET_DELETE_FULFILLED, id, payload });

    return payload;
  } catch (error) {
    yield put({ type: FOREIGN_ASSET_DELETE_REJECTED, error });

    return error;
  }
}
