import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ValidationResult } from '@evoja-web/react-form';

import { SelectableList } from '../../../../General/index';
import ContractListItem from './ContractListItem';

const ContractList = React.memo(({
  bpfContracts,
  codes,
  customers,
  language,
  selected,
  onChange,
  validations
}) => {
  return (
    <div className="securities-account-unlock-contract-list">
      <Label>
        <FormattedMessage id="SecuritiesAccount.Unlock.Form.Contracts" />
      </Label>

      <SelectableList
        id="contracts"
        language={language}
        listItemComponent={ContractListItem}
        listItemProps={{
          codes,
          customers
        }}
        data={bpfContracts}
        selected={selected}
        onChange={onChange}
        multi
        disabled
      />

      <ValidationResult
        validations={validations}
        show
      />
    </div>
  );
});

ContractList.propTypes = {
  bpfContracts: PropTypes.array,
  codes: PropTypes.object,
  customers: PropTypes.object,
  language: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

ContractList.defaultProps = {
  bpfContracts: [],
  codes: { groups: {} },
  customers: {},
  language: 'de',
  selected: [],
  onChange: noop,
  validations: undefined
};

export default ContractList;
