import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import birthdaysAction from '../actions/Birthdays';

const {
  BIRTHDAYS_PENDING,
  BIRTHDAYS_FULFILLED,
  BIRTHDAYS_REJECTED
} = birthdaysAction;

export default function* getBirthdays(request) {
  yield put({ type: BIRTHDAYS_PENDING });

  try {
    const {
      consultantId,
      daysForward = 90
    } = request;

    const url = `/analytics/customerbirthdays?consultantId=${consultantId}&daysForward=${daysForward}&clientStatus=clientStatus-0`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: BIRTHDAYS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: BIRTHDAYS_REJECTED, error });

    return error;
  }
}
