import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { get, noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import { Toggle } from '@evoja-web/react-form';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import './BvgClassification.css';

const BvgClassification = React.memo(({ onChange, data }) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="Provision.Form.BvgClassification.Title" />
      </SectionTitle>
      <SectionContent>
        <Row>
          <Col>
            <Toggle
              id="occupationalPension"
              onChange={onChange}
              value={get(data, 'occupationalPension', false)}
            >
              <Row className="provision-bvg-classification--label-row">
                <Col md={12}>
                  <FormattedMessage id="Provision.Form.BvgClassification.OccupationalPension.Label" />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="provision-bvg-classification--description-col">
                  <FormattedMessage id="Provision.Form.BvgClassification.OccupationalPension.Description" />
                </Col>
              </Row>
            </Toggle>
          </Col>
        </Row>
        <Row>
          <Col className="provision-bvg-classification--document-change-beneficiary-desired-col">
            <Row className="provision-bvg-classification--label-row">
              <Col md={12}>
                <FormattedMessage id="Provision.Form.BvgClassification.DocumentChangeBeneficiaryDesired.Label" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormattedMessage id="Provision.Form.BvgClassification.DocumentChangeBeneficiaryDesired.Description" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <strong>
                  <FormattedMessage id="Provision.Form.BvgClassification.DocumentChangeBeneficiaryDesired.Description2" />
                </strong>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContent>
    </Section>
  );
});

BvgClassification.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object
};

BvgClassification.defaultProps = {
  onChange: noop,
  data: {}
};

export default injectIntl(BvgClassification);
