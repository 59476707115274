import { get } from 'lodash';
import { put } from 'redux-saga/effects';

import {
  ADD_TOAST
} from '../../actions/Toast';

/**
 * Default failed handler
 *
 * @param  {Object} result Result from request
 *
 * @return {Generator}
 */
export default function* defaultEventStatusFailed(result) {
  const messages = get(result, 'data.information', []).map((i) => get(i, 'content', '')).join('<br/> ');

  yield put({
    type: ADD_TOAST,
    kind: 'error',
    title: 'Error.EventStatus.FailedTitle',
    description: 'Error.EventStatus.FailedDescription',
    html: true,
    values: { eventId: get(result, 'data.id'), messages }
  });
}
