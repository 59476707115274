import { Actions } from '@evoja-web/redaction';

import { provisionAccountsAction } from './ProvisionAccounts';
import { withdrawalLimitCancellationFormAction } from './Form/WithdrawalLimitCancellationForm';
import { openPrivorRetirementSavingsAccountDepositFormAction } from './Form/OpenPrivorRetirementSavingsAccountDepositForm';

export const actions = Actions([
  provisionAccountsAction,
  withdrawalLimitCancellationFormAction,
  openPrivorRetirementSavingsAccountDepositFormAction
]);

export default actions.get();
