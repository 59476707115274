import { get } from 'lodash';
import update from 'immutability-helper';

export default function updateAccountInPortfolios(account, portfolios = []) {
  const accountId = get(account, 'id');

  return portfolios.map((portfolio) => {
    const index = get(portfolio, 'accounts', []).findIndex(({ id }) => id === accountId);
    if (index < 0) return portfolio;

    return update(portfolio, {
      accounts: {
        $splice: [[index, 1, account]]
      }
    });
  });
}
