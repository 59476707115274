export const guidedTour = [
    {
        "place": "dashboard",
        "items": [
            {
                "order": 1,
                "text": {
                    "de": {
                        "Suche": "Durch tippen finden Sie Kunden und Aktivitäten. Kunden können mit Eingabe Partnernummer, Name, Vorname, Ort gefunden werden.",
                        "Leads": "Die Lead-Zusammenfassung zeigen zu bearbeitende Leads, gruppiert nach Wichtigkeit und Dringlichkeit (=Score). Klick auf eine Gruppe zeigt detailliert die einzelnen Leads im Bereich Lead-Management.",
                        "Geburtstage": "Die Geburtstage Ihrer Kunden für die nächsten 30 Tagen werden angezeigt. Klick auf den Namen zeigt die 360 Grad Sicht des Kunden.",
                        "AktivitätenUndNotizen": "Es werden sämtliche Aktivitäten und Notizen zu Ihren Kunden aufgelistet. Nutzen Sie die nützlichen Filterfunktionen, um einen besseren Überblick zu erhalten. Führen Sie die Maus über längere Texte um die vollständige Notiz zu sehen.",
                        "Kunden": "Grafische Darstellung Ihrer Kunden gruppiert nach Segment und Kundengruppe. Klick auf «Mehr anzeigen» zeigt die vollständige Kundenliste.",
                        "KPIUndZiele": "Sehen sie hier Ihre KPIs und Ziele und deren Erreichungsgrad für die letzte Auswertungsperiode.",
                        "MeinProfil": "Hier kann die Spracheinstellung geändert werden. Laden Sie den MAP mit der Sicht eines anderen Beraters (beispielsweise bei einer Stellvertretung).  Von hier kann die diese geführte Tour wieder aktiviert werden.",
                        "Beratungsprozess": "Der Beratungsprozess ist hier abgebildet. Mit Klick auf die einzelnen Schritte finden Sie weitere Informationen zum Beratungsprozess"
                    },
                    "fr": {
                        "Suche": "En saisissant du texte, vous trouvez des clients et des activités. Les clients peuvent être recherchés en saisissant le numéro de partenaire, le nom, le prénom ou le lieu.",
                        "Leads": "Le récapitulatif des leads affiche les leads à traiter, regroupés selon l’importance et l’urgence de traitement (= score). Pour afficher les leads détaillés dans le domaine LeadManagement, cliquez sur le groupe correspondant.",
                        "Geburtstage": "Les dates d’anniversaire de vos clients sont affichées pour les trente prochains jours. En cliquant sur le nom du client, vous pouvez consulter les informations concernant le client à 360 degrés.",
                        "AktivitätenUndNotizen": "Toutes les activités et commentaires concernant vos clients sont affichés. Utilisez les filtres pour obtenir un meilleur aperçu. Passez votre souris sur les commentaires pour afficher la totalité du texte.",
                        "Kunden": "Un graphique présente les segments et les groupes dans lesquels vos clients sont classés. Cliquez sur « Afficher plus » (Mehr anzeigen) pour afficher la liste complète des clients." ,
                        "KPIUndZiele": "Consultez vos ICP, vos objectifs et leur degré de réalisation lors de la dernière période d’évaluation.",
                        "MeinProfil": "Ici vous pouvez modifier la langue de défaut et afficher votre profil TPC tel que le voient les autres conseillers (p.ex. pour un remplacement). Ici vous pouvez également revoir le tutoriel.",
                        "Beratungsprozess": "Le processus de conseil est affiché sous cet onglet. En cliquant sur les différentes étapes, vous trouverez des informations supplémentaires concernant le processus de conseil."
                    }
                }
            }
        ]
    },
    {
        "place": "grad360",
        "items": [
            {
                "order": 1,
                "text": {
                    "de": {
                        "Kunde": "Name, Vorname, Ort, Partnernummer und Geburtsdatum des Kunden werden angezeigt. Es stehen Funktionen zur Kontaktaufnahme (E-Mail, Telefon) und zur Identifikation des Kunden bereit. Klicken Sie den Stern, wenn Sie den selektierten Kunden als erstes in der Suche erscheinen soll.",
                        "Kundenberater": "Anzeige des Beraters des gewählten Kunden. Klicken Sie auf die Telefonnummer, um den Berater anzurufen. Klicken Sie auf die E-Mail-Adresse, um dem Berater eine E-Mail zu schreiben.",
                        "Grad360": "Die 360 Grad Sicht zeigt alle Informationen zu einem Kunden. Insbesondere Leads und Notizen. Klicken Sie auf einen Lead oder eine Notiz um die Details zu zeigen. Filtern Sie Ihre Leads, um beispielsweise auch erledigte anzuschauen. Die Übersicht wird in den kommenden Wochen laufend ergänzt"
                    },
                    "fr": {
                        "Kunde": "Nom, prénom, lieu et date de naissance du client sont affichés ici. Des fonctions sont à votre disposition pour la prise de contact (par e-mail ou téléphone) et l’identification du client. Cliquez sur l’étoile si vous souhaitez que le client sélectionné s’affiche en premier dans la fonction de recherche.",
                        "Kundenberater": "Cette rubrique permet d’afficher le conseiller du client sélectionné. Cliquez sur le numéro de téléphone pour appeler le conseiller. Cliquez sur l’adresse e-mail pour le contacter par e-mail.",
                        "Grad360": "La vue à 360 degrés d’un client permet d’afficher la totalité des informations concernant le client. Notamment les leads et les commentaires. Cliquez sur un lead ou sur un commentaire pour afficher les détails. Appliquez des filtres à vos leads, pour afficher p. ex. ceux qui ont déjà été réalisés. L’aperçu sera complété en continu durant les prochaines semaines."
                    }
                }
            }
        ]
    },
    {
        "place": "workguide",
        "items": [
            {
                "order": 1,
                "text": {
                    "de": {
                        "Shortcuts": "Diese Links führen Sie auf derselben Seite direkt zu den gewünschten Information.",
                        "Kontextbereich": "Im Kontextbereich sehen Sie die verantwortlichen Personen/Stellen, die durchschnittliche Erledigungszeit der gewählten Aktivität, sowie allfällige Anmeldeinformationen (bei einem Absprung in ein Drittsystem)."
                    },
                    "fr": {
                        "Shortcuts": "Ces liens vous permettent d’accéder directement à l’information souhaitée sur la même page.",
                        "Kontextbereich": "Dans la zone de contexte vous pouvez consulter les différentes personnes/fonctions, le temps de réalisation moyen de l’activité sélectionnée tout comme d’éventuelles informations de connexion (lors d’un écart vers un système tiers)."
                    }
                }
            }
        ]
    },
    {
        "place": "leadManagement",
        "items": [
            {
                "order": 1,
                "text": {
                    "de": {
                        "SucheUndFilter": "Es kann nach Kunden (Vorname, Name) und nach der Lead-Art (Pendenz aus KB) gesucht werden. Die zahlreichen Filterfunktionen ermöglichen eine Einschränkung auf bestimmte Kriterien, wie z.B. auf Pendenzen, nur ungelesene Leads, einen bestimmten Score-Bereich (Score < 50), etc.",
                        "Leadmanagement": "Leads können mittels Drag & Drop in den aktuellen Bearbeitungszustand verschoben werden. Je nach Leadkategorie stehen andere Bearbeitungszustände zur Verfügung.",
                        "Lead": "Der Lead zeigt Informationen zum Kunden, die Lead-Art, den Score, sowie Termindatum und Verfalldatum. Doppelklick auf dem Lead zeigt seine Details. Wurde der Lead im Detail bearbeitet, gilt er als gelesen (blauer Punkt auf Lead verschwindet).Das «Ketten» Symbol zeigt, welche anderen Leads dieses Kunden vorhanden sind. Mit Klick auf das Symbol werden nur noch die Leads dieses Kunden angezeigt.",
                        "LeadQualifikation": "Neu eingespielte Leads müssen täglich qualifiziert werden. Ist der Lead angenommen, wird er automatisch in den Status «offen» überführt. Abgelehnte Leads gehen zum Leadmanager zurück."
                    },
                    "fr": {
                        "SucheUndFilter": "Il est possible d’effectuer une recherche du client (prénom, nom) et du type de lead (en suspens chez le conseiller à la clientèle). Les différents filtres permettent de restreindre les critères, comme les opérations en cours, les leads nonlus, un score précis (score < 50) etc.",
                        "Leadmanagement": "Les leads peuvent être transférés vers le statut de traitement via la fonction drag & drop. Différents statuts de traitement sont disponibles selon la catégorie de lead.",
                        "Lead": "Le lead affiche les informations du client, le type de lead, le score tout comme le délai et la date d’échéance.  Cliquez deux fois sur le lead pour en afficher les détails. Lorsque le lead a été traité dans les détails, il apparaît comme lu (le point bleu sur le lead disparaît). Le symbole de « chaîne » montre quels sont les autres leads disponibles pour ce client. En cliquant sur le symbole, seulement les leads du client en question sont affichés.",
                        "LeadQualifikation": "Les nouveaux leads doivent être qualifiés quotidiennement. Lorsque le lead est accepté, il passe automatiquement au statut « ouvert » (offen). Les leads refusés sont renvoyés au lead-manager."
                    }
                }
            }
        ]
    }
]
