import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty, noop, toNumber } from 'lodash';
import { Col, Row, Button, Form, FormGroup, Label } from 'reactstrap';
import { withCodeGroups } from '@evoja-web/entity-code';

import './CreateRelation.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CustomerSearch,
  InputField,
  Select
} from '../../General/index';
import { getValidator } from '../../../globals';
import { withWorkguideInstanceData } from '../../Workguide';
import withWorkguideModalToggle from '../../Workguide/hocs/withWorkguideModalToggle';
import relationActions from '../actions/Actions';

const validator = getValidator();

class CreateRelation extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeClass = this.onChangeClass.bind(this);
  }

  componentDidMount() {
    const { relationActions, language } = this.props;
    relationActions.relationFormInitRequest({ language });
  }

  onSubmit() {
    const {
      relationActions,
      customer,
      toggleRedirectModal,
      form,
      relationCodes,
      language
    } = this.props;

    const relationCode = get(relationCodes, 'data').find((c) => get(c, 'relationshipType') === get(form, 'relationType')
      && get(c, 'relationshipClass') === get(form, 'relationClass'));

    const requestObject = {
      ...form,
      customerId: get(customer, 'id'),
      isExisting: get(form, 'isExisting'),
      relationCode,
      language
    };

    relationActions.relationFormSaveRequest(requestObject);

    // Close workguide
    toggleRedirectModal();
  }

  onChangeClass(identifier, value) {
    const { relationActions } = this.props;
    relationActions.relationFormSetValue('relationType', undefined);
    relationActions.relationFormSetValue(identifier, value);
  }

  validate() {
    const { requiredFields, form } = this.props;

    const definition = {
      validations: {
        relationType: {
          type: 'number',
          required: requiredFields.includes('relationType')
        },
        customerRelatedTo: {
          type: 'object',
          required: requiredFields.includes('customerRelatedTo')
        },
        relationClass: {
          type: 'number',
          required: requiredFields.includes('relationClass')
        }
      }
    };

    return validator.validate(definition, form);
  }

  render() {
    const {
      form,
      relationActions,
      codes,
      language
    } = this.props;

    const validations = this.validate();

    const groups = get(form, 'relationTypeClassGroups', {});
    const types = get(codes, 'groups.relationType', []);
    const classes = get(codes, 'groups.relationClass', []);

    // Prepare codes for relationClass
    const relationClassOptions = classes.filter((c) => Object.keys(groups).includes(get(c, 'number'))).map((c) => ({ value: toNumber(c.number), label: get(c, `text.${language}`) }));

    // Prepare codes for relationType
    const relationTypeOptions = types.filter((type) => get(groups, `${get(form, 'relationClass')}`, []).includes(toNumber(get(type, 'number'))))
      .map((el) => ({ value: toNumber(el.number), label: get(el, `text.${language}`) }));

    return (
      <Form className="create-form">
        <Row>
          <Col lg={6} md={6}>
            <FormGroup>
              <Label for="relationClass">
                <FormattedMessage id="Customer.Relations.RelationClass" />
              </Label>

              <Select
                options={relationClassOptions}
                id="relationClass"
                name="relationClass"
                onChange={this.onChangeClass}
                showValidations
                validations={validations.relationClass}
                value={get(form, 'relationClass')}
              />
            </FormGroup>
          </Col>

          <Col lg={6} md={6}>
            <FormGroup>
              <Label for="relationType">
                <FormattedMessage id="Customer.Relations.Part" />
              </Label>

              <Select
                options={relationTypeOptions}
                id="relationType"
                name="relationType"
                onChange={relationActions.relationFormSetValue}
                showValidations
                validations={validations.relationType}
                value={get(form, 'relationType')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <Label for="customerRelatedTo">
                <FormattedMessage id="Customer.Relations.Customer" />
              </Label>

              <CustomerSearch
                id="customerRelatedTo"
                isClearable
                value={get(form, 'customerRelatedTo')}
                onChange={relationActions.relationFormSetValue}
                showValidations
                validations={validations.customerRelatedTo}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormGroup>
              <Label for="comment">
                <FormattedMessage id="Customer.Relations.Note" />
              </Label>

              <InputField
                type="textarea"
                id="comment"
                name="comment"
                rows={4}
                validations={validations.comment}
                onChange={relationActions.relationFormSetValue}
                value={get(form, 'comment')}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <Button
              color="primary"
              disabled={!isEmpty(validations)}
              onClick={this.onSubmit}
            >
              <FormattedMessage id="Customer.Relations.Save" />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

CreateRelation.propTypes = {
  customer: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
  relationActions: PropTypes.object.isRequired,
  requiredFields: PropTypes.array,
  relationCodes: PropTypes.object,
  form: PropTypes.object,
  codes: PropTypes.object,
  language: PropTypes.string
};

CreateRelation.defaultProps = {
  toggleRedirectModal: noop,
  requiredFields: ['relationClassification', 'relationType', 'relationPart', 'customerRelatedTo', 'relationClass'],
  relationCodes: {},
  form: {},
  codes: { groups: {} },
  language: 'de'
};

function mapStateToProps(state) {
  const { language } = state.login;
  return {
    customer: state.powersearch.customer,
    language,
    codeMapping: state.relation.codeMapping,
    form: get(state, 'relation.relationForm.data'),
    relationCodes: get(state, `relation.relationCodes.${language}`)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    relationActions: bindActionCreators(relationActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withWorkguideModalToggle()(withCodeGroups({ groups: ['relationType', 'relationClass'] })(
    connect(mapStateToProps, mapDispatchToProps)(CreateRelation)
  ))
);
