import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isArray } from 'lodash';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withCodeGroups } from '@evoja-web/entity-code';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';
import { Checkbox } from '@evoja-web/react-form';

import { ProcessComments } from '../../../../Bpf/index';
import CustomerInfo from './CustomerInfo';

class ValidateOnboardingForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onCommentsChange = this.onCommentsChange.bind(this);
  }

  /**
   * Handle process comments change
   *
   * @param   {Array}  comments  Array of comments
   *
   * @return  void
   */
  onCommentsChange(comments) {
    const { onChange } = this.props;

    onChange('comments', comments);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfTask,
      codes,
      customer,
      environment,
      language,
      session,
      showCustomerInfo
    } = this.props;

    const comments = !isArray(get(bpfTask, 'data.process.comments', []))
      ? []
      : get(bpfTask, 'data.process.comments', []);

    return (
      <div>
        <Checkbox
          id="pepCheck"
          label={<FormattedMessage id="Onboarding.Process.PepCheck" />}
          value={!get(bpfTask, 'data.process.pepCheck', false)}
          disabled
        />

        <Checkbox
          id="domicileCH"
          label={<FormattedMessage id="Onboarding.Process.DomicileCH" />}
          value={get(bpfTask, 'data.process.domicileCH', false)}
          disabled
        />

        <Checkbox
          id="beneficialOwner"
          label={<FormattedMessage id="Onboarding.Process.BeneficialOwner" />}
          value={get(bpfTask, 'data.process.customerInformation.legalInformation.beneficialOwner', false)}
          disabled
        />

        <Checkbox
          id="AiaFatca"
          label={<FormattedMessage id="Onboarding.Process.AiaFatca" />}
          value={
            get(bpfTask, 'data.process.customerInformation.legalInformation.aia', false)
            || get(bpfTask, 'data.process.customerInformation.legalInformation.fatca', false)
          }
          disabled
        />

        <Checkbox
          id="beneficialOwner"
          label={<FormattedMessage id="Onboarding.Process.IdentificationInformation" />}
          value={get(bpfTask, 'data.process.clientIdentification.identificationStatus') === 'green'}
          disabled
        />

        {showCustomerInfo && (
          <CustomerInfo bpfTask={bpfTask} codes={codes} language={language} />
        )}

        <Row style={{ paddingTop: '15px' }}>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Documents.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <div style={{ paddingBottom: '15px' }}>
          <a
            className="text-primary"
            href={get(bpfTask, 'data.process.camUserInformation.url')}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="Onboarding.CamLink" />
          </a>
        </div>

        <Row style={{ paddingBottom: '2rem' }}>
          <Col lg={12} md={12}>
            <a
              className="text-primary"
              href={`${get(environment, 'config.edossier_url')}${get(customer, 'customerNumber')}`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="Onboarding.CustomerInfo.OpenEDossier" />
            </a>
          </Col>
        </Row>

        <Alert color="type" style={{ width: '100%' }}>
          <AlertContent>
            <FormattedMessage id="Onboarding.Warning.Validate" />
          </AlertContent>
        </Alert>

        <Row style={{ paddingTop: '15px' }}>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Documents.Comments" />
            </h5>
            <hr />
          </Col>
        </Row>

        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12}>
            <ProcessComments
              comments={comments}
              onChange={this.onCommentsChange}
              readOnly={false}
              session={session}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ValidateOnboardingForm.propTypes = {
  bpfTask: PropTypes.object,
  codes: PropTypes.object,
  customer: PropTypes.object,
  environment: PropTypes.object.isRequired,
  language: PropTypes.string,
  onChange: PropTypes.func,
  session: PropTypes.object.isRequired,
  showCustomerInfo: PropTypes.bool
};

ValidateOnboardingForm.defaultProps = {
  bpfTask: {},
  codes: { groups: {} },
  customer: undefined,
  language: 'de',
  onChange: noop,
  showCustomerInfo: true
};

export default withCodeGroups({ groups: ['documentIdType', 'customerCheckType', 'country'] })(ValidateOnboardingForm);
