import React from 'react';
// eslint-disable-next-line
import chrome_ie from './chrome_ie.png';

function UseChrome() {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ maxWidth: 450, textAlign: 'center' }}>
          <h1>Valiant MAP / PTC Valiant</h1>
          <div style={{ textAlign: 'center', color: 'red', paddingBottom: '20px' }}>
            {/* eslint-disable-next-line */}
            <img src={chrome_ie} alt="use chrome" />
            <h2>Bitte öffnen Sie den Link im Chrome-Browser..</h2>
            {/* eslint-disable-next-line */}
            <h2>Veuillez ouvrir le lien dans le navigateur Chrome.</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseChrome;
