import { put } from 'redux-saga/effects';
import {
  GUIDED_TOUR_GET_TEXT_FULFILLED,
  GUIDED_TOUR_REJECTED
} from '../../actions/GuidedTour';
import { guidedTour } from '../../fakes/guidedTour';

export default function* getGuidedTourText() {
  try {
    const data = guidedTour;
    yield put({ type: GUIDED_TOUR_GET_TEXT_FULFILLED, data });
  } catch (error) {
    yield put({ type: GUIDED_TOUR_REJECTED });
  }
}
