import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import customerManyCapitalTaxesFormAction from '../../actions/CustomerManyCapitalTaxesForm';
import getCustomerFamily from '../../../CustomerBaseData/sagas/CustomerFamily/getCustomerFamily';
import getCustomerDetail from '../../../CustomerBaseData/sagas/CustomerDetail/getCustomerDetail';

const {
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_PENDING,
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_FULFILLED,
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_REJECTED
} = customerManyCapitalTaxesFormAction;

export default function* getCustomerManyCapitalTaxes(request) {
  const { customer, account } = request;

  yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_PENDING });

  try {
    // get Account balance
    const totalAccountBalance = parseFloat(get(account, 'provision.balancePrivor', 0)) + parseFloat(get(account, 'provision.balanceRevor', 0));
    const totalDepotBalance = get(account, 'positions', [])
      .reduce((acc, pos) => parseFloat(acc) + parseFloat(get(pos, 'provision.valuePrivor', 0)) + parseFloat(get(pos, 'provision.valueRevor', 0)), 0);
    const capital = totalAccountBalance + totalDepotBalance;

    // get Family data
    const customerFamilyData = yield call(getCustomerFamily, { customerId: get(customer, 'id') });

    // get confession
    const customerDetailData = yield call(getCustomerDetail, { customerId: get(customer, 'id') });

    const payload = {
      maritalStatus: get(customer, 'maritalStatus'),
      confession: get(customerDetailData, 'confession'),
      partnerConfession: undefined,
      numberOfChildren: get(customerFamilyData, 'childrenLiableForCount', 0),
      gender: get(customer, 'sex'),
      ageAtPayment: get(customer, 'age', 0),
      capital
    };

    yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_REJECTED, error });

    return error;
  }
}
