import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import _, { get } from 'lodash';
import moment from 'moment';
import { encodeRqlString } from '@evoja-web/redux-saga-utils';

import searchByCustomerAction from '../actions/SearchByCustomer';

const {
  SEARCH_BY_CUSTOMER_PENDING,
  SEARCH_BY_CUSTOMER_FULFILLED,
  SEARCH_BY_CUSTOMER_REJECTED
} = searchByCustomerAction;

function* fetchRelatedAccountsById(relatedCustomerAccounts) {
  const uniqueRelatedCustomerAccounts = _(relatedCustomerAccounts).compact().uniq().value();

  if (get(uniqueRelatedCustomerAccounts, 'length', 0) === 0) return {};

  const urlParts = [
    `in(number,(${uniqueRelatedCustomerAccounts.map((id) => `${encodeRqlString(id)}`)}))`,
    `limit(${uniqueRelatedCustomerAccounts.length},0)`
  ].join('&');

  const { data } = yield call(axios.get, `/account/account/?${urlParts}`);
  return _.keyBy(data, 'number');
}

/**
 * Do the search
 *
 * @param  {Object} request Request object from throttle
 *
 * @return {Generator}
 */
export default function* searchByCustomer(request) {
  yield put({ type: SEARCH_BY_CUSTOMER_PENDING });

  const customerNumber = get(request, 'customerNumber', '');

  try {
    const customers = yield call(axios, {
      url: `/person/customer-migration/?eq(customerNumber,string:${customerNumber})`,
      method: 'get'
    });
    const customerMigrations = customers.data;
    const accounts = yield call(axios, {
      url: `/account/account-migration/?eq(customerNumber,string:${customerNumber})`,
      method: 'get'
    });
    const accountMigrations = accounts.data;

    // eslint-disable-next-line
    const relatedAccountNumbers = [...new Set([].concat(...accountMigrations.map((row) => get(row, 'migrations'))))].map((target) => target.keyTarget);
    const accountByAccountNumber = yield call(fetchRelatedAccountsById, relatedAccountNumbers);

    accountMigrations.forEach((accountMigration) => {
      const accountByNumber = accountByAccountNumber[accountMigration.migrations[0].keyTarget];
      if (accountByNumber) {
        // eslint-disable-next-line no-param-reassign
        accountMigration.account = accountByNumber;
      }
      accountMigration.migrations.sort((a, b) => (moment(b.migrationDate) - moment(a.migrationDate)));
    });

    customerMigrations.forEach((customerMigration) => {
      customerMigration.migrations.sort((a, b) => (moment(b.migrationDate) - moment(a.migrationDate)));
    });

    yield put({ type: SEARCH_BY_CUSTOMER_FULFILLED,
      payload: {
        customerMigrations,
        accountMigrations,
      } });
    return true;
  } catch (error) {
    yield put({ type: SEARCH_BY_CUSTOMER_REJECTED, error });
    return error;
  }
}
