import { call, put } from 'redux-saga/effects';

import axios from 'axios';
import actions from '../actions/Actions';

const {
  FEATURE_FLAG_PENDING,
  FEATURE_FLAG_FULFILLED,
  FEATURE_FLAG_REJECTED
} = actions;

export default function* getFeatureFlags() {
  yield put({ type: FEATURE_FLAG_PENDING });

  try {
    const { data } = yield call(axios.get, '/core/featureflag/');

    yield put({ type: FEATURE_FLAG_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: FEATURE_FLAG_REJECTED, error });

    return error;
  }
}
