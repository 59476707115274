import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import textblockActions from '../../actions/SetAdvisor/Textblock';

const {
  TEXTBLOCKS_PENDING,
  TEXTBLOCKS_FULFILLED,
  TEXTBLOCKS_REJECTED
} = textblockActions;

export default function* getTextblocks() {
  yield put({ type: TEXTBLOCKS_PENDING });
  try {
    const url = '/core/textblock/?in(output.ref.$id,(lilaSetAdvisor,businessSetAdvisor))&limit(9999)';
    // const url = `/core/textblock/?eq(output.ref.$id,lilaSetAdvisor)&limit(9999)`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: TEXTBLOCKS_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: TEXTBLOCKS_REJECTED, error });

    return error;
  }
}
