import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment/moment';
import taxLocationAction from '../actions/TaxLocation';

const {
  TAX_LOCATION_PENDING,
  TAX_LOCATION_FULFILLED,
  TAX_LOCATION_REJECTED
} = taxLocationAction;

export default function* getTaxLocation(request) {
  const { dataKey, customer } = request;

  yield put({ type: TAX_LOCATION_PENDING, dataKey });

  try {
    const addressString = `${get(customer, 'legalAddress.zipCode')} ${get(customer, 'legalAddress.city')}`;

    const urlTaxLocation = '/tax/API_searchLocation';
    const dataTaxLocation = {
      Search: addressString,
      Language: 1,
      TaxYear: moment().year()
    };

    const { data: payloadTaxLocation } = yield call(axios, {
      url: urlTaxLocation,
      method: 'post',
      data: dataTaxLocation
    });

    if (get(payloadTaxLocation, 'response.length', 0) !== 1) {
      throw Error('Could not get Tax Location');
    }
    const payload = get(payloadTaxLocation, 'response.0');

    yield put({ type: TAX_LOCATION_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: TAX_LOCATION_REJECTED, dataKey, error });

    return error;
  }
}
