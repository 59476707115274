import { registerCustomWorkguide } from '../Workguide/index';
import WorkguideSecuritiesAccountOpening from './containers/Workguide/Opening';
import WorkguideSecuritiesAccountUnlock from './containers/Workguide/Unlock';

/**
 * Register workguides
 *
 * @return  void
 */
export default function initBpfSecuritiesAccountPackage() {
  registerCustomWorkguide({ key: 'SecuritiesAccountOpeningForm', component: WorkguideSecuritiesAccountOpening });
  registerCustomWorkguide({ key: 'SecuritiesAccountUnlockForm', component: WorkguideSecuritiesAccountUnlock });
}
