import { call, put } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import contractsAction from '../actions/Contracts';

const {
  CONTRACTS_PENDING,
  CONTRACTS_FULFILLED,
  CONTRACTS_REJECTED
} = contractsAction;

export default function* getContracts(request) {
  yield put({ type: CONTRACTS_PENDING });

  try {
    const { contractIds } = request;

    const query = QueryBuilder()
      .in('number', contractIds, { type: 'string' })
      .getQueryString();

    const url = `/entity/contract/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: CONTRACTS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONTRACTS_REJECTED, error });

    return error;
  }
}
