import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from './ToastMessage';

export default class ToastHandler extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { props } = this;

    return nextProps.data !== props.data;
  }

  render() {
    const { data } = this.props;

    if (data && _.isFunction(toast[data.kind])) {
      toast[data.kind](
        <ToastMessage
          title={data.title}
          description={data.description}
          values={data.values}
          html={data.html}
          onClick={data.onClick}
        />
      );
    }

    return (
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={10000}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        style={{ zIndex: '99999' }}
      />
    );
  }
}

ToastHandler.propTypes = {
  data: PropTypes.object
};

ToastHandler.defaultProps = {
  data: undefined
};
