import { Reducers } from '@evoja-web/redaction';

import { reducer as provisionAccountsReducers } from './ProvisionAccounts';
import { reducer as withdrawalLimitCancellationFormReducers } from './Form/WithdrawalLimitCancellationForm';
import { reducer as openPrivorRetirementSavingsAccountDepositFormReducers } from './Form/OpenPrivorRetirementSavingsAccountDepositForm';

export const reducers = Reducers([
  provisionAccountsReducers,
  withdrawalLimitCancellationFormReducers,
  openPrivorRetirementSavingsAccountDepositFormReducers
]);

export default reducers.get();
