import _ from 'lodash';
import { Reducers } from '@evoja-web/redaction';

import { reducer as customerGroupsReducer } from './CustomerGroups';
import { reducer as customerSegmentsReducer } from './CustomerSegments';
import { reducer as kpiReducer } from './Kpi';
import { reducer as mboReducer } from './Mbo';
import { reducer as outlookCalendarReducer } from './OutlookCalendar';
import { reducer as customerIdByCustomerNumberReducer } from './CustomerIdByCustomerNumber';
import { reducer as consultantInsightsReducer } from './ConsultantInsights';
import { reducer as outlookCalendarAndBirthdaysReducer } from './OutlookCalendarAndBirthdays';
import { reducer as birthdaysReducer } from './Birthdays';

/**
 * Return a copy of activity filters.
 * This is important and the reason why activityFilters are not exported.
 * If we don't clone the filters, a ref to the object would be passed which
 * could lead to some unexpected behaviour
 *
 * @param {Array} exclude Array of filters to exclude
 *
 * @return {Object} filters Copy of lead filters (whitout keys from exclude)
 */
export function getActivityFilters(state, exclude = []) {
  const filters = _.omitBy(
    _.cloneDeep(state.activitiesFilter),
    (active, key) => exclude.includes(key)
  );

  return filters;
}

export const reducers = Reducers([
  customerGroupsReducer,
  customerSegmentsReducer,
  kpiReducer,
  mboReducer,
  outlookCalendarReducer,
  customerIdByCustomerNumberReducer,
  consultantInsightsReducer,
  outlookCalendarAndBirthdaysReducer,
  birthdaysReducer
]);

export default reducers.get();
