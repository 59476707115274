import { DefaultReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';

import { get, isNil } from 'lodash';
import moment from 'moment';
import { latestReleaseNoteAction, LATEST_RELEASE_NOTE_SET_READ_AT } from '../actions/LatestReleaseNote';

const getReadAt = () => {
  const readAt = localStorage.getItem('uiNotesReadAt');
  return !isNil(readAt) ? readAt : undefined;
};

const defaultState = {
  data: {},
  readAt: getReadAt()
};

function afterSetReadAt(state, action) {
  const time = get(action, 'readAt', moment().format());
  localStorage.setItem('uiNotesReadAt', time);

  return update(state, {
    latestReleaseNote: {
      readAt: { $set: time }
    }
  });
}

export const reducer = DefaultReducer('latestReleaseNote', latestReleaseNoteAction, defaultState);
reducer.addMethod(LATEST_RELEASE_NOTE_SET_READ_AT, afterSetReadAt);

export default reducer.get();
