import { TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import { isUndefined, isNull } from 'lodash';
import moment from 'moment';

/**
 * Format the given date string by the given format
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} date Formatted date
 */
export function formatDateString(resolvedArgs = []) {
  const [date, format = 'L'] = resolvedArgs;

  if (isUndefined(date) || isNull(date)) return '';

  return moment(date).format(format);
}

export default {
  key: 'formatDateString',
  func: formatDateString,
  types: [{ types: [TYPE_STRING, TYPE_NULL] }, { types: [TYPE_STRING], optional: true }]
};
