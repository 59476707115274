import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, get } from 'lodash';
import { Modal } from 'reactstrap';

// Moved to new activity package
import { InstantiateNewWorkguideModal, INSTANTIATE_NEW_WORKGUIDE_MODAL } from '@evoja-web/entris-portal-shared';
import { CUSTOMER_ACTIVITIES_MODAL_IDENTIFIER } from '../packages/Activity/constants';

import { FileMetadata } from '../packages/General/index';
import WorkbenchActivityChangeCheckModal,
{ MODAL_IDENTIFIER as MODAL_IDENTIFIER_ACTIVITY_CHANGE_CHECK } from '../packages/Workbench/containers/ActivityChangeCheck/Modal';
import PermissionSwitchModal, { MODAL_IDENTIFIER as MODAL_IDENTIFIER_PERMISSION_SWITCH } from './PermissionSwitch/Modal';
import CustomerActivitiesModal from '../packages/Activity/containers/Customer/ActivitiesModal';
import ActivitiesModal, { MODAL_IDENTIFIER as MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL } from '../packages/Activity/containers/ActivitiesOverviewModal';
import SendByPostModal, { MODAL_IDENTIFIER as CUSTOMER_DOCUMENT_SEND_BY_POST_MODAL_IDENTIFIER } from '../packages/Sps/containers/Modal/SendByPost';
import SendByPostApproveModal, { MODAL_IDENTIFIER as CUSTOMER_DOCUMENT_SEND_BY_POST_APPROVE_MODAL_IDENTIFIER } from '../packages/Sps/containers/Modal/SendByPostApprove';
import SendByPostDiscardModal, { MODAL_IDENTIFIER as CUSTOMER_DOCUMENT_SEND_BY_POST_DISCARD_MODAL_IDENTIFIER } from '../packages/Sps/containers/Modal/SendByPostDiscard';
import {
  ActivityEditModal,
  ActivityRejectReasonModal,
  FormRelatedPartners,
  MODAL_IDENTIFIER_ACTIVITY_EDIT,
  MODAL_IDENTIFIER_ACTIVITY_REJECT_REASON,
  MODAL_IDENTIFIER_ACTIVITY_RELATED_PARTNERS
} from '../packages/Activity/index';
import ChangeCreateDateModal, { MODAL_IDENTIFIER as WORKGUIDE_ACTION_CHANGE_CREATE_DATE_MODAL } from '../packages/Workbench/containers/Overview/Columns/Actions/Workguide/ChangeCreateDateModal';
import KpiChartModal, { MODAL_IDENTIFIER as KPI_CHART_MODAL_IDENTIFIER } from '../packages/CustomerDetail/containers/Header/Kpi/KpiChartModal';
import Db2ChartModal, { MODAL_IDENTIFIER as DB2_CHART_MODAL_IDENTIFIER } from '../packages/CustomerDetail/containers/Header/Db2/Db2ChartModal';
import KpiFinancingChartModal, { MODAL_IDENTIFIER_KPI_FINANCING_HISTORY_MODAL } from '../packages/CustomerDetail/containers/Header/Kpi/KpiFinancingChartModal';
import CustomerManyCapitalTaxCalculateModal, { MODAL_IDENTIFIER as CUSTOMER_MANY_CAPITAL_TAX_MODAL_IDENTIFIER } from '../packages/Tax/containers/CustomerManyCapitalTaxCalculateModal';
// TODO: Refactor me. Hold modal state/props in state as props are not updated the way its implemented at the moment
class ModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modals: []
    };

    const { handler } = props;
    handler.on('toggle', () => this.setState({ modals: handler.getAll() }));
    handler.on('componentPropsChange', () => this.setState({ modals: handler.getAll() }));

    // Register modals
    if (!handler.has(MODAL_IDENTIFIER_ACTIVITY_REJECT_REASON)) handler.add(MODAL_IDENTIFIER_ACTIVITY_REJECT_REASON, ActivityRejectReasonModal);
    if (!handler.has('FILE_METADATA')) handler.add('FILE_METADATA', FileMetadata);
    if (!handler.has(MODAL_IDENTIFIER_ACTIVITY_EDIT)) handler.add(MODAL_IDENTIFIER_ACTIVITY_EDIT, ActivityEditModal);
    if (!handler.has(MODAL_IDENTIFIER_ACTIVITY_CHANGE_CHECK)) handler.add(MODAL_IDENTIFIER_ACTIVITY_CHANGE_CHECK, WorkbenchActivityChangeCheckModal);
    if (!handler.has(MODAL_IDENTIFIER_PERMISSION_SWITCH)) handler.add(MODAL_IDENTIFIER_PERMISSION_SWITCH, PermissionSwitchModal);
    if (!handler.has(CUSTOMER_ACTIVITIES_MODAL_IDENTIFIER)) handler.add(CUSTOMER_ACTIVITIES_MODAL_IDENTIFIER, CustomerActivitiesModal);
    if (!handler.has(INSTANTIATE_NEW_WORKGUIDE_MODAL)) handler.add(INSTANTIATE_NEW_WORKGUIDE_MODAL, InstantiateNewWorkguideModal);
    if (!handler.has(MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL)) handler.add(MODAL_IDENTIFIER_ACTIVITY_LINK_LIST_MODAL, ActivitiesModal);
    if (!handler.has(CUSTOMER_DOCUMENT_SEND_BY_POST_MODAL_IDENTIFIER)) handler.add(CUSTOMER_DOCUMENT_SEND_BY_POST_MODAL_IDENTIFIER, SendByPostModal);
    if (!handler.has(CUSTOMER_DOCUMENT_SEND_BY_POST_APPROVE_MODAL_IDENTIFIER)) handler.add(CUSTOMER_DOCUMENT_SEND_BY_POST_APPROVE_MODAL_IDENTIFIER, SendByPostApproveModal);
    if (!handler.has(CUSTOMER_DOCUMENT_SEND_BY_POST_DISCARD_MODAL_IDENTIFIER)) handler.add(CUSTOMER_DOCUMENT_SEND_BY_POST_DISCARD_MODAL_IDENTIFIER, SendByPostDiscardModal);
    if (!handler.has(MODAL_IDENTIFIER_ACTIVITY_RELATED_PARTNERS)) handler.add(MODAL_IDENTIFIER_ACTIVITY_RELATED_PARTNERS, FormRelatedPartners);
    if (!handler.has(WORKGUIDE_ACTION_CHANGE_CREATE_DATE_MODAL)) handler.add(WORKGUIDE_ACTION_CHANGE_CREATE_DATE_MODAL, ChangeCreateDateModal);
    if (!handler.has(KPI_CHART_MODAL_IDENTIFIER)) handler.add(KPI_CHART_MODAL_IDENTIFIER, KpiChartModal);
    if (!handler.has(DB2_CHART_MODAL_IDENTIFIER)) handler.add(DB2_CHART_MODAL_IDENTIFIER, Db2ChartModal);
    if (!handler.has(MODAL_IDENTIFIER_KPI_FINANCING_HISTORY_MODAL)) handler.add(MODAL_IDENTIFIER_KPI_FINANCING_HISTORY_MODAL, KpiFinancingChartModal);
    if (!handler.has(CUSTOMER_MANY_CAPITAL_TAX_MODAL_IDENTIFIER)) handler.add(CUSTOMER_MANY_CAPITAL_TAX_MODAL_IDENTIFIER, CustomerManyCapitalTaxCalculateModal);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { language, handler } = this.props;
    const modals = handler.getAll();

    const children = filter(modals, (m) => get(m, 'active', false))
      .map((m) => (
        <Modal key={m.id} isOpen={m.active} {...m.modalProps} toggle={() => handler.hide(m.id)}>
          {React.createElement(m.component, {
            language,
            toggle: () => handler.hide(m.id),
            ...m.componentProps
          })}
        </Modal>
      ));

    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

ModalContainer.propTypes = {
  handler: PropTypes.object.isRequired,
  language: PropTypes.string
};

ModalContainer.defaultProps = {
  language: 'de'
};

function mapStateToProps(state) {
  return {
    language: state.login.language
  };
}

export default connect(mapStateToProps)(ModalContainer);
