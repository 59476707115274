import moment from 'moment';
import { get, isNil } from 'lodash';
import axios from 'axios';

import AxiosResponseInterceptor from './Proto';
import { hasStore, getStore } from '../../../../Store';

export default function ResponsePerformanceRuntimeInterceptor(props = {}) {
  const maxWaitTime = get(props, 'maxWaitTime', 10000);
  let timings = [];

  /**
   * Write current records to backend and clear timings array
   *
   * @return void
   */
  async function flush() {
    // Do nothing if store is not already configured or if there are no entries to write to the backend
    if (!hasStore() || timings.length <= 0) return;

    const store = getStore();
    const session = get(store.getState(), 'login.session');

    if (isNil(session)) return;

    try {
      await axios({
        url: '/_timings',
        method: 'post',
        data: { times: timings }
      });

      timings = [];
      console.info('Successfully wrote axios timings to backend!');
    } catch (error) {
      console.error('Unable to write actios timings to the backend!', error);
    }
  }

  /**
   * Check if the given request should be ignored.
   * At this time, this is only the case for requests to the _timings service itself.
   *
   * @param  {Object} response Axios response
   *
   * @return {Boolean}
   */
  function shouldIgnore(response) {
    return get(response, 'config.url', '').includes('/_timings');
  }

  /**
   * Handle resolve of a request
   *
   * @param  {Object} response Axios response
   *
   * @return void
   */
  function onResolve(response) {
    const startTime = moment(get(response, 'config.metadata.startTime'));
    const endTime = moment();
    const time = endTime.diff(startTime);
    const id = get(response, 'headers.x-gateway-audit-id');

    if (time > 0 && !shouldIgnore(response)) {
      timings.push({ id, time });
    }

    return response;
  }

  // Set the initial timeout and call flush after maxWaitTime
  setInterval(async () => flush(), maxWaitTime);

  return AxiosResponseInterceptor({ onResolve });
}
