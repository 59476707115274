import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/ConsultantSearch';

import getAllConsultants from './ConsultantSearch/getAllConsultants';

const { CONSULTANTS_REQUEST } = actions;

/**
 * Root saga that takes the lates CONSULTANTSEARCH_REQUEST action
 * and performs the consultant search
 *
 * @return {Generator}
 */
export default function* root() {
  yield takeLatest(CONSULTANTS_REQUEST, getAllConsultants);
}
