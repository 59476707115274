import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, isUndefined, reduce } from 'lodash';
import moment from 'moment';

// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import openingFormAction from '../actions/OpeningForm';
import { removeUnwantedValues, toIntIfSet } from '../../../lib/Utils/index';
import { clientMainTypeMap } from '../../../staticMappings/mapBpfCodes';

const {
  OPENING_FORM_SAVE_PENDING,
  OPENING_FORM_SAVE_FULFILLED,
  OPENING_FORM_SAVE_REJECTED
} = openingFormAction;

function mapConnectionAccounts(data) {
  const codes = get(data, 'connectionAccount');

  if (isUndefined(codes)) return undefined;

  return codes.map((c) => get(c, 'textShort.de'));
}

function getSelectedFunds(data) {
  const funds = get(data, 'funds');

  if (isUndefined(funds)) return undefined;

  return reduce(funds, (result, value, key) => {
    if (!isUndefined(value)) {
      result.push({
        id: toIntIfSet(key),
        percentage: value
      });
    }

    return result;
  }, []);
}

function Payments(data) {
  const payments = get(data, 'payments');

  return isUndefined(payments)
    ? undefined
    : [{
      debitAccount: toIntIfSet(get(payments, 'debitAccount.id')),
      debitAccountClientKey: toIntIfSet(get(payments, 'debitAccount.customer.id')),
      amount: toIntIfSet(get(payments, 'amount')),
      currency: get(payments, 'currency.textShort.de'),
      executionDate: moment(get(payments, 'executionDate')).format('YYYY-MM-DD')
    }];
}

function CustomDocContents(customer, data) {
  const qiDeclaration = get(data, 'qiDeclaration', {});

  // correspondence has been hardcoded within MAP-8305 (https://issue.swisscom.ch/browse/MAP-8305)
  const correspondence = {
    korreAlleDok: get(data, 'correspondence') === 'all',
    korrePeriodisch: get(data, 'correspondence') === 'periodic'
  };

  return reduce(qiDeclaration, (result, dec, customerId) => {
    return {
      ...result,
      [customerId]: {
        ...get(result, customerId, {}),
        dbaLandCh: get(dec, 'dbaLandCh', false),
        dbaLandOther: !get(dec, 'dbaLandCh', false),
        dbaLandOtherEingabe: get(dec, 'dbaLandOther.textShort.de')
      }
    };
  }, { [get(customer, 'id')]: correspondence });
}

export default function* saveOpeningForm(request) {
  const {
    customer,
    data,
    session
  } = request;

  yield put({ type: OPENING_FORM_SAVE_PENDING });

  try {
    const payload = {
      messageName: 'valiant-depot-open',
      businessKey: `${get(customer, 'id')}-${get(data, 'product')}`,
      tenantId: '6300',
      resultEnabled: true,
      variablesInResultEnabled: true,
      processVariables: {
        clientKey: toIntIfSet(get(customer, 'id')),
        clientMainType: clientMainTypeMap.get(get(customer, 'mainType.id')),
        defaultPaymentRule: get(data, 'defaultPaymentRule'),
        eegDesired: get(data, 'eeg'),
        initiator: toIntIfSet(get(data, 'authorizedCustomer.id')),
        internalTransferPortfolioId: get(data, 'portfolioNumber'),
        investmentAmount: toIntIfSet(get(data, 'investmentAmount')),
        investmentObjective: toIntIfSet(get(data, 'investmentStrategy.number')),
        investmentProduct: get(data, 'product'),
        payments: Payments(data),
        processCallOrigin: 'MAP',
        responsibleUser: toIntIfSet(get(session, 'id')),
        selectedFonds: getSelectedFunds(data),
        settlementAccounts: mapConnectionAccounts(data),
        customDocContents: CustomDocContents(customer, data)
      }
    };

    const url = '/sif/bpf/public/api/v1/message';
    yield call(axios, {
      url,
      method: 'post',
      data: removeUnwantedValues(payload, [undefined, null])
    });

    yield put({ type: OPENING_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: OPENING_FORM_SAVE_REJECTED, error });

    return error;
  }
}
