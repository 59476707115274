import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import getConsultantCustomers from './getConsultantCustomers';

const { CONSULTANT_CUSTOMERS_REQUEST } = actions;

export default function* customerOverviewRootSaga() {
  yield takeLatestByDataKey(CONSULTANT_CUSTOMERS_REQUEST, getConsultantCustomers);
}
