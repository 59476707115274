import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import realEstateTypeAction from '../../actions/RealEstateType/RealEstateType';

const {
  REAL_ESTATE_TYPE_PENDING,
  REAL_ESTATE_TYPE_FULFILLED,
  REAL_ESTATE_TYPE_REJECTED
} = realEstateTypeAction;

export default function* getRealEstateTypes() {
  yield put({ type: REAL_ESTATE_TYPE_PENDING });

  try {
    const url = '/financing/realestatetype/';
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: REAL_ESTATE_TYPE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: REAL_ESTATE_TYPE_REJECTED, error });

    return error;
  }
}
