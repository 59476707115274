import axios from 'axios';
import { isFunction, get, every, some } from 'lodash';
import { call, put, cancel, delay } from 'redux-saga/effects';

import defaultEventStatusSuccess from './defaultEventStatusSuccess';
import defaultEventStatusFailed from './defaultEventStatusFailed';
import defaultEventStatusError from './defaultEventStatusError';
import defaultEventStatusTimeout from './defaultEventStatusTimeout';

/**
 * Poll worker.
 * Call the given url with with the defined interval (default 3000ms)
 * You can pass in generator function to be called if an event occures (onSuccess, onFailed, onTimeout, onError)
 * or an action object to be put.
 *
 * If a generator was given, it will recieve the result from service call or an error object onError.
 *
 * OnSuccess: Execute the given success function / put the given success action
 * OnFailed: Execute the given failed function / put the given failed action
 * OnError: Execute the given error function / put the given error action
 * OnTimeout: If max tries are reached, execute the given timeout function / put the given timeout action
 *
 * @param  {String}   url                                    Url to be called
 * @param  {Number}   [interval=3000]                        Interval in ms
 * @param  {Number}   [maxTries=20]                          Max number of tries
 * @param  {Mixed}    [onSuccess=defaultEventStatusSuccess]  Generator function to handle success or action object to be put
 * @param  {Mixed}    [onFailed=defaultEventStatusFailed]    Generator function to handle failed or action object to be put
 * @param  {Mixed}    [onError=defaultEventStatusError]      Generator function to handle error or action object to be put
 * @param  {Mixed}    [onTimeout=defaultEventStatusTimeout]  Generator function to handle timeout or action object to be put
 *
 * @return {Generator}
 */
export default function* pollWorker(request) {
  const {
    url,
    interval = 3000,
    maxTries = 20,
    onSuccess = defaultEventStatusSuccess,
    onFailed = defaultEventStatusFailed,
    onError = defaultEventStatusError,
    onTimeout = defaultEventStatusTimeout
  } = request;

  let tries = 0;
  while (true) {
    try {
      // Get data from backend
      const result = yield call(axios, { url });
      const { data } = result;
      const status = get(data, 'status', []);

      // Cancel request after maxTries is reached
      if (tries >= maxTries) {
        // eslint-disable-next-line
        (isFunction(onTimeout)) ? yield call(onTimeout, result) : yield put(onTimeout);
        yield cancel();
      }

      // Execute success function / put success action
      if (every(status, (item) => get(item, 'status') === 'done' || get(item, 'status') === 'ignored')) {
        // eslint-disable-next-line
        (isFunction(onSuccess)) ? yield call(onSuccess, result) : yield put(onSuccess);
        yield cancel();
      }

      // If an error occures, execute failed function / put failed action
      if (some(status, (item) => get(item, 'status') === 'failed')) {
        // eslint-disable-next-line
        (isFunction(onFailed)) ? yield call(onFailed, result) : yield put(onFailed);
        yield cancel();
      }

      // Enhance tries and delay execution
      tries += 1;
      yield delay(interval);
    } catch (error) {
      // eslint-disable-next-line
      (isFunction(onError)) ? yield call(onError, error) : yield put(onError);
      yield cancel();
    }
  }
}
