import { get } from 'lodash';

import AxiosResponseInterceptor from './Proto';
import { hasStore, getStore } from '../../../../Store';
import { ADD_TOAST } from '../../../../actions/Toast';

/*
Sadly, camunda API can not return a 400 Error Code if validation fails.
As it returns always a 200, we have to handle this with an interceptor, that checks
the response for various props that indicate an error
 */
export default function ResponseBpfErrorInterceptor() {
  function onResolve(response) {
    if (!hasStore()) return response;

    const store = getStore();
    // Array of errors if input variables are not valid. Empty if everything is fine
    const errors = get(response, 'data.resultVariables.validationErrors', []);
    // Set to false if address change is not possible for this address type
    const addressInitiatorPolicy = get(response, 'data.resultVariables.addressInitiatorPolicy', true);
    // Set to true if there is already a pending address change for this customer / address type
    const processExists = get(response, 'data.resultVariables.processExists', false);

    if (get(errors, 'length', 0) > 0) {
      store.dispatch({
        type: ADD_TOAST,
        kind: 'error',
        title: 'Camunda.Error.Validation.Title',
        description: 'Camunda.Error.Validation.Description'
      });
    }

    if (!addressInitiatorPolicy) {
      store.dispatch({
        type: ADD_TOAST,
        kind: 'error',
        title: 'Camunda.Error.AddressInitiatorPolicy.Title',
        description: 'Camunda.Error.AddressInitiatorPolicy.Description'
      });
    }

    if (processExists) {
      store.dispatch({
        type: ADD_TOAST,
        kind: 'error',
        title: 'Camunda.Error.ProcessExists.Title',
        description: 'Camunda.Error.ProcessExists.Description'
      });
    }

    return response;
  }

  return AxiosResponseInterceptor({ onResolve });
}
