import { get, isUndefined, some, isNil } from 'lodash';

import AxiosResponseInterceptor from './Proto';
import parseUrl from '../../../Utils/parseUrl';
import { hasStore, getStore } from '../../../../Store';

export default function ResponseUnauthorizedOrForbiddenInterceptor(props = {}) {
  const ignorePathNames = get(props, 'ignorePathNames', ['/viseca', '/adog', '/airlock', '/graph', '/presence', '/archive/edossier', '/sif']);
  const statusCodes = get(props, 'statusCodes', [401, 403]);

  function onReject(error) {
    const response = get(error, 'response');
    const { pathname = '' } = parseUrl(get(response, 'config.url'));

    if (
      hasStore()
      && !isNil(response)
      && statusCodes.includes(get(response, 'status'))
      && !some(ignorePathNames, (ignore) => pathname.startsWith(ignore))
    ) {
      const store = getStore();
      const session = get(store.getState(), 'login.session');
      if (!isUndefined(session)) {
        window.location.replace(`/logout?redir=${window.location.pathname}`);
      }
    }

    return Promise.reject(error);
  }

  return AxiosResponseInterceptor({ onReject });
}
