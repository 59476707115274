import { put } from 'redux-saga/effects';
import getFeatureFlags from '../../packages/FeatureFlag/sagas/getFeatureFlags';
import actions from '../../packages/PeriodicUpdate/actions/Actions';

const { TASK_REQUEST } = actions;

export default function* bootstrapFeatureFlags() {
  const request = {
    dataKey: 'featureFlags',
    interval: 60 * 60 * 1000,
    onInit: getFeatureFlags,
    onUpdate: getFeatureFlags
  };

  yield put({ type: TASK_REQUEST, ...request });
}
