import React from 'react';
import PropTypes from 'prop-types';
import { head, noop } from 'lodash';
import { Label, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import './PaymentOrder.css';
import { ButtonSelect } from '../../../../General/index';

const PaymentOrder = React.memo(({
  onChange,
  validations,
  value
}) => {
  const target = 'payment-order-info';
  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.PaymentOrder" />

        <span
          id={target}
          className="mdi mdi-information-outline"
        />

        <UncontrolledPopover
          className="securities-account-opening-payment-order-info"
          target={target}
          trigger="hover"
        >
          <PopoverBody className="securities-account-opening-payment-order-info--body">
            <FormattedMessage id="SecuritiesAccount.Opening.Form.PaymentOrderInfo" />
          </PopoverBody>
        </UncontrolledPopover>
      </Label>

      <ButtonSelect
        id="paymentOrder"
        value={[value]}
        showValidations
        validations={validations}
        onChange={(field, value) => onChange(field, head(value))}
        options={[{
          id: 'yes',
          value: true,
          label: <FormattedMessage id="General.Yes" />
        }, {
          id: 'no',
          value: false,
          label: <FormattedMessage id="General.No" />
        }]}
      />

      {!value && (
        <Alert type="info" style={{ width: '100%' }}>
          <AlertContent>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.PaymentOrderAlert" />
          </AlertContent>
        </Alert>
      )}

    </React.Fragment>
  );
});

PaymentOrder.propTypes = {
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.bool
};

PaymentOrder.defaultProps = {
  onChange: noop,
  validations: {},
  value: undefined
};

export default PaymentOrder;
