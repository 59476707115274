import { takeLatest } from 'redux-saga/effects';

import releaseActions from '../actions/Actions';
import getEnvironmentJson from './getEnvironmentJson';

const { ENVIRONMENT_JSON_REQUEST } = releaseActions;

export default function* releaseRootSaga() {
  yield takeLatest(ENVIRONMENT_JSON_REQUEST, getEnvironmentJson);
}
