import { Reducers } from '@evoja-web/redaction';

import { reducer as releaseNotesReducer } from './ReleaseNotes';
import { reducer as latestReleaseNoteReducer } from './LatestReleaseNote';

export const reducers = Reducers([
  releaseNotesReducer,
  latestReleaseNoteReducer
]);

export default reducers.get();
