import { call, put } from 'redux-saga/effects';
import { has } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  ESTIMATION_PROTOCOL_LOAD_FULFILLED,
  ESTIMATION_PROTOCOL_LOAD_PENDING,
  ESTIMATION_PROTOCOL_LOAD_REJECTED,
} from '../../actions/EstimationProtocol';

/**
 * Get FinancingEstimation from backend
 *
 * @param  {Object} terms Search terms
 *
 * @return {Generator}            [description]
 */
export default function* getEstimationProtocol({ terms }) {
  const {
    createdAfter,
    createdUntil,
    createdBy,
    city,
    street,
    propertyName,
    propertyType,
    status,
    useType,
    limit,
  } = terms;

  yield put({ type: ESTIMATION_PROTOCOL_LOAD_PENDING });
  try {
    let termsString = '?';

    if (has(propertyType, 'text.de')) {
      termsString += `propertyType=${propertyType.text.de}&`;
    }

    if (has(useType, 'text.de')) {
      termsString += `useType=${useType.text.de}&`;
    }

    if (propertyName) {
      termsString += `objectName=${propertyName}&`;
    }
    if (street) {
      termsString += `street=${street}&`;
    }
    if (city) {
      termsString += `city=${city}&`;
    }

    if (has(status, 'text.de')) {
      termsString += `status=${status.text.de}&`;
    }

    if (has(createdBy, 'username')) {
      termsString += `createdBy=${createdBy.username}&`;
    }

    if (createdAfter) {
      termsString += `createdAfter=${createdAfter}&`;
    }
    if (createdUntil) {
      termsString += `createdUntil=${createdUntil}&`;
    }

    termsString += `limit=${limit}`;

    const data = yield call(handleNextHeaders, `/analytics/financing-estimation-explicit${termsString}`);

    yield put({ type: ESTIMATION_PROTOCOL_LOAD_FULFILLED, payload: data });
  } catch (error) {
    yield put({ type: ESTIMATION_PROTOCOL_LOAD_REJECTED, error });
  }
}
