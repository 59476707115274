import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, ModalHeader, ModalBody, DropdownItem, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { get, isNil } from 'lodash';

import { FormattedMessage } from 'react-intl';
import releaseNoteActions from '../actions/Actions';
import './ReleaseNotes.scss';
import ReleaseNoteItem from '../components/ReleaseNoteItem';
import { BeatLoader, Pagination } from '../../General';

class ReleaseNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      elementsPerPage: 3,
      dataPerPage: [],
      releaseNotes: []
    };

    this.toggle = this.toggle.bind(this);
  }

  getIconClass() {
    const { latestReleaseNote } = this.props;
    if (!latestReleaseNote) {
      return 'mdi mdi-bell-outline';
    }

    const { readAt } = this.props;
    const hasNewReleaseNotes = isNil(readAt) || (
      !isNil(readAt)
      && latestReleaseNote
      && moment(readAt) <= moment(get(latestReleaseNote, 'publishDate'))
    );

    return !hasNewReleaseNotes
      ? 'mdi mdi-bell-outline'
      : 'mdi mdi-bell-badge-outline active';
  }

  getReleaseNotePages() {
    const { releaseNotes } = this.props;
    const { page, elementsPerPage } = this.state;

    const skip = (page - 1) * elementsPerPage;
    const releaseNotesPerPage = releaseNotes.slice(skip, (skip + elementsPerPage));
    return releaseNotesPerPage;
  }

  toggle() {
    const { isOpen } = this.state;
    const { actions } = this.props;
    actions.latestReleaseNoteSetReadAt();

    this.setState({ isOpen: !isOpen });

    if (!isOpen === true) {
      actions.releaseNotesRequest();
    }
  }

  renderContent() {
    const { language, releaseNotesRequesting } = this.props;
    const { dataPerPage } = this.state;

    if (releaseNotesRequesting) return <BeatLoader />;

    return (
      <div className="release-notes-content">
        {dataPerPage.map((item) => (
          <ReleaseNoteItem key={get(item, 'id')} item={item} language={language} />
        ))}
      </div>
    );
  }

  /**
   * Render the pagination
   *
   * @return {ReactElement} markup
   */
  renderPagination() {
    const { releaseNotes, releaseNotesRequesting } = this.props;
    const { elementsPerPage } = this.state;

    return (
      <Pagination
        data={releaseNotes}
        requesting={releaseNotesRequesting}
        elementsPerPage={elementsPerPage}
        setDataPerPages={(data) => this.setState({ dataPerPage: data })}
      />
    );
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <DropdownItem
          className="release-notes-icon--dropdown-item"
          onClick={this.toggle}
          toggle={false}
        >
          <span className={this.getIconClass()} />
          <FormattedMessage id="ReleaseNotes.Profile.Dropdown" />
        </DropdownItem>

        <Modal isOpen={isOpen} toggle={this.toggle} className="release-notes-modal">
          <ModalHeader className="release-notes-header" toggle={this.toggle}>
            <FormattedMessage id="ReleaseNotes.Modal.Title" />
          </ModalHeader>

          <ModalBody>
            {this.renderContent()}
          </ModalBody>
          <ModalFooter>
            {this.renderPagination()}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

ReleaseNotes.propTypes = {
  actions: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  readAt: PropTypes.string,
  releaseNotes: PropTypes.array,
  latestReleaseNote: PropTypes.object,
  releaseNotesRequesting: PropTypes.bool
};

ReleaseNotes.defaultProps = {
  readAt: undefined,
  releaseNotes: [],
  latestReleaseNote: {},
  releaseNotesRequesting: false
};

function mapStateToProps(state) {
  return {
    language: state.login.language,
    readAt: state.releaseNote.latestReleaseNote.readAt,
    latestReleaseNote: state.releaseNote.latestReleaseNote.data,
    releaseNotes: state.releaseNote.releaseNotes.data,
    releaseNotesRequesting: state.releaseNote.releaseNotes.requesting
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(releaseNoteActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
