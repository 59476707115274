import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

// ToDo: Move functions like removeUnwantedProperties/removeUnwantedValues to own repo
import openingFormAction from '../../actions/BaseContract/OpeningForm';
import {
  toIntIfSet,
  toStringIfSet
} from '../../lib/Utils/index';

const {
  OPENING_FORM_SAVE_PENDING,
  OPENING_FORM_SAVE_FULFILLED,
  OPENING_FORM_SAVE_REJECTED
} = openingFormAction;

export default function* saveOpeningForm(request) {
  yield put({ type: OPENING_FORM_SAVE_PENDING });

  try {
    const {
      consultantId,
      customerId,
      formData,
      tenant = 6300,
    } = request;

    const url = '/sif/bpf/public/api/v1/message';
    const data = {
      messageName: 'valiant-cms-basisvertrag',
      resultEnabled: true,
      tenantId: toStringIfSet(tenant),
      businessKey: customerId,
      processVariables: {
        clientKey: toIntIfSet(customerId),
        responsibleUser: toIntIfSet(consultantId),
        processCallOrigin: 'MAP',
        customDocContents: {
          einzelZeichnung: get(formData, 'drawingRight') === 'individually' ? 'true' : 'false',
          kollektivZeichnung: get(formData, 'drawingRight') === 'collective' ? 'true' : 'false'
        }
      }
    };

    const result = yield call(axios, {
      url,
      method: 'post',
      data
    });

    const payload = {
      data,
      result
    };

    yield put({ type: OPENING_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: OPENING_FORM_SAVE_REJECTED, error });

    return error;
  }
}
