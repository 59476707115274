import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop, sortBy } from 'lodash';

import { CodeSelect } from '../../../../General/index';

const investmentStrategyMap = {
  8: [
    'investmentStrategy-10',
    'investmentStrategy-3',
    'investmentStrategy-9',
    'investmentStrategy-5'
  ],
  9: [
    'investmentStrategy-10',
    'investmentStrategy-3',
    'investmentStrategy-9',
    'investmentStrategy-5'
  ],
  10: [
    'investmentStrategy-12'
  ],
  11: [
    'investmentStrategy-3',
    'investmentStrategy-5',
    'investmentStrategy-9',
    'investmentStrategy-10'
  ],
  default: [
    'investmentStrategy-10',
    'investmentStrategy-1',
    'investmentStrategy-3',
    'investmentStrategy-9',
    'investmentStrategy-5'
  ]
};

function getStrategiesByProductId(productId = 'default') {
  return get(
    investmentStrategyMap,
    productId,
    get(investmentStrategyMap, 'default', [])
  );
}

const InvestmentStrategy = React.memo(({
  codes,
  language,
  onChange,
  selectedProduct,
  validations,
  value
}) => {
  const options = get(codes, 'groups.investmentStrategy', [])
    .filter((code) => getStrategiesByProductId(selectedProduct).includes(get(code, 'id')));
  const optionsSorted = sortBy(options, (o) => getStrategiesByProductId(selectedProduct).indexOf(o.id));

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.InvestmentStrategy" />
      </Label>

      <CodeSelect
        id="investmentStrategy"
        codes={optionsSorted}
        language={language}
        onChange={onChange}
        value={value}
        validations={validations}
        showValidations
      />
    </FormGroup>
  );
});

InvestmentStrategy.propTypes = {
  codes: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  selectedProduct: PropTypes.number,
  validations: PropTypes.object,
  value: PropTypes.object
};

InvestmentStrategy.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  onChange: noop,
  selectedProduct: undefined,
  validations: undefined,
  value: undefined
};

export default InvestmentStrategy;
