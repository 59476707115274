import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { get, isNil } from 'lodash';

const CardHolder = React.memo(({ process }) => {
  const { cardHolder } = process;

  const yes = <FormattedMessage id="General.Yes" />;
  const no = <FormattedMessage id="General.No" />;

  return (
    <div className="workguide-onboarding-card--card-holder">
      <Row>
        <Col lg={12} md={12}>
          <strong>
            <FormattedMessage id="Onboarding.ProcessInfo.CardHolder.Title" />
          </strong>
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.CardHolder.Designation" />
        </Col>

        <Col lg={8} md={8}>
          {`${get(cardHolder, 'clientDesignation', '')} (${get(cardHolder, 'editClientNumber', '')})`}
        </Col>
      </Row>

      {!isNil(get(process, 'cardHolderClientKeyBasisContract')) && (
        <Row>
          <Col lg={4} md={4}>
            <FormattedMessage id="Onboarding.ProcessInfo.BaseContract" />
          </Col>

          <Col lg={8} md={8}>
            {get(process, 'cardHolderClientKeyBasisContract', false) ? yes : no}
          </Col>
        </Row>
      )}

      {!isNil(get(process, 'cardHolderClientKeyAiaFatcaDeclaration')) && (
        <Row>
          <Col lg={4} md={4}>
            <FormattedMessage id="Onboarding.ProcessInfo.AiaFatca" />
          </Col>

          <Col lg={8} md={8}>
            {get(process, 'cardHolderClientKeyAiaFatcaDeclaration', false) ? yes : no}
          </Col>
        </Row>
      )}

      {!isNil(get(process, 'cardHolderClientKeyIsIdentityOk')) && (
        <Row>
          <Col lg={4} md={4}>
            <FormattedMessage id="Onboarding.ProcessInfo.Identification" />
          </Col>

          <Col lg={8} md={8}>
            {get(process, 'cardHolderClientKeyIsIdentityOk', false) ? yes : no}
          </Col>
        </Row>
      )}

      {!isNil(get(process, 'allPoaStatus90')) && (
        <Row>
          <Col lg={4} md={4}>
            <FormattedMessage id="Onboarding.ProcessInfo.ValidAuthorization" />
          </Col>

          <Col lg={8} md={8}>
            {get(process, 'allPoaStatus90', false) ? yes : no}
          </Col>
        </Row>
      )}
    </div>
  );
});

CardHolder.propTypes = {
  process: PropTypes.object
};

CardHolder.defaultProps = {
  process: {}
};

export default CardHolder;
