import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import { takeLatest } from 'redux-saga/effects';
import actions from '../actions/Actions';
import getProvisionAccounts from './getProvisionAccounts';
import withdrawalLimitCancellationFormSave from './Form/WithdrawalLimitCancellationFormSave';
import openPrivorRetirementSavingsAccountDepositFormSave from './Form/OpenPrivorRetirementSavingsAccountDepositFormSave';
import openPrivorRetirementSavingsAccountDepositFormInit from './Form/OpenPrivorRetirementSavingsAccountDepositFormInit';
import withdrawalLimitCancellationFormInit from './Form/WithdrawalLimitCancellationFormInit';

const {
  PROVISION_ACCOUNTS_REQUEST,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_REQUEST,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_REQUEST,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_REQUEST,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_REQUEST
} = actions;

export default function* setAdvisorRootSaga() {
  yield takeLatestByDataKey(PROVISION_ACCOUNTS_REQUEST, getProvisionAccounts);
  yield takeLatest(WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_REQUEST, withdrawalLimitCancellationFormSave);
  yield takeLatest(WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_REQUEST, withdrawalLimitCancellationFormInit);
  yield takeLatest(OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_REQUEST, openPrivorRetirementSavingsAccountDepositFormSave);
  yield takeLatest(OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_REQUEST, openPrivorRetirementSavingsAccountDepositFormInit);
}
