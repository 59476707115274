const spsStyles = {
  text: {
    fontSize: 9.5,
    fontFamily: 'Arial MT',
    fontStyle: 'normal'
  },
  textBold: {
    fontSize: 9.5,
    fontFamily: 'Arial MT',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  textItalic: {
    fontSize: 9.5,
    fontFamily: 'Arial MT',
    fontStyle: 'italic',
  },
  textBoldItalic: {
    fontSize: 9.5,
    fontFamily: 'Arial MT',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  page: {
    paddingHorizontal: 90,
    fontFamily: 'Arial MT'
  },
};

export default spsStyles;
