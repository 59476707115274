import { call } from 'redux-saga/effects';
import { getCodeGroups, initCodeGroups } from '@evoja-web/entity-code/sagas';

const items = [
  'orderOrigin',
  'workguideStatus',
  'workguideApprovalStatus'
];

export default function* bootstrapCodes() {
  yield call(initCodeGroups);
  yield call(getCodeGroups, { groups: items });
}
