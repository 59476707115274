import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle } from '@evoja-web/react-layout-components';

import { Container } from 'reactstrap';
import * as userSwitchActions from '../actions/UserSwitch';
import { BeatLoader } from '../packages/General';

class UserSwitch extends React.Component {
  componentDidMount() {
    const { actions, match } = this.props;
    const { consultant } = match.params;

    actions.switchUser(consultant);
  }

  render() {
    const { userSwitch, component: Component } = this.props;

    let content = '';
    if (userSwitch.requesting) content = (<BeatLoader />);
    if (userSwitch.error) {
      content = (
        <Container
          fluid
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh'
          }}
        >
          <Alert type="error">
            <AlertTitle>
              <FormattedMessage id="UserSwitch.Error" />
            </AlertTitle>
          </Alert>
        </Container>
      );
    }

    if (userSwitch.selected) content = (<Component {...this.props} />);

    return (
      <div>{content}</div>
    );
  }
}

UserSwitch.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  component: PropTypes.node.isRequired,
  consultant: PropTypes.object,
  userSwitch: PropTypes.object
};

UserSwitch.defaultProps = {
  consultant: undefined,
  userSwitch: {}
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userSwitchActions, dispatch) };
}

function mapStateToProps(state) {
  return {
    userSwitch: state.userSwitch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSwitch));
