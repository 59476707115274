import React from 'react';
import { get } from 'lodash';
import { Text, View } from '@react-pdf/renderer';
import htmlToPdfStyles from './parseHtmlToPdfStyles';

const textTags = ['H1', 'H2', 'H6', 'BLOCKQUOTE'];
const listTags = ['UL', 'OL'];

const possibleStylingTags = [
  'CODE',
  'U',
  'EM',
  'STRONG'
];

function mergeStyles(style, style2) {
  return { ...style, ...style2 };
}

function getStyleByTag(tag) {
  return get(htmlToPdfStyles, tag, {});
}

function getElementContentStyled(element) {
  // Get Child Nodes of Element
  const childNodes = Array.from(element.childNodes).filter((node) => {
    return node.nodeType === 1 || (node.nodeType === 3 && node.textContent.trim() !== '');
  });

  // Loop Throw nodes and get pdf element with styling
  const elementArray = childNodes.map((el) => {
    const tagName = get(el, 'tagName');

    if (possibleStylingTags.includes(tagName)) { // is styling tag like strong, em, u ....
      // Call the same function for el, so that if it has subordinate styles, these are also taken
      const childElements = getElementContentStyled(el);

      // get styling by tag
      const style = getStyleByTag(tagName);

      return (
        <Text style={style}>
          {childElements}
        </Text>
      );
    }
    // is any other tag, normally should by text node
    return (
      <Text style={htmlToPdfStyles.NormalText}>
        {get(el, 'textContent')}
      </Text>
    );
  });
  return elementArray;
}

export default function parseEditorHtmlStringToPdfElements(htmlElement) {
  const children = get(htmlElement, 'children');

  const htmlElementChildrenArray = Array.from(children);
  const result = htmlElementChildrenArray.map((element) => {
    let resultElement;

    const elementChildren = get(element, 'children', []);

    // if element is only a Empty Line
    if (get(Array.from(elementChildren), 'length', 0) === 1 && get(elementChildren, 'tagName') === 'BR') {
      return (<Text>{'\n'}</Text>);
    }

    // get tag Name
    const elementTagName = get(element, 'tagName');

    if (elementTagName === 'P') {
      // Paragraph
      const elements = getElementContentStyled(element);

      resultElement = (
        <View style={{ marginTop: 8, flexDirection: 'row' }}>
          <Text style={{ fontSize: 10, fontFamily: 'Arial MT' }}>
            {elements}
          </Text>
        </View>
      );
    } else if (textTags.includes(elementTagName)) {
      // Style title by tag
      const style = getStyleByTag(elementTagName);

      // get child element and styles
      const elements = getElementContentStyled(element);

      // merge styles
      const allStyles = mergeStyles(style);

      resultElement = (
        <Text style={allStyles}>
          {elements}
        </Text>
      );
    } else if (listTags.includes(elementTagName)) {
      const list = Array.from(elementChildren).map((childEl, index) => {
        const listPrefix = elementTagName === 'UL' ? '•' : `${index + 1}.`;

        const elements = getElementContentStyled(childEl);

        return (
          <View style={htmlToPdfStyles.ListItem}>
            <Text style={htmlToPdfStyles.ListItemPrefix}>{listPrefix}</Text>
            <Text>{elements}</Text>
          </View>
        );
      });

      resultElement = (
        <View style={htmlToPdfStyles.List}>
          {list}
        </View>
      );
    } else if (elementTagName === 'A') {
      // do something if its a link,
      // but why would you put a link into a Letter ?
    }

    return resultElement;
  });

  return result;
}
