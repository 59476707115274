import React from 'react';
import { NavbarBrand } from 'reactstrap';

import './Logo.css';

const HeaderLogo = React.memo(() => {
  return (
    <NavbarBrand
      href="/dashboard"
      className="layout-header-navbar-logo"
    >
      <img
        src="/images/logo_valiant.png"
        alt="Valiant"
      />
    </NavbarBrand>
  );
});

HeaderLogo.propTypes = {

};

HeaderLogo.defaultProps = {

};

export default HeaderLogo;
