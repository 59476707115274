import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { get } from 'lodash';

const ClientVersion = React.memo(({ clientInfo }) => {
  return (
    <DropdownItem
      className="layout-header-user-profile--dropdown-item"
      disabled
    >
      <span className="mdi mdi-information-outline" />
      MAP/PTC
      {' '}
      {get(clientInfo, 'version', '')}
    </DropdownItem>
  );
});

ClientVersion.propTypes = {
  clientInfo: PropTypes.object
};

ClientVersion.defaultProps = {
  clientInfo: {}
};

export default ClientVersion;
