import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { chain, get, isNil } from 'lodash';
import memoizeOne from 'memoize-one';

import './Banners.css';
import { PeriodicUpdate } from '../../PeriodicUpdate/index';
import Banner from '../components/Banner';
import uiNoteActions from '../actions/Actions';

class Banners extends React.Component {
  constructor(props) {
    super(props);

    this.onLoadBanners = this.onLoadBanners.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  /**
   * Initially load banners
   *
   * @return  {[type]}  [return description]
   */
  onLoadBanners() {
    const { uiNoteActions } = this.props;

    uiNoteActions.bannersRequest();
  }

  /**
   * Close current banner and set to confirmed
   *
   * @param   {String}  id  Banner id
   *
   * @return  void
   */
  onClose({ id }) {
    const { uiNoteActions } = this.props;

    uiNoteActions.confirmBannerRequest({ dataKey: id });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      banner,
      language
    } = this.props;

    return (
      <PeriodicUpdate
        id="uiNoteBanners"
        interval={300000}
        onInit={this.onLoadBanners}
        onUpdate={this.onLoadBanners}
      >
        {!isNil(banner) && (
          <div className="ui-note-banners">
            <div className="ui-note-banners--banner">
              <Banner
                key={get(banner, 'id')}
                banner={banner}
                language={language}
                onClose={this.onClose}
              />
            </div>

            <div className="ui-note-banners--backdrop" />
          </div>
        )}
      </PeriodicUpdate>
    );
  }
}

Banners.propTypes = {
  banner: PropTypes.object,
  banners: PropTypes.object,
  language: PropTypes.string,
  uiNoteActions: PropTypes.object.isRequired
};

Banners.defaultProps = {
  banner: undefined,
  banners: {},
  language: 'de'
};

function mapStateToProps(state, ownProps) {
  const banner = chain(state)
    .get('uiNote.banners.data', [])
    .filter((b) => !get(b, 'confirmed', false))
    .first()
    .value();

  return {
    banner,
    banners: state.uiNote.banners
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uiNoteActions: bindActionCreators(uiNoteActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
