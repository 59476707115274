import React from 'react';
import PropTypes from 'prop-types';
import { get, has, orderBy, noop, isEmpty, isUndefined } from 'lodash';
import { FormGroup, Label, Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withCodeGroups } from '@evoja-web/entity-code';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CodeSelect, InputField } from '../../../General/index';
import { getValidator } from '../../../../globals';
import interestActions from '../../actions/Actions';
import { withWorkguideInstanceData } from '../../../Workguide';
import { withWorkguideModalToggle } from '../../../Workguide/hocs';

class EditInterestForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mainCategory: undefined,
      subCategory: undefined,
      comment: ''
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onValueChange(id, value) {
    this.setState({ [id]: value });

    const subCategory = id === 'mainCategory'
      ? undefined
      : get(this, 'state.subCategory');

    this.setState({ subCategory, [id]: value });
  }

  onSubmit() {
    const { customer, interestActions, toggleRedirectModal } = this.props;
    const { subCategory, comment } = this.state;

    const data = {
      comment,
      customerId: get(customer, 'id'),
      interestCategory: subCategory
    };
    interestActions.saveInterestRequest({ data });
    toggleRedirectModal();
  }

  /**
   * Call onCancel handler from parent component
   *
   * @return void
   */
  onCancel() {
    const { onFormCancel } = this.props;

    return onFormCancel();
  }

  /**
   * Validate current form data
   *
   * @return  {Object} validations Validation result
   */
  validate() {
    const definition = {
      validations: {
        mainCategory: {
          type: 'object',
          required: true
        },
        subCategory: {
          type: 'object',
          required: true
        },
        comment: {
          type: 'string',
          required: false
        }
      }
    };

    const validator = getValidator();

    return validator.validate(definition, this.state);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { codes, language } = this.props;
    const { mainCategory, subCategory, comment } = this.state;

    const interestCategories = orderBy(get(codes, 'groups.interestCategory', [`text.${language}`]), [], ['asc']);
    const mainCategories = interestCategories.filter((i) => !has(i, 'parentCodeId'));
    const subCategories = isUndefined(mainCategory)
      ? []
      : interestCategories.filter((i) => get(i, 'parentCodeId') === get(mainCategory, 'id'));

    const validations = this.validate();

    return (
      <div style={{ paddingBottom: '20px', borderBottom: '1px solid lightgrey' }}>
        <Row>
          <Col>
            <Row lg={12}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="Interest.Form.Edit.MainCategory" />
                </Label>

                <CodeSelect
                  id="mainCategory"
                  name="mainCategory"
                  codes={mainCategories}
                  isClearable
                  language={language}
                  onChange={this.onValueChange}
                  value={mainCategory}
                  showValidations
                  validations={get(validations, 'mainCategory')}
                />
              </FormGroup>
            </Row>
            <Row lg={12}>
              <FormGroup>
                <Label>
                  <FormattedMessage id="Interest.Form.Edit.SubCategory" />
                </Label>

                <CodeSelect
                  id="subCategory"
                  name="subCategory"
                  codes={subCategories}
                  isClearable
                  language={language}
                  onChange={this.onValueChange}
                  value={subCategory}
                  showValidations
                  validations={get(validations, 'subCategory')}
                />
              </FormGroup>
            </Row>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                <FormattedMessage id="Interest.Form.Edit.Comment" />
              </Label>
              <InputField
                id="comment"
                name="comment"
                type="textarea"
                value={comment}
                rows={4}
                onChange={this.onValueChange}
                limit={3000}
              />
            </FormGroup>
          </Col>
        </Row>

        <div>
          <Button
            disabled={!isEmpty(validations)}
            onClick={this.onSubmit}
            color="primary"
            className="workguide-form-submit"
          >
            <FormattedMessage id="General.Button.Add" />
          </Button>

          <span style={{ paddingRight: '5px' }} />

          <Button
            onClick={this.onCancel}
            outline
            color="primary"
          >
            <FormattedMessage id="General.Button.Cancel" />
          </Button>
        </div>
      </div>
    );
  }
}

EditInterestForm.propTypes = {
  codes: PropTypes.object,
  customer: PropTypes.object.isRequired,
  interestActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  onFormCancel: PropTypes.func,
  toggleRedirectModal: PropTypes.func
};

EditInterestForm.defaultProps = {
  codes: { grous: {} },
  language: 'de',
  onFormCancel: noop,
  toggleRedirectModal: noop
};

function mapStateToProps(state) {
  return {
    customer: state.powersearch.customer,
    language: state.login.language
  };
}

function mapDispatchToProps(dispatch) {
  return {
    interestActions: bindActionCreators(interestActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  withWorkguideModalToggle()(withCodeGroups({ groups: ['interestCategory'] })(
    connect(mapStateToProps, mapDispatchToProps)(EditInterestForm)
  ))
);
