import { call, put } from 'redux-saga/effects';
import { has, get } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  FINANCING_ESTIMATION_LOAD_FULFILLED,
  FINANCING_ESTIMATION_LOAD_PENDING,
  FINANCING_ESTIMATION_LOAD_REJECTED,
} from '../../actions/FinancingEstimation';

/**
 * Get FinancingEstimation from backend
 *
 * @param  {Object} terms Search terms
 *
 * @return {Generator}            [description]
 */
export default function* getFinancingEstimation({ terms }) {
  const {
    objectType,
    useType,
    status,
    createdBy,
    objectId,
    street,
    city,
    createdAfter,
    createdUntil,
    limit,
  } = terms;

  yield put({ type: FINANCING_ESTIMATION_LOAD_PENDING });
  try {
    let termsString = '?';

    if (has(objectType, 'id')) {
      termsString += `objectType=${objectType.id}&`;
    }

    if (has(useType, 'id')) {
      termsString += `useType=${useType.id}&`;
    }

    if (has(status, 'id')) {
      termsString += `status=${status.id}&`;
    }

    if (has(createdBy, 'username')) {
      termsString += `createdBy=${createdBy.username}&`;
    }

    if (get(objectId, 'length', 0) > 0) {
      termsString += `objectId=${objectId}&`;
    }

    if (get(street, 'length', 0) > 0) {
      termsString += `street=${street}&`;
    }

    if (get(city, 'length', 0) > 0) {
      termsString += `city=${city}&`;
    }

    if (createdAfter) {
      termsString += `createdAfter=${createdAfter}&`;
    }
    if (createdUntil) {
      termsString += `createdUntil=${createdUntil}&`;
    }

    termsString += `limit=${limit}`;

    const data = yield call(handleNextHeaders, `/analytics/financing-estimation-iazi${termsString}`);

    yield put({ type: FINANCING_ESTIMATION_LOAD_FULFILLED, payload: data });
  } catch (error) {
    yield put({ type: FINANCING_ESTIMATION_LOAD_REJECTED, error });
  }
}
