import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import axios from 'axios';

import financingTrancheAction from '../../actions/Customer/FinancingTranche';

const {
  CUSTOMER_FINANCING_TRANCHE_PENDING,
  CUSTOMER_FINANCING_TRANCHE_FULFILLED,
  CUSTOMER_FINANCING_TRANCHE_REJECTED
} = financingTrancheAction;

export default function* getFinancingTranche(request) {
  const { customerId } = request;

  yield put({ type: CUSTOMER_FINANCING_TRANCHE_PENDING });

  try {
    const url = `/financing/tranche/?eq(customerId,string:${customerId})&limit(500)`;
    let financingTranches = yield call(handleNextHeaders, url);
    if (financingTranches.length > 0) {
      const loanProduct = yield call(
        handleNextHeaders,
        `/entity/code/?in(id,(${financingTranches.map((ft) => ft.loanProduct.$ref.substring(ft.loanProduct.$ref.lastIndexOf('/') + 1))}))`
      );
      const accountRefIds = financingTranches
        .map((ft) => ft.account && ft.account.$ref.substring(ft.account.$ref.lastIndexOf('/') + 1))
        .filter((el) => el);

      let accounts = { data: [] };

      if (accountRefIds.length > 0) {
        accounts = yield call(axios, {
          url: `/account/account/?in(id,(${accountRefIds}))`,
          method: 'GET',
        });
      }

      financingTranches = financingTranches.map((ft) => {
        const accountId = ft.account ? ft.account.$ref.substring(ft.account.$ref.lastIndexOf('/') + 1) : '';
        const loan = loanProduct.find((loan) => loan.id === ft.loanProduct.$ref.substring(ft.loanProduct.$ref.lastIndexOf('/') + 1));
        const account = accounts.data.find((account) => account.id === accountId);

        return {
          ...ft,
          account,
          loanProduct: loan
        };
      });
    }

    yield put({ type: CUSTOMER_FINANCING_TRANCHE_FULFILLED, payload: financingTranches });

    return financingTranches;
  } catch (error) {
    yield put({ type: CUSTOMER_FINANCING_TRANCHE_REJECTED, error });

    return error;
  }
}
