import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import collateralsAction from '../../actions/Collateral/Collaterals';

const {
  COLLATERALS_PENDING,
  COLLATERALS_FULFILLED,
  COLLATERALS_REJECTED
} = collateralsAction;

function* getCollateralTypes() {
  const query = QueryBuilder()
    .eq('group', 'collateralType')
    .limit(1000)
    .getQueryString();

  const url = `/entity/code/${query}`;

  const codes = yield handleNextHeaders(url);

  return codes;
}

export default function* getCollaterals(request) {
  const { dataKey } = request;

  yield put({ type: COLLATERALS_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('orderId', dataKey, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/financing/collateral/${query}`;
    const result = yield call(handleNextHeaders, url);
    const collateralTypes = yield call(getCollateralTypes);

    const payload = result.map((item) => {
      const id = get(get(item, 'collateralType.$ref', '').match(/entity\/code\/(.*)/), 1);
      const collateralType = collateralTypes.find((c) => get(c, 'id') === id);

      return {
        ...item,
        collateralType
      };
    });

    yield put({ type: COLLATERALS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: COLLATERALS_REJECTED, dataKey, error });

    return error;
  }
}
