import React from 'react';
import PropTypes from 'prop-types';
import { noop, upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cl from 'classnames';

import './LanguageSwitch.css';

const LanguageSwitch = React.memo(({
  current,
  languages,
  onChange
}) => {
  const classNames = [
    'layout-header-user-profile--dropdown-item',
    'layout-header-user-profile--dropdown-item--no-icon',
    'layout-header-user-profile--dropdown--language-switch'
  ];

  return (
    <div className={cl(classNames)}>
      <FormattedMessage id="CustomerDetail.Header.Profile.Dropdown.LanguageSwitch" />
      <div className="layout-header-user-profile--dropdown--container">
        {languages.map((l) => (
          <span
            key={l}
            className={`layout-header-user-profile--dropdown--language-switch--language text-primary ${l === current ? 'active' : ''} `}
            onClick={() => onChange(l)}
          >
            <FormattedMessage id={`General.Languages.${upperFirst(l)}`} />
          </span>
        ))}
      </div>
    </div>
  );
});

LanguageSwitch.propTypes = {
  current: PropTypes.string,
  languages: PropTypes.array,
  onChange: PropTypes.func
};

LanguageSwitch.defaultProps = {
  current: 'de',
  languages: ['de', 'fr'],
  onChange: noop
};

export default LanguageSwitch;
