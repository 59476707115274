import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getFeatureFlags from './getFeatureFlags';

const { FEATURE_FLAG_REQUEST } = actions;

export default function* generalRootSaga() {
  yield takeLatest(FEATURE_FLAG_REQUEST, getFeatureFlags);
}
