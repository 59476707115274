import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import completeTask from './completeTask';

const { COMPLETE_TASK_REQUEST } = actions;

export default function* bpfOnboardingRootSaga() {
  yield takeLatestByDataKey(COMPLETE_TASK_REQUEST, completeTask);
}
