import update from 'immutability-helper';
import { DefaultReducer } from '@evoja-web/redaction';
import { logoutActions } from '@evoja-web/react-login';

import { userRolesAction } from '../../actions/Acl/UserRoles';

const {
  GATEWAY_LOGOUT_FULFILLED,
  OIDC_LOGOUT_FULFILLED
} = logoutActions;

function aclOnLogoutFulfilled(state) {
  return update(state, {
    data: { $set: undefined }
  });
}

export const reducer = DefaultReducer('userRoles', userRolesAction);
reducer.addMethod(GATEWAY_LOGOUT_FULFILLED, aclOnLogoutFulfilled);
reducer.addMethod(OIDC_LOGOUT_FULFILLED, aclOnLogoutFulfilled);

export default reducer.get();
