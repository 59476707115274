import { put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import axios from 'axios';
import { parseLinkHeader } from '@web3-storage/parse-link-header';
import { pollSync, getIdFromEventStatusUri } from '@evoja-web/event-status';
import { actions as gamificatorActions } from '@evoja-web/gamificator';

import actions from '../../actions/Actions';
import { ADD_TOAST } from '../../../../actions/Toast';
import deleteDocuments from '../../../PrintDocumentPreview/sagas/deleteDocuments';

const {
  SEND_DOCUMENTS_WITH_SPS_PENDING,
  SEND_DOCUMENTS_WITH_SPS_FULFILLED,
  SEND_DOCUMENTS_WITH_SPS_REJECTED
} = actions;

const { TRIGGER_ACTION_REQUEST } = gamificatorActions;

export default function* sendDocumentsWithSps(request) {
  const {
    fileId, replyEnvelope = false, intl, documents, customerId
  } = request;

  yield put({ type: SEND_DOCUMENTS_WITH_SPS_PENDING });

  try {
    const fileUrl = 'file/action';
    const params = {
      fileId,
      actions: ['physicalShipment'],
      additionalProperties: [
        {
          name: 'replyEnvelope',
          value: `${replyEnvelope}`
        }
      ]
    };

    const eventStatus = yield call(axios, {
      url: fileUrl,
      method: 'post',
      data: params
    });

    const linkHeaders = parseLinkHeader(get(eventStatus, 'headers.link'), { maxHeaderLength: 5000 });
    const eventStatusId = getIdFromEventStatusUri(get(linkHeaders, 'eventStatus.url'));

    const result = yield call(pollSync, {
      dataKey: eventStatusId,
      interval: 2000,
      maxTries: 15,
      suppressToast: true,
      workers: [{ id: 'subscriptionrelay' }]
    });

    yield put({ type: SEND_DOCUMENTS_WITH_SPS_FULFILLED, payload: result });

    yield put({
      type: ADD_TOAST,
      kind: 'success',
      title: intl.formatMessage({ id: 'Sps.ApproveModal.SuccessMessage' }),
      description: '',
    });

    yield put({ type: TRIGGER_ACTION_REQUEST, dataKey: 'spsDocuments' });

    const mapWorkguideDocuments = documents.filter((d) => get(d, 'callingProcessKeyDE') === 'MAP Workguide');
    if (get(mapWorkguideDocuments, 'length', 0) > 0) {
      const documentIds = mapWorkguideDocuments.map((d) => get(d, 'id'));
      yield call(deleteDocuments, { documentIds, customerId, reloadData: true });
    }

    return result;
  } catch (error) {
    yield put({ type: SEND_DOCUMENTS_WITH_SPS_REJECTED, error });

    return error;
  }
}
