import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { extractCustomerDataFromPDFProps } from '@evoja-web/entris-portal-shared';
import { get } from 'lodash';
import { baseStyles } from '../styles';
import makeT from '../../../lib/Utils/makeT';
import vabMatrixReplyEnvelope from '../../../images/pdf/vabMatrixReplyEnvelope.jpg';
import spsStyles from '../spsStyles';
import ArialBoldItalic from '../Fonts/Arial/ARIALBOLDITALICMT.OTF';
import ArialBold from '../Fonts/Arial/ARIALBOLDMT.OTF';
import Arial from '../Fonts/Arial/arialmt.ttf';
import ArialItalic from '../Fonts/Arial/ARIALMTITALIC.OTF';
import valiantLogo from '../../../images/pdf/Valiant_RGB.jpg';

function SpsCoverLetter({ customer, intl }) {
  Font.register({ family: 'Arial MT',
    fonts: [
      { src: Arial },
      { src: ArialBold, fontStyle: 'normal', fontWeight: 'bold' },
      { src: ArialItalic, fontStyle: 'italic', fontWeight: 'normal' },
      { src: ArialBoldItalic, fontStyle: 'italic', fontWeight: 'bold' },
    ] });

  const { t } = makeT(intl);

  const { kubeContactName } = extractCustomerDataFromPDFProps(customer);

  const consultantAddress = () => {
    const { location } = get(customer, 'consultant');
    if (customer.consultant) {
      // eslint-disable-next-line max-len
      return {
        name: kubeContactName,
        street: location.street,
        city: `${location.zip} ${location.city}`
      };
    }
    return {
      name: 'Valiant Bank AG',
      street: 'Mattenstrasse 8, Postfach',
      city: '3073 Gümligen'
    };
  };

  const headerStyles = {
    image: {
      maxWidth: 140
    },
    container: {
      ...baseStyles.container,
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      height: '80pt'
    }
  };

  const consultant = consultantAddress();
  const style = { ...spsStyles.text, fontSize: 10 }; // lineHeight: 1.5

  const displayFlexColumnStyle = { display: 'flex', flexDirection: 'column' };
  const displayFlexRowStyle = { display: 'flex', flexDirection: 'row' };
  const infoTextStyle = { ...spsStyles.text, fontSize: 10 };

  return (
    <Document>
      <Page size="A4" style={{ ...baseStyles.page, paddingHorizontal: 0, paddingTop: 0, fontFamily: 'Arial MT' }}>
        <View style={{...headerStyles.container, marginTop: '40', marginRight: '90', }} fixed>
          <Image style={{ maxHeight: 35 }} src={valiantLogo} />
        </View>
        <View style={{ ...displayFlexColumnStyle, paddingTop: '135', marginLeft: '314', width: '150' }}>
          <View style={{ ...displayFlexColumnStyle }}>
            <View style={{ borderBottom: '2 solid black', }}>
              <View style={{
                ...displayFlexRowStyle,
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '1px'
              }}
              >
                <Text style={{ ...spsStyles.text, fontSize: 15 }}>{t('Pdf.ReplyEnvelopeLetter.GAS')}</Text>
                <Text style={{ ...spsStyles.text, fontSize: 7 }}>{t('Pdf.ReplyEnvelopeLetter.Post')}</Text>
              </View>
            </View>
            <View style={{ ...displayFlexRowStyle, alignItems: 'center', marginTop: 8 }}>
              <View style={{ ...displayFlexRowStyle, width: '110px' }}>
                <View style={{ display: 'flex' }}>
                  <Text style={style}>{t('Pdf.General.ValiantBank')}</Text>
                  <Text style={style}>{consultant.name}</Text>
                  <Text style={style}>{consultant.street}</Text>
                  <Text style={style}>{consultant.city}</Text>
                </View>
              </View>
              <View style={{ height: '25', width: '25' }}>
                <Image style={{ height: '25', width: '25' }} src={vabMatrixReplyEnvelope} />
              </View>
            </View>
          </View>
        </View>
        <View style={{ paddingTop: '160px', paddingHorizontal: 70 }}>
          <View>
            <Text style={{ ...infoTextStyle }}>
              {t('Pdf.ReplyEnvelopeLetter.LetterReturningInformation')}
            </Text>
            <Text>{'\n'}</Text>
            <Text style={{ ...infoTextStyle, lineHeight: '1.5' }}>
              {t('Pdf.ReplyEnvelopeLetter.ValiantAlreadyFranked')}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

SpsCoverLetter.propTypes = {
  intl: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

export default SpsCoverLetter;
