import { all, call, put } from 'redux-saga/effects';

import { isNil } from 'lodash';
import actions from '../actions/Actions';
import deleteDocument from './deleteDocument';

const {
  DELETE_DOCUMENTS_PENDING,
  DELETE_DOCUMENTS_FULFILLED,
  DELETE_DOCUMENTS_REJECTED,
  DOCUMENTS_REQUEST
} = actions;

export default function* deleteDocuments(request) {
  yield put({ type: DELETE_DOCUMENTS_PENDING });

  try {
    const { documentIds, customerId, reloadData = false } = request;

    const operations = documentIds.map((id) => {
      return call(deleteDocument, { id });
    });

    const payload = yield all(operations);

    yield put({ type: DELETE_DOCUMENTS_FULFILLED, payload });

    if (reloadData && !isNil(customerId)) {
      yield put({ type: DOCUMENTS_REQUEST, dataKey: customerId });
    }

    return {};
  } catch (error) {
    yield put({ type: DELETE_DOCUMENTS_REJECTED, error });

    return error;
  }
}
