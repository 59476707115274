import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import mboAction from '../actions/Mbo';

const {
  MBO_PENDING,
  MBO_FULFILLED,
  MBO_REJECTED
} = mboAction;

export default function* getMbo(request) {
  yield put({ type: MBO_PENDING });

  try {
    const { consultantId } = request;
    const url = `/person/consultant/mbo/?eq(id,string:${consultantId})`;
    const data = yield call(handleNextHeaders, url);
    const payload = get(data, 0);

    yield put({ type: MBO_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: MBO_REJECTED, error });

    return error;
  }
}
