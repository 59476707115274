import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import { noop } from 'lodash';

import { InputField } from '../../../../General/index';

class PortfolioNumber extends React.PureComponent {
  componentWillUnmount() {
    const { onChange } = this.props;

    // Unset value on unmount
    onChange('portfolioNumber');
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      onChange,
      validations,
      value
    } = this.props;

    return (
      <React.Fragment>
        <Label>
          <FormattedMessage id="SecuritiesAccount.Opening.Form.PortfolioNumber" />
        </Label>

        <InputField
          id="portfolioNumber"
          onChange={onChange}
          value={value}
          showValidations
          validations={validations}
        />
      </React.Fragment>
    );
  }
}

PortfolioNumber.propTypes = {
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

PortfolioNumber.defaultProps = {
  onChange: noop,
  validations: {},
  value: undefined
};

export default PortfolioNumber;
