import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEqual } from 'lodash';
import update from 'immutability-helper';
import { withRouter } from 'react-router';

import FilterMenu from '../../components/Filter/Menu';
import activityActions from '../../actions/Actions';
import getCustomerIdFromState from '../../lib/Utils/getCustomerIdFromState';

class CustomerActivitiesFilterMenu extends React.Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFiltersReset = this.onFiltersReset.bind(this);
  }

  /**
   * Handle filter change.
   * Update current filter set and reload data
   *
   * @param  {String} key    Filter key
   * @param  {Object} filter Filter object containing state (active) and value
   *
   * @return void
   */
  onFilterChange(key, filter) {
    const {
      activityActions,
      customerActivities,
      customerId,
      filters,
      search
    } = this.props;

    const current = get(filters, 'data', {});
    const updated = update(current, {
      [key]: { $set: filter }
    });

    activityActions.customerActivitiesFilterRequest({ filters: updated });
    activityActions.customerActivitiesReset();
    activityActions.customerProvisionWorkguidesReset();
    activityActions.customerActivitiesRequest({
      customerId,
      filters: updated,
      limit: get(customerActivities, 'limit', 20),
      offset: 0,
      searchTerm: get(search, 'data')
    });
  }

  /**
   * Handle filter reset.
   * Reset filters to defaults and reload data
   *
   * @return void
   */
  onFiltersReset() {
    const {
      activityActions,
      customerActivities,
      customerId,
      filters,
      search
    } = this.props;

    activityActions.customerActivitiesFilterReset();
    activityActions.customerActivitiesReset();
    activityActions.customerActivitiesRequest({
      customerId,
      filters: get(filters, 'defaults', {}),
      limit: get(customerActivities, 'limit', 20),
      offset: 0,
      searchTerm: get(search, 'data')
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { filters } = this.props;

    return (
      <FilterMenu
        filters={get(filters, 'data', {})}
        onFilterChange={this.onFilterChange}
        onFiltersReset={this.onFiltersReset}
        hiddenItems={['creator', 'toggleAll']}
        showResetIcon={!isEqual(get(filters, 'data'), get(filters, 'defaults'))}
      />
    );
  }
}

CustomerActivitiesFilterMenu.propTypes = {
  activityActions: PropTypes.object.isRequired,
  customerActivities: PropTypes.object,
  customerId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  search: PropTypes.object
};

CustomerActivitiesFilterMenu.defaultProps = {
  customerActivities: {},
  filters: {},
  search: {}
};

function mapStateToProps(state, ownProps) {
  const { match } = ownProps;

  return {
    customerActivities: state.activity.customerActivities,
    customerId: getCustomerIdFromState(state, match),
    filters: state.activity.customerActivitiesFilter,
    search: state.activity.customerActivitiesSearch
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerActivitiesFilterMenu)
);
