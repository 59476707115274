import { Actions } from '@evoja-web/redaction';

import { permissionsAction } from './Acl/Permissions';
import { temporaryUserRolesAction } from './Acl/TemporaryUserRoles';
import { userRolesAction } from './Acl/UserRoles';

export const actions = Actions([
  permissionsAction,
  temporaryUserRolesAction,
  userRolesAction
]);

export default actions.get();
