import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { get, isEmpty, isNil } from 'lodash';

/**
 * Get the display name for the given component
 *
 * @param  {Object} WrappedComponent Wrapped component
 *
 * @return {String} name Display name
 */
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Adds Feature flag properties and function to Component Props
 *
 * @param {Function} keys        List of Identifier for FeatureFlag Entries
 *
 * @return  {Function} withFeatureFlagsInner Inner decorator function
 */
function withFeatureFlags(
  { keys } = {}
) {
  return function withFeatureFlagsInner(WrappedComponent) {
    class WithFeatureFlags extends React.Component {
      constructor(props) {
        super(props);

        this.isFeatureFlagActive = this.isFeatureFlagActive.bind(this);
      }

      isFeatureFlagActive(key) {
        const { allFeatureFlags, allFeatureFlagsRequesting } = this.props;

        const featureFlags = allFeatureFlags.filter((flag) => keys.includes(get(flag, 'identifier')));
        // Get Flag vom filtered by Identifiers list
        const featureFlag = featureFlags.find((flag) => get(flag, 'identifier') === key);

        if (isNil(featureFlag) && !allFeatureFlagsRequesting) {
          // Check if Flag is in All Flag list.
          const searchedFlag = allFeatureFlags.find((ff) => get(ff, 'identifier') === key);
          // eslint-disable-next-line max-len
          let errorMessage = `Feature flag with the identifier "${key}" could not be found. Please remove the feature flag call from the code or create the feature flag entry!`;
          if (searchedFlag) {
            errorMessage = `Feature flags with the identifier "${key}" exists, but it has not been loaded. Please add it to the wrapper call!`;
          }

          throw Error(errorMessage);
        }

        return get(featureFlag, 'active', false);
      }

      /**
       * Render method
      *
      * @return {ReactElement} markup
      */
      render() {
        const {
          forwardedRef,
          allFeatureFlags,
          allFeatureFlagsRequesting,
          ...rest
        } = this.props;

        const featureFlags = allFeatureFlags.filter((flag) => keys.includes(get(flag, 'identifier')));
        const activeFeatureFlags = allFeatureFlags.filter((flag) => get(flag, 'active') === true);

        if ((isEmpty(featureFlags) || get(featureFlags, 'length', 0) !== get(keys, 'length', 0)) && !allFeatureFlagsRequesting) {
          const listOfExistingIdentifiers = allFeatureFlags.map((flag) => get(flag, 'identifier'));
          const listOfNotFoundedIdentifiers = keys.filter((key) => !listOfExistingIdentifiers.includes(key));
          // eslint-disable-next-line max-len
          throw Error(`FeatureFlags with the identifiers "${listOfNotFoundedIdentifiers.join(', ')}" could not be found. Please remove the feature flags call from the code or create the feature flag entries!`);
        }

        return (
          <WrappedComponent
            ref={forwardedRef}
            featureFlags={featureFlags}
            activeFeatureFlags={activeFeatureFlags}
            isFeatureFlagActive={this.isFeatureFlagActive}
            {...rest}
          />
        );
      }
    }

    // Assign static methods
    hoistNonReactStatic(WithFeatureFlags, WrappedComponent);

    // Wrap display name for easy debugging
    WithFeatureFlags.displayName = `${getDisplayName(WrappedComponent)}`;

    WithFeatureFlags.propTypes = {
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
      ]),
      allFeatureFlags: PropTypes.array,
      allFeatureFlagsRequesting: PropTypes.bool
    };

    WithFeatureFlags.defaultProps = {
      forwardedRef: undefined,
      allFeatureFlags: [],
      allFeatureFlagsRequesting: false
    };

    function mapStateToProps(state) {
      return {
        allFeatureFlags: get(state, 'featureFlag.featureFlags.data'),
        allFeatureFlagsRequesting: get(state, 'featureFlag.featureFlags.requesting')
      };
    }

    return connect(mapStateToProps, null)(WithFeatureFlags);
  };
}

export default withFeatureFlags;
