import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { noop, get } from 'lodash';

import { Select } from '../../../../General/index';

const Portfolios = React.memo(({
  language,
  onChange,
  portfolios,
  validations,
  value
}) => {
  const options = get(portfolios, 'data', [])
    .map((p) => ({
      value: get(p, 'id'),
      portfolio: p,
      label: `${get(p, `portfolioKind.text.${language}`)} (${get(p, 'numberFormatted')})`
    }));

  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.Portfolios" />
      </Label>

      <Select
        id="portfolio"
        showValidations
        validations={validations}
        value={get(value, 'id')}
        onChange={onChange}
        options={options}
        returnValue="portfolio"
      />
    </React.Fragment>
  );
});

Portfolios.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  portfolios: PropTypes.object,
  validations: PropTypes.object,
  value: PropTypes.object
};

Portfolios.defaultProps = {
  language: 'de',
  onChange: noop,
  portfolios: {},
  validations: {},
  value: undefined
};

export default Portfolios;
