import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { get, isNil } from 'lodash';

/**
 * Get the display name for the given component
 *
 * @param  {Object} WrappedComponent Wrapped component
 *
 * @return {String} name Display name
 */
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Adds Feature flag properties to Component Props
 *
 * @param {Function} key        Identifier of the FeatureFlag Entry
 *
 * @return  {Function} withFeatureFlagInner Inner decorator function
 */
function withFeatureFlag(
  { key } = {}
) {
  return function withFeatureFlagInner(WrappedComponent) {
    class WithFeatureFlag extends React.Component {
      /**
       * Render method
      *
      * @return {ReactElement} markup
      */
      render() {
        const {
          forwardedRef,
          featureFlags,
          featureFlagsRequesting,
          ...rest
        } = this.props;

        const featureFlag = featureFlags.find((flag) => get(flag, 'identifier') === key);

        if (isNil(featureFlag) && !featureFlagsRequesting) {
          // eslint-disable-next-line max-len
          throw Error(`Feature flag with the identifier "${key}" could not be found. Please remove the feature flag call from the code or create the feature flag entry!`);
        }

        return (
          <WrappedComponent
            ref={forwardedRef}
            featureFlag={featureFlag}
            isFeatureFlagActive={get(featureFlag, 'active')}
            {...rest}
          />
        );
      }
    }

    // Assign static methods
    hoistNonReactStatic(WithFeatureFlag, WrappedComponent);

    // Wrap display name for easy debugging
    WithFeatureFlag.displayName = `${getDisplayName(WrappedComponent)}`;

    WithFeatureFlag.propTypes = {
      forwardedRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(React.Component) }),
      ]),
      featureFlags: PropTypes.array,
      featureFlagsRequesting: PropTypes.bool
    };

    WithFeatureFlag.defaultProps = {
      forwardedRef: undefined,
      featureFlags: [],
      featureFlagsRequesting: false
    };

    function mapStateToProps(state) {
      return {
        featureFlags: get(state, 'featureFlag.featureFlags.data'),
        featureFlagsRequesting: get(state, 'featureFlag.featureFlags.requesting')
      };
    }

    return connect(mapStateToProps, null)(WithFeatureFlag);
  };
}

export default withFeatureFlag;
