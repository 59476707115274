import { TYPE_NUMBER, TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import moment from 'moment';
import { isNull } from 'lodash';

/**
 * Subtract the given amount of <<day, month, year>> to the given date.
 * @see: https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/02-subtract/
 * @example: Subtract 23 days: dateStringSubtract(formData.cmpXYZ,`23`,'day')
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} formatted Formatted number
 */
export function dateStringSubtract(resolvedArgs = []) {
  const [value, amount, key = 'days'] = resolvedArgs;

  if (isNull(value)) return undefined;

  const date = moment(value);

  if (!date.isValid()) return undefined;

  return date.subtract(amount, key).format();
}

export default {
  key: 'dateStringSubtract',
  func: dateStringSubtract,
  types: [
    { types: [TYPE_STRING, TYPE_NULL] },
    { types: [TYPE_NUMBER] },
    { types: [TYPE_STRING], optional: true }
  ]
};
