import { Reducers } from '@evoja-web/redaction';

import {
  permissionsReducer,
  temporaryUserRolesReducer,
  userRolesReducer
} from './Acl/index';

export const reducers = Reducers([
  permissionsReducer,
  temporaryUserRolesReducer,
  userRolesReducer
]);

export default reducers.get();
