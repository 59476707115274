import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import QueryBuilder from '@evoja-web/rql-query-builder';

import actions from '../actions/Actions';

const {
  DOCUMENTS_PENDING,
  DOCUMENTS_FULFILLED,
  DOCUMENTS_REJECTED
} = actions;

export default function* getDocuments(request) {
  // Fallback to customer id if data key is missing
  const dataKey = get(request, 'dataKey', get(request, 'customerId'));

  yield put({ type: DOCUMENTS_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('customerId', dataKey, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/print/document/preview/${query}`;

    const { data: payload } = yield call(axios, {
      url,
      method: 'GET',
    });

    yield put({ type: DOCUMENTS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: DOCUMENTS_REJECTED, dataKey, error });

    return error;
  }
}
