import { Reducers } from '@evoja-web/redaction';

import {
  accountsReducer,
  contractsReducer,
  customerBalanceReducer,
  pricingReducer,
  productbundleBundleReducer,
  productbundleProductReducer,
  productbundleUsageReducer,
  productbundleUsageDetailReducer,
  textblockReducer
} from './SetAdvisor/index';

export const reducers = Reducers([
  accountsReducer,
  contractsReducer,
  customerBalanceReducer,
  pricingReducer,
  productbundleBundleReducer,
  productbundleProductReducer,
  productbundleUsageReducer,
  productbundleUsageDetailReducer,
  textblockReducer
]);

export default reducers.get();
