import { getReducer } from '@evoja-web/redaction';

import * as dataLoadState from '../actions/DataLoadState';

export const defaultState = {
  requesting: false,
  error: false,
  data: undefined,
  fulfilled: false
};

export const methods = {};

methods[dataLoadState.DATA_LOAD_STATE_PENDING] = (state) => ({
  ...state,
  requesting: true,
  error: false,
  data: [],
  fulfilled: false
});

methods[dataLoadState.DATA_LOAD_STATE_FULFILLED] = (state, action) => ({
  ...state,
  requesting: false,
  error: false,
  data: action.payload,
  fulfilled: true
});

methods[dataLoadState.DATA_LOAD_STATE_REJECTED] = (state, action) => ({
  ...state,
  requesting: false,
  error: action.error,
  data: [],
  fulfilled: false
});

export default getReducer(methods, defaultState);
