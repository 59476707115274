import { Reducers } from '@evoja-web/redaction';

import {
  userSettingsReducer,
  saveUserSettingsReducer
} from './UserSettings';

export const reducers = Reducers([
  userSettingsReducer,
  saveUserSettingsReducer
]);

export default reducers.get();
