import moment from 'moment';
import { sortBy } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  UINOTES_BANNERS_FULFILLED,
  UINOTES_BANNERS_PENDING,
  UINOTES_BANNERS_REJECTED,
} from '../../actions/UiNotes';

export default function* fetchUiNotesBanners() {
  yield put({ type: UINOTES_BANNERS_PENDING });

  try {
    const now = moment.utc().format('YYYY-MM-DD\\THH:mm:ss\\Z');
    const query = [
      'eq(enabled,true())',
      'eq(type.id,string:userInterfaceNoteType-2)',
      `or(eq(publishDate,null()),le(publishDate,${now}))`,
    ].join('&');

    const uiNotes = yield call(handleNextHeaders, `/work/uinotes/?${query}`);

    // eslint-disable-next-line
    const banners = sortBy(uiNotes, ({ publishDate }) => publishDate
      ? moment(publishDate).valueOf()
      : Infinity).reverse();

    yield put({ type: UINOTES_BANNERS_FULFILLED, payload: banners });
  } catch (error) {
    yield put({ type: UINOTES_BANNERS_REJECTED, error });
  }
}
