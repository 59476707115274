import { takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';
import actions from '../actions/Actions';
import calculateManyCapitalTaxes from './calculateManyCapitalTaxes';
import getTaxLocation from './getTaxLocation';
import initCalculateManyCapitalTaxesForm from './CustomerManyCapitalTaxesForm/initCustomerManyCapitalTaxesForm';
import saveCalculateManyCapitalTaxesForm from './CustomerManyCapitalTaxesForm/saveCustomerManyCapitalTaxesForm';

const {
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_REQUEST ,
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_REQUEST ,
  CALCULATE_MANY_CAPITAL_TAXES_REQUEST,
  TAX_LOCATION_REQUEST
} = actions;

export default function* taxRootSaga() {
  yield takeLatestByDataKey(TAX_LOCATION_REQUEST, getTaxLocation);
  yield takeLatest(CALCULATE_MANY_CAPITAL_TAXES_REQUEST, calculateManyCapitalTaxes);
  yield takeLatest(CUSTOMER_MANY_CAPITAL_TAXES_FORM_INIT_REQUEST, initCalculateManyCapitalTaxesForm);
  yield takeLatest(CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_REQUEST, saveCalculateManyCapitalTaxesForm);
}
