import { DataKeyReducer } from '@evoja-web/redaction';
import update from 'immutability-helper';
import { get } from 'lodash';

import action, { confirmBannerAction } from '../actions/ConfirmBanner';

const { CONFIRM_BANNER_FULFILLED } = action;

function afterConfirmBannerFulfilled(state, action) {
  const { dataKey, payload } = action;
  const index = get(state, 'banners.data', []).findIndex((b) => b.id === dataKey);

  return index > -1
    ? update(state, {
      banners: {
        data: { $splice: [[index, 1, payload]] }
      }
    })
    : state;
}

export const reducer = DataKeyReducer('confirmBanner', confirmBannerAction);
reducer.after(CONFIRM_BANNER_FULFILLED, afterConfirmBannerFulfilled);

export default reducer.get();
