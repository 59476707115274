import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const BootstrapDataError = React.memo(({ error }) => {
  return (
    <Container
      fluid
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
      }}
    >
      <Alert type="error">
        <AlertTitle>
          <FormattedMessage id="Bootstrap.DataError.Title" />
        </AlertTitle>
        <AlertContent>
          <p>
            <FormattedMessage id="Bootstrap.DataError.Description" />
            <br />
            <FormattedMessage id="Bootstrap.DataError.CallHelpdesk" />
          </p>

          <p>
            <FormattedMessage id="Bootstrap.DataError.ErrorMessage" values={{ error: get(error, 'message', '') }} />
          </p>

          <Button
            color="primary"
            onClick={() => window.location.reload(true)}
          >
            <FormattedMessage id="General.ReloadPage" />
          </Button>
        </AlertContent>
      </Alert>
    </Container>
  );
});

BootstrapDataError.propTypes = {
  error: PropTypes.object
};

BootstrapDataError.defaultProps = {
  error: undefined
};

export default BootstrapDataError;
