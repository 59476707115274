import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { noop, head } from 'lodash';

import { ButtonSelect } from '../../../../General/index';

const Correspondence = React.memo(({
  onChange,
  validations,
  value
}) => {
  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.Correspondence" />
      </Label>

      <ButtonSelect
        id="correspondence"
        options={[{
          id: 'periodic',
          value: 'periodic',
          label: <FormattedMessage id="SecuritiesAccount.Opening.Form.CorrespondencePeriodic" />
        }, {
          id: 'all',
          value: 'all',
          label: <FormattedMessage id="SecuritiesAccount.Opening.Form.CorrespondenceAll" />
        }]}
        value={[value]}
        onChange={(field, updated) => onChange(field, head(updated))}
        showValidations
        validations={validations}
      />
    </React.Fragment>
  );
});

Correspondence.propTypes = {
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.string
};

Correspondence.defaultProps = {
  onChange: noop,
  validations: {},
  value: undefined
};

export default Correspondence;
