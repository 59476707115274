import { ReleaseActionProto } from '@evoja-web/react-application-release';
import { TarzanInstances } from '@evoja-web/tarzan';

/**
 * Get the tarzan instances and destroy them
 *
 * @return  void
 */
async function destroyTarzanInstances() {
  const instances = TarzanInstances();

  await instances.destoryAllInstances();
}

export default function ReleaseActionTarzanDestroyAllInstances(props = {}) {
  return ReleaseActionProto({
    ...props,
    id: 'destroyTarzanInstances',
    action: destroyTarzanInstances
  });
}
