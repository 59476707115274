import { put, call } from 'redux-saga/effects';
import { get, isEmpty, isNil } from 'lodash';

import { createIntl } from 'react-intl';
import actions from '../../actions/Actions';
import SpsCoverLetter from '../../../../components/Pdf/Letters/SpsCoverLetter';
import { generatePdf } from '../../../../lib/Pdf/Utils';

// Intl stuff
import messages from '../../../../intl/messages';
import { flattenMessages } from '../../../../utils';
import prepareCoverLetterDescription from '../../utils/prepareCoverLetterDescription';

const {
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_PENDING,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_FULFILLED,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REJECTED,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SET_VALUE
} = actions;

export default function* saveForm(request) {
  const { customer, data, consultant } = request;

  yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_PENDING });

  try {
    // Prepare Description for Cover Letter
    const description = get(data, 'description');
    if (!isNil(description) && !isEmpty(description)) {
      data.descriptionHtmlElement = prepareCoverLetterDescription({ description });
    }

    const language = get(customer, 'languageIso', 'de');
    const intl = createIntl({
      locale: language,
      messages: flattenMessages(messages[language])
    });

    // Cover Letter generation
    const spsCoverLetter = SpsCoverLetter({ customer, formData: data, language, intl, consultant, isLocalPrint: true });

    const spsCoverLetterResult = yield call(generatePdf, spsCoverLetter);

    const file = get(spsCoverLetterResult, 'blob');

    const payload = {
      ...data,
      previewDocument: file
    };

    // Set previewDocument value with set_value action, because the save fulfilled action didnt set the new value
    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SET_VALUE,
      payload: {
        key: 'previewDocument', value: file
      } });

    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REJECTED, error });

    return error;
  }
}
