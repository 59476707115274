import { call, put } from 'redux-saga/effects';
import { get, filter } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import customerSegmentsAction from '../actions/CustomerSegments';

const {
  CUSTOMER_SEGMENTS_PENDING,
  CUSTOMER_SEGMENTS_FULFILLED,
  CUSTOMER_SEGMENTS_REJECTED
} = customerSegmentsAction;

export default function* getCustomerSegments(request) {
  yield put({ type: CUSTOMER_SEGMENTS_PENDING });

  try {
    const { consultantId } = request;
    const url = `/analytics/customersegment?consultantId=${consultantId}`;
    const data = yield call(handleNextHeaders, url);
    const payload = filter(data, (r) => get(r, 'segment.number') < 90);

    yield put({ type: CUSTOMER_SEGMENTS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_SEGMENTS_REJECTED, error });

    return error;
  }
}
