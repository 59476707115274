import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import customerBalanceActions from '../../actions/SetAdvisor/CustomerBalance';

const {
  CUSTOMER_BALANCE_PENDING,
  CUSTOMER_BALANCE_FULFILLED,
  CUSTOMER_BALANCE_REJECTED
} = customerBalanceActions;

export default function* getCustomerBalance(request) {
  const { customerId } = request;

  yield put({ type: CUSTOMER_BALANCE_PENDING });
  try {
    const payload = yield call(handleNextHeaders, `/person/customer-balance/?eq(customerId,string:${customerId})&limit(9999)`);

    yield put({ type: CUSTOMER_BALANCE_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_BALANCE_REJECTED, error });

    return error;
  }
}
