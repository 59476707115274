import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import paymentRulesAction from '../../actions/PaymentRule/PaymentRules';

const {
  PAYMENT_RULES_PENDING,
  PAYMENT_RULES_FULFILLED,
  PAYMENT_RULES_REJECTED
} = paymentRulesAction;

export default function* getPaymentRules(request) {
  yield put({ type: PAYMENT_RULES_PENDING });

  try {
    const { customerId } = request;

    const query = QueryBuilder()
      .or()
      // Indent for readability
      /* eslint-disable */
        .eq('originCustomer.id', customerId, { type: 'string' })
        .eq('targetCustomer.id', customerId, { type: 'string' })
      /* eslint-enable */
      .end()
      .limit(1000)
      .getQueryString();

    const url = `/account/instruction/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: PAYMENT_RULES_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PAYMENT_RULES_REJECTED, error });

    return error;
  }
}
