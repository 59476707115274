import { get } from 'lodash';
import { call } from 'redux-saga/effects';
import QueryBuilder from '@evoja-web/rql-query-builder';
import axios from 'axios';

const prepareDataMap = {
  account: (data, newProp) => { return { ...data, account: newProp }; },
  portfolio: (data, newProp) => { return { ...data, portfolio: newProp }; },
  customer: (data, newProp) => { return { ...data, customer: newProp }; },
  default: (data, newProp) => { return { ...data, customer: newProp }; }
};

function addMissingPropertyToElement(type, data, resolvedElementList, resolvedElementListKey, resolvedElementListFindKey, condition) {
  const preparedData = data.map((entry) => {
    const resultElement = resolvedElementList.find((q) => get(q, resolvedElementListKey, '').toString() === get(entry, resolvedElementListFindKey, '').toString());
    if (!condition(entry)) {
      return entry;
    }
    const prepareDataFunc = get(prepareDataMap, type);
    return prepareDataFunc(entry, resultElement);
  });
  return preparedData;
}

/**
 * Gets Customers by Ids
 * @param {Object} customerIds object with customerIds Array
 * @returns Array of Customers
 */
function* getCustomersByCustomerIds({ customerIds = [] }) {
  const query = QueryBuilder()
    .in('id', customerIds, { type: 'string' })
    .getQueryString();

  const url = `/person/customer/${query}`;

  // Return list of Customers
  const { data: payload } = yield call(axios, {
    url,
    method: 'get'
  });

  return payload;
}

/**
 * Gets Accounts by Ids
 * @param {Object} accountIds object with accountIds Array
 * @returns Array of Accounts
 */
function* getAccountsByAccountIds({ accountIds = [] }) {
  const query = QueryBuilder()
    .in('id', accountIds, { type: 'string' })
    .getQueryString();

  const url = `/account/account/${query}`;

  // Return list of Customers
  const { data: payload } = yield call(axios, {
    url,
    method: 'get'
  });

  return payload;
}

/**
 * Gets Portfolio by Ids
 * @param {Object} portfolioIds object with portfolioIds Array
 * @returns Array of Portfolio
 */
function* getPortfoliosByPortfolioIds({ portfolioIds = [] }) {
  const query = QueryBuilder()
    .in('id', portfolioIds, { type: 'string' })
    .getQueryString();

  const url = `/account/group/${query}`;

  // Return list of Customers
  const { data: payload } = yield call(axios, {
    url,
    method: 'get'
  });

  return payload;
}

function* prepareTariffData({ data }) {
  let preparedData = data;

  // Account / Prepare Account Data
  const accountIds = data.map((entry) => get(entry, 'accountId'));
  if (get(accountIds, 'length', 0) > 0) {
    const accounts = yield call(getAccountsByAccountIds, { accountIds });
    preparedData = addMissingPropertyToElement(
      'account',
      preparedData,
      accounts,
      'id',
      'accountId',
      () => true
    );
  }

  // Protfolio / Ermittelt für welche einträge Portfolios geladen werden müssen
  const portfolioIds = data.filter((entry) => get(entry, 'tier.id') === 'tier-2').map((entry) => get(entry, 'portfolioId')); // portfolioId
  if (get(portfolioIds, 'length', 0) > 0) {
    const portfolios = yield call(getPortfoliosByPortfolioIds, { portfolioIds });
    preparedData = addMissingPropertyToElement(
      'portfolio',
      preparedData,
      portfolios,
      'id',
      'portfolioId',
      (data) => get(data, 'tier.id') === 'tier-2'
    );
  }

  // Customer / Ermittelt für welche einträge Customers geladen werden müssen
  const customerIds = data.filter((entry) => get(entry, 'tier.id') !== 'tier-1' && get(entry, 'tier.id') !== 'tier-2').map((entry) => get(entry, 'customerId'));
  if (get(customerIds, 'length', 0) > 0) {
    const customers = yield call(getCustomersByCustomerIds, { customerIds });
    preparedData = addMissingPropertyToElement(
      'customer',
      preparedData,
      customers,
      'id',
      'customerId',
      (data) => get(data, 'tier.id') !== 'tier-1' || get(data, 'tier.id') !== 'tier-2'
    );
  }

  return preparedData;
}

export default prepareTariffData;
