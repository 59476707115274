import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import kpiFinacingHistoryAction from '../../actions/Kpi/KpiFinancingHistory';

const {
  KPI_FINANCING_HISTORY_PENDING,
  KPI_FINANCING_HISTORY_FULFILLED,
  KPI_FINANCING_HISTORY_REJECTED
} = kpiFinacingHistoryAction;

export default function* getKpiFinancingHistory(request) {
  const { customerId } = request;
  yield put({ type: KPI_FINANCING_HISTORY_PENDING });

  try {
    const query = QueryBuilder()
      .eq('customerId', customerId, { type: 'string' })
      .eq('balanceSubType.id', 'liabilityType-4939', { type: 'string' })
      .sort('+entryDate')
      .limit(999)
      .getQueryString();

    const url = `/person/customer-balance/history/${query}`;

    const data = yield call(handleNextHeaders, url);

    yield put({ type: KPI_FINANCING_HISTORY_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: KPI_FINANCING_HISTORY_REJECTED, error });

    return error;
  }
}
