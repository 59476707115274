import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem
} from 'reactstrap';
import cl from 'classnames';
import { get } from 'lodash';

import './Header.css';
import Logo from '../../../components/Layout/Header/Logo';
import Links from '../../../components/Layout/Header/Links';
import Powersearch from './Powersearch/Powersearch';
import UserProfile from './UserProfile';
import ToastHandler from '../../../../../components/General/ToastHandler';

function openNewTab() {
  window.open(window.location.href, '_blank');
}

class LayoutHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isSearchFocused: false
    };

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.onSearchFocus = this.onSearchFocus.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
  }

  onSearchFocus() {
    this.setState({ isSearchFocused: true });
  }

  onSearchBlur() {
    this.setState({ isSearchFocused: false });
  }

  toggleNavigation() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen, isSearchFocused } = this.state;
    const {
      toast,
      language
    } = this.props;

    return (
      <div className="layout-header">
        <Navbar
          className="layout-header-navbar"
          fixed="top"
          expand="md"
        >
          <Logo />

          <NavbarToggler onClick={this.toggleNavigation} />

          <Collapse isOpen={isOpen} navbar>
            <Nav
              className={cl({
                'layout-header-navbar--nav': true,
                'layout-header-navbar--nav--hidden': isSearchFocused,
                'layout-header-navbar--nav--visible': !isSearchFocused
              })}
              navbar
              tag="div"
            >
              <Links />
            </Nav>

            <Nav
              className="ms-auto layout-header-navbar--nav"
              navbar
              tag="div"
            >
              <NavItem
                className="layout-header-navbar--nav--powersearch"
                tag="div"
              >
                <Powersearch
                  onFocus={this.onSearchFocus}
                  onBlur={this.onSearchBlur}
                />
              </NavItem>

              <NavItem
                className="layout-header-navbar--nav--open-new-tab"
                onClick={openNewTab}
                tag="div"
              >
                <span className="mdi mdi-plus open-new-tab-icon" />
              </NavItem>

              <NavItem
                className="layout-header-navbar--nav--user-profile"
                tag="div"
              >
                <UserProfile language={language} />
              </NavItem>

              {/* <NavItem
                className="layout-header-navbar--nav--news"
                tag="div"
              >
                <News />
              </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>

        <ToastHandler data={toast} />
      </div>
    );
  }
}

LayoutHeader.propTypes = {
  language: PropTypes.string,
  toast: PropTypes.object,
};

LayoutHeader.defaultProps = {
  language: 'de',
  toast: {},
};

function mapStateToProps(state) {
  return {
    toast: state.toast,
    textblock: get(state, 'textblock.textblock.technicalSupport'),
    language: state.login.language,
  };
}

export default connect(mapStateToProps)(LayoutHeader);
