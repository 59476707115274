import { put } from 'redux-saga/effects';

import {
  ADD_TOAST
} from '../../actions/Toast';

/**
 * Default error handler
 *
 * @param  {Object} error Error object
 *
 * @return {Generator}
 */
export default function* defaultEventStatusError(error) {
  yield put({
    type: ADD_TOAST,
    kind: 'error',
    title: 'Error.EventStatus.FailedTitle',
    description: 'Error.EventStatus.ErrorDescription',
    values: { message: error.message }
  });
}
