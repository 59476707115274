import * as Sentry from '@sentry/react';
import { call, put, select } from 'redux-saga/effects';
import { get } from 'lodash';

import axios from 'axios';
import initAction from '../../actions/Sentry/Init';

const {
  INIT_PENDING,
  INIT_FULFILLED,
  INIT_REJECTED
} = initAction;

function beforeBreadcrumb() {
  return null;
}

export default function* bootstrapSentry() {
  yield put({ type: INIT_PENDING });

  try {
    const { fulfilled = false } = yield select((state) => get(state, 'sentry.init', {}));

    if (fulfilled) return Sentry;

    const { data: environment } = yield call(axios, {
      url: '/environment.json',
      method: 'get',
      baseURL: null,
      headers: {
        'Cache-Control': 'no-store',
        Pragma: 'no-cache'
      }
    });

    Sentry.init({
      dsn: get(environment, 'sentry_url'),
      environment: get(environment, 'environment', 'unknown'),
      maxBreadcrumbs: 7,
      autoSessionTracking: false,
      release: `map@${get(environment, 'version')}`,
      normalizeDepth: 10,
      /*
      * Errors caught by the error boundary from packages/Error/containers/Boundary
      * Are not written to the sentry instance when Dedupe is active
      * Not clear why this happens as it is a different error with differen stack trace and message
      * but it may be a problem with react development mode which bubbles all errors up to the global onError
      * handler. If this does not work in production mode we will have to find another way to make sure that custom error
      * that were cauth in boundary are written to sentry instead of the original one...
      integrations: function(integrations) {
        return integrations.filter((i) => i.name !== 'Dedupe');
      },
      */
      beforeBreadcrumb
    });

    yield put({ type: INIT_FULFILLED, payload: true });

    return Sentry;
  } catch (error) {
    yield put({ type: INIT_REJECTED, error });

    return error;
  }
}
