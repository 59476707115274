import { TYPE_ARRAY, TYPE_NULL } from '@metrichor/jmespath-plus';
import { head, isNull, isUndefined } from 'lodash';

/**
 * Get the first value from the given array that is not null or undefined
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {Mixed} value First defined value in array
 */
export function firstDefinedValue(resolvedArgs) {
  const [values = []] = resolvedArgs;

  return head(
    values.filter((v) => !isNull(v) && !isUndefined(v))
  );
}

export default {
  key: 'firstDefinedValue',
  func: firstDefinedValue,
  types: [{ types: [TYPE_ARRAY, TYPE_NULL] }]
};
