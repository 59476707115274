import { Actions } from '@evoja-web/redaction';

import {
  accountActions,
  contractActions,
  customerBalanceActions,
  pricingActions,
  productbundleBundleActions,
  productbundleProductActions,
  productbundleUsageActions,
  productbundleUsageDetailActions,
  textblockActions
} from './SetAdvisor/index';

const actions = Actions([
  accountActions,
  contractActions,
  customerBalanceActions,
  pricingActions,
  productbundleBundleActions,
  productbundleProductActions,
  productbundleUsageActions,
  productbundleUsageDetailActions,
  textblockActions
]);

export default actions.get();
