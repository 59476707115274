import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, uniq } from 'lodash';

import financingCollateralAction from '../../actions/Customer/FinancingCollateral';

const {
  CUSTOMER_FINANCING_COLLATERAL_PENDING,
  CUSTOMER_FINANCING_COLLATERAL_FULFILLED,
  CUSTOMER_FINANCING_COLLATERAL_REJECTED
} = financingCollateralAction;

export default function* getFinancingCollateral(request) {
  yield put({ type: CUSTOMER_FINANCING_COLLATERAL_PENDING });

  try {
    const customerId = get(request, 'customerId', '');
    let { data } = yield call(axios, { url: `/financing/collateral/?eq(customerId,string:${customerId})` });

    // get the collateralType entity code with the provided $ref url
    const getCodeId = (ref) => get(ref.match(/entity\/code\/(.*)/), '1', '');
    data = data.map((el) => ({ ...el, collateralType: getCodeId(get(el, 'collateralType.$ref', '')) }));
    const params = uniq(data.filter((el) => !!el.collateralType).map((el) => el.collateralType)).join(',') || ' ';
    const { data: codes } = yield call(axios, { url: `/entity/code/?in(id,(${params}))` });

    // eslint-disable-next-line
    data.forEach((el) => el.collateralType = codes.find(c => c.id === el.collateralType));

    data.sort((a, b) => {
      const var1 = get(a, 'rank', 999);
      const var2 = get(b, 'rank', 999);
      return var1 < var2 ? -1 : var1 > var2 ? 1 : 0;
    });

    yield put({ type: CUSTOMER_FINANCING_COLLATERAL_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: CUSTOMER_FINANCING_COLLATERAL_REJECTED, error });

    return error;
  }
}
