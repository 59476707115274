import { get } from 'lodash';
// eslint-disable-next-line
import { utils } from '@evoja-web/entris-portal-shared';

/**
 * Custom check function to decide if the pledge document should be added to the document in summary.
 * As defined in https://issue.swisscom.ch/browse/MAP-1924, the document must be added if the account has a pledge
 * (same condition as in AccountSelector Line 36)
 *
 * @param  {Object} props Props of the AcountValuta component
 *
 * @return {Bollean}
 */
export default function shouldAddPledgeDocumentCheck(props) {
  const { data, global } = props;
  const { selectedRivorAccount } = utils.entrisWorkguideSelectedRivorAccount(data, global);

  return (
    get(selectedRivorAccount, 'pledge.length', 0) > 0
    && get(selectedRivorAccount, 'pledge[0].type.id') !== 'suspension-no'
  );
}
