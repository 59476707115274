import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import './Title.css';

const UserProfileDropdownTitle = React.memo(({
  consultant,
  toggle
}) => {
  return (
    <div className="layout-header-user-profile--dropdown--title">
      <FormattedMessage
        id="CustomerDetail.Header.Profile.Dropdown.Title"
        values={{
          firstName: get(consultant, 'firstName', ''),
          lastName: get(consultant, 'lastName', '')
        }}
        tagName="span"
        className="layout-header-user-profile--dropdown--title--consultant-name"
      />

      <span
        className="mdi mdi-close float-end layout-header-user-profile--dropdown--title--close"
        onClick={toggle}
      />
    </div>
  );
});

UserProfileDropdownTitle.propTypes = {
  consultant: PropTypes.object.isRequired,
  toggle: PropTypes.func
};

UserProfileDropdownTitle.defaultProps = {
  toggle: noop
};

export default UserProfileDropdownTitle;
