import React from 'react';
import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import AccountHolder from './AccountHolder';
import CardHolder from './CardHolder';

const ProcessInfo = React.memo(({
  language,
  process
}) => {
  const { address } = process;
  const yes = <FormattedMessage id="General.Yes" />;
  const no = <FormattedMessage id="General.No" />;

  return (
    <div className="pt-2">
      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.Product" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, `cardVarietyText.${language}`, '-')}
        </Col>
      </Row>

      <AccountHolder process={process} />

      <Row className="pb-4" />

      <CardHolder process={process} />

      <Row className="pb-4" />

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.ShippingToBank" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'shippingToBank', false) ? yes : no}
        </Col>
      </Row>

      {!isNil(address) && (
        <Row>
          <Col lg={4} md={4}>
            <FormattedMessage id="Onboarding.ProcessInfo.ShippingAddress" />
          </Col>

          <Col lg={8} md={8}>
            {/* eslint-disable-next-line */}
            {`${get(address, 'street', '')} ${get(address, 'houseNumber', '')}, ${get(address, 'postcode', '')} ${get(address, 'place', '')}, ${get(address, 'country', '')}`}
          </Col>
        </Row>
      )}

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.DailyLimit" />
        </Col>

        <Col lg={8} md={8}>
          <FormattedNumber
            value={get(process, 'dailyLimit', 0)}
            // eslint-disable-next-line
            style="currency"
            currency="CHF"
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.MonthlyLimit" />
        </Col>

        <Col lg={8} md={8}>
          <FormattedNumber
            value={get(process, 'monthlyLimit', 0)}
            // eslint-disable-next-line
            style="currency"
            currency="CHF"
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={4}>
          <FormattedMessage id="Onboarding.ProcessInfo.MultiAccount" />
        </Col>

        <Col lg={8} md={8}>
          {get(process, 'multiAccountsAssign', false) ? yes : no}
        </Col>
      </Row>
    </div>
  );
});

ProcessInfo.propTypes = {
  language: PropTypes.string,
  process: PropTypes.object
};

ProcessInfo.defaultProps = {
  language: 'de',
  process: {}
};

export default ProcessInfo;
