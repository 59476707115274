import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBulder from '@evoja-web/rql-query-builder';
import { isNil, head } from 'lodash';

import workbenchRecordAction from '../actions/WorkbenchRecord';

const {
  WORKBENCH_RECORD_PENDING,
  WORKBENCH_RECORD_FULFILLED,
  WORKBENCH_RECORD_REJECTED
} = workbenchRecordAction;

export default function* getWorkbenchRecord(request) {
  const {
    dataKey,
    id,
    originId
  } = request;

  yield put({ type: WORKBENCH_RECORD_PENDING, dataKey });

  try {
    const builder = QueryBulder();

    if (!isNil(id)) {
      builder.eq('id', id, { type: 'string' });
    }

    if (!isNil(originId)) {
      builder.eq('originId', originId, { type: 'string' });
    }

    const query = builder
      .limit(1)
      .getQueryString();

    const url = `/work/workbench/${query}`;
    const result = yield call(handleNextHeaders, url);

    const payload = head(result);

    yield put({ type: WORKBENCH_RECORD_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: WORKBENCH_RECORD_REJECTED, dataKey, error });

    return error;
  }
}
