import { every } from 'lodash';

import isBasicContractFulfilledOrRunning from '../../../BpfCms/lib/ProfileCompletion/Common/isBasicContractFulfilledOrRunning';
import isAiaFatcaDeclarationFulfilledOrRunning from '../../../BpfCms/lib/ProfileCompletion/Common/isAiaFatcaDeclarationFulfilledOrRunning';

/**
 * Check if conditions for a double partner are fulfilled
 *
 * @param  {Object} formalities        Formalities of the main customer (double partner)
 * @param  {Object} partnerFormalities Formalities of each partner of the main customer
 *
 * @return {Boolean}
 */
export default function doublePartnerFormalitiesFulfilled({ formalities, partnerFormalities = [] }) {
  const partnersFulfilled = every(partnerFormalities, (formalities) => isAiaFatcaDeclarationFulfilledOrRunning(formalities));

  return (
    isBasicContractFulfilledOrRunning(formalities)
    && partnersFulfilled
  );
}
