import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ItemDataLoadState from './ItemDataLoadState';

const DataLoad = React.memo(({ dataLoadState }) => {
  return (
    <>
      <ItemDataLoadState
        date={get(dataLoadState, 'data.baseData.workHistory.done', '')}
        labelId="CustomerDetail.Header.Profile.DataLoadMenu.BaseData"
      />
      <ItemDataLoadState
        date={get(dataLoadState, 'data.transactionData.workHistory.done', '')}
        labelId="CustomerDetail.Header.Profile.DataLoadMenu.TransactionData"
      />
      <ItemDataLoadState
        date={get(dataLoadState, 'data.leadManagement.workHistory.done', '')}
        labelId="CustomerDetail.Header.Profile.DataLoadMenu.LeadManagement"
      />
      <ItemDataLoadState
        date={get(dataLoadState, 'data.mailData.workHistory.done', '')}
        labelId="CustomerDetail.Header.Profile.DataLoadMenu.MailData"
      />
    </>
  );
});

DataLoad.propTypes = {
  dataLoadState: PropTypes.object
};

DataLoad.defaultProps = {
  dataLoadState: {}
};

export default DataLoad;
