import { TYPE_ARRAY, TYPE_NULL } from '@metrichor/jmespath-plus';
import { get, multiply as m, toNumber, isUndefined, isNull } from 'lodash';

/**
 * Multiply multiple values
 *
 * @param  {Array}  [resolvedArgs=[]] Array of values to multiply
 * @example: multiply([formData.cmpABC, formData.cmpXYZ, formData.cmpFOO])
 *
 * @return {Number} result Result
 */
export function multiply(resolvedArgs = []) {
  const values = get(resolvedArgs, 0, []);

  if (get(values, 'length', 0) === 0) return 0;

  const first = values.splice(0, 1);
  const f = isUndefined(first) || isNull(first)
    ? 1
    : first;

  return values.reduce((result, value) => {
    const v = isUndefined(value) || isNull(value)
      ? 1
      : value;

    return m(result, toNumber(v));
  }, toNumber(f));
}

export default {
  key: 'multiply',
  func: multiply,
  types: [{ types: [TYPE_ARRAY, TYPE_NULL] }]
};
