import update from 'immutability-helper';
import { DefaultReducer } from '@evoja-web/redaction';
import { get } from 'lodash';

import action, { outlookCalendarAction } from '../actions/OutlookCalendar';

const { OUTLOOK_CALENDAR_REJECTED } = action;

function onOutlookCalendarRejected(state, action) {
  return update(state, {
    outlookCalendar: {
      error: { $set: get(action, 'error') },
      requesting: { $set: false },
      fulfilled: { $set: true },
      data: { $set: get(action, 'payload', {}) }
    }
  });
}

export const reducer = DefaultReducer('outlookCalendar', outlookCalendarAction);
reducer.replaceMethod(OUTLOOK_CALENDAR_REJECTED, onOutlookCalendarRejected);

export default reducer.get();
