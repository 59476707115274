import React, { useState } from 'react';
import { Button, DropdownItem, ModalBody, ModalFooter, ModalHeader, Modal } from 'reactstrap';
import { isFunction, isNil } from 'lodash';
import Dexie from 'dexie';
import { FormattedMessage } from 'react-intl';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

function FirstAid() {
  const [databases, setDatabases] = useState();
  const [isModalOpen, toggleModal] = useState(false);
  const defaultDatabases = ['codes', 'map', 'react-table', '__dbnames', 'customerWorkguideSearch'];

  /**
   * Load the local db names via dexie and set them in state.
   *
   * @return  void
   */
  const loadDatabases = async () => {
    const databases = isFunction(indexedDB.databases)
      ? await Dexie.getDatabaseNames()
      : defaultDatabases;

    setDatabases(databases);
  };

  /**
   * Close db connections and delete the all databases
   */
  const deleteIndexDB = async () => {
    await Promise.all(databases.map(async (name) => {
      const db = getDbInstance({ id: name });

      if (!isNil(db)) {
        await db.close();
      }
      return true;
    }));

    await Promise.all(databases.map(async (name) => Dexie.delete(name)));
  };

  /**
   * Invalidate all cookies
   */
  const deleteAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
  };

  const cleanup = async () => {
    await deleteIndexDB();
    localStorage.clear();
    deleteAllCookies();
    window.location.reload();
  };

  return (
    <>
      <DropdownItem
        className="layout-header-user-profile--dropdown-item"
        onClick={() => toggleModal(!isModalOpen)}
      >
        <span className="mdi mdi-restore" />
        <FormattedMessage id="General.FirstAid.Title" />
      </DropdownItem>
      <Modal
        isOpen={isModalOpen}
        onOpened={loadDatabases}
        size="lg"
        toggle={() => toggleModal(!isModalOpen)}
        unmountOnClose
      >
        <ModalHeader toggle={() => toggleModal(!isModalOpen)}>
          <FormattedMessage id="General.FirstAid.Title" />
        </ModalHeader>

        <ModalBody>
          <FormattedMessage id="General.FirstAid.ParagraphOne" />
          <br />
          <br />
          <FormattedMessage id="General.FirstAid.ParagraphTwo" />
        </ModalBody>

        <ModalFooter style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            outline
            onClick={() => {
              toggleModal(!isModalOpen);
              cleanup();
            }}
          >
            <FormattedMessage id="General.Cancel" />
          </Button>

          <Button
            color="primary"
            onClick={cleanup}
          >
            <FormattedMessage id="General.FirstAid.Title" />
          </Button>
        </ModalFooter>
      </Modal>
    </>

  );
}

export default FirstAid;
