import { noop, forEach, isNil } from 'lodash';

/**
 * Get all available fuunctions from this lib and use the given reister funciton to extend jems path
 *
 * @param  {Function} [registerFunction=noop] Register funciton from @metrichor/jmespath-plus
 *
 * @return void
 */
export default function extendJmesPath({
  functions = {},
  registerFunction = noop
}) {
  forEach(functions, (config) => {
    const { key, func, types } = config;

    if (!isNil(key) && !isNil(func) && !isNil(types)) {
      registerFunction(key, func, types);
    } else {
      console.warn('Try to register jmes function with missing value! Skip... key: ', key, ' func: ', func, ' types: ', types);
    }
  });
}
