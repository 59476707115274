import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import update from 'immutability-helper';

import saveUserSettings from '../UserSettings/saveUserSettings';

export default function* saveVisibility(request) {
  const { name, visible = false } = request;

  try {
    const settings = yield select((state) => get(state, 'userSettings.settings.data', {}));
    const session = yield select((state) => get(state, 'login.session'));
    const updated = update(settings, {
      guidedTour: { [name]: { $set: visible } }
    });

    yield call(saveUserSettings, { userId: get(session, 'id'), settings: updated });

    return updated;
  } catch (error) {
    return error;
  }
}
