import { Reducers } from '@evoja-web/redaction';

import { reducer as amortizationsReducer } from './Amortization/Amortizations';
import { reducer as collateralsReducer } from './Collateral/Collaterals';
import { reducer as customerCreditsReducer } from './Customer/Credits';
import { reducer as customerObjectsReducer } from './Customer/Objects';
import { reducer as etpsReducer } from './Etp/Etps';
import { reducer as tranchesReducer } from './Tranche/Tranches';
import { reducer as realEstateTypeReducer } from './RealEstateType/RealEstateType';

export const reducers = Reducers([
  amortizationsReducer,
  collateralsReducer,
  customerCreditsReducer,
  customerObjectsReducer,
  etpsReducer,
  tranchesReducer,
  realEstateTypeReducer
]);

export default reducers.get();
