import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import provisionNariloAction from '../../actions/Portfolio/ProvisionNarilo';

const {
  GET_PROVISION_NARILO_PENDING,
  GET_PROVISION_NARILO_FULFILLED,
  GET_PROVISION_NARILO_REJECTED
} = provisionNariloAction;

export default function* fetchProvisionNarilo(request) {
  const { dataKey } = request;

  yield put({ type: GET_PROVISION_NARILO_PENDING, dataKey });

  try {
    const url = `/account/account/?eq(customer.id,string:${dataKey})&eq(provision.dispatchability,false)&select(provision.dispatchability)`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: GET_PROVISION_NARILO_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: GET_PROVISION_NARILO_REJECTED, dataKey, error });

    return error;
  }
}
