import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './FeatureFlagMenu.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import FeatureFlagMenuItem from '../components/FeatureFlagMenuItem';
import featureFlagActions from '../actions/Actions';

class FeatureFlagMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(ev) {
    const { isOpen } = this.state;

    ev.preventDefault();
    ev.stopPropagation();

    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { featureFlags, language } = this.props;

    const children = featureFlags.map((ff) => {
      return (
        <FeatureFlagMenuItem
          key={get(ff, 'id')}
          featureFlag={ff}
          language={language}
        />
      );
    });

    return (
      <>
        <DropdownItem
          onClick={this.toggle}
          toggle={false}
          className="profile-dropdown-item--feature-flag"
        >
          <span className="mdi mdi-flag-outline" />
          <FormattedMessage id="FeatureFlags.Title" />
        </DropdownItem>
        <Collapse isOpen={isOpen}>
          {children}
        </Collapse>
      </>
    );
  }
}

FeatureFlagMenu.propTypes = {
  featureFlags: PropTypes.array,
  language: PropTypes.string
};

FeatureFlagMenu.defaultProps = {
  featureFlags: [],
  language: 'de'
};

function mapStateToProps(state) {
  return {
    featureFlags: state.featureFlag.featureFlags.data,
    language: state.login.language
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(featureFlagActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagMenu);
