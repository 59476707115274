import { registerCustomWorkguide } from '../Workguide/index';
import WorkguideOnboardingCardOrder from './containers/Workguide/Card/Order';
import WorkguideOnboardingCardKubeEdit from './containers/Workguide/Card/KubeEdit';
import WorkguideCheckOnboarding from './containers/Workguide/CheckOnboarding';
import WorkguideOnboardingClassification from './containers/Workguide/Classification';
import WorkguideValidateOnboarding from './containers/Workguide/ValidateOnboarding';

export default function initBpfCmsPackage() {
  registerCustomWorkguide({ key: 'OnboardingCardOrder', component: WorkguideOnboardingCardOrder });
  registerCustomWorkguide({ key: 'OnboardingCardKubeEdit', component: WorkguideOnboardingCardKubeEdit });
  registerCustomWorkguide({ key: 'CheckOnboardingForm', component: WorkguideCheckOnboarding });
  registerCustomWorkguide({ key: 'ClassificationForm', component: WorkguideOnboardingClassification });
  registerCustomWorkguide({ key: 'ValidateOnboardingForm', component: WorkguideValidateOnboarding });
}
