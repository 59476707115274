import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';

import { CodeCheckboxGroup } from '../../../../General/index';

const availableCodes = [
  'currency-1',
  'currency-814',
  'currency-333'
];

const ConnectionAccount = React.memo(({
  codes,
  language,
  onChange,
  validations,
  value
}) => {
  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.ConnectionAccount" />
      </Label>

      <CodeCheckboxGroup
        id="connectionAccount"
        codes={get(codes, 'groups.currency', []).filter((code) => availableCodes.includes(get(code, 'id')))}
        language={language}
        labelPath="textShort"
        multi
        onChange={onChange}
        showValidations
        validations={validations}
        value={value}
      />
    </FormGroup>
  );
});

ConnectionAccount.propTypes = {
  codes: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.array
};

ConnectionAccount.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  onChange: noop,
  validations: undefined,
  value: undefined
};

export default ConnectionAccount;
