import { call, put, select } from 'redux-saga/effects';
import { omit, get, isNil } from 'lodash';

import duplicateCheckAction from '../../actions/DuplicateCheck/DuplicateCheck';
import duplicatesCheck from '../../../BpfCms/sagas/CustomerData/duplicatesCheck';

const {
  CUSTOMER_DETAIL_DUPLICATES_CHECK_PENDING,
  CUSTOMER_DETAIL_DUPLICATES_CHECK_FULFILLED,
  CUSTOMER_DETAIL_DUPLICATES_CHECK_REJECTED
} = duplicateCheckAction;

export default function* customerDuplicatesCheck(request) {
  const { dataKey, suppressToast } = request;

  yield put({ type: CUSTOMER_DETAIL_DUPLICATES_CHECK_PENDING, dataKey });

  try {
    const existingDuplicateCheck = yield select((state) => get(state, `customerDetail.duplicateCheck.${dataKey}.prevData`));

    let payload = existingDuplicateCheck;
    if (isNil(existingDuplicateCheck)) {
      const omitedRequest = omit(request, 'dataKey');

      payload = yield call(duplicatesCheck, omitedRequest);

      if (payload instanceof Error) {
        throw payload;
      }
    }

    yield put({ type: CUSTOMER_DETAIL_DUPLICATES_CHECK_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_DETAIL_DUPLICATES_CHECK_REJECTED, dataKey, error, suppressToast });

    return error;
  }
}
