export const GUIDED_TOUR_PENDING = 'map/guidedTour/GUIDED_TOUR_PENDING';
export const GUIDED_TOUR_GET_TEXT_PENDING = 'map/guidedTour/GUIDED_TOUR_GET_TEXT_PENDING';
export const GUIDED_TOUR_GET_TEXT_FULFILLED = 'map/guidedTour/GUIDED_TOUR_GET_TEXT_FULFILLED';
export const GUIDED_TOUR_REJECTED = 'map/guidedTour/GUIDED_TOUR_REJECTED';
export const GUIDED_TOUR_SHOW = 'map/guidedTour/GUIDED_TOUR_SHOW';
export const GUIDED_TOUR_HIDE = 'map/guidedTour/GUIDED_TOUR_HIDE';
export const GUIDED_TOUR_UPDATE_VISIBILITY = 'map/guidedTour/GUIDED_TOUR_UPDATE_VISIBILITY';

export function guidedTourShowRequest(guidedTourName, run) {
  if (run) {
    return { type: GUIDED_TOUR_SHOW, name: guidedTourName, run };
  }
  return { type: GUIDED_TOUR_HIDE, name: guidedTourName, run };
}

export function getGuidedTourText() {
  return { type: GUIDED_TOUR_GET_TEXT_PENDING };
}

export function hideGuidedTour(guidedTourName) {
  return { type: GUIDED_TOUR_HIDE, name: guidedTourName };
}

export function updateVisibility(props) {
  return { type: GUIDED_TOUR_UPDATE_VISIBILITY, ...props };
}
