import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { get, noop } from 'lodash';
import { utils as codeUtils } from '@evoja-web/entity-code';

import { CodeSelect } from '../../../../General/index';

const QIDeclaration = React.memo(({
  codes,
  customer,
  data,
  language,
  onChange,
  validations
}) => {
  const values = get(data, `qiDeclaration.${get(customer, 'id')}`, {});

  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.DoubleTaxationAgreement" />
      </Label>

      <Toggle
        value={get(values, 'dbaLandCh', false)}
        onChange={(id, val, ...rest) => onChange(customer, 'dbaLandCh', val, ...rest)}
      >
        <FormattedMessage id="SecuritiesAccount.Opening.Form.Switzerland" />
      </Toggle>

      {!get(values, 'dbaLandCh', false) && (
        <React.Fragment>
          <Label>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.SelectCountries" />
          </Label>

          <CodeSelect
            codes={codeUtils.getCountryCodes(codes, language)}
            id="dbaLandOther"
            language={language}
            onChange={(key, value, ...rest) => onChange(customer, key, value, ...rest)}
            showValidations
            validations={get(validations, `qiDeclaration.${get(customer, 'id')}.dbaLandOther`)}
            value={get(values, 'dbaLandOther')}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

QIDeclaration.propTypes = {
  codes: PropTypes.object,
  customer: PropTypes.object.isRequired,
  data: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

QIDeclaration.defaultProps = {
  codes: { groups: {} },
  data: {},
  language: 'de',
  onChange: noop,
  validations: {}
};

export default QIDeclaration;
