import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import {
  USERSWITCH_PENDING,
  USERSWITCH_FULFILLED,
  USERSWITCH_REJECTED
} from '../../actions/UserSwitch';

/**
 * Load the given user (consultant) from backend
 *
 * @param  {Object} request Request object from takeLatest
 *
 * @return {Generator}
 */
export default function* userSwitch(request) {
  yield put({ type: USERSWITCH_PENDING });

  const { username } = request.data;
  try {
    const result = yield call(axios, {
      url: `/person/consultant/?eq(username,${username})`,
      method: 'get'
    });

    if (result.data[0]) {
      yield put({ type: USERSWITCH_FULFILLED, data: result.data[0] });
    } else {
      const error = new Error(`No user with ${username} found!`);
      yield put({ type: USERSWITCH_REJECTED, error });
    }

    return result.data;
  } catch (error) {
    yield put({ type: USERSWITCH_REJECTED, error });

    return error;
  }
}
