import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import axios from 'axios';
import amortizationsAction from '../../actions/Amortization/Amortizations';

const {
  AMORTIZATIONS_PENDING,
  AMORTIZATIONS_FULFILLED,
  AMORTIZATIONS_REJECTED
} = amortizationsAction;

/**
 * Gets Accounts by Ids
 * @param {Object} accountIds object with accountIds Array
 * @returns Array of Accounts
 */
function* getAccountsByAccountIds({ accountIds = [] }) {
  const query = QueryBuilder()
    .in('id', accountIds, { type: 'string' })
    .getQueryString();

  const url = `/account/account/${query}`;

  // Return list of Customers
  const { data: payload } = yield call(axios, {
    url,
    method: 'get'
  });

  return payload;
}

export function* getAccountIds(orderId) {
  const query = QueryBuilder()
    .eq('orderId', orderId, { type: 'string' })
    .select(['account'])
    .limit(1000)
    .getQueryString();

  const url = `/financing/tranche/${query}`;
  const result = yield call(handleNextHeaders, url);

  return result.map((r) => get(get(r, 'account.$ref', '').match(/account\/account\/(.*)/), 1));
}

export default function* amortizations(request) {
  const { dataKey } = request;

  yield put({ type: AMORTIZATIONS_PENDING, dataKey });

  try {
    const accountIds = yield call(getAccountIds, dataKey);

    const query = QueryBuilder()
      .in('accountId', accountIds, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/financing/amortization/details/${query}`;
    const amortizations = yield call(handleNextHeaders, url);

    const accounts = yield call(getAccountsByAccountIds, { accountIds });

    const payload = amortizations.map((amor) => {
      const account = accounts.find((acc) => get(acc, 'id') === get(amor, 'accountId'));
      return {
        ...amor,
        account
      };
    });

    yield put({ type: AMORTIZATIONS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: AMORTIZATIONS_REJECTED, dataKey, error });

    return error;
  }
}
