import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import moment from 'moment/moment';
import customerManyCapitalTaxesFormAction from '../../actions/CustomerManyCapitalTaxesForm';
import calculateManyCapitalTaxes from '../calculateManyCapitalTaxes';
import getTaxLocation from '../getTaxLocation';

const mapping = {
  //  1 = Alleinstehend, 2 = Verheiratet, 3 = Konkubinat, 4 = Eingetragene Partnerschaft
  Relationship: {
    // Ledig
    'customerMaritalStatus-0': 1, // leer
    'customerMaritalStatus-1': 1, // ledig
    'customerMaritalStatus-11': 1, // aufgehobene eingetragene Partnerschaft
    'customerMaritalStatus-3': 1, // Geschieden
    'customerMaritalStatus-4': 1, // Verwitwet
    'customerMaritalStatus-5': 1, // Gerichtlich getrennt
    // Verheiratet
    'customerMaritalStatus-2': 2, // Verheiratet
    // Konkubinat
    'customerMaritalStatus-12': 3, // Konkubinat
    // eingetragene Partnerschaft
    'customerMaritalStatus-10': 4 // eingetragene Partnerschaft,
  },
  Confession: {
    // Reformiert
    'confession-1': 1, // Christentum (evangelisch)
    // Römisch-katholisch
    'confession-2': 2, // Christentum (römisch-katholisch)
    // Christkatholisch
    'confession-3': 3, // Christentum (orthodox)
    // Andere/keine
    'confession-4': 5, // islam
    'confession-5': 5, // Hinduismus
    'confession-6': 5, // Buddhismus
    'confession-7': 5, // Judentum,
    'confession-8': 5, // keine Konfession,
    'confession-9': 5, // unbekannt
  },
  Gender: {
    'customerSex-2': 1,
    'customerSex-1': 2
  }
};

const {
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_PENDING,
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_FULFILLED,
  CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_REJECTED
} = customerManyCapitalTaxesFormAction;

export default function* getCustomerManyCapitalTaxes(request) {
  const { customer, formData } = request;

  yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_PENDING });

  try {
    const taxLocation = yield call(getTaxLocation, { customer });

    const maritalStatus = get(formData, 'maritalStatus.id', 'customerMaritalStatus-1');
    const relationship = get(mapping, `Relationship.[${maritalStatus}]`);

    const genderCode = get(formData, 'gender.id');
    const gender = get(mapping, `Gender.[${genderCode}]`);

    const confession = get(formData, 'confession.id');
    const confession1 = get(mapping, `Confession.${confession}`);

    let confession2;
    if (relationship !== 1) { // wenn nicht alleinstehend
      const partnerConfession = get(formData, 'partnerConfession.id');
      confession2 = get(mapping, `Confession.${partnerConfession}`);
    }

    const data = {
      taxYear: moment().year(),
      taxLocationId: get(taxLocation, 'TaxLocationID'),
      relationship, // ---> 1 = Alleinstehend, 2 = Verheiratet, 3 = Konkubinat, 4 = Eingetragene Partnerschaft
      confession1, // ---> 1 = Reformiert, 2 = Römisch-katholisch, 3 = Christkatholisch, 5 = Andere/keine
      confession2, // ---> Nur wenn nicht alleinstehend, muss in Dropdown ausgewählt werden 1 = Reformiert, 2 = Römisch-katholisch, 3 = Christkatholisch, 5 = Andere/keine
      numberOfChildren: get(formData, 'numberOfChildren', 0), // ---> Anzahl Kinder
      gender, // ---> 1 = Männlich, 2 = Weiblich
      ageAtPayment: get(formData, 'ageAtPayment', 0), // ---> Aktuelles Alter des Kunden
      capital: get(formData, 'capital', 0) // 40000 //---> Aktueller Wert des Vorsorgekontos
    };

    yield call(calculateManyCapitalTaxes, { data });

    yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_FULFILLED, payload: data });

    return data;
  } catch (error) {
    yield put({ type: CUSTOMER_MANY_CAPITAL_TAXES_FORM_SAVE_REJECTED, error });

    return error;
  }
}
