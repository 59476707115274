import { call, put } from 'redux-saga/effects';

import { get } from 'lodash';
import paymentAccountsAction from '../actions/PaymentAccounts';
import getCustomerAccounts from '../../Account/sagas/getCustomerAccounts';

const {
  PAYMENT_ACCOUNTS_PENDING,
  PAYMENT_ACCOUNTS_FULFILLED,
  PAYMENT_ACCOUNTS_REJECTED
} = paymentAccountsAction;

export default function* getPaymentAccounts(request) {
  yield put({ type: PAYMENT_ACCOUNTS_PENDING });

  try {
    const { customerId } = request;

    const accounts = yield call(getCustomerAccounts, { dataKey: customerId });
    const payload = accounts.filter((account) => (
      ['sector-10', 'sector-20'].includes(get(account, 'sector.id'))
      && (
        get(account, 'authorizedCustomer.ref.$id') === customerId
        || get(account, 'customer.id') === customerId
      )
    ));

    yield put({ type: PAYMENT_ACCOUNTS_FULFILLED, payload });

    return accounts;
  } catch (error) {
    yield put({ type: PAYMENT_ACCOUNTS_REJECTED, error });

    return error;
  }
}
