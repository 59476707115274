import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const ExistingOpeningError = React.memo(() => {
  return (
    <Alert type="error">
      <AlertTitle>
        <FormattedMessage id="SecuritiesAccount.Opening.Error.ExistingOpening.Title" />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage id="SecuritiesAccount.Opening.Error.ExistingOpening.Description" />
      </AlertContent>
    </Alert>
  );
});

ExistingOpeningError.propTypes = {};

ExistingOpeningError.defaultProps = {};

export default ExistingOpeningError;
