import { takeEvery } from 'redux-saga/effects';
import saveCreateCoverLetterForm from './SendByPost/saveCreateCoverLetterForm';
import initCreateCoverLetterForm from './SendByPost/initCreateCoverLetterForm';
import sendDocumentsWithSps from './SendByPost/sendDocumentsWithSps';
import initCreateCoverLetterWorkguideForm from './Workguide/initCreateCoverLetterWorkguideForm';
import saveCreateCoverLetterWorkguideForm from './Workguide/saveCreateCoverLetterWorkguideForm';

import spsActions from '../actions/Actions';
import saveAndArchiveCoverLetter from './Workguide/saveAndArchiveCoverLetter';

const {
  CREATE_COVER_LETTER_FORM_INIT_REQUEST,
  CREATE_COVER_LETTER_FORM_SAVE_REQUEST,
  SEND_DOCUMENTS_WITH_SPS_REQUEST,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_REQUEST,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REQUEST,
  SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_REQUEST
} = spsActions;

export default function* spsRootSaga() {
  yield takeEvery(CREATE_COVER_LETTER_FORM_INIT_REQUEST, initCreateCoverLetterForm);
  yield takeEvery(CREATE_COVER_LETTER_FORM_SAVE_REQUEST, saveCreateCoverLetterForm);
  yield takeEvery(SEND_DOCUMENTS_WITH_SPS_REQUEST, sendDocumentsWithSps);
  yield takeEvery(CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_REQUEST, initCreateCoverLetterWorkguideForm);
  yield takeEvery(CREATE_COVER_LETTER_WORKGUIDE_FORM_SAVE_REQUEST, saveCreateCoverLetterWorkguideForm);
  yield takeEvery(SAVE_ARCHIVE_COVER_LETTER_WORKGUIDE_REQUEST, saveAndArchiveCoverLetter);
}
