import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { TextblockHtml } from '@evoja-web/react-core-textblock';

import './TechnicalSupport.css';

const TechnicalSupport = React.memo(function TechnicalSupport({ language }) {
  const [collapsed, setCollapse] = useState(false);

  return (
    <div className="technical-support">
      <DropdownItem
        toggle={false}
        className="layout-header-user-profile--dropdown-item"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCollapse(!collapsed);
        }}
      >
        <span className="mdi mdi-face-agent" />
        <FormattedMessage id="General.Header.Dropdown.TechnicalSupport" />
      </DropdownItem>
      <div className="technical-support__dropdown__content">
        <Collapse isOpen={collapsed}>
          <TextblockHtml
            id="technicalSupport"
            language={language}
          />
        </Collapse>
      </div>
    </div>
  );
});

TechnicalSupport.propTypes = {
  language: PropTypes.string
};

TechnicalSupport.defaultProps = {
  language: 'de'
};

export default TechnicalSupport;
