import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get, isNil } from 'lodash';

import './UserSwitch.css';
import ConsultantSelect from '../../../../Form/ConsultantSelect';

class UserSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.onSelect = this.onSelect.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onSelect(id, selected) {
    this.setState({ isOpen: false });

    if (!isNil(selected)) {
      window.open(`/dashboard/share/${get(selected, 'username')}`, '_blank');
    }
  }

  toggle(ev) {
    const { isOpen } = this.state;

    ev.preventDefault();
    ev.stopPropagation();

    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { consultants } = this.props;

    return (
      <React.Fragment>
        <DropdownItem
          className="layout-header-user-profile--dropdown-item"
          onClick={this.toggle}
          toggle={false}
        >
          <span className="mdi mdi-plus-circle-outline" />
          <FormattedMessage id="CustomerDetail.Header.Profile.AddConsultant" />
        </DropdownItem>

        {isOpen && (
          <ConsultantSelect
            className="layout-header-user-profile--dropdown--user-switch--select"
            id="conultantSwitch"
            consultants={consultants}
            onChange={this.onSelect}
            autoFocus
          />
        )}
      </React.Fragment>
    );
  }
}

UserSwitch.propTypes = {
  consultants: PropTypes.array,
  intl: PropTypes.object.isRequired
};

UserSwitch.defaultProps = {
  consultants: []
};

export default injectIntl(UserSwitch);
