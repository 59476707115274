import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { get, isNil, noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import { Toggle } from '@evoja-web/react-form';
import moment from 'moment';
import CancellationNotice from '../../components/Alerts/CancellationNoticeAlert';
import DateSelection from '../../components/DepotOpening/DateSelection';
import FundSelection from './FundSelection';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import Execution from '../../components/DepotOpening/Execution';
import './DepotOpening.css';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

class DepotOpening extends PureComponent {
  constructor(props) {
    super(props);

    this.renderDateSelection = this.renderDateSelection.bind(this);
    this.renderFundSelection = this.renderFundSelection.bind(this);
    this.onChangeSecuritiesSolutionCancellation = this.onChangeSecuritiesSolutionCancellation.bind(this);
    this.onChangeSecuritiesSolution = this.onChangeSecuritiesSolution.bind(this);
  }

  /**
   * When SecuritiesSolutionCancellation value changes, it sets the values for ValidForm and ValidUntil.
   * Because they can be derived from the selectedAccount Object.
   * @param {*} id SecuritiesSolutionCancellation field id
   * @param {*} value SecuritiesSolutionCancellation value
   */
  onChangeSecuritiesSolutionCancellation(id, value) {
    const { data, onChange } = this.props;

    if (value) {
      if (get(data, 'selectedAccount.accountKey') !== 0) {
        if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
          const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
          const validFrom = isAfterToday ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')) : moment();
          onChange('validFrom', validFrom.format());
          onChange('validUntil', validFrom.clone().add(3, 'months').format());
        } else {
          const startDate = moment().add(31, 'days');
          onChange('validFrom', startDate.format());
          onChange('validUntil', startDate.clone().add(3, 'months').format());
        }
      } else {
        onChange('validFrom', moment().format());
        onChange('validUntil', moment().add(3, 'months').format());
      }
    } else {
      onChange('validFrom', undefined);
      onChange('validUntil', undefined);
    }

    onChange(id, value);
  }

  /**
   * When SecuritiesSolution value changes, it sets the values for executionStart.
   * Because they can be derived from the selectedAccount Object.
   * @param {*} id SecuritiesSolution field id
   * @param {*} value SecuritiesSolution value
   */
  onChangeSecuritiesSolution(id, value) {
    const { data, onChange } = this.props;

    if (value) {
      // if its not a new account, calculate executionStartDate
      if (get(data, 'selectedAccount.accountKey') !== 0) {
        if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
          const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
          onChange('executionStartDate', !isNil(get(data, 'selectedAccount.withdrawalPeriodStart')) && isAfterToday
            ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')).format()
            : moment().format());
        } else {
          onChange('executionStartDate', moment().add(31, 'days').format());
        }
      } else {
        onChange('executionStartDate', moment().add(1, 'days').format());
      }
    } else {
      onChange('executionStartDate', undefined);
    }

    onChange(id, value);
  }

  /**
   * Checks if an alert should be rendered informing about the notice period
   * @returns
   */
  shouldRenderCancellationNoticeAlert() {
    const { data } = this.props;
    const isBeforeToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isBefore(moment());

    return get(data, 'securitiesSolution', false) && get(data, 'selectedAccount.accountKey') !== 0
      && (
        (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount')) && isBeforeToday)
        || !hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))
      );
  }

  renderDateSelection() {
    const { data, validations, onChange } = this.props;

    return (
      <DateSelection
        data={data}
        validations={validations}
        onChange={onChange}
      />
    );
  }

  renderFundSelection() {
    const {
      data, validations, onChange, language, customer
    } = this.props;

    return (
      <>
        <FundSelection
          customer={customer}
          data={data}
          validations={validations}
          onChange={onChange}
          language={language}
        />
        <Execution
          data={data}
          validations={validations}
          onChange={onChange}
        />
      </>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <Section>
        <SectionTitle>
          <FormattedMessage id="Provision.Form.DepotOpening.Title" />
        </SectionTitle>
        <SectionContent className="provision-depot-opening--section-content">
          <Row className="provision-depot-opening--space--bottom">
            <Col>
              <FormattedMessage id="Provision.Form.DepotOpening.Description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Toggle
                id="securitiesSolution"
                onChange={this.onChangeSecuritiesSolution}
                value={get(data, 'securitiesSolution', false)}
              >
                <Row>
                  <Col md={12}>
                    <FormattedMessage id="Provision.Form.DepotOpening.SecuritiesSolution.Label" />
                  </Col>
                </Row>
              </Toggle>
            </Col>
          </Row>
          {
            !get(data, 'securitiesSolution', false) && (
              <Row>
                <Col className="provision-depot-opening--space--top">
                  <Toggle
                    id="securitiesSolutionCancellation"
                    onChange={this.onChangeSecuritiesSolutionCancellation}
                    value={get(data, 'securitiesSolutionCancellation', false)}
                  >
                    <Row>
                      <Col md={12}>
                        <FormattedMessage id="Provision.Form.DepotOpening.SecuritiesSolutionCancellation.Label" />
                      </Col>
                    </Row>
                  </Toggle>
                </Col>
              </Row>
            )
          }
          {
            this.shouldRenderCancellationNoticeAlert() && (
              <Row>
                <CancellationNotice data={data} />
              </Row>
            )
          }
          {get(data, 'securitiesSolution', false) && this.renderFundSelection()}
          {!get(data, 'securitiesSolution', false) && get(data, 'securitiesSolutionCancellation', false) && this.renderDateSelection()}
        </SectionContent>
      </Section>
    );
  }
}

DepotOpening.propTypes = {
  customer: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  language: PropTypes.string
};

DepotOpening.defaultProps = {
  onChange: noop,
  data: {},
  language: 'de'
};

export default DepotOpening;
