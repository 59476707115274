import { takeLatest } from 'redux-saga/effects';
import { takeLatestByDataKey } from '@evoja-web/redux-saga-utils';

import actions from '../actions/Actions';
import getBanners from './getBanners';
import confirmBanner from './confirmBanner';

const {
  BANNERS_REQUEST,
  CONFIRM_BANNER_REQUEST
} = actions;

export default function* uiNoteRootSaga() {
  yield takeLatest(BANNERS_REQUEST, getBanners);
  yield takeLatestByDataKey(CONFIRM_BANNER_REQUEST, confirmBanner);
}
