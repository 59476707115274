import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { get } from 'lodash';
import Img from '../../../components/UiNotes/Img';
import HtmlPreview from '../../General/components/HtmlPreview';

import Card from '../../General/components/Layout/Card/Card';

import './ReleaseNoteItem.css';

class NewsItem extends React.Component {
  renderImage() {
    const { item } = this.props;
    const image = get(item, 'image');

    return image && (
      <Card className="release-notes-item--image-card">
        <Img blob={image} className="release-notes-item--image" />
      </Card>
    );
  }

  renderContent() {
    const { item, language } = this.props;

    return (
      <Row>
        <HtmlPreview>{get(item, `text.${language}`, '')}</HtmlPreview>
      </Row>
    );
  }

  render() {
    const { item, language } = this.props;
    const momentPublishDate = moment(get(item, 'publishDate'));

    return (
      <Card
        className="release-notes-card"
        category={momentPublishDate.format('DD.MM.YYYY')}
        title={get(item, `title.${language}`, '')}
      >
        <Row>
          <div className="release-notes-item--divider" />
          <Row>
            {get(item, 'image') ? (
              <>
                <Col md={7} className="release-notes-item--content-row">
                  {this.renderContent()}
                </Col>
                <Col md={5} className="release-notes-item--image-row">
                  {this.renderImage()}
                </Col>
              </>
            ) : (
              <Col md={12} className="release-notes-item--content-row">
                {this.renderContent()}
              </Col>
            )}
          </Row>
        </Row>
      </Card>
    );
  }
}

NewsItem.propTypes = {
  item: PropTypes.object,
  language: PropTypes.string
};

NewsItem.defaultProps = {
  item: {},
  language: 'de'
};

export default NewsItem;
