import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { USERTRACKING_LOAD_REQUEST } from '../../actions/UserTracking';

export default function* addEvent({
  event,
  consultantId,
  value,
  customerId,
  timestamp
}) {
  try {
    const result = yield call(axios, {
      url: '/work/usertracking',
      method: 'post',
      data: {
        event,
        consultantId,
        value,
        customerId,
        timestamp
      }
    });

    yield put({ type: USERTRACKING_LOAD_REQUEST, customerId });

    return result;
  } catch (error) {
    // eslint-disable-next-line
    console.error(`ERROR CREATING USERTRACKING ENTRY FOR EVENT ${event}`, consultantId, value, customerId, timestamp);

    return error;
  }
}
