import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isArray } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';

import bpfOnboardingActions from '../../actions/Actions';
import ValidateOnboardingForm from '../../components/Workguide/Common/ValidateOnboardingForm';
import { withWorkguideInstanceData } from '../../../Workguide/index';

class WorkguideValidateOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onFormValueChange = this.onFormValueChange.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  key    Form element id
   * @param   {Mixed}   value  Value
   *
   * @return  void
   */
  onFormValueChange(key, value) {
    this.setState({ [key]: value });
  }

  /**
   * Handle form submit
   *
   * @param   {Boolean}  accept  Accepted or not
   *
   * @return  void
   */
  onSubmit(accept = false) {
    const { comments } = this.state;
    const {
      acl,
      bpfOnboardingActions,
      bpfTask,
      redirect
    } = this.props;

    bpfOnboardingActions.completeTaskRequest({
      dataKey: get(bpfTask, 'data.task.id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      variables: {
        onboardingValidated: accept,
        comments
      }
    });

    redirect();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfTask,
      language,
      session
    } = this.props;

    return (
      <div>
        <Row>
          <Col lg={12}>
            <h5>
              <FormattedMessage id="Onboarding.Process.Title" />
            </h5>
            <hr />
          </Col>
        </Row>

        <p>
          <FormattedMessage id="Onboarding.Process.Descritption" />
        </p>

        <ValidateOnboardingForm
          bpfTask={bpfTask}
          language={language}
          onChange={this.onFormValueChange}
          session={session}
        />

        <Row>
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              disabled={get(bpfTask, 'data.erroneous', false)}
              onClick={() => this.onSubmit(true)}
            >
              <FormattedMessage id="Activity.Form.Submit" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button color="primary" onClick={() => this.onSubmit(false)} outline>
              <FormattedMessage id="General.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

WorkguideValidateOnboarding.propTypes = {
  acl: PropTypes.object.isRequired,
  bpfOnboardingActions: PropTypes.object.isRequired,
  bpfTask: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  redirect: PropTypes.func,
  session: PropTypes.object
};

WorkguideValidateOnboarding.defaultProps = {
  redirect: noop,
  session: undefined
};

function mapStateToProps(state) {
  return {
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfOnboardingActions: bindActionCreators(bpfOnboardingActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(
      injectIntl(WorkguideValidateOnboarding)
    )
  )
);
