import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import advertisingAction from '../../actions/Advertising/Advertising';

const {
  CUSTOMER_ADVERTISING_PENDING,
  CUSTOMER_ADVERTISING_FULFILLED,
  CUSTOMER_ADVERTISING_REJECTED
} = advertisingAction;

export default function* advertising(request) {
  const { dataKey } = request;

  yield put({ type: CUSTOMER_ADVERTISING_PENDING, dataKey });

  try {
    const url = `/person/advertising/?eq(customerId,string:${dataKey})`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: CUSTOMER_ADVERTISING_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CUSTOMER_ADVERTISING_REJECTED, dataKey, error });

    return error;
  }
}
