import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import existingOpeningAction from '../../actions/BaseContract/ExistingOpening';

const {
  EXISTING_OPENING_PENDING,
  EXISTING_OPENING_FULFILLED,
  EXISTING_OPENING_REJECTED
} = existingOpeningAction;

export default function* getExistingOpening(request) {
  yield put({ type: EXISTING_OPENING_PENDING });

  try {
    const {
      customerId,
      tenant = 6300
    } = request;

    const queryParts = [
      '/sif/bpf/public/api/v1/history/process-instance',
      `?tenantId=${tenant}`,
      '&processDefinitionKey=valiant-cms-basisvertrag',
      `&processInstanceBusinessKey=${customerId}`,
      '&unfinished=true'
    ];

    const url = queryParts.join('');
    const { data } = yield call(axios, {
      url,
      method: 'get'
    });

    const payload = get(data, 0);

    yield put({ type: EXISTING_OPENING_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: EXISTING_OPENING_REJECTED, error });

    return error;
  }
}
