import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const AccountPledgeAlert = React.memo(() => {
  return (
    <Alert type="error">
      <AlertTitle>
        <FormattedMessage id="Provision.Form.AccountSelection.PledgeWarning.Title" />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage id="Provision.Form.AccountSelection.PledgeWarning.Description" tagName="p" />
      </AlertContent>
    </Alert>
  );
});

AccountPledgeAlert.propTypes = {};

AccountPledgeAlert.defaultProps = {};

export default AccountPledgeAlert;
