import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get, has, isUndefined, map } from 'lodash';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import environmentJsonAction from '../actions/EnvironmentJson';
import convertUrlToUri from '../../../lib/Utils/convertUrlToUri';

const {
  ENVIRONMENT_JSON_PENDING,
  ENVIRONMENT_JSON_FULFILLED,
  ENVIRONMENT_JSON_REJECTED
} = environmentJsonAction;

/**
 * Get the environment config (environment.json) from public folder.
 * The environment.json file gets generated when the container starts and contains
 * the current client version as well as some environment specific variables like urls to external systems.
 *
 * @param   {Boolean}  forceReload  If set to true, always reload the environment.json file. If not, use data from indexDB if available
 *
 * @return  {Object} payload Environment config as object
 */
export default function* getEnvironmentJson(request = {}) {
  yield put({ type: ENVIRONMENT_JSON_PENDING });

  try {
    const { forceReload } = request;

    const db = getDbInstance({ id: 'map' });
    let expired = true;

    if (!isUndefined(db) && has(db, 'isExpired')) {
      expired = yield call(db.isExpired, { table: 'environment' });
    }

    if (!forceReload && !expired) {
      const data = yield call(db.getAllRecords, { table: 'environment' });
      const payload = data.reduce((result, record) => ({ ...result, [record.id]: record.value }), {});
      axios.defaults.baseURL = get(payload, 'api_url');

      yield put({ type: ENVIRONMENT_JSON_FULFILLED, payload });

      return payload;
    }

    const { data: payload } = yield call(axios, {
      url: '/environment.json',
      method: 'get',
      baseURL: null,
      headers: {
        'Cache-Control': 'no-store',
        Pragma: 'no-cache'
      }
    });

    const records = map(payload, (value, id) => ({ id, value }));
    yield call(db.bulkUpdate, { table: 'environment', data: records, clear: true });

    yield put({ type: ENVIRONMENT_JSON_FULFILLED, payload });
    axios.defaults.baseURL = payload.api_url;

    return payload;
  } catch (error) {
    console.error('ERROR READING environment.json', error);
    yield put({ type: ENVIRONMENT_JSON_REJECTED, error, suppressToast: true });

    return error;
  }
}
