import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import pricingActions from '../../actions/SetAdvisor/Pricing';

const {
  PRICING_PENDING,
  PRICING_FULFILLED,
  PRICING_REJECTED
} = pricingActions;

export default function* getPricing() {
  yield put({ type: PRICING_PENDING });
  try {
    const payload = yield call(handleNextHeaders, '/basic/productbundle/pricing/?limit(9999)');

    yield put({ type: PRICING_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: PRICING_REJECTED, error });

    return error;
  }
}
