import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import productbundleProductActions from '../../actions/SetAdvisor/ProductbundleProduct';

const {
  PRODUCTBUNDLE_PRODUCT_PENDING,
  PRODUCTBUNDLE_PRODUCT_FULFILLED,
  PRODUCTBUNDLE_PRODUCT_REJECTED
} = productbundleProductActions;

export default function* getProductbundleProduct() {
  yield put({ type: PRODUCTBUNDLE_PRODUCT_PENDING });
  try {
    const payload = yield call(handleNextHeaders, '/basic/productbundle/product/?limit(9999)');

    yield put({ type: PRODUCTBUNDLE_PRODUCT_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: PRODUCTBUNDLE_PRODUCT_REJECTED, error });

    return error;
  }
}
