/* Prepares SPS Cover Letter Description for File (PDF) generation
 *
 * @param  {String}  description
 *
 * @return {HtmlElement} Html Element for CoverLetter Description in PDF generation
 */
export default function prepareCoverLetterDescription({ description }) {
  const div = document.createElement('div');
  div.style.display = 'none';
  div.style.height = 0;
  div.style.visibility = 'hidden';
  // document.body.appendChild(div);
  div.innerHTML = description;

  return div;
}
