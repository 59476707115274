import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';

const Logout = React.memo(({ onClick }) => {
  return (
    <DropdownItem
      className="layout-header-user-profile--dropdown-item"
      onClick={onClick}
    >
      <span className="mdi mdi-logout" />
      <FormattedMessage id="CustomerDetail.Header.Profile.Logout" />
    </DropdownItem>
  );
});

Logout.propTypes = {
  onClick: PropTypes.func
};

Logout.defaultProps = {
  onClick: noop
};

export default Logout;
