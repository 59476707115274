import { call, put, all, select } from 'redux-saga/effects';
import { compact, get, maxBy, minBy, uniqBy, set } from 'lodash';
import { getCodeGroups } from '@evoja-web/entity-code/sagas';

import getForeignAssets from '../../CustomerDetail/sagas/ForeignAsset/getForeignAssets';
import getObjects from '../../Financing/sagas/Customer/getObjects';
import getRatings from '../../CustomerDetail/sagas/Rating/getRatings';
import getProfileCompletion from '../../CustomerDetail/sagas/ProfileCompletion/getCompany';

import consultationFormAction from '../actions/ConsultationForm';
import getCustomerFamily from '../../CustomerBaseData/sagas/CustomerFamily/getCustomerFamily';
import getFinancingCollateral from './Customer/getFinancingCollateral';
import getFinancingTranche from './Customer/getFinancingTranche';
import getRelations from '../../Relation/sagas/Customer/getRelations';
import getLeads from '../../Lead/sagas/Customer/getLeads';
import getCreditData from '../../CustomerBaseData/sagas/CreditData/getCreditData';
import getSubscriptions from '../../Newsletter/sagas/Customer/getSubscriptions';
import getEnforcements from '../../Enforcement/sagas/getEnforcements';
import getKubiRatio from '../../CustomerDetail/sagas/Kpi/getKubiRatio';
import getByDonor from '../../PowerOfAttorney/sagas/getByDonor';
import getByReceiver from '../../PowerOfAttorney/sagas/getByReceiver';
import getAccounts from '../../../sagas/SetAdvisor/getAccounts';
import getCustomerBalance from '../../../sagas/SetAdvisor/getCustomerBalance';
import getProductbundleUsage from '../../../sagas/SetAdvisor/getProductbundleUsage';
import getProductBundleUsageDetail from '../../../sagas/SetAdvisor/getProductBundleUsageDetail';
import getContracts from '../../../sagas/SetAdvisor/getContracts';
import getProductbundleBundle from '../../../sagas/SetAdvisor/getProductbundleBundle';
import getProductbundleProduct from '../../../sagas/SetAdvisor/getproductbundleProduct';
import getTextblocks from '../../../sagas/SetAdvisor/getTextblocks';
import getPricing from '../../../sagas/SetAdvisor/getPricing';
import getSetModule from '../../SetAdvisor/sagas/Customer/getLilaSet';
import getPortfolios from '../../CustomerDetail/sagas/Portfolio/getPortfolios';
import fetchBusinessModel from '../../SuccessorPlanning/sagas/getBusinessModel';
import getInterests from '../../Interest/sagas/Customer/getInterests';
import getCommunicationEntries, { Contacts } from '../../BpfCms/sagas/ContactInformation/getCommunicationEntries';
import groupLeadsByStatus from '../../Lead/lib/Board/groupLeadsByStatus';
import sortGroupedLeads from '../../Lead/lib/Board/sortGroupedLeads';
import getSummary from '../../CustomerDetail/sagas/Suspension/getSummary';
import getIncomes from '../../CustomerDetail/sagas/Income/getIncomes';

const {
  CONSULTATION_FORM_PENDING,
  CONSULTATION_FORM_FULFILLED,
  CONSULTATION_FORM_REJECTED
} = consultationFormAction;

function* executeOperations(operationsObject) {
  const operations = operationsObject.map((op) => op.value);
  const keys = operationsObject.map((op) => op.key);

  const results = yield all(operations);

  return results.reduce((resultObject, value, index) => {
    const key = get(keys, `${index}`);
    set(resultObject, key, value);
    return resultObject;
  }, {});
}

function prepareLeads(leads = []) {
  const grouped = groupLeadsByStatus(leads);
  const {
    closed,
    open,
    pending,
    qualified,
    unqualified,
  } = sortGroupedLeads({
    state: grouped,
    sort: {}
  });

  const earliest = minBy(leads, (lead) => lead.dueDate);
  const latest = maxBy(leads, (lead) => lead.dueDate);
  const customers = uniqBy(leads.map((lead) => get(lead, 'customer')), 'id');
  const customerTaskTypes = uniqBy(leads.map((lead) => get(lead, 'customerTaskType')), 'id');

  return {
    customers: compact(customers),
    customerTaskTypes: compact(customerTaskTypes),
    open,
    pending,
    qualified,
    unqualified,
    closed,
    minDate: get(earliest, 'dueDate'),
    maxDate: get(latest, 'dueDate'),
  };
}

export default function* getConsultationFormData(request) {
  yield put({ type: CONSULTATION_FORM_PENDING });

  try {
    const {
      customerId, isBusiness, showBoth = false, language
    } = request;

    const operationList = [
      { key: 'codes', value: call(getCodeGroups, { groups: ['interestCategory', 'setAdvisorDomain', 'setAdvisorReductionType', 'contactVariation', 'relationType'] }) },
      { key: 'customerDetail.foreignAssets', value: call(getForeignAssets, { customerId }) },
      { key: 'customerDetail.ratings', value: call(getRatings, { customerId }) },
      { key: 'customerDetail.profileCompletionCompany', value: call(getProfileCompletion, { customerId }) },
      { key: 'customerDetail.kubiRatio', value: call(getKubiRatio, { customerId }) },
      { key: 'customerDetail.enforcements', value: call(getEnforcements, { dataKey: customerId }) },
      { key: 'financing.customerObjects', value: call(getObjects, { customerId }) },
      { key: 'powersOfAttorney.donor', value: call(getByDonor, { dataKey: customerId }) },
      { key: 'powersOfAttorney.receiver', value: call(getByReceiver, { dataKey: customerId }) },
      { key: 'financingTranches', value: call(getFinancingCollateral, { customerId }) },
      { key: 'customerCollateral', value: call(getFinancingTranche, { customerId }) },
      { key: 'customerRelations', value: call(getRelations, { dataKey: customerId, language }) },
      { key: 'cardSuspension', value: call(getSummary, { customerId }) },
      { key: 'customerSubscriptions', value: call(getSubscriptions, { customerId }) },
      { key: 'customerLeads', value: call(getLeads, { customerId }) },
      { key: 'customerInterests', value: call(getInterests, { customerId }) },
      { key: 'contacts', value: call(getCommunicationEntries, { dataKey: customerId }) },

      // Set Advisor
      { key: 'setadvisor.accounts', value: call(getAccounts, { customerId }) },
      { key: 'setadvisor.customerBalance', value: call(getCustomerBalance, { customerId }) },
      { key: 'setadvisor.productbundleUsage', value: call(getProductbundleUsage, { customerId }) },
      { key: 'setadvisor.productbundleUsageDetail', value: call(getProductBundleUsageDetail, { customerId }) },
      { key: 'setadvisor.contracts', value: call(getContracts, { customerId }) },
      { key: 'setadvisor.bundles', value: call(getProductbundleBundle) },
      { key: 'setadvisor.products', value: call(getProductbundleProduct) },
      { key: 'setadvisor.textblocks', value: call(getTextblocks) },
      { key: 'setadvisor.pricing', value: call(getPricing) },

    ];

    const results = yield executeOperations(operationList);

    // Contact mapping
    results.contacts = Contacts({ codes: results.codes, data: results.contacts });

    // Leads mapping
    const preparedLeadsObject = prepareLeads(results.customerLeads);
    results.customerLeads = [...get(preparedLeadsObject, 'open', []), ...get(preparedLeadsObject, 'pending', [])];

    // customer Details mappings
    results.enforcements = results.customerDetail.enforcements;

    // set advisor mapping
    results.customerBalance = results.setadvisor.customerBalance;

    // Only Load data if customer is business or both consultationForms are shown
    if (isBusiness || showBoth) {
      results.businessCanvas = yield call(fetchBusinessModel, { customerId });
      results.creditData = yield call(getCreditData, { customerId });
    }

    // Only Load data if customer is privateIndividual because businesses dont have a lilaset
    if (!isBusiness) {
      results.setAdvisor = yield call(getSetModule, { customerId });
    }

    // Only Load data if customer is privateIndividual or both consultationForms are shown
    if (!isBusiness || showBoth) {
      results.customerFamily = yield call(getCustomerFamily, { customerId });
      results.portfolios = yield call(getPortfolios, { customerId });
      results.customerDetail.portfolios = results.portfolios;
      results.income = yield call(getIncomes, { dataKey: customerId });
    }

    yield put({ type: CONSULTATION_FORM_FULFILLED, payload: results });

    return results;
  } catch (error) {
    yield put({ type: CONSULTATION_FORM_REJECTED, error });

    return error;
  }
}
