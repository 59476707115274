import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, filter } from 'lodash';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import SuspensionTooltip from './SuspensionTooltip';
import { cardStatusMap } from '../../../../staticMappings/mapVisecaCodes';

const CardListItem = React.memo(function CardListItem({
  id,
  data,
  language,
  cardDetails
}) {
  const cardId = get(data, 'id');
  const details = get(cardDetails, cardId);
  const status = get(details, 'data.status.status');

  const errors = filter(Object.values(get(details, 'data', {})), (d) => d instanceof Error);
  const tooltipId = `tooltip-card-status-${get(data, 'id')}`;

  return (
    <React.Fragment>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Card.Category" /></strong>
          <p>{get(data, `cardCategory.text.${language}`)}</p>
        </div>

        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Card.Type" /></strong>
          <p>{get(data, `cardType.text.${language}`)}</p>
        </div>

        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Card.Number" /></strong>
          <p>{get(data, 'cardNumber')}</p>
        </div>

        <div className="selectable-description-item" style={{ width: '20%' }}>
          <strong><FormattedMessage id="Card.CustomerName" /></strong>
          <p>{get(data, 'customerName')}</p>
        </div>

        {(get(errors, 'length', 0) > 0) ? (
          <div className="selectable-description-item">
            <i
              className="mdi mdi-alert-circle-outline text-danger"
              id={`tooltip-error-${id}`}
              style={{ cursor: 'pointer' }}
            />
            <UncontrolledPopover
              placement="left"
              target={`tooltip-error-${id}`}
              className="card-error-popover"
            >
              <PopoverHeader>
                <FormattedMessage id="Error.General.Summary" />
              </PopoverHeader>
              <PopoverBody>
                {errors.map((e, index) => (
                  <div key={index}>
                    <strong>{get(e, 'message')}</strong>
                    <br />
                    <p>{get(e, 'response.data.Exception', '')}</p>
                  </div>
                ))}
                {errors.map((e, index) => <p key={index}>{`${e.message} -- ${get(e, 'response.data.Exception', '')}`}</p>)}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        ) : null}
      </div>

      <div style={{ display: 'flex', width: '100%' }}>
        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Card.AccountType" /></strong>
          <p>{get(data, `account.accountType.text.${language}`)}</p>
        </div>

        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Card.AccountNumber" /></strong>
          <p>{get(data, 'account.numberFormatted')}</p>
        </div>

        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Customer.CardAndProduct.BlockingCode" /></strong>
          <p>
            <span id={tooltipId}>
              {cardStatusMap.has(status) ? <FormattedMessage id={cardStatusMap.get(status)} /> : '-'}
            </span>
          </p>

          <SuspensionTooltip
            target={tooltipId}
            card={data}
          />
        </div>

        <div className="selectable-description-item" style={{ width: '25%' }}>
          <strong><FormattedMessage id="Workguide.Form.Card.VisecaAccountNumber" /></strong>
          <p>
            {get(data, 'visecaAccountNumber', '-')}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
});

CardListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  data: PropTypes.object.isRequired,
  language: PropTypes.string,
  cardDetails: PropTypes.object,
};

CardListItem.defaultProps = {
  language: 'de',
  cardDetails: {}
};

export default (CardListItem);
