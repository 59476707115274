import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isUndefined } from 'lodash';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ValidationResult } from '@evoja-web/react-form';

import { PowersOfAttorneyList } from '../../../../PowerOfAttorney/index';
import { CustomerSearch } from '../../../../General/index';

class AuthorizedCustomer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedAuthorization: undefined
    };

    this.onAuthorizationChange = this.onAuthorizationChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { value: prevValue } = prevProps;

    if (!isUndefined(prevValue) && isUndefined(value)) {
      // eslint-disable-next-line
      this.setState({ selectedAuthorization: undefined });
    }
  }

  /**
   * In case of authorizations, store selected authorization in state
   * and only pass the attorneyReceiver to parent onChange
   *
   * @param  {String} fieldId               Field id
   * @param  {Array}  selectedAuthorization Array of selected authorizations
   *
   * @return void
   */
  onAuthorizationChange(fieldId, selectedAuthorization) {
    const { onChange } = this.props;

    this.setState({ selectedAuthorization });

    const customer = get(selectedAuthorization, '0.attorneyReceiver');

    onChange('authorizedCustomer', customer);
  }

  renderAuthorizations() {
    const { selectedAuthorization } = this.state;
    const { authorizations } = this.props;

    return (
      <PowersOfAttorneyList
        id="authorizedCustomer"
        authorizations={get(authorizations, 'data', [])}
        onChange={this.onAuthorizationChange}
        selected={selectedAuthorization}
        customerPath="attorneyReceiver"
      />
    );
  }

  renderCustomerSearch() {
    const {
      onChange,
      value
    } = this.props;

    return (
      <CustomerSearch
        id="authorizedCustomer"
        isClearable
        onChange={onChange}
        value={value}
      />
    );
  }

  render() {
    const {
      authorizations,
      customer,
      validations
    } = this.props;

    // Show nothing if no customer is selected or authorizations are still loading
    if (isUndefined(customer) || !get(authorizations, 'fulfilled', false)) return null;

    return (
      <div>
        <Label>
          <FormattedMessage id="SecuritiesAccount.Opening.Form.Authorization" />
        </Label>

        {get(authorizations, 'data.length', 0) > 0 ? this.renderAuthorizations() : this.renderCustomerSearch()}

        <ValidationResult
          validations={validations}
          show
        />
      </div>
    );
  }
}

AuthorizedCustomer.propTypes = {
  authorizations: PropTypes.object,
  customer: PropTypes.object,
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.object
};

AuthorizedCustomer.defaultProps = {
  authorizations: {},
  customer: undefined,
  onChange: noop,
  validations: {},
  value: undefined
};

export default AuthorizedCustomer;
