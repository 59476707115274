import { get } from 'lodash';
import { put } from 'redux-saga/effects';

import {
  ADD_TOAST
} from '../../actions/Toast';

/**
 * Default success handler
 *
 * @param  {Object} result Result from request
 *
 * @return {Generator}
 */
export default function* defaultEventStatusSuccess(result) {
  yield put({
    type: ADD_TOAST,
    kind: 'success',
    title: 'EventStatus.SuccessTitle',
    description: 'EventStatus.SuccessDescription',
    values: { eventId: get(result, 'data.id') }
  });
}
