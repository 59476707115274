import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'reactstrap';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

const StandingOrderAlert = React.memo(() => {
  return (
    <Alert type="info">
      <AlertTitle>
        <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.Title" />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.Description1" tagName="p" />
        <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.Description2" tagName="span" />
        <List style={{ paddingLeft: '1.4em', paddingTop: '0.3em', lineHeight: '1.5em' }}>
          <li>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.IntervalDescriptions.Weekly" tagName="p" />
          </li>
          <li>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.IntervalDescriptions.Monthly" tagName="p" />
          </li>
          <li>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.IntervalDescriptions.Quarterly" tagName="p" />
          </li>
          <li>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.IntervalDescriptions.HalfYearly" tagName="p" />
          </li>
          <li>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.StandingOrder.Alert.IntervalDescriptions.Yearly" tagName="p" />
          </li>
        </List>
      </AlertContent>
    </Alert>
  );
});

StandingOrderAlert.propTypes = {};

StandingOrderAlert.defaultProps = {};

export default StandingOrderAlert;
