import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import { Checkbox } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';

import './Banner.css';
import { HtmlPreview } from '../../General/index';

class UiNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false
    };

    this.onToggleConfirm = this.onToggleConfirm.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  /**
   * Set confirmed to true (checkbox click)
   *
   * @return  void
   */
  onToggleConfirm() {
    const { confirmed } = this.state;

    this.setState({ confirmed: !confirmed });
  }

  /**
   * Close the notification and mark it as read
   *
   * @return  void
   */
  onClose() {
    const {
      banner,
      onClose
    } = this.props;

    onClose(banner);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { confirmed } = this.state;
    const {
      banner,
      language
    } = this.props;

    return (
      <div className="ui-note-banner">
        <Row>
          <Col
            className="text-danger"
            lg={12}
            md={12}
          >
            <h5>
              <div className="d-flex align-items-center">
                <span className="mdi mdi-alert-circle-outline" />

                <span style={{ paddingRight: '2em' }} />

                <span>
                  {get(banner, `title.${language}`)}
                </span>
              </div>
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <HtmlPreview>
              {get(banner, `text.${language}`)}
            </HtmlPreview>
          </Col>
        </Row>

        <Row className="d-flex align-items-center">
          <Col lg={2} md={2}>
            <Checkbox
              id="confirmed"
              onChange={this.onToggleConfirm}
              value={confirmed}
            >
              <FormattedMessage id="UiNote.Banner.Confirm" />
            </Checkbox>
          </Col>

          <Col lg={10} md={10}>
            <Button
              color="primary"
              onClick={this.onClose}
              disabled={!confirmed}
            >
              <FormattedMessage id="UiNote.Banner.Close" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

UiNotification.propTypes = {
  banner: PropTypes.object.isRequired,
  language: PropTypes.string,
  onClose: PropTypes.func
};

UiNotification.defaultProps = {
  language: 'de',
  onClose: noop
};

export default UiNotification;
