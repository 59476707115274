/**
 * Just return true...
 *
 * @return {Boolean}
 */
export function setTrue() {
  return true;
}

export default {
  key: 'setTrue',
  func: setTrue,
  types: []
};
