import { TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import { isString, trim } from 'lodash';

/**
 * Remove whitespaces from both ends of the given string.
 * Return undefined if the given value is not a string
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} value Trimmed value
 */
export function trimIfSet(resolvedArgs) {
  const [value] = resolvedArgs;

  return isString(value) ? trim(value) : undefined;
}

export default {
  key: 'trimIfSet',
  func: trimIfSet,
  types: [{ types: [TYPE_STRING, TYPE_NULL] }]
};
