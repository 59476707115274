import { TYPE_STRING, TYPE_ARRAY, TYPE_NULL } from '@metrichor/jmespath-plus';
import { get } from 'lodash';

/**
 * Find the contact item of the given type and return its value
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {Mixed} value Contact value
 */
export function getEmailFromContacts(resolvedArgs = []) {
  const [contacts = [], type = 'business'] = resolvedArgs;

  return get(
    contacts.find((c) => get(c, 'type') === type && get(c, 'protocol') === 'mailto'),
    'value',
    ''
  );
}

export default {
  key: 'getEmailFromContacts',
  func: getEmailFromContacts,
  types: [{ types: [TYPE_ARRAY] }, { types: [TYPE_STRING, TYPE_NULL] }]
};
