import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import './Limitations.css';
import {
  Summary as ErrorSummary,
  ERROR_SCOPE_BPF,
  ERROR_SCOPE_SIF,
  ERROR_SCOPE_VISECA,
  ERROR_SCOPE_FINNOVA,
  ERROR_SCOPE_GRAVITON,
  ERROR_SCOPE_MICROSOFT_GRAPH
} from '../../../../../../Error/index';

const scopes = [
  ERROR_SCOPE_BPF,
  ERROR_SCOPE_SIF,
  ERROR_SCOPE_VISECA,
  ERROR_SCOPE_FINNOVA,
  ERROR_SCOPE_GRAVITON,
  ERROR_SCOPE_MICROSOFT_GRAPH
];

const Limitations = React.memo(({ history }) => {
  return (
    <DropdownItem
      className="layout-header-user-profile--dropdown-item"
      onClick={() => history.push('/error/overview')}
    >
      <div>
        <div className="d-flex align-items-center">
          <div className="mdi mdi-alert fs-6" />
          <FormattedMessage id="General.Header.Dropdown.Limitations" />
        </div>

        <div className="layout-header-user-profile--dropdown-item--limitations--summary">
          <ErrorSummary scopes={scopes} />
        </div>
      </div>
    </DropdownItem>
  );
});

Limitations.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Limitations);
