import { Reducers } from '@evoja-web/redaction';

import { reducer as taxLocationReducer } from './TaxLocation';
import { reducer as calculateManyCapitalTaxesReducer } from './CalculateManyCapitalTaxes';
import { reducer as customerManyCapitalTaxesFormReducer } from './CustomerManyCapitalTaxesForm';

export const reducers = Reducers([
  taxLocationReducer,
  calculateManyCapitalTaxesReducer,
  customerManyCapitalTaxesFormReducer
]);

export default reducers.get();
