import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isNil, isUndefined } from 'lodash';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withAcl } from '@evoja-web/client-acl';
import { withRouter } from 'react-router';
import { actions as loginActions } from '@evoja-web/react-login';
import { actions as bootstrapActions } from '@evoja-web/react-bootstrapper';

import './UserProfile.css';
import BuildDate from '../../../components/Layout/Header/UserProfile/Dropdown/BuildDate';
import ClientVersion from '../../../components/Layout/Header/UserProfile/Dropdown/ClientVersion';
import DataLoad from '../../../components/Layout/Header/UserProfile/Dropdown/DataLoad';
import DeleteIndexDB from '../../../components/Layout/Header/UserProfile/Dropdown/DeleteIndexDB';
import DropdownTitle from '../../../components/Layout/Header/UserProfile/Dropdown/Title';
import Image from '../../../components/Layout/Header/UserProfile/Image';
import LanguageSwitch from '../../../components/Layout/Header/UserProfile/Dropdown/LanguageSwitch';
import Logout from '../../../components/Layout/Header/UserProfile/Dropdown/Logout';
import ReleaseNotes from '../../../../ReleaseNote/containers/ReleaseNotes';
import PermissionSwitch from '../../../components/Layout/Header/UserProfile/Dropdown/PermissionSwitch';
import UserSwitch from '../../../components/Layout/Header/UserProfile/Dropdown/UserSwitch';
import UserSwitchImages from '../../../components/Layout/Header/UserProfile/UserSwitchImages';
import clientInfo from '../../../../../clientInfo';
import FeatureFlagMenu from '../../../../FeatureFlag/containers/FeatureFlagMenu';
import TechnicalSupport from '../../../components/Layout/Header/UserProfile/Dropdown/TechnicalSupport';
import FirstAid from '../../../components/Layout/Header/UserProfile/Dropdown/FirstAid';
import UiTheme from '../../../components/Layout/Header/UserProfile/Dropdown/UiTheme';
import Limitations from '../../../components/Layout/Header/UserProfile/Dropdown/Limitations';
import fileActions from '../../../../File/actions/Actions';
import aclActions from '../../../../../actions/Acl';
import * as dataLoadStateActions from '../../../../../actions/DataLoadState';

class HeaderUserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  /**
   * Handle language change
   *
   * @param  {String} [language='de'] Selected language
   *
   * @return void
   */
  onLanguageChange(language = 'de') {
    const { loginActions } = this.props;

    loginActions.setLanguageRequest({ payload: language });
    window.REACT_TABLE_FORCE_LOCALE = `${language}-CH`;
  }

  /**
   * Logout the user
   *
   * @return void
   */
  onLogout() {
    const {
      aclActions,
      bootstrapActions,
      history,
      location
    } = this.props;

    aclActions.permissionsReset();
    aclActions.userRolesReset();
    bootstrapActions.bootstrapReinitializeAll();
    history.push(`/logout?redir=${location.pathname}`);
  }

  /**
   * Open / close the dropdown
   *
   * @return void
   */
  toggle() {
    const { isOpen } = this.state;
    const { dataLoadStateActions, dataLoadState } = this.props;

    this.setState({ isOpen: !isOpen });

    if (
      !isOpen === true
      && !get(dataLoadState, 'fulfilled', false)
      && !get(dataLoadState, 'requesting', false)
      && isNil(get(dataLoadState, 'data', undefined))
    ) {
      dataLoadStateActions.dataLoadStateRequest();
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen } = this.state;
    const {
      acl,
      consultants,
      dataLoadState,
      language,
      session,
      userSwitch,
      readAt,
      latestReleaseNote,
      fileActions,
      profilePictures,
      readAtFulfilled
    } = this.props;

    const userSwitchConsultant = get(userSwitch, 'selected');
    const ImageComponent = !isUndefined(userSwitchConsultant)
      ? UserSwitchImages
      : Image;

    return (
      <div className="layout-header-user-profile">
        <Dropdown
          className="layout-header-user-profile--dropwdown"
          nav
          inNavbar
          isOpen={isOpen}
          toggle={this.toggle}
        >
          <DropdownToggle
            tag="div"
            className="d-flex align-items-center"
            nav
            caret
          >
            <ImageComponent
              consultant={session}
              userSwitch={userSwitch}
              itemProp={{ readAt, latestReleaseNote, readAtFulfilled }}
              fileActions={fileActions}
              profilePictures={profilePictures}
            />
          </DropdownToggle>

          <DropdownMenu end>
            <DropdownTitle
              consultant={session}
              toggle={this.toggle}
            />

            <LanguageSwitch
              current={language}
              onChange={this.onLanguageChange}
            />

            <DropdownItem divider />
            <ReleaseNotes />

            {isUndefined(userSwitchConsultant) && (
              <UserSwitch consultants={consultants} />
            )}

            {(acl.isAllowed('PermissionSwitch') || acl.hasTemporaryUserRoles()) && (
              <PermissionSwitch />
            )}

            {acl.isAllowed('DeleteIndexDB') && (
              <DeleteIndexDB />
            )}

            {acl.isAllowed('FeatureFlag') && (
              <FeatureFlagMenu />
            )}

            {acl.isAllowed('ChangeUiTheme') && (
              <>
                <DropdownItem divider />
                <UiTheme />
              </>
            )}

            <DropdownItem divider />

            <TechnicalSupport language={language} />
            <FirstAid />

            {isUndefined(userSwitchConsultant) && (
              <Logout onClick={this.onLogout} />
            )}

            <DropdownItem divider />

            <Limitations />

            <DropdownItem divider />

            <ClientVersion clientInfo={clientInfo} />

            {acl.isAllowed('ShowBuildTime') && (
              <BuildDate clientInfo={clientInfo} />
            )}

            {
              !get(dataLoadState, 'requesting', false) && (
                <DataLoad dataLoadState={dataLoadState} />
              )
            }

          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

HeaderUserProfile.propTypes = {
  acl: PropTypes.object.isRequired,
  aclActions: PropTypes.object.isRequired,
  bootstrapActions: PropTypes.object.isRequired,
  consultants: PropTypes.array,
  dataLoadState: PropTypes.object,
  history: PropTypes.object.isRequired,
  language: PropTypes.string,
  location: PropTypes.object.isRequired,
  loginActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  userSwitch: PropTypes.object,
  readAt: PropTypes.string,
  readAtFulfilled: PropTypes.bool,
  latestReleaseNote: PropTypes.object,
  profilePictures: PropTypes.object,
  fileActions: PropTypes.object.isRequired,
  dataLoadStateActions: PropTypes.object.isRequired,
};

HeaderUserProfile.defaultProps = {
  consultants: [],
  dataLoadState: {},
  language: 'de',
  userSwitch: {},
  readAt: undefined,
  readAtFulfilled: false,
  latestReleaseNote: {},
  profilePictures: {}
};

function mapStateToProps(state) {
  return {
    consultants: state.consultantSearch.activeConsultants,
    dataLoadState: state.dataLoadState,
    language: state.login.language,
    session: state.login.session,
    userSwitch: state.userSwitch,
    readAt: state.releaseNote.latestReleaseNote.readAt,
    readAtFulfilled: state.releaseNote.latestReleaseNote.fulfilled,
    latestReleaseNote: state.releaseNote.latestReleaseNote.data,
    profilePictures: state.file.profilePicture,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dataLoadStateActions: bindActionCreators(dataLoadStateActions, dispatch),
    aclActions: bindActionCreators(aclActions, dispatch),
    bootstrapActions: bindActionCreators(bootstrapActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withAcl()(
    withRouter(HeaderUserProfile)
  )
);
