/* eslint-disable react/react-in-jsx-scope */
import { get } from 'lodash';

export const suspension = [
  'cardSuspension-0',
  'cardSuspension-50',
  'cardSuspension-51',
  'cardSuspension-52',
  'cardSuspension-54',
  'cardSuspension-55',
  'cardSuspension-59',
  'cardSuspension-61',
  'cardSuspension-62',
  'cardSuspension-72',
  'cardSuspension-73',
  'cardSuspension-90',
  'cardSuspension-92'
];

/**
 * Filter options based on current card status
 *
 * @param  {String} status  Card status
 * @param  {Array}  options Array of possible options
 *
 * @return {Array} Filtered array
 */
export function filterCardStatusOptions(status, options) {
  if (status === 'active') return options.filter((o) => ['suspended_by_cardholder', 'suspended_by_bank'].includes(get(o, 'value')));
  if (['suspended_by_cardholder', 'suspended_by_bank'].includes(status)) return options.filter((o) => get(o, 'value') === 'active');

  return [];
}

/**
   * Filter cardSuspension codes based on the fiven code ids
   *
   * @param  {Array} codes            Codes array
   * @param  {Array} [ids=suspension] Array of code ids
   *
   * @return {Array} codes Filtered codes
   */
export function filterCardSuspensionCodes(codes = [], ids = suspension) {
  return codes.filter((c) => ids.includes(get(c, 'id')));
}
