import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import './MainTypeError.css';

const MainTypeError = React.memo(({ language, links }) => {
  const openingAndMutationLink = links.find((l) => get(l, 'id') === 'depotOpeningAndMutation');
  const fondsInvestLink = links.find((l) => get(l, 'id') === 'depotOpeningFondsInvest');

  return (
    <Alert
      type="error"
    >
      <AlertTitle>
        <FormattedMessage id="SecuritiesAccount.Opening.Error.MainType.Title" />
      </AlertTitle>
      <AlertContent>

        <p>
          <FormattedMessage id="SecuritiesAccount.Opening.Error.MainType.Line1" />
        </p>

        <p>
          <FormattedMessage id="SecuritiesAccount.Opening.Error.MainType.Line2" />
        </p>

        <ul className="securities-account-opening-main-type-error--links">
          <li className="react-anchor">
            <a href={get(openingAndMutationLink, `translatedUrl.${language}`)} target="_blank" rel="noreferrer">
              {get(openingAndMutationLink, `name.${language}`)}
            </a>
          </li>

          <li className="react-anchor">
            <a href={get(fondsInvestLink, `translatedUrl.${language}`)} target="_blank" rel="noreferrer">
              {get(fondsInvestLink, `name.${language}`)}
            </a>
          </li>
        </ul>
        <p>
          <FormattedMessage id="SecuritiesAccount.Opening.Error.MainType.Line4" />
        </p>
      </AlertContent>
    </Alert>
  );
});

MainTypeError.propTypes = {
  language: PropTypes.string,
  links: PropTypes.array
};

MainTypeError.defaultProps = {
  language: 'de',
  links: []
};

export default MainTypeError;
