import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import getUserRoles from '../Acl/getUserRoles';

export default function* bootstrapUserRoles() {
  const session = yield select((state) => get(state, 'login.session'));

  return yield call(getUserRoles, { consultantId: get(session, 'id') });
}
