/**
 * Just return false...
 *
 * @return {Boolean}
 */
export function setFalse() {
  return false;
}

export default {
  key: 'setFalse',
  func: setFalse,
  types: []
};
