import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import stockPriceAction from '../actions/StockPriceAction';

const {
  STOCK_PRICE_PENDING,
  STOCK_PRICE_FULFILLED,
  STOCK_PRICE_REJECTED
} = stockPriceAction;

export default function* getStockPrice() {
  yield put({ type: STOCK_PRICE_PENDING });

  try {
    const url = '/marketdata/high-low?idnotation=1528521';
    const { data: payload } = yield call(axios.get, url);

    yield put({ type: STOCK_PRICE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: STOCK_PRICE_REJECTED, error });

    return error;
  }
}
