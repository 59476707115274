import { get } from 'lodash';
import { put } from 'redux-saga/effects';

import {
  ADD_TOAST
} from '../../actions/Toast';

/**
 * Default timeout handler
 *
 * @param  {Object} result Result from request
 *
 * @return {Generator}
 */
export default function* defaultEventStatusTimeout(result) {
  yield put({
    type: ADD_TOAST,
    kind: 'error',
    title: 'Error.EventStatus.TimeoutTitle',
    description: 'Error.EventStatus.TimeoutDescription',
    values: { eventId: get(result, 'data.id') }
  });
}
