import { put } from 'redux-saga/effects';

import { get, isNil } from 'lodash';
import moment from 'moment';
import withdrawalLimitCancellationFormAction from '../../actions/Form/WithdrawalLimitCancellationForm';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

const {
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_PENDING,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_FULFILLED,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_REJECTED
} = withdrawalLimitCancellationFormAction;

export default function* withdrawalLimitCancellationFormInit(request) {
  const { data } = request;

  yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_PENDING });

  try {
    const payload = {
      selectedAccount: undefined,
      validFrom: moment().format(),
      validUntil: moment().add(3, 'month').subtract(1, 'day').format(),
    };

    // is used to reset values if selected account changes
    if (!isNil(data) && !isNil(get(data, 'selectedAccount'))) {
      payload.selectedAccount = get(data, 'selectedAccount');

      if (!isNil(get(data, 'selectedAccount')) && get(data, 'selectedAccount.accountKey') !== 0) {
        if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
          // if withdrawalPeriodStart is in the future, use withdrawalPeriodStart
          const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
          const validFromMinDate = isAfterToday ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')) : moment();
          payload.validFrom = validFromMinDate.format();
          payload.validUntil = validFromMinDate.clone().add(3, 'months').format();
        } else {
          const startDate = moment().add(31, 'days');
          payload.validFrom = startDate.format();
          payload.validUntil = startDate.clone().add(3, 'months').subtract(1, 'days').format();
        }
      } else {
        payload.validFrom = undefined;
        payload.validUntil = undefined;
      }
    }

    yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_INIT_REJECTED, error });

    return error;
  }
}
