import { TYPE_STRING } from '@metrichor/jmespath-plus';
import { replace } from 'lodash';

/**
 * Replace whitespaces in the given string with the given replace value
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} formatted Formatted number
 */
export function replaceWhitespaces(resolvedArgs = []) {
  const [str = '', r = ''] = resolvedArgs;

  return replace(str, / /gi, r);
}

export default {
  key: 'replaceWhitespaces',
  func: replaceWhitespaces,
  types: [
    { types: [TYPE_STRING] },
    { types: [TYPE_STRING], optional: true }
  ]
};
