import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const ItemDataLoadState = React.memo(({ date, labelId }) => {
  const minutes = moment().diff(moment(date), 'minutes');
  const hours = moment().diff(moment(date), 'hours');
  const days = moment().diff(moment(date), 'days');
  return (
    <DropdownItem disabled>
      <span className="mdi mdi-database" />
      <FormattedMessage id={labelId} />
      :
      {'\u00A0'}
      {date && (
        <span style={moment().diff(moment(date), 'days') >= 1 ? { color: 'rgb(242, 45, 84)' } : {}}>
          {minutes <= 60 ? (
            <FormattedMessage id="CustomerDetail.Header.Profile.ObjectLoadStatus.Minutes" values={{ minutes }} />
          ) : hours <= 24 ? (
            <FormattedMessage id="CustomerDetail.Header.Profile.ObjectLoadStatus.Hours" values={{ hours }} />
          ) : (
            <FormattedMessage id="CustomerDetail.Header.Profile.ObjectLoadStatus.Days" values={{ days }} />
          )}
        </span>
      )}
      {!date && (
        <span style={{ color: 'rgb(242, 45, 84)' }}>
          <FormattedMessage id="CustomerDetail.Header.Profile.ObjectLoadStatus.TitleUnknown" />
        </span>
      )}
    </DropdownItem>
  );
});

ItemDataLoadState.propTypes = {
  date: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
};

export default ItemDataLoadState;
