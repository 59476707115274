import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import cl from 'classnames';

import './UserSwitchImages.css';
import Image from './Image';
import ConsultantPopover from '../../../../../InfoPopover/containers/Consultant/ConsultantPopover';

const UserSwitchImages = React.memo(({
  className,
  consultant,
  userSwitch,
  fileActions,
  profilePictures,
  onToggle,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={cl('layout-header-user-profile--user-switch-images', className)}
    >
      <Image consultant={consultant} fileActions={fileActions} profilePictures={profilePictures} onToggle={onToggle} />

      <div className="layout-header-user-profile--user-switch-images--arrow">
        <span className="mdi mdi-arrow-right" />
      </div>

      <ConsultantPopover consultantId={get(userSwitch, 'selected.id')}>
        <Image
          consultant={get(userSwitch, 'selected')}
          fileActions={fileActions}
          profilePictures={profilePictures}
          className="userswitch-profile-image"
        />
      </ConsultantPopover>
    </div>
  );
});

UserSwitchImages.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  consultant: PropTypes.object.isRequired,
  userSwitch: PropTypes.object.isRequired,
  fileActions: PropTypes.object.isRequired,
  profilePictures: PropTypes.object,
  onToggle: PropTypes.func,
};

UserSwitchImages.defaultProps = {
  className: undefined,
  profilePictures: {},
  onToggle: noop
};

export default UserSwitchImages;
