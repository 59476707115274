function onResolveDefault(config) {
  return config;
}

function onRejectDefault(error) {
  return Promise.reject(error);
}

export default function AxiosRequestInterceptor({
  onResolve = onResolveDefault,
  onReject = onRejectDefault
}) {
  function register(axios) {
    axios.interceptors.request.use(onResolve, onReject);
  }

  return Object.freeze({
    register
  });
}
