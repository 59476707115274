import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@evoja-web/react-form';
import { get, noop } from 'lodash';
import { utils as codeUtils } from '@evoja-web/entity-code';

import { CodeSelect } from '../../../General/index';

const QIDeclaration = React.memo(({
  codes,
  data,
  language,
  onChange,
  validations
}) => {
  const onChangeQi = (id, val) => {
    const values = get(data, 'qiCustomDocContent', {});

    const updatedValues = {
      ...values,
      [id]: val
    };

    onChange('qiCustomDocContent', updatedValues);
  };

  const values = get(data, 'qiCustomDocContent', {});

  return (
    <React.Fragment>
      <Row className="provision-depot-opening--space--top">
        <Col md={6} className="provision-depot-opening--label--center">
          <FormattedMessage
            id="Provision.Form.DepotOpening.QIDeclaration.Title"
            tagName="h3"
          />
        </Col>
      </Row>

      <Row className="provision-depot-opening--space--top">
        <Col md={6} className="provision-depot-opening--label--center">
          <FormattedMessage id="Provision.Form.DepotOpening.QIDeclaration.DoubleTaxationAgreement" />
        </Col>
      </Row>
      <Row className="provision-depot-opening--space--top">
        <Col md={6} className="provision-depot-opening--label--center">
          <Toggle
            value={get(values, 'dbaLandCh', false)}
            onChange={(id, val) => onChangeQi('dbaLandCh', val)}
          >
            <FormattedMessage id="Provision.Form.DepotOpening.QIDeclaration.Switzerland" />
          </Toggle>
        </Col>
      </Row>

      {!get(values, 'dbaLandCh', false) && (
        <>
          <Row className="provision-depot-opening--space--top">
            <Col md={6} className="provision-depot-opening--label--center">
              <FormattedMessage id="Provision.Form.DepotOpening.QIDeclaration.SelectCountries" />
            </Col>
          </Row>
          <Row className="provision-depot-opening--space--top">
            <Col md={2} className="provision-depot-opening--label--center">
              <CodeSelect
                className="provision-depot-opening-qi-declaration-other-country-select"
                style={{ width: 'inherit' }}
                codes={codeUtils.getCountryCodes(codes, language)}
                id="dbaLandOther"
                language={language}
                onChange={(key, value) => onChangeQi('dbaLandOther', value)}
                showValidations
                validations={get(validations, 'qiCustomDocContent.dbaLandOther')}
                value={get(values, 'dbaLandOther')}
              />
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
});

QIDeclaration.propTypes = {
  codes: PropTypes.object,
  data: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

QIDeclaration.defaultProps = {
  codes: { groups: {} },
  data: {},
  language: 'de',
  onChange: noop,
  validations: {}
};

export default QIDeclaration;
