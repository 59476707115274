import { takeLatest } from 'redux-saga/effects';

import {
  ESTIMATION_PROTOCOL_LOAD_REQUEST,
} from '../actions/EstimationProtocol';

import { getEstimationProtocol } from './EstimationProtocol/index';

export default function* root() {
  yield takeLatest(ESTIMATION_PROTOCOL_LOAD_REQUEST, getEstimationProtocol);
}
