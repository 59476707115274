import { TYPE_ANY } from '@metrichor/jmespath-plus';

/**
 * Javascript logical not
 * Converts true to false and vice versa
 *
 * @param  {Array} resolvedArgs Array of resolved args
 *
 * @return {Boolean} value Inverted value
 */
export function not(resolvedArgs) {
  const [value] = resolvedArgs;

  return !value;
}

export default {
  key: 'not',
  func: not,
  types: [{ types: [TYPE_ANY] }]
};
