import { call } from 'redux-saga/effects';

import getCompactables from '../../packages/Compactable/sagas/getCompactables';

export default function* bootstrapCompactable() {
  const compactables = yield call(getCompactables);

  return {
    compactables
  };
}
