import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import { get } from 'lodash';
import withdrawalLimitCancellationFormAction from '../../actions/Form/WithdrawalLimitCancellationForm';
import { toIntIfSet } from '../../../../lib/Utils';
import moment from 'moment';

const {
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_PENDING,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_FULFILLED,
  WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_REJECTED
} = withdrawalLimitCancellationFormAction;

export default function* withdrawalLimitCancellationFormSave(request) {
  const {
    data, customerId, consultantId, tenant = 6300
  } = request;

  yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_PENDING });

  try {
    const noticeWithdrawalLimit = {
      validFrom: moment(get(data, 'validFrom')).toISOString(),
      validUntil: moment(get(data, 'validUntil')).toISOString(),
      accountKey: get(data, 'selectedAccount.accountKey')

    };

    const payload = {
      variables: {
        responsibleUser: toIntIfSet(consultantId),
        processCallOrigin: 'MAP',
        clientKey: toIntIfSet(customerId),
        noticeWithdrawalLimit
      },
      businessKey: 'valiant-depot-withdrawalPensionAccount3aV1'
    };

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-depot-withdrawalPensionAccount3aV1/tenant-id/${tenant}/start`;
    yield call(axios, {
      url,
      method: 'post',
      data: payload
    });

    yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: WITHDRAWAL_LIMIT_CANCELLATION_FORM_SAVE_REJECTED, error });

    return error;
  }
}
