import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  USERTRACKING_REQUEST,
  USERTRACKING_LOAD_REQUEST,
} from '../actions/UserTracking';
import { addEvent, getEvents } from './UserTracking/index';

export default function* root() {
  yield takeLatest(USERTRACKING_LOAD_REQUEST, getEvents);
  yield takeEvery(USERTRACKING_REQUEST, addEvent);
}
