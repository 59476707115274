import { Reducers } from '@evoja-web/redaction';

import { reducer as authorizationsReducer } from './Authorizations';
import { reducer as cancelOpeningReducer } from './CancelOpening';
import { reducer as checkExistingOpeningReducer } from './CheckExistingOpening';
import { reducer as contractsReducer } from './Contracts';
import { reducer as customersReducer } from './Customers';
import { reducer as fundsReducer } from './Funds';
import { reducer as openingFormReducer } from './OpeningForm';
import { reducer as paymentAccountsReducer } from './PaymentAccounts';
import { reducer as portfoliosReducer } from './Portfolios';
import { reducer as productsReducer } from './Products';
import { reducer as qiDeclarationReducer } from './QiDeclaration';
import { reducer as unlockFormReducer } from './UnlockForm';
import { reducer as workbenchRecordReducer } from './WorkbenchRecord';

export const reducers = Reducers([
  authorizationsReducer,
  cancelOpeningReducer,
  checkExistingOpeningReducer,
  contractsReducer,
  customersReducer,
  fundsReducer,
  openingFormReducer,
  paymentAccountsReducer,
  portfoliosReducer,
  productsReducer,
  qiDeclarationReducer,
  unlockFormReducer,
  workbenchRecordReducer
]);

export default reducers.get();
