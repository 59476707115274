import { call, select } from 'redux-saga/effects';
import { get } from 'lodash';

import getConsultantCustomers from '../../packages/CustomerOverview/sagas/getConsultantCustomers';

export default function* boostrapCustomerOverview() {
  const dataKey = yield select((state) => get(state, 'userSwitch.selected.id', get(state, 'login.session.id')));

  const { fulfilled = false, data = [] } = yield select((state) => get(state, `customerOverview.consultantCustomers.${dataKey}`, {}));

  if (fulfilled) return data;

  return yield call(getConsultantCustomers, { dataKey });
}
