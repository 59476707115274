import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import { get, uniq } from 'lodash';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import consultantsAction from '../../actions/ConsultantSearch/Consultants';
import { userExceptions } from '../../fakes/acl';

const {
  CONSULTANTS_PENDING,
  CONSULTANTS_FULFILLED,
  CONSULTANTS_REJECTED
} = consultantsAction;

/**
 * Get all consultants from backend and store them in indexDB.
 * If data in indexDB is not expired, return data from there instead of calling the backend again
 *
 * @param   {Boolean}  forceReload  If set to true, always reload consultants from backend
 *
 * @return  {Array} payload Prepared consultants from backend
 */
export default function* getAllConsultants(request = {}) {
  const { forceReload = false } = request;

  yield put({ type: CONSULTANTS_PENDING });

  try {
    // Check if data in indexDB is not expired. If so, do not load
    // from backend and directly return data from indexDB
    const db = getDbInstance({ id: 'map' });
    const expired = yield call(db.isExpired, { table: 'consultant' });
    if (!forceReload && !expired) {
      const payload = yield call(db.getAllRecords, { table: 'consultant' });

      yield put({ type: CONSULTANTS_FULFILLED, payload });

      return payload;
    }

    const query = QueryBuilder()
      .deselect(['groupMembership'])
      .limit(5000)
      .getQueryString();

    const result = yield call(handleNextHeaders, `/person/consultant/${query}`);

    // Assign acl roles based on groupMembership
    const payload = result.map((c) => {
      const roles = get(c, 'permission', []);

      // https://issue.swisscom.ch/browse/MAP-2810
      const exception = get(userExceptions, get(c, 'id'), []);

      // eslint-disable-next-line
      return {
        ...c,
        acl: { roles: uniq([...roles, ...exception, 'ALL_USERS']) }
      };
    });

    yield call(db.bulkUpdate, { table: 'consultant', data: payload, clear: true });

    yield put({ type: CONSULTANTS_FULFILLED, payload, expired: true });

    return payload;
  } catch (error) {
    yield put({ type: CONSULTANTS_REJECTED, error });

    return error;
  }
}
