import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import createDocument from './createDocument';
import deleteDocument from './deleteDocument';
import deleteDocuments from './deleteDocuments';
import getDocuments from './getDocuments';

const {
  CREATE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENTS_REQUEST,
  DOCUMENTS_REQUEST
} = actions;

export default function* printDocumentPreviewRootSaga() {
  yield takeLatest(CREATE_DOCUMENT_REQUEST, createDocument);
  yield takeLatest(DELETE_DOCUMENT_REQUEST, deleteDocument);
  yield takeLatest(DELETE_DOCUMENTS_REQUEST, deleteDocuments);
  yield takeLatest(DOCUMENTS_REQUEST, getDocuments);
}
