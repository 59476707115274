import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import moment from 'moment';
import db2HistoryAction from '../../actions/Db2/Db2History';

const {
  DB2_HISTORY_PENDING,
  DB2_HISTORY_FULFILLED,
  DB2_HISTORY_REJECTED
} = db2HistoryAction;

export default function* getDb2History(request) {
  const { dataKey } = request;
  yield put({ type: DB2_HISTORY_PENDING, dataKey });

  try {
    const currentDate = moment();

    // Subtract 3 months
    const date2YearsBefore = currentDate.subtract(2, 'years');

    const query = QueryBuilder()
      .eq('customerId', dataKey, { type: 'string' })
      .sort('+entryDate')
      .limit(999)
      .getQueryString();

    const url = `/person/customer/profit/${query}`;

    const data = yield call(handleNextHeaders, url);

    yield put({ type: DB2_HISTORY_FULFILLED, dataKey, payload: data });

    return data;
  } catch (error) {
    yield put({ type: DB2_HISTORY_REJECTED, dataKey, error });

    return error;
  }
}
