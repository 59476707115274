import { TYPE_ANY, TYPE_NULL } from '@metrichor/jmespath-plus';
/**
 * Convert the given value to a json string
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} value Json string
 */
export function toJsonString(resolvedArgs) {
  const [value] = resolvedArgs;

  return JSON.stringify(value);
}

export default {
  key: 'toJsonString',
  func: toJsonString,
  types: [{ types: [TYPE_ANY, TYPE_NULL] }]
};
