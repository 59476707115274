import { Reducers } from '@evoja-web/redaction';

import { reducer as bannersReducer } from './Banners';
import { reducer as confirmBannerReducer } from './ConfirmBanner';

export const reducers = Reducers([
  bannersReducer,
  confirmBannerReducer
]);

export default reducers.get();
