import { takeEvery, takeLatest } from 'redux-saga/effects';

import financingActions from '../actions/Actions';

import getAmortizations from './Amortization/getAmortizations';
import getCollaterals from './Collateral/getCollaterals';
import getCustomerCredits from './Customer/getCredits';
import getCustomerObjects from './Customer/getObjects';
import getEtps from './Etp/getEtps';
import getTranches from './Tranche/getTranches';
import getRealEstateTypes from './RealEstateType/getRealEstateTypes';

const {
  AMORTIZATIONS_REQUEST,
  COLLATERALS_REQUEST,
  CUSTOMER_CREDITS_REQUEST,
  CUSTOMER_OBJECTS_REQUEST,
  ETPS_REQUEST,
  TRANCHES_REQUEST,
  REAL_ESTATE_TYPE_REQUEST
} = financingActions;

export default function* financingRootSaga() {
  yield takeEvery(AMORTIZATIONS_REQUEST, getAmortizations);
  yield takeEvery(COLLATERALS_REQUEST, getCollaterals);
  yield takeEvery(ETPS_REQUEST, getEtps);
  yield takeEvery(TRANCHES_REQUEST, getTranches);
  yield takeLatest(CUSTOMER_CREDITS_REQUEST, getCustomerCredits);
  yield takeLatest(CUSTOMER_OBJECTS_REQUEST, getCustomerObjects);
  yield takeLatest(REAL_ESTATE_TYPE_REQUEST, getRealEstateTypes);
}
