const htmlToPdfStyles = {
  // Text styling
  CODE: {
    fontFamily: 'Arial MT',
    color: '#d63384',
    wordWrap: 'break-word',
    fontSize: 9
  },
  U: { fontFamily: 'Arial MT', textDecoration: 'underline' },
  EM: {
    fontFamily: 'Arial MT',
    fontStyle: 'italic',
  },
  STRONG: {
    fontFamily: 'Arial MT',
    fontWeight: 'bold',
  },

  // Text elements
  H1: {
    fontFamily: 'Arial MT',
    fontSize: '16'
  },
  H2: {
    fontFamily: 'Arial MT',
    fontSize: '13'
  },
  H6: {
    fontFamily: 'Arial MT',
    fontSize: '11'
  },
  BLOCKQUOTE: {
    fontFamily: 'Arial MT',
    padding: '10px 20px !important',
    margin: '0 0 1em 0 !important',
    borderLeft: '5px solid #eef3f6 !important'
  },
  List: {
    fontFamily: 'Arial MT',
    flexDirection: 'column',
    width: 400
  },
  ListItem: {
    fontFamily: 'Arial MT',
    flexDirection: 'row',
    marginBottom: 4,
    fontSize: 10
  },
  ListItemPrefix: {
    fontFamily: 'Arial MT',
    marginHorizontal: 8
  },

  // normalText
  NormalText: { fontFamily: 'Arial MT', }
};

export default htmlToPdfStyles;
