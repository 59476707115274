import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import accountActions from '../../actions/SetAdvisor/Account';

const {
  ACCOUNTS_PENDING,
  ACCOUNTS_FULFILLED,
  ACCOUNTS_REJECTED
} = accountActions;

export default function* getAccounts(request) {
  const { customerId } = request;

  yield put({ type: ACCOUNTS_PENDING });
  try {
    const payload = yield call(handleNextHeaders, `/account/account/?eq(customer.id,string:${customerId})&limit(9999)`);

    yield put({ type: ACCOUNTS_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: ACCOUNTS_REJECTED, error });

    return error;
  }
}
