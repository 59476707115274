import { Reducers } from '@evoja-web/redaction';

import {
  searchReducer
} from './CustomerSearch';

export const reducers = Reducers([
  searchReducer
]);

export default reducers.get();
