import { throttle, takeLatest } from 'redux-saga/effects';

import migrationActions from '../actions/Actions';

import searchAccount from './searchAccount';
import searchByCustomer from './searchByCustomer';
import searchCustomer from './searchCustomer';

const {
  SEARCH_BY_CUSTOMER_REQUEST,
  SEARCH_ACCOUNT_REQUEST,
  SEARCH_CUSTOMER_REQUEST
} = migrationActions;

export default function* referencingRootSaga() {
  yield throttle(500, SEARCH_BY_CUSTOMER_REQUEST, searchByCustomer);
  yield throttle(500, SEARCH_ACCOUNT_REQUEST, searchAccount);
  yield takeLatest(SEARCH_CUSTOMER_REQUEST, searchCustomer);
}
