import { call, put } from 'redux-saga/effects';
import actions from '../actions/Actions';
import axios from 'axios';

const {
  EXTERNAL_LINK_PENDING,
  EXTERNAL_LINK_FULFILLED,
  EXTERNAL_LINK_REJECTED
} = actions;

export default function* getExternalLinks() {
  yield put({ type: EXTERNAL_LINK_PENDING });

  try {
    const url = '/core/external-link/?sort(+order)&limit(999)';

    const { data: payload } = yield call(axios, {
      url,
      method: 'get',
    });

    yield put({ type: EXTERNAL_LINK_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: EXTERNAL_LINK_REJECTED, error });

    return error;
  }
}
