import { DefaultReducer } from '@evoja-web/redaction';

import action, { environmentJsonAction } from '../actions/EnvironmentJson';

const { ENVIRONMENT_JSON_FULFILLED } = action;

/**
 * Update state.
 * Write result from environmentJson as config object to state to keep backwards compatibility
 *
 * @param   {Object}  state   Current application state
 * @param   {Object}  action  Result from saga
 *
 * @return  {Object} state Updated state
 */
function afterEnvironmentJsonFulfilled(state, action) {
  const { payload } = action;

  return {
    ...state,
    config: payload
  };
}

export const reducer = DefaultReducer('environmentJson', environmentJsonAction);
reducer.after(ENVIRONMENT_JSON_FULFILLED, afterEnvironmentJsonFulfilled);

export default reducer.get();
