import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import { get, isNil, map, toPairs, parseInt, isEqual } from 'lodash';
import moment from 'moment';
import openPrivorRetirementSavingsAccountDepositFormAction from '../../actions/Form/OpenPrivorRetirementSavingsAccountDepositForm';
import { toIntIfSet } from '../../../../lib/Utils';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

const {
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_PENDING,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_FULFILLED,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_REJECTED
} = openPrivorRetirementSavingsAccountDepositFormAction;

function preprareNoticeWithdrawalLimit({ data, pensionAccountKey }) {
  // Neues depot mit existiernedem Konto kündigung nicht notwendig
  if (!isNil(pensionAccountKey) && get(data, 'securitiesSolution', false) && hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
    return {
      validFrom: !isNil(get(data, 'selectedAccount.withdrawalPeriodStart')) ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')).toISOString() : undefined,
      validUntil: !isNil(get(data, 'selectedAccount.withdrawalPeriodEnd')) ? moment(get(data, 'selectedAccount.withdrawalPeriodEnd')).toISOString() : undefined,
    };
  }

  // Neues Depot mit existierendem Konto kündigung notwendig
  if (!isNil(pensionAccountKey) && get(data, 'securitiesSolution', false)) {
    const startDate = moment().add(31, 'days');
    return {
      validFrom: startDate.toISOString(),
      validUntil: moment(startDate).add(3, 'months').toISOString(),
    };
  }

  // sets validForm and validUntil Date when depot opening without securitiesSolution and with securitiesSolutionCancellation
  if (!get(data, 'securitiesSolution', false) && get(data, 'securitiesSolutionCancellation', false)) {
    return {
      validFrom: !isNil(get(data, 'validFrom')) ? moment(get(data, 'validFrom')).toISOString() : undefined,
      validUntil: !isNil(get(data, 'validUntil')) ? moment(get(data, 'validUntil')).toISOString() : undefined,
    };
  }

  return undefined;
}

function setupData({ data, qiDeclaration }) {
  const payload = {
    occupationalPension: get(data, 'occupationalPension'),
    documentChangeBeneficiaryDesired: get(data, 'documentChangeBeneficiaryDesired'),
    qiCustomDocContent: get(data, 'qiCustomDocContent')
  };

  // selected account // is not setted when a new Account should be created
  if (get(data, 'selectedAccount.accountKey') !== 0 && !isNil(get(data, 'selectedAccount.accountKey'))) {
    payload.pensionAccountKey = get(data, 'selectedAccount.accountKey');
  }

  // set qiDecleration
  if (isNil(get(qiDeclaration, 'data.resultVariables.contract'))) {
    payload.qiCustomDocContent = {
      dbaLandCh: get(data, 'qiCustomDocContent.dbaLandCh', false),
      dbaLandOther: !get(data, 'qiCustomDocContent.dbaLandCh', false),
      dbaLandOtherEingabe: get(data, 'qiCustomDocContent.dbaLandOther.textShort.de')
    };
  }

  if ((get(data, 'securitiesSolution', false) || get(data, 'securitiesSolutionCancellation', false))) {
    // Nur wenn kein neues Konto oder kein Depot erstellt wird.
    payload.noticeWithdrawalLimit = preprareNoticeWithdrawalLimit({ data, pensionAccountKey: payload.pensionAccountKey });
  }

  // only sets data when depot opening with securitiesSolution
  if (get(data, 'securitiesSolution', false)) {
    // sets funds
    payload.selectedFonds = map(toPairs(get(data, 'funds', [])), ([id, percentage]) => ({
      id: parseInt(id),
      percentage
    }));

    payload.investmentObjective = get(data, 'investmentObjective');

    // sets execution data
    const executionData = {
      executionType: get(data, 'executionType'),
      executionStartDate: !isNil(get(data, 'executionStartDate')) ? moment(get(data, 'executionStartDate')).format('yyyy-MM-DD') : undefined,
    };

    if (get(data, 'executionType') !== 'standingOrderBalance') {
      executionData.amount = get(data, 'amount');
    }

    if (get(data, 'executionType') === 'standingOrderAmount') {
      executionData.periodicity = get(data, 'periodicity');
    }

    if (!isNil(get(data, 'executionEndDate'))) {
      executionData.executionEndDate = !isNil(get(data, 'executionEndDate')) ? moment(get(data, 'executionEndDate')).format('yyyy-MM-DD') : undefined;
    }

    payload.execution = executionData;
  }
  return payload;
}

export default function* openPrivorRetirementSavingsAccountDepositFormSave(request) {
  const {
    data, customerId, consultantId, qiDeclaration, tenant = 6300
  } = request;

  yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_PENDING });

  try {
    const preparedData = setupData({ data, qiDeclaration });

    const payload = {
      variables: {
        responsibleUser: toIntIfSet(consultantId),
        processCallOrigin: 'MAP',
        clientKey: toIntIfSet(customerId),
        ...preparedData
      },
      businessKey: 'valiant-depot-openPensionAccount3aV1'
    };

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-depot-openPensionAccount3aV1/tenant-id/${tenant}/start`;
    yield call(axios, {
      url,
      method: 'post',
      data: payload
    });

    yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_SAVE_REJECTED, error });

    return error;
  }
}
