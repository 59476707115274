import { takeLatest } from 'redux-saga/effects';

import baseContractActions from '../actions/BaseContract';
import {
  getExistingContract,
  getExistingOpening,
  saveOpeningForm
} from './BaseContract/index';

const {
  EXISTING_CONTRACT_REQUEST,
  EXISTING_OPENING_REQUEST,
  OPENING_FORM_SAVE_REQUEST
} = baseContractActions;

export default function* baseContractRootSaga() {
  yield takeLatest(EXISTING_CONTRACT_REQUEST, getExistingContract);
  yield takeLatest(EXISTING_OPENING_REQUEST, getExistingOpening);
  yield takeLatest(OPENING_FORM_SAVE_REQUEST, saveOpeningForm);
}
