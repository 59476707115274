import { TYPE_NUMBER, TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import moment from 'moment';
import { isNull, isUndefined } from 'lodash';

/**
 * Set the given key <<day, month, year>> on the given date.
 * @see: https://momentjs.com/docs/#/get-set/day/
 * @example: Set the first day of month: dateStringSet(formData.cmpXYZ,`1`,'date')
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} formatted Formatted number
 */
export function dateStringSet(resolvedArgs = []) {
  const [value, amount, key = 'date'] = resolvedArgs;

  if (isNull(value) || isUndefined(value)) return undefined;

  const date = moment(value);

  if (!date.isValid()) return undefined;

  return date[key](amount).format();
}

export default {
  key: 'dateStringSet',
  func: dateStringSet,
  types: [
    { types: [TYPE_STRING, TYPE_NULL] },
    { types: [TYPE_NUMBER] },
    { types: [TYPE_STRING], optional: true }
  ]
};
