import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { isUndefined, noop } from 'lodash';

import { NumberField } from '../../../../General/index';

const InvestmentAmount = React.memo(({
  onChange,
  selectedProduct,
  validations,
  value
}) => {
  const labelId = selectedProduct === 10
    ? 'SecuritiesAccount.Opening.Form.InvestmentAmount'
    : 'SecuritiesAccount.Opening.Form.InvestmentAmountWithMin';

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id={labelId} />
      </Label>

      <NumberField
        id="investmentAmount"
        onChange={onChange}
        value={value}
        isAllowed={({ floatValue }) => isUndefined(floatValue) || (floatValue >= 0 && Number.isInteger(floatValue))}
        decimalSeparator={false}
        showValidations
        validations={validations}
      />
    </FormGroup>
  );
});

InvestmentAmount.propTypes = {
  onChange: PropTypes.func,
  selectedProduct: PropTypes.number,
  validations: PropTypes.object,
  value: PropTypes.number
};

InvestmentAmount.defaultProps = {
  onChange: noop,
  selectedProduct: undefined,
  validations: {},
  value: undefined
};

export default InvestmentAmount;
