import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { head } from 'lodash';

import deleteAction from '../../actions/PaymentRule/Delete';

const {
  PAYMENT_RULE_DELETE_PENDING,
  PAYMENT_RULE_DELETE_FULFILLED,
  PAYMENT_RULE_DELETE_REJECTED
} = deleteAction;

export default function* deletePaymentRule(request) {
  yield put({ type: PAYMENT_RULE_DELETE_PENDING });

  try {
    const {
      customerId,
      id,
      tenant = 6300
    } = request;

    const paymentRuleKey = head(id.split('-'));
    const url = `/sif/finnova/paymentrulesadministration/api/v1/banks/${tenant}/clients/${customerId}/paymentRules/${paymentRuleKey}`;

    const fil = yield call(axios, {
      url,
      method: 'delete'
    });

    const grv = yield call(axios, {
      url: `/account/instruction/${id}`,
      method: 'delete'
    });

    const payload = { fil, grv };

    yield put({ type: PAYMENT_RULE_DELETE_FULFILLED, id, payload });

    return payload;
  } catch (error) {
    yield put({ type: PAYMENT_RULE_DELETE_REJECTED, error });

    return error;
  }
}
