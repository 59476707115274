import { Actions } from '@evoja-web/redaction';

import { releaseNoteAction } from './ReleaseNotes';
import { latestReleaseNoteAction } from './LatestReleaseNote';

export const actions = Actions([
  releaseNoteAction,
  latestReleaseNoteAction
]);

export default actions.get();
