import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';
import { ValidationResult } from '@evoja-web/react-form';
import CardList from '../List';

import { BeatLoader } from '../../../../General';

class WithCardSelect extends PureComponent {
  render() {
    const {
      cardInfo, language, onCardSelected, selected, validations, disabled
    } = this.props;

    return (
      <>
        <FormGroup>
          <Label>
            <FormattedMessage id="Card.Suspension.Cards" />
          </Label>
          {get(cardInfo, 'requesting') ? <BeatLoader />
            : (
              <CardList
                id="selected"
                language={language}
                cards={get(cardInfo, 'cards')}
                cardDetails={get(cardInfo, 'cardDetails')}
                selected={selected}
                onChange={onCardSelected}
                disabled={disabled}
              />
            )}
        </FormGroup>

        <ValidationResult
          show
          validations={validations.selected}
        />
      </>
    );
  }
}
WithCardSelect.propTypes = {
  language: PropTypes.string,
  cardInfo: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCardSelected: PropTypes.func,
  validations: PropTypes.object,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]),
};

WithCardSelect.defaultProps = {
  language: 'de',
  cardInfo: {},
  selected: undefined,
  onCardSelected: noop,
  validations: {},
  disabled: false

};

export default (WithCardSelect);
