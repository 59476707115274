import { call, put } from 'redux-saga/effects';
import { getInstance as getDbInstance } from '@evoja-web/indexdb-common';

import confirmBannerAction from '../actions/ConfirmBanner';

const {
  CONFIRM_BANNER_PENDING,
  CONFIRM_BANNER_FULFILLED,
  CONFIRM_BANNER_REJECTED
} = confirmBannerAction;

export default function* confirmBanner(request) {
  const { dataKey } = request;

  yield put({ type: CONFIRM_BANNER_PENDING, dataKey });

  try {
    const db = getDbInstance({ id: 'map' });
    const table = yield call(db.table, 'uiNote');
    const current = yield call([table, table.get], dataKey);
    const payload = { ...current, confirmed: true };
    yield call([table, table.put], payload);

    yield put({ type: CONFIRM_BANNER_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: CONFIRM_BANNER_REJECTED, dataKey, error });

    return error;
  }
}
