import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash';
import { Alert, AlertTitle, AlertContent } from '@evoja-web/react-layout-components';

import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

const CancellationNotice = React.memo(({ data }) => {
  const isBeforeToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isBefore(moment());

  const isAlreadyCanceled = hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount')) && isBeforeToday;

  const title = isAlreadyCanceled
    ? 'Provision.Form.DepotOpening.CancellationNotice.CancellationAlreadyEffected.Title'
    : 'Provision.Form.DepotOpening.CancellationNotice.CancellationIn31Days.Title';

  const description = isAlreadyCanceled
    ? 'Provision.Form.DepotOpening.CancellationNotice.CancellationAlreadyEffected.Description'
    : 'Provision.Form.DepotOpening.CancellationNotice.CancellationIn31Days.Description';

    return (
    <Alert type="warning" style={{ marginTop: '1em' }}>
      <AlertTitle>
        <FormattedMessage id={title} />
      </AlertTitle>
      <AlertContent>
        <FormattedMessage id={description} tagName="p" values={{ date: moment(get(data, 'selectedAccount.withdrawalPeriodStart')).format('DD.MM.YYYY') }} />
      </AlertContent>
    </Alert>
  );
});

CancellationNotice.propTypes = {
  data: PropTypes.object
};

CancellationNotice.defaultProps = {
  data: {}
};

export default CancellationNotice;
