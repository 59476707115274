import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import etpAction from '../../actions/Etp/Etps';

const {
  ETPS_PENDING,
  ETPS_FULFILLED,
  ETPS_REJECTED
} = etpAction;

export default function* getEtp(request) {
  const { dataKey } = request;

  yield put({ type: ETPS_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('orderId', dataKey, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/financing/etp/${query}`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: ETPS_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: ETPS_REJECTED, dataKey, error });

    return error;
  }
}
