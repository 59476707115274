import axios from 'axios';
import moment from 'moment';
import { call, put, all } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import action from '../actions/ReleaseNotes';

const {
  RELEASE_NOTES_PENDING,
  RELEASE_NOTES_FULFILLED,
  RELEASE_NOTES_REJECTED,
} = action;

function* fetchImageData(uiNote) {
  if (!uiNote.image) {
    return uiNote;
  }

  try {
    const filePath = new URL(uiNote.image.$ref).pathname;
    const { data: image } = yield call(axios.get, filePath, {
      method: 'get',
      timeout: 50000,
      responseType: 'blob',
    });

    // eslint-disable-next-line
    uiNote.image = image;
    return uiNote;
  } catch (error) {
    // eslint-disable-next-line
    uiNote.image = null;
    return uiNote;
  }
}

export default function* fetchReleaseNotes() {
  yield put({ type: RELEASE_NOTES_PENDING });

  try {
    const now = moment.utc().format('YYYY-MM-DD\\THH:mm:ss\\Z');
    const query = [
      'eq(enabled,true())',
      'eq(type.id,string:userInterfaceNoteType-1)',
      `le(publishDate,${now})`,
      'sort(-publishDate)',
    ].join('&');

    const news = yield call(handleNextHeaders, `/work/uinotes/?${query}`);

    yield all(news.map((it) => call(fetchImageData, it)));

    yield put({ type: RELEASE_NOTES_FULFILLED, payload: news });
  } catch (error) {
    yield put({ type: RELEASE_NOTES_REJECTED, error });
  }
}
