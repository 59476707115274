import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import qiDeclarationAction from '../actions/QiDeclaration';
import toStringIfSet from '../../../lib/Utils/toStringIfSet';

const {
  QI_DECLARATION_PENDING,
  QI_DECLARATION_FULFILLED,
  QI_DECLARATION_REJECTED
} = qiDeclarationAction;

export default function* getQiDeclaration(request) {
  const { dataKey } = request;

  yield put({ type: QI_DECLARATION_PENDING, dataKey });

  try {
    const { tenant = 6300 } = request;

    const url = `/sif/bpf/public/api/v1/process-definition/key/valiant-fil-is-genericGet/tenant-id/${tenant}/execute`;
    const data = {
      variables: {
        contractIdFromToken: false,
        clientKey: dataKey,
        contractType: 6023,
        contractStatus: 90,
        serviceName: 'contract.api.v2.get',
        bankNumber: toStringIfSet(tenant),
        handledErrors: '400,404'
      },
      resultVariableNames: ['contract', 'validationErrors'],
      waitTimeoutInMs: 12000
    };

    const { data: payload } = yield call(axios, {
      url,
      method: 'post',
      data
    });

    yield put({ type: QI_DECLARATION_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: QI_DECLARATION_REJECTED, dataKey, error });

    return error;
  }
}
