export const USERSWITCH_REQUEST = 'map/userswitch/REQUEST';
export const USERSWITCH_PENDING = 'map/userswitch/PENDING';
export const USERSWITCH_FULFILLED = 'map/userswitch/FULFILLED';
export const USERSWITCH_REJECTED = 'map/userswitch/REJECTED';
export const USERSWITCH_CLEAR = 'map/userswitch/CLEAR';

export function switchUser(username) {
  return { type: USERSWITCH_REQUEST, data: { username } };
}

export function unswitchUser() {
  return { type: USERSWITCH_CLEAR };
}
