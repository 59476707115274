import { Reducers } from '@evoja-web/redaction';

import { reducer as activeConsultantsReducer } from './ConsultantSearch/ActiveConsultants';
import { reducer as allConsultantsReducer } from './ConsultantSearch/AllConsultants';
import { reducer as consultantsReducer } from './ConsultantSearch/Consultants';
import { reducer as teamMainAdvisorsReducer } from './ConsultantSearch/TeamMainAdvisors';

export const reducers = Reducers([
  activeConsultantsReducer,
  allConsultantsReducer,
  consultantsReducer,
  teamMainAdvisorsReducer
]);

export default reducers.get();
