import { takeEvery, take, fork } from 'redux-saga/effects';
import {
  GUIDED_TOUR_PENDING,
  GUIDED_TOUR_GET_TEXT_PENDING,
  GUIDED_TOUR_UPDATE_VISIBILITY
} from '../actions/GuidedTour';

import { guidedTourPending, getGuidedTourText, saveVisibility } from './GuidedTour/index';

export default function* root() {
  yield takeEvery(GUIDED_TOUR_UPDATE_VISIBILITY, saveVisibility);

  const textRequest = yield take(GUIDED_TOUR_GET_TEXT_PENDING);
  yield fork(getGuidedTourText, textRequest);

  const pendingRequest = yield take(GUIDED_TOUR_PENDING);
  yield fork(guidedTourPending, pendingRequest);
}
