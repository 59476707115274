import { takeLatest } from 'redux-saga/effects';

import { USERSWITCH_REQUEST } from '../actions/UserSwitch';
import { userSwitch } from './UserSwitch/index';

/**
 * Root saga that takes the lates USERSWITCH_REQUEST action
 * and loads the user from then backend
 *
 * @return {Generator}
 */
export default function* root() {
  yield takeLatest(USERSWITCH_REQUEST, userSwitch);
}
