import { takeLatest } from 'redux-saga/effects';

import aclActions from '../actions/Acl';

import {
  getPermissions,
  getUserRoles,
  setTemporaryUserRoles
} from './Acl/index';

const {
  PERMISSIONS_REQUEST,
  TEMPORARY_USER_ROLES_REQUEST,
  USER_ROLES_REQUEST
} = aclActions;

export default function* root() {
  yield takeLatest(PERMISSIONS_REQUEST, getPermissions);
  yield takeLatest(TEMPORARY_USER_ROLES_REQUEST, setTemporaryUserRoles);
  yield takeLatest(USER_ROLES_REQUEST, getUserRoles);
}
