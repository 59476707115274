import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { withModalHandler } from '@evoja-web/react-modal';
import { MODAL_IDENTIFIER } from '../../../../../../../containers/PermissionSwitch/Modal';

const PermissionSwitch = React.memo(({ modalHandler }) => {
  function toggleModal() {
    const componentProps = {
      onCancel: () => modalHandler.hide(MODAL_IDENTIFIER),
      onSubmit: () => modalHandler.hide(MODAL_IDENTIFIER)
    };
    const modalProps = {};

    modalHandler.show({
      id: MODAL_IDENTIFIER,
      componentProps,
      modalProps
    });
  }

  return (
    <DropdownItem
      className="layout-header-user-profile--dropdown-item layout-header-user-profile--dropdown-item--no-icon"
      onClick={toggleModal}
      toggle={false}
    >
      <FormattedMessage id="PermissionSwitch.MenuItem.Title" />
    </DropdownItem>
  );
});

PermissionSwitch.propTypes = {
  modalHandler: PropTypes.object.isRequired
};

export default withModalHandler()(PermissionSwitch);
