import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { encodeRqlString } from '@evoja-web/redux-saga-utils';

import searchAction from '../../actions/CustomerSearch/Search';

const {
  SEARCH_PENDING,
  SEARCH_FULFILLED,
  SEARCH_REJECTED
} = searchAction;

export default function* getCustomers(request) {
  yield put({ type: SEARCH_PENDING });

  try {
    const { term } = request;
    const url = `/person/customer/?search(string:${encodeRqlString(term)})&limit(20)`;
    const { data: payload } = yield call(axios, {
      url,
      method: 'get',
      timeout: 40000
    });

    yield put({ type: SEARCH_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: SEARCH_REJECTED, error });

    return error;
  }
}
