import { call, put } from 'redux-saga/effects';
import { archive, upload } from '@evoja-web/react-file-upload/sagas';

import consultationFormActions from '../actions/Actions';

const {
  CONSULTATION_FORM_EDOSSIER_UPLOAD_PENDING,
  CONSULTATION_FORM_EDOSSIER_UPLOAD_FULFILLED,
  CONSULTATION_FORM_EDOSSIER_UPLOAD_REJECTED
} = consultationFormActions;

/**
 * Upload and archive the given file
 *
 * @param   {Object}                       customer  Custoemr from /person/customer
 * @param   {ReactFileUploadEdossierFile}  file      ReactFileUploadEdossierFile instance
 *
 * @return  {[type]}            [return description]
 */
export default function* uploadToEdossier({ customer, file }) {
  yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_PENDING });

  try {
    const dataKey = file.getId();

    yield call(upload, { dataKey, file });
    const archiveFile = yield call(archive, { dataKey, customer, file });

    if (archiveFile instanceof Error) {
      throw archiveFile;
    }

    yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_FULFILLED, payload: archiveFile });

    return archiveFile;
  } catch (error) {
    yield put({ type: CONSULTATION_FORM_EDOSSIER_UPLOAD_REJECTED, error });

    return error;
  }
}
