import { call, fork, put } from 'redux-saga/effects';

import pollWorker from './pollWorker';
import Deferred from '../../lib/Utils/Deferred';
import { POLL_REQUEST } from '../../actions/EventStatus';

/**
 * In most cases you don't want to wait until the poll worker finishes,
 * as it may take a while based on the given configuration (interval, maxTries).
 *
 * In some special cases (e.g. workguide post actions) its necessary to make sure
 * the post request is successful before starting the next request / action.
 * This function wraps the post worker saga into a promise which gets resolved / rejected
 * as soon the worker has done its work.
 *
 * @constructor
 */
export function PollRequestWrapper() {
  const deferred = Deferred();

  function getPromise() {
    return deferred.promise;
  }

  /**
   * Start the poll worker saga and resolve / reject the promise when done
   *
   * @param {Object} request Request object containing the necessary params
   *
   * @return {Generator}         [description]
   */
  function* startPolling(request) {
    const {
      url,
      interval,
      maxTries
    } = request;

    const params = {
      url,
      interval,
      maxTries,
      onSuccess: (...args) => deferred.resolve(...args),
      onFailed: (...args) => deferred.reject(...args),
      onError: (...args) => deferred.reject(...args),
      onTimeout: (...args) => deferred.reject(...args)
    };

    // Use fork to make sure we don't block the execute saga
    yield fork(pollWorker, params);
  }

  /**
   * Execute the wrapper.
   * Fork the poll request and wait for the promise to get resolved
   *
   * @param {Object} request Request object containing the necessary params
   *
   * @return {Generator}         [description]
   */
  function* execute(request) {
    yield fork(startPolling, request);

    // This is necessary as we need a regular function that returns a promise to
    // make the generator works. Else it will not wait for the promise to get resolved
    // and will not throw an error if the promise gets rejected
    // https://stackoverflow.com/questions/63002822/redux-saga-isnt-waiting-for-api-calls-to-resolve-keeps-returning-promises-how
    const result = yield call(() => deferred.promise);

    return result;
  }

  return Object.freeze({
    getPromise,
    execute
  });
}

/**
 * Wrap event status call into a promise
 *
 * @param  {Object} request Reqeuast object (action)
 *
 * @return {Generator}
 */
export default function* wrapPollRequest(request) {
  const {
    url,
    onSuccess,
    onTimeout,
    onFailed,
    onError,
    interval,
    maxTries
  } = request;

  const deferred = Deferred();

  yield put({
    type: POLL_REQUEST,
    url,
    interval,
    maxTries,
    onSuccess: function* pollRequestSuccess(result) {
      deferred.resolve(result);
      yield onSuccess(result);
    },
    onFailed: function* pollRequestOnFailed(result) {
      deferred.reject(result);
      yield onFailed(result);
    },
    onTimeout: function* pollRequestOnTimeout(result) {
      deferred.reject(result);
      yield onTimeout(result);
    },
    onError: function* pollRequestOnFailed(result) {
      deferred.reject(result);
      yield onError(result);
    }
  });

  deferred.promise.catch((error) => {
    // We want to show the error in console
    // eslint-disable-next-line
    console.error('Error in wrapPollRequest: ', error);

    return error;
  });

  return deferred.promise;
}
