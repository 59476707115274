export const groupMapping = new Map([
  ['CN=sec_map_lead_distributor,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'LEAD_DISTRIBUTOR'],
  ['CN=sec_map_uv_betreuer,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'UV_CONSULTANT'],
  ['CN=sec_map_kdc,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'KDC'],
  ['CN=sec_map_backoffice,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'BACKOFFICE'],
  ['CN=sec_map_postfinance,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'POSTFINANCE'],
  ['CN=sec_map_admin,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'ADMINISTRATOR'],
  ['CN=sec_vopo_pfandaufloesung,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'PLEDGE_RESOLVE'],
  ['CN=sec_map_personal,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'PERSONNEL_RIGHTS'],
  ['CN=_DL VAB Electronic Banking Center,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'ELECTRONIC_BANKING_CENTER'],
  ['CN=_DL VAB Kundendienst,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'CUSTOMER_SERVICE'],
  ['CN=_DL VAB Kundendienst Guemligen,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'CUSTOMER_SERVICE_GUEMLIGEN'],
  ['CN=_DL VAB Kundendienst Gümligen,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'CUSTOMER_SERVICE_GUEMLIGEN'],
  ['CN=_DL VAB Kundendienst Biel,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'CUSTOMER_SERVICE_BIEL'],
  ['CN=_DLVABKundendienstBiel,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'CUSTOMER_SERVICE_BIEL'],
  ['CN=_DL VAB CRO_Compliance,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'COMPLIANCE'],
  ['CN=_DL VAB Konto_Karten,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'ACCOUNT_CARDS'],
  ['CN=_DL VAB Datenzentrum,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'DATACENTER'],
  ['CN=_DL VAB Qualitätsmanagement,OU=_VAB_Groups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'QUALITY_MANAGEMENT'],
  ['CN=sec_map_evv,OU=Application,OU=SecurityGroups,OU=Groups,OU=Production,OU=_vabgroup,DC=ad,DC=vabgroup,DC=ch', 'EVV'],
  ['CN=VAB-CU-MAP-B2B-GS,OU=Resources,OU=CU-MAP,OU=ixService,OU=ixCloud,DC=intra,DC=valiant,DC=ch', 'B2B']
]);

export const permissions = [{
  id: 'ALL_USERS',
  name: 'ALL_USERS',
  permissions: {
    ShowBuildTime: false,
    PermissionSwitch: false,
    DeleteIndexDB: false,
    FeatureFlag: false,
    ChangeUiTheme: false,
    Gamification: {
      Disable: false,
      NeverDisable: false
    },
    Bpf: {
      SkipUserCheck: false
    },
    CustomerIdentification: {
      ShowPersonnelInformation: false
    },
    CustomerDetail: {
      ShowPersonnelSetAndProduct: false,
    },
    Leads: {
      Sort: {
        AppointmentCommissioned: false
      },
      AppointmentCommissioned: {
        ShowRemainingTime: false
      },
      Create: {
        AllowOrderOriginAU: false,
        AllowBusinessCase: false,
        SetAdditionalEditor: false,
        SetCustomerTaskStatus: false
      },
      Edit: {
        AllowOrderOriginAU: false,
        CustomerTaskType: false,
        ExpireDate: false,
        Note: false,
        Info: false,
        DueDate: false,
        Backoffice: false,
        AllowBusinessCase: false,
        SetAdditionalEditor: false
      },
      Types: {
        Frontoffice: true,
        Backoffice: false
      }
    },
    Activities: {
      Delete: {
        AllowDeleteActivities: false
      },
      Overview: false,
      MarkActivitySticky: false
    },
    Powersearch: {
      Customer: {
        ShowPostfinance: false
      }
    },
    Workguides: {
      EditIfPending: false,
      Reject: false,
      Finish: false,
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Approval: {
        AlwaysEdit: false,
        Edit: false,
        Approve: false,
        Reject: false
      },
      Entris: {
        PledgeResolve: false,
        Pledge: false,
        PledgeCancellation: false,
        CancelWorkguides: false,
        ReplayWorkguide: false
      }
    },
    Customer: {
      ShowSetAdvisor: true,
      ShowMessenger: false,
      ShowCustomerReload: false,
      Form: {
        SetResponsibleUser: false
      },
    },
    Card: {
      Dmc: {
        Approve: false,
        Reject: false,
        Edit: false,
        Form: {
          EditCardOwner: false,
          EditAccountOwner: false,
          EditCategory: false
        }
      }
    },
    Contract: {
      Airlock: {
        LockOrUnlock: false
      }
    },
    Workbench: {
      Assignee: {
        Change: false,
      },
      Workguide: {
        Delete: false
      },
      Messenger: {
        Delete: false
      },
      ProvisionWorkguide: {
        Delete: false,
        Complete: false
      },
      Processes: {
        OpenCamundaCockpit: false,
        Cancel: {
          DepotOpening: false,
          AiaFatca: false,
          CustomerIdentification: false,
          CreditcardApproval: false,
        },
        Edit: {
          Activity_03tphje: false,
          Activity_064v0eq: true,
          Activity_07qljmp: true,
          Activity_0lfhswx: false,
          Activity_0vgjqtv: false,
          Activity_1bnsdpn: false,
          Activity_1thnnsl: false,
          AdressanderungAnpassenKUBETask: true,
          AdressanderungPrufenDAZTask: false,
          AdressanderungPrufenQMSTask: false,
          All: false,
          ApprovalTask: true,
          ChangeEDocumentsTask: false,
          CheckOnboardingRequestKUBETask: true,
          Collaboration_182ij0p: false,
          DepotActivityChargeFeeTask: true,
          DepotActivityUnlockKUBETask: true,
          DepotActivityUnlockQMSTask: false,
          DepotActivityUnlockTask: true,
          EnterPaymentOrderTask: false,
          LegitimationStudentTask: true,
          MakeInvestmentByAdvisoryTask: false,
          MakeInvestmentByPortfolioManagementTask: false,
          OrderCardTask: true,
          OrderCreditcardTask: true,
          OrderDebitcardTask: false,
          PostprocessCreditcardTask: true,
          PostprocessKUBETask: true,
          PostprocessingBackofficeTask: false,
          RuckbestatigungErfassenKUBETask: true,
          SetAiaFatcaTask: true,
          SetPMSPlusAndFeesTask: false,
          UpdateIdentificationManuallyQMSTask: false,
          ValidateIdentificationQMSTask: false,
          ValidateOnboardingRequestQMSTask: false,
          IdentificationResponsibleUserTask: true,
          ChangeCreateDate: false,
          KDCCheckTask: false,
          KDCCheckClientTask: false,
          KDCOpenEBankingContractTask: false
        }
      },
      Overview: {
        Customer: {
          ShowIdColumn: false,
          ShowOriginIdColumn: false
        },
        Consultant: {
          ShowIdColumn: false,
          ShowOriginIdColumn: false
        }
      }
    },
    ForeignExchange: {
      Edit: false
    },
    BpfDocument: {
      Sign: false
    },
    Messenger: {
      ShowMessenger: false
    },
    AisDocumentSigning: {
      ConsentUrl: {
        QRCode: false
      }
    },
    Edossier: {
      ShowPersonnelDocuments: false
    },
    ProfileCompletion: {
      ShowConsultationCompanyNe0: false
    },
    Transactions: {
      ShowPersonnelTransactions: false
    },
    QuickFilter: {
      Create: {
        Consultant: true,
        Backoffice: false,
        Administrator: false,
        ElectronicBankingCenter: false,
        CustomerService: false,
        CustomerServiceGuemligen: false,
        CustomerServiceBiel: false,
        AccountCards: false,
        Datacenter: false,
        QualityManagement: false
      },
      Edit: {
        Consultant: true,
        Backoffice: false,
        Administrator: false,
        ElectronicBankingCenter: false,
        CustomerService: false,
        CustomerServiceGuemligen: false,
        CustomerServiceBiel: false,
        AccountCards: false,
        Datacenter: false,
        QualityManagement: false
      },
      Show: {
        Consultant: true,
        Backoffice: false,
        Administrator: false,
        ElectronicBankingCenter: false,
        CustomerService: false,
        CustomerServiceGuemligen: false,
        CustomerServiceBiel: false,
        AccountCards: false,
        Datacenter: false,
        QualityManagement: false
      },
      Delete: {
        Consultant: false,
        Backoffice: false,
        Administrator: false,
        ElectronicBankingCenter: false,
        CustomerService: false,
        CustomerServiceGuemligen: false,
        CustomerServiceBiel: false,
        AccountCards: false,
        Datacenter: false,
        QualityManagement: false,
        Undeletable: false
      }
    },
    DevTools: {
      Overview: false,
      Ruedi: false,
      Tarzan: false,
      FileUpload: false
    }
  }
}, {
  id: 'LEAD_DISTRIBUTOR',
  name: 'LEAD_DISTRIBUTOR',
  permissions: {
    Leads: {},
    Workguides: {
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: true,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: true,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
    }
  }
}, {
  id: 'COMPLIANCE',
  name: 'COMPLIANCE',
  permissions: {
    Activities: {
      MarkActivitySticky: true
    },
  }
},
{
  id: 'UV_CONSULTANT',
  name: 'UV_CONSULTANT',
  permissions: {
    Workguides: {
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: true,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: true,
        PledgeResolve: false
      },
    }
  }
}, {
  id: 'KDC',
  name: 'KDC',
  permissions: {
    Workguides: {
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: true,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: true,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
    }
  }
}, {
  id: 'PLEDGE_RESOLVE',
  name: 'PLEDGE_RESOLVE',
  permissions: {
    Workguides: {
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: true
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: true
      },
      Entris: {
        PledgeResolve: true,
        Pledge: true,
        PledgeCancellation: true,
        ReplayWorkguide: false
      }
    }
  }
}, {
  id: 'ELECTRONIC_BANKING_CENTER',
  name: 'ELECTRONIC_BANKING_CENTER',
  permissions: {
    QuickFilter: {
      Show: {
        ElectronicBankingCenter: true
      }
    },
    Workbench: {}
  }
}, {
  id: 'CUSTOMER_SERVICE',
  name: 'CUSTOMER_SERVICE',
  permissions: {
    QuickFilter: {
      Show: {
        CustomerService: true
      }
    },
    Workbench: {
      Processes: {
        Edit: {
          KDCCheckTask: true,
          KDCCheckClientTask: true,
          KDCOpenEBankingContractTask: true
        },
        Cancel: {
          DepotOpening: false,
          AiaFatca: false,
          CustomerIdentification: false,
          CreditCardApproval: true
        }
      }
    }
  }
}, {
  id: 'CUSTOMER_SERVICE_GUEMLIGEN',
  name: 'CUSTOMER_SERVICE_GUEMLIGEN',
  permissions: {
    QuickFilter: {
      Show: {
        CustomerServiceGuemligen: true
      }
    },
    Workbench: {
      Edit: {
        KDCCheckTask: true,
        KDCCheckClientTask: true,
        KDCOpenEBankingContractTask: true
      }
    }
  }
}, {
  id: 'CUSTOMER_SERVICE_BIEL',
  name: 'CUSTOMER_SERVICE_BIEL',
  permissions: {
    QuickFilter: {
      Show: {
        CustomerServiceBiel: true
      }
    },
    Workbench: {
      Edit: {
        KDCCheckTask: true,
        KDCCheckClientTask: true,
        KDCOpenEBankingContractTask: true
      }
    }
  }
}, {
  id: 'ACCOUNT_CARDS',
  name: 'ACCOUNT_CARDS',
  permissions: {
    QuickFilter: {
      Show: {
        AccountCards: true
      }
    },
    Workbench: {}
  }
}, {
  id: 'DATACENTER',
  name: 'DATACENTER',
  permissions: {
    QuickFilter: {
      Show: {
        Datacenter: true
      }
    },
    Workbench: {}
  }
}, {
  id: 'QUALITY_MANAGEMENT',
  name: 'QUALITY_MANAGEMENT',
  permissions: {
    QuickFilter: {
      Show: {
        QualityManagement: true
      }
    },
    Workbench: {}
  }
}, {
  id: 'PERSONNEL_RIGHTS',
  name: 'PERSONNEL_RIGHTS',
  permissions: {
    Messenger: {
      ShowMessenger: true
    },
    Edossier: {
      ShowPersonnelDocuments: true
    },
    CustomerIdentification: {
      ShowPersonnelInformation: true
    },
    CustomerDetail: {
      ShowPersonnelSetAndProduct: true,
    },
    Transactions: {
      ShowPersonnelTransactions: true
    }
  }
}, {
  id: 'BACKOFFICE',
  name: 'BACKOFFICE',
  permissions: {
    Gamification: {
      Disable: true,
      NeverDisable: false
    },
    Leads: {
      Sort: {
        AppointmentCommissioned: true
      },
      AppointmentCommissioned: {
        ShowRemainingTime: true
      },
      Create: {
        AllowOrderOriginAU: true,
        AllowBusinessCase: true,
        SetAdditionalEditor: true
      },
      Edit: {
        AllowOrderOriginAU: true,
        CustomerTaskType: true,
        ExpireDate: true,
        Note: true,
        Info: true,
        DueDate: true,
        Backoffice: true,
        AllowBusinessCase: true,
        SetAdditionalEditor: true
      },
      Types: {
        Frontoffice: true,
        Backoffice: true
      },
    },
    Activities: {
      Delete: {
        AllowDeleteActivities: false
      },
      Overview: true
    },
    Powersearch: {
      Customer: {
        ShowPostfinance: true
      }
    },
    Workguides: {
      EditIfPending: true,
      Reject: true,
      Finish: true,
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: true,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: true,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: false,
        UvConsultant: false,
        PledgeResolve: false
      },
      Approval: {
        AlwaysEdit: true,
        Edit: true,
        Approve: true,
        Reject: true
      },
      Entris: {
        PledgeResolve: true,
        Pledge: true,
        PledgeCancellation: true,
        ReplayWorkguide: true
      }
    },
    Card: {
      Dmc: {
        Approve: true,
        Reject: true,
        Edit: true,
        Form: {
          EditCardOwner: true,
          EditAccountOwner: true,
          EditCategory: true
        }
      }
    },
    Customer: {
      ShowCustomerReload: true
    },
    Contract: {
      Airlock: {
        LockOrUnlock: true
      }
    },
    Workbench: {
      Assignee: {
        Change: true,
      },
      Processes: {
        Cancel: {
          DepotOpening: false,
          AiaFatca: false,
          CustomerIdentification: true,
          CreditcardApproval: false
        },
        Edit: {
          Activity_03tphje: true,
          Activity_064v0eq: true,
          Activity_07qljmp: true,
          Activity_0lfhswx: true,
          Activity_0vgjqtv: true,
          Activity_1bnsdpn: true,
          Activity_1thnnsl: true,
          AdressanderungAnpassenKUBETask: true,
          AdressanderungPrufenDAZTask: true,
          AdressanderungPrufenQMSTask: true,
          All: false,
          ApprovalTask: true,
          ChangeEDocumentsTask: true,
          Collaboration_182ij0p: true,
          DepotActivityChargeFeeTask: true,
          DepotActivityUnlockQMSTask: true,
          DepotActivityUnlockTask: true,
          EnterPaymentOrderTask: true,
          MakeInvestmentByAdvisoryTask: true,
          MakeInvestmentByPortfolioManagementTask: true,
          OrderCreditcardTask: true,
          OrderDebitcardTask: true,
          PostprocessCreditcardTask: true,
          PostprocessKUBETask: true,
          PostprocessingBackofficeTask: true,
          RuckbestatigungErfassenKUBETask: true,
          SetPMSPlusAndFeesTask: true,
          UpdateIdentificationManuallyQMSTask: true,
          ValidateIdentificationQMSTask: true,
          ValidateOnboardingRequestQMSTask: true,
          IdentificationResponsibleUserTask: true,
          KDCCheckTask: true,
          KDCCheckClientTask: true,
          KDCOpenEBankingContractTask: true
        }
      }
    },
    ForeignExchange: {
      Edit: true
    },
    BpfDocument: {
      Sign: true
    },
    Messenger: {
      ShowMessenger: false
    },
    QuickFilter: {
      Create: {
        Consultant: true,
        Backoffice: true,
        Administrator: false,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Edit: {
        Consultant: true,
        Backoffice: true,
        Administrator: false,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Show: {
        Consultant: true,
        Backoffice: true,
        Administrator: false,
        ElectronicBankingCenter: false,
        CustomerService: false,
        CustomerServiceGuemligen: false,
        CustomerServiceBiel: false,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Delete: {
        Consultant: true,
        Backoffice: true,
        Administrator: false,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true,
        Undeletable: false
      }
    }
  }
}, {
  id: 'POSTFINANCE',
  name: 'POSTFINANCE',
  permissions: {
    Powersearch: {
      Customer: {
        ShowPostfinance: true
      }
    },
    ProfileCompletion: {
      ShowConsultationCompanyNe0: true
    },
    Workguides: {
      Create: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: true,
        UvConsultant: false,
        PledgeResolve: false
      },
      Edit: {
        AllUsers: true,
        Administrator: false,
        Backoffice: false,
        Kdc: false,
        LeadDistributor: false,
        Postfinance: true,
        UvConsultant: false,
        PledgeResolve: false
      },
    }
  }
}, {
  id: 'EVV',
  name: 'EVV',
  permissions: {
    ProfileCompletion: {
      ShowConsultationCompanyNe0: true
    }
  }
}, {
  id: 'ACTIVITY_ADMIN',
  name: 'ACTIVITY_ADMIN',
  permissions: {
    Workbench: {
      Workguide: {
        Delete: true
      },
      Messenger: {
        Delete: true
      }
    },
    Activities: {
      Delete: {
        AllowDeleteActivities: true
      }
    }
  }
}, {
  id: 'BETA',
  name: 'BETA',
  permissions: {
  }
}, {
  id: 'B2B',
  name: 'B2B (AGILE_FACTORY)',
  permissions: {
    Workbench: {
      Workguide: {
        Delete: true
      },
      Processes: {
        Edit: {
          ChangeCreateDate: true
        }
      }
    }
  }
}, {
  id: 'DELETE_ACTIVITIES',
  name: 'DELETE_ACTIVITIES',
  permissions: {
    Workbench: {
      Workguide: {
        Delete: true
      },
      Messenger: {
        Delete: true
      },
    },
    Activities: {
      Delete: {
        AllowDeleteActivities: true
      }
    }
  }
}, {
  id: 'PROVISIONADMIN',
  name: 'PROVISIONADMIN',
  permissions: {
    Workbench: {
      ProvisionWorkguide: {
        Delete: true,
        Complete: true
      }
    }
  }
}, {
  id: 'ADMINISTRATOR',
  name: 'ADMINISTRATOR',
  permissions: {
    ShowBuildTime: true,
    PermissionSwitch: true,
    DeleteIndexDB: true,
    FeatureFlag: true,
    ChangeUiTheme: true,
    Gamification: {
      Disable: false,
      NeverDisable: true
    },
    Bpf: {
      SkipUserCheck: true
    },
    Leads: {
      Sort: {
        AppointmentCommissioned: true
      },
      AppointmentCommissioned: {
        ShowRemainingTime: true
      },
      Create: {
        AllowOrderOriginAU: true,
        AllowBusinessCase: true,
        SetAdditionalEditor: true,
        SetCustomerTaskStatus: true
      },
      Edit: {
        AllowOrderOriginAU: true,
        CustomerTaskType: true,
        ExpireDate: true,
        Note: true,
        Info: true,
        Backoffice: true,
        AllowBusinessCase: true,
        SetAdditionalEditor: true
      },
      Types: {
        Frontoffice: true,
        Backoffice: true
      }
    },
    Activities: {
      Delete: {
        AllowDeleteActivities: true
      },
      Overview: true,
      MarkActivitySticky: true
    },
    Powersearch: {
      Customer: {
        ShowPostfinance: true
      }
    },
    Workguides: {
      EditIfPending: true,
      Reject: true,
      Finish: true,
      Create: {
        AllUsers: true,
        Administrator: true,
        Backoffice: true,
        Kdc: true,
        LeadDistributor: true,
        Postfinance: true,
        UvConsultant: true,
        PledgeResolve: true
      },
      Edit: {
        AllUsers: true,
        Administrator: true,
        Backoffice: true,
        Kdc: true,
        LeadDistributor: true,
        Postfinance: true,
        UvConsultant: true,
        PledgeResolve: true
      },
      Approval: {
        AlwaysEdit: true,
        Edit: true,
        Approve: true,
        Reject: true
      },
      Entris: {
        PledgeResolve: true,
        Pledge: true,
        PledgeCancellation: true,
        CancelWorkguides: true,
        ReplayWorkguide: true
      }
    },
    Customer: {
      ShowSetAdvisor: true,
      ShowMessenger: true,
      ShowCustomerReload: true,
      Form: {
        SetResponsibleUser: true
      }
    },
    Card: {
      Dmc: {
        Approve: true,
        Reject: true,
        Edit: true,
        Form: {
          EditCardOwner: true,
          EditAccountOwner: true,
          EditCategory: true
        }
      }
    },
    Contract: {
      Airlock: {
        LockOrUnlock: true
      }
    },
    Workbench: {
      Assignee: {
        Change: true,
      },
      Workguide: {
        Delete: true
      },
      Messenger: {
        Delete: true
      },
      ProvisionWorkguide: {
        Delete: true,
        Complete: true
      },
      Processes: {
        OpenCamundaCockpit: true,
        Cancel: {
          DepotOpening: true,
          AiaFatca: true,
          CustomerIdentification: true,
          CreditcardApproval: true
        },
        Edit: {
          Activity_03tphje: true,
          Activity_064v0eq: true,
          Activity_07qljmp: true,
          Activity_0lfhswx: true,
          Activity_0vgjqtv: true,
          Activity_1bnsdpn: true,
          Activity_1thnnsl: true,
          AdressanderungAnpassenKUBETask: true,
          AdressanderungPrufenDAZTask: true,
          AdressanderungPrufenQMSTask: true,
          All: true,
          ApprovalTask: true,
          ChangeEDocumentsTask: true,
          Collaboration_182ij0p: true,
          DepotActivityChargeFeeTask: true,
          DepotActivityUnlockQMSTask: true,
          DepotActivityUnlockTask: true,
          OrderCreditcardTask: true,
          OrderDebitcardTask: true,
          PostprocessCreditcardTask: true,
          PostprocessKUBETask: true,
          PostprocessingBackofficeTask: true,
          RuckbestatigungErfassenKUBETask: true,
          UpdateIdentificationManuallyQMSTask: true,
          ValidateIdentificationQMSTask: true,
          ChangeCreateDate: true,
          KDCCheckTask: true,
          KDCCheckClientTask: true,
          KDCOpenEBankingContractTask: true
        },
        Replay: {
          Administrator: true,
        }
      },
      Overview: {
        Customer: {
          ShowIdColumn: true,
          ShowOriginIdColumn: true
        },
        Consultant: {
          ShowIdColumn: true,
          ShowOriginIdColumn: true
        }
      }
    },
    ForeignExchange: {
      Edit: true
    },
    BpfDocument: {
      Sign: true
    },
    Messenger: {
      ShowMessenger: false
    },
    AisDocumentSigning: {
      ConsentUrl: {
        QRCode: true
      }
    },
    ProfileCompletion: {
      ShowConsultationCompanyNe0: true
    },
    QuickFilter: {
      Create: {
        Consultant: true,
        Backoffice: true,
        Administrator: true,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Edit: {
        Consultant: true,
        Backoffice: true,
        Administrator: true,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Show: {
        Consultant: true,
        Backoffice: true,
        Administrator: true,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true
      },
      Delete: {
        Consultant: true,
        Backoffice: true,
        Administrator: true,
        ElectronicBankingCenter: true,
        CustomerService: true,
        CustomerServiceGuemligen: true,
        CustomerServiceBiel: true,
        AccountCards: true,
        Datacenter: true,
        QualityManagement: true,
        Undeletable: false
      }
    },
    DevTools: {
      Overview: true,
      Ruedi: true,
      Tarzan: true,
      FileUpload: true
    }
  }
}];

// https://issue.swisscom.ch/browse/MAP-2810
export const userExceptions = {
  38550172: ['BACKOFFICE'],
  45831464005166: ['BACKOFFICE'],
  64045752753425: ['BACKOFFICE'],
  64045769961724: ['BACKOFFICE'],
  65902452973200: ['BACKOFFICE'],
  64045778765889: ['BACKOFFICE']
};

export const developAdmins = ['96491889460001'];
