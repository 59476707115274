import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get, flatten } from 'lodash';

import incomesAction from '../../actions/Income/Incomes';

const {
  INCOMES_PENDING,
  INCOMES_FULFILLED,
  INCOMES_REJECTED
} = incomesAction;

export default function* getIncomes(request) {
  const { dataKey } = request;

  yield put({ type: INCOMES_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('customerId', dataKey, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/person/customer-income/${query}`;
    const result = yield call(handleNextHeaders, url);

    const payload = flatten(result.map((r) => get(r, 'entries', [])));

    yield put({ type: INCOMES_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: INCOMES_REJECTED, dataKey, error });

    return error;
  }
}
