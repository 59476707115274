import { put } from 'redux-saga/effects';
import moment from 'moment';
import { get, isNil } from 'lodash';
import openPrivorRetirementSavingsAccountDepositFormAction from '../../actions/Form/OpenPrivorRetirementSavingsAccountDepositForm';
import hasProvisionAccounWithdrawalLimit from '../../lib/hasProvisionAccountWithdrawalLimit';

const {
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_PENDING,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_FULFILLED,
  OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_REJECTED
} = openPrivorRetirementSavingsAccountDepositFormAction;

export default function* openPrivorRetirementSavingsAccountDepositFormInit(request) {
  yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_PENDING });

  try {
    const { data, customer } = request;

    const payload = {
      // Account Select
      selectedAccount: { accountKey: 0 },

      // Bvg Classification
      occupationalPension: get(customer, 'bvgCode.id', 'bvgCode-1') === 'bvgCode-1' 
        ? true 
        : get(customer, 'bvgCode.id') === 'bvgCode-2',
      
      // Is default. Cannot be changed in the UI.
      documentChangeBeneficiaryDesired: false,

      // Depot Opening
      securitiesSolution: false,
      securitiesSolutionCancellation: false,

      // SecuritiesSolutionCancellation
      validFrom: moment().format(),
      validUntil: moment().add(3, 'month').subtract(1, 'day').format(),

      // securitiesSolution
      fundsTotal: 0,
      funds: {},
      qiCustomDocContent: {
        dbaLandCh: true,
        dbaLandOther: undefined
      },

      // execution
      executionType: 'standingOrderBalance'
    };

    // is used to reset values if selected account changes
    if (!isNil(data) && !isNil(get(data, 'selectedAccount'))) {
      payload.selectedAccount = get(data, 'selectedAccount');
      payload.occupationalPension = get(data, 'occupationalPension');
      // payload.documentChangeBeneficiaryDesired = get(data, 'documentChangeBeneficiaryDesired');

      if (!isNil(get(data, 'selectedAccount')) && get(data, 'selectedAccount.accountKey') !== 0) {
        if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
          // if withdrawalPeriodStart is in the future, use withdrawalPeriodStart
          const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
          const validFromMinDate = isAfterToday ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')) : moment();
          payload.validFrom = validFromMinDate.format();
          payload.validUntil = validFromMinDate.clone().add(3, 'months').format();
        } else {
          const startDate = moment().add(31, 'days');
          payload.validFrom = startDate.format();
          payload.validUntil = startDate.clone().add(3, 'months').subtract(1, 'days').format();
        }
      } else {
        payload.validFrom = undefined;
        payload.validUntil = undefined;
      }
    }

    yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: OPEN_PRIVOR_RETIREMENT_SAVINGS_ACCOUNT_DEPOSIT_FORM_INIT_REJECTED, error });

    return error;
  }
}
