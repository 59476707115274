import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import productsAction from '../actions/Products';
import BpfSyncCallError from '../../Error/lib/Error/Bpf/SyncCall';

const {
  PRODUCTS_PENDING,
  PRODUCTS_FULFILLED,
  PRODUCTS_REJECTED
} = productsAction;

const availableProducts = [1, 2, 3, 4, 5, 8, 9, 10, 11];

function filterProducts(products = []) {
  return products.filter((p) => availableProducts.includes(get(p, 'productGroups')));
}

export default function* getProducts(request) {
  yield put({ type: PRODUCTS_PENDING });

  try {
    const { customerId } = request;

    const url = '/sif/bpf/public/api/v1/process-definition/key/valiant-depot-rolfsPottyCheck/tenant-id/6300/execute';
    const params = {
      variables: {
        clientKey: parseInt(customerId, 10)
      },
      resultVariableNames: [
        'processStatus',
        'productGroups',
        'inRolfsPotty',
        'validationErrors'
      ],
      waitTimeoutInMs: 20000
    };

    const { data: result } = yield call(axios, {
      url,
      method: 'post',
      data: params
    });

    if (get(result, 'resultVariables.processStatus') !== 'checked') {
      throw BpfSyncCallError();
    }

    const payload = filterProducts(get(result, 'resultVariables.productGroups', []));

    yield put({ type: PRODUCTS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: PRODUCTS_REJECTED, error });

    return error;
  }
}
