import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import moment from 'moment';
import { get } from 'lodash';

const BuildDate = React.memo(({ clientInfo }) => {
  return (
    <DropdownItem
      className="layout-header-user-profile--dropdown-item--no-icon"
      disabled
    >
      Build Date:
      {' '}
      {moment(get(clientInfo, 'timestamp')).format('L LT')}
    </DropdownItem>
  );
});

BuildDate.propTypes = {
  clientInfo: PropTypes.object
};

BuildDate.defaultProps = {
  clientInfo: { }
};

export default BuildDate;
