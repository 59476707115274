import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import productbundleUsageActions from '../../actions/SetAdvisor/ProductbundleUsage';

const {
  PRODUCTBUNDLE_USAGE_PENDING,
  PRODUCTBUNDLE_USAGE_FULFILLED,
  PRODUCTBUNDLE_USAGE_REJECTED
} = productbundleUsageActions;

export default function* getProductbundleUsage(request) {
  const { customerId } = request;

  yield put({ type: PRODUCTBUNDLE_USAGE_PENDING });
  try {
    const url = `/basic/productbundle/usage/?eq(customer.ref.$id,string:${customerId})&eq(detail,false)&limit(9999)`;
    const result = yield call(handleNextHeaders, url);

    const payload = {
      business: result.find((item) => get(item, 'id', '').includes('corporate')),
      private: result.find((item) => get(item, 'id', '').includes('residential'))
    };

    if (get(result, 'length', 0) === 0) {
      yield put({ type: PRODUCTBUNDLE_USAGE_FULFILLED, payload: result, error: new Error(`No data for customer with id ${customerId} found!`) });
    } else {
      yield put({ type: PRODUCTBUNDLE_USAGE_FULFILLED, payload });
    }

    return payload;
  } catch (error) {
    yield put({ type: PRODUCTBUNDLE_USAGE_REJECTED, error });

    return error;
  }
}
