import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';
import action from '../actions/LatestReleaseNote';
import QueryBuilder from '@evoja-web/rql-query-builder';
import moment from 'moment';

const {
  LATEST_RELEASE_NOTE_PENDING,
  LATEST_RELEASE_NOTE_FULFILLED,
  LATEST_RELEASE_NOTE_REJECTED,
} = action;

export default function* fetchLatestReleaseNotes() {
  yield put({ type: LATEST_RELEASE_NOTE_PENDING });

  try {
    const query = QueryBuilder()
      .eq('enabled', true)
      .eq('type.id', 'userInterfaceNoteType-1', { type: 'string' })
      .le('publishDate', moment().utc().format())
      .sort('-publishDate')
      .limit(1)
      .select('publishDate')
      .getQueryString();

    const { data: news } = yield call(axios.get, `/work/uinotes/${query}`);
    const latestReleaseNote = get(news, 'length', 0) > 0 ? get(news, '0') : undefined;

    const payload = latestReleaseNote;

    yield put({ type: LATEST_RELEASE_NOTE_FULFILLED, payload });
  } catch (error) {
    yield put({ type: LATEST_RELEASE_NOTE_REJECTED, error });
  }
}
