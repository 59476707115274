import { Reducers } from '@evoja-web/redaction';

import { reducer as createDocumentReducer } from './CreateDocument';
import { reducer as deleteDocumentReducer } from './DeleteDocument';
import { reducer as deleteDocumentsReducer } from './DeleteDocuments';
import { reducer as documentsReducer } from './Documents';

const reducers = Reducers([
  createDocumentReducer,
  deleteDocumentReducer,
  deleteDocumentsReducer,
  documentsReducer
]);

export default reducers.get();
