import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import productbundleUsageDetailActions from '../../actions/SetAdvisor/ProductbundleUsageDetail';

const {
  PRODUCTBUNDLE_USAGE_DETAIL_PENDING,
  PRODUCTBUNDLE_USAGE_DETAIL_FULFILLED,
  PRODUCTBUNDLE_USAGE_DETAIL_REJECTED
} = productbundleUsageDetailActions;

export default function* getProductbundleUsageDetail(request) {
  const { customerId } = request;

  yield put({ type: PRODUCTBUNDLE_USAGE_DETAIL_PENDING });
  try {
    const url = `/basic/productbundle/usage/?eq(customer.ref.$id,string:${customerId})&eq(detail,true)&limit(9999)`;
    const payload = yield call(handleNextHeaders, url);

    yield put({ type: PRODUCTBUNDLE_USAGE_DETAIL_FULFILLED, payload });
    return payload;
  } catch (error) {
    yield put({ type: PRODUCTBUNDLE_USAGE_DETAIL_REJECTED, error });

    return error;
  }
}
