import { Actions } from '@evoja-web/redaction';

import { bannersAction } from './Banners';
import { confirmBannerAction } from './ConfirmBanner';

export const actions = Actions([
  bannersAction,
  confirmBannerAction
]);

export default actions.get();
