import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import {
  USERTRACKING_LOAD_PENDING,
  USERTRACKING_LOAD_FULFILLED,
  USERTRACKING_LOAD_REJECTED,
} from '../../actions/UserTracking';

/**
 * Get Events from backend
 *
 * @param  {String} customerId Customer id
 *
 * @return {Generator}            [description]
 */
export default function* getEvents({ customerId }) {
  if (customerId) {
    yield put({ type: USERTRACKING_LOAD_PENDING });
    try {
      const data = yield call(
        handleNextHeaders,
        `/work/usertracking/?eq(customerId,string:${customerId})&or(eq(event,CUSTOMER%5fIDENTIFICATION%5fDISCARDED),eq(event,CUSTOMER%5fIDENTIFIED))`,
      );
      yield put({ type: USERTRACKING_LOAD_FULFILLED, payload: data });
    } catch (error) {
      yield put({ type: USERTRACKING_LOAD_REJECTED, error });
    }
  }
}
