import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, isArray } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';
import { Alert, AlertContent } from '@evoja-web/react-layout-components';

import './Order.css';
import { ProcessComments } from '../../../../Bpf/index';
import ProcessInfo from '../../../components/Workguide/Card/Common/ProcessInfo';
import bpfOnboardingActions from '../../../actions/Actions';
import { withWorkguideInstanceData } from '../../../../Workguide/index';

class WorkguideOnboardingCardOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: []
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
  }

  onCommentsChange(comments) {
    this.setState({ comments });
  }

  onSubmit(cardOrdered = false) {
    const { comments } = this.state;
    const {
      acl,
      bpfOnboardingActions,
      task,
      toggleRedirectModal
    } = this.props;

    bpfOnboardingActions.completeTaskRequest({
      dataKey: get(task, 'id'),
      skipUserCheck: acl.isAllowed('Bpf.SkipUserCheck'),
      variables: {
        cardOrdered,
        comments
      }
    });

    toggleRedirectModal();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      bpfErroneous,
      language,
      process,
      redirect,
      session
    } = this.props;

    const comments = isArray(get(process, 'comments', []))
      ? get(process, 'comments', [])
      : [];

    return (
      <div className="workguide-onboarding-card-order">
        <Row>
          <Col lg={12} md={12}>
            <FormattedMessage
              id="Onboarding.CardOrder.Title"
              tagName="h5"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12}>
            <FormattedMessage id="Onboarding.CardOrder.Descritption" />
          </Col>
        </Row>

        <ProcessInfo
          language={language}
          process={process}
        />

        <Row className="pb-4" />

        <Col lg={12} md={12}>
          <FormattedMessage
            id="Onboarding.ProcessInfo.Comments"
            tagName="h6"
          />
        </Col>

        <Row className="pb-2" />

        <ProcessComments
          comments={comments}
          onChange={this.onCommentsChange}
          readOnly={false}
          session={session}
        />

        <Row className="mb-6" />

        <Row>
          <Col lg={12} md={12}>
            <Alert type="warning" style={{ width: '100%' }}>
              <AlertContent>
                <FormattedMessage id="Onboarding.Warning.Activity" />
              </AlertContent>
            </Alert>
          </Col>
        </Row>

        <Row className="justify-content-end">
          <Col lg="auto" md="auto" sm="auto">
            <Button
              color="primary"
              onClick={redirect}
              outline
            >
              <FormattedMessage id="General.Button.Cancel" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button
              color="primary"
              disabled={bpfErroneous}
              onClick={() => this.onSubmit(false)}
              outline
            >
              <FormattedMessage id="Activity.Form.Reject" />
            </Button>

            <span style={{ paddingRight: '20px' }} />

            <Button
              color="primary"
              disabled={bpfErroneous}
              onClick={() => this.onSubmit(true)}
            >
              <FormattedMessage id="Activity.Form.Approve" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

WorkguideOnboardingCardOrder.propTypes = {
  acl: PropTypes.object.isRequired,
  bpfErroneous: PropTypes.bool,
  bpfOnboardingActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  process: PropTypes.object,
  redirect: PropTypes.func,
  session: PropTypes.object.isRequired,
  task: PropTypes.object,
  toggleRedirectModal: PropTypes.func
};

WorkguideOnboardingCardOrder.defaultProps = {
  bpfErroneous: false,
  language: 'de',
  process: {},
  redirect: noop,
  task: {},
  toggleRedirectModal: noop
};

function mapStateToProps(state, ownProps) {
  return {
    bpfErroneous: get(ownProps, 'bpf.erroneous', false),
    process: get(ownProps, 'bpf.process'),
    session: state.login.session,
    task: get(ownProps, 'bpf.task'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bpfOnboardingActions: bindActionCreators(bpfOnboardingActions, dispatch)
  };
}

export default withWorkguideInstanceData()(
  connect(mapStateToProps, mapDispatchToProps)(
    withAcl()(WorkguideOnboardingCardOrder)
  )
);
