import isBasicContractFulfilledOrRunning from '../../../BpfCms/lib/ProfileCompletion/Common/isBasicContractFulfilledOrRunning';
import isAiaFatcaDeclarationFulfilledOrRunning from '../../../BpfCms/lib/ProfileCompletion/Common/isAiaFatcaDeclarationFulfilledOrRunning';

/**
 * Check if conditions for a single partner are fulfilled
 *
 * @param  {Object} formalities Customer formalities
 *
 * @return {Boolean}
 */
export default function singlePartnerFormalitiesFulfilled({ formalities }) {
  return (
    isBasicContractFulfilledOrRunning(formalities)
    && isAiaFatcaDeclarationFulfilledOrRunning(formalities)
  );
}
