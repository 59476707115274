import { call, put } from 'redux-saga/effects';
import { ChunkLoader, handleNextHeaders } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';
import { get } from 'lodash';

import tranchesAction from '../../actions/Tranche/Tranches';

const {
  TRANCHES_PENDING,
  TRANCHES_FULFILLED,
  TRANCHES_REJECTED
} = tranchesAction;

function* getAccounts(refs = []) {
  const ids = refs.map((ref = '') => get(ref.match(/account\/account\/(.*)/), 1));

  const cl = ChunkLoader()
    .setServiceUrl('/account/account/')
    .setIdentifiers(ids, { type: 'string' });

  const accounts = yield call(cl.execute);

  return accounts;
}

export default function* getTranches(request) {
  const { dataKey } = request;

  yield put({ type: TRANCHES_PENDING, dataKey });

  try {
    const query = QueryBuilder()
      .eq('orderId', dataKey, { type: 'string' })
      .limit(1000)
      .getQueryString();

    const url = `/financing/tranche/${query}`;
    const tranches = yield call(handleNextHeaders, url);
    const accounts = yield call(getAccounts, tranches.map((t) => get(t, 'account.$ref')));

    const payload = tranches.map((tranche) => {
      const accountId = get(get(tranche, 'account.$ref', '').match(/account\/account\/(.*)/), 1);
      const account = accounts.find((a) => get(a, 'id') === accountId);

      return {
        ...tranche,
        account
      };
    });

    yield put({ type: TRANCHES_FULFILLED, dataKey, payload });

    return payload;
  } catch (error) {
    yield put({ type: TRANCHES_REJECTED, dataKey, error });

    return error;
  }
}
