import { DefaultReducer } from '@evoja-web/redaction';
import { get, isNil } from 'lodash';
import update from 'immutability-helper';

import action, { consultantsAction } from '../../actions/ConsultantSearch/Consultants';
import { removeInactive } from '../../lib/Consultant/Utils/index';

const { CONSULTANTS_FULFILLED } = action;
function afterConsultantsFulfilled(state, action) {
  const { payload = [] } = action;

  return update(state, {
    allConsultants: { $set: payload },
    activeConsultants: { $set: removeInactive(payload) },
    teamMainAdvisors: { $set: payload.filter((consultant) => !isNil(get(consultant, 'teamMainAdvisorId'))) },
  });
}

export const reducer = DefaultReducer('consultants', consultantsAction, { data: [] });
reducer.after(CONSULTANTS_FULFILLED, afterConsultantsFulfilled);

export default reducer.get();
