import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';
import moment from 'moment';
import { ValidationResult } from '@evoja-web/react-form';

import {
  CodeSelect,
  InputField,
  NumberField,
  Select,
  CodeCheckboxGroup,
  Checkbox,
  DateField
} from '../../../../General/index';
import { PowersOfAttorneyList } from '../../../../PowerOfAttorney';
import { getTranslationKey } from '../../../../../staticMappings/mapVisecaCodes';

const Field = React.memo(({ label, Component, props }) => {
  return (
    <FormGroup>
      <Label>
        {label}
      </Label>

      <Component {...props} />
    </FormGroup>
  );
});

Field.propTypes = {
  label: PropTypes.node,
  Component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  props: PropTypes.object
};

Field.defaultProps = {
  label: undefined,
  Component: undefined,
  props: {}
};

export const Accounts = React.memo((props) => {
  let options = get(props, 'accounts', [])
    .map((a) => ({
      value: get(a, 'id'),
      account: a,
      label: `${get(a, `accountType.text.${get(props, 'language', 'de')}`)} (${get(a, 'numberFormatted')})`
    }));

  const additionalOptions = get(props, 'additionalOptions', []);
  options = [...options, ...additionalOptions];

  props = {
    ...props,
    id: 'account',
    name: 'account',
    tabIndex: 1,
    returnValue: 'account',
    value: get(props, 'value.id'),
    options,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.Account" />}
      Component={Select}
      props={props}
    />
  );
});

export const Authorization = React.memo(({
  language,
  authorizations,
  value,
  validations,
  onChange
}) => {
  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="Card.Field.Authorizations" />
      </Label>

      <PowersOfAttorneyList
        id="authorization"
        language={language}
        authorizations={authorizations}
        selected={value}
        onChange={onChange}
        customerPath="attorneyReceiver"
      />

      <ValidationResult
        show
        validations={validations}
      />
    </React.Fragment>
  );
});

export const CustomerName = React.memo((props) => {
  props = {
    ...props,
    id: 'customerName',
    name: 'customerName',
    tabIndex: 3,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.CustomerName" />}
      Component={InputField}
      props={props}
    />
  );
});

export const CardOwner = React.memo((props) => {
  props = {
    ...props,
    id: 'customerName',
    name: 'customerName',
    tabIndex: 3,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.CardOwner" />}
      Component={InputField}
      props={props}
    />
  );
});

export const AccountOwner = React.memo((props) => {
  props = {
    ...props,
    id: 'accountOwner',
    name: 'accountOwner',
    tabIndex: 4,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.AccountOwner" />}
      Component={InputField}
      props={props}
    />
  );
});

export const Category = React.memo((props) => {
  props = {
    ...props,
    id: 'cardCategory',
    name: 'cardCategory',
    tabIndex: 5,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.Category" />}
      Component={CodeSelect}
      props={props}
    />
  );
});

export const LimitCash = React.memo((props) => {
  props = {
    ...props,
    id: 'directDebitLimit',
    name: 'directDebitLimit',
    tabIndex: 6,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.LimitCash" />}
      Component={NumberField}
      props={props}
    />
  );
});

export const LimitTotal = React.memo((props) => {
  props = {
    ...props,
    id: 'monthlyLimit',
    name: 'monthlyLimit',
    tabIndex: 7,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.LimitTotal" />}
      Component={NumberField}
      props={props}
    />
  );
});

export const Language = React.memo((props) => {
  const options = [{
    value: '0',
    label: <FormattedMessage id="Card.Code.Language.0" />
  }, {
    value: '1',
    label: <FormattedMessage id="Card.Code.Language.1" />
  }];
  props = {
    ...props,
    id: 'customerCardLanguageCode',
    name: 'customerCardLanguageCode',
    tabIndex: 8,
    showValidations: true,
    options
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.Language" />}
      Component={Select}
      props={props}
    />
  );
});

export const Formalities = React.memo((props) => {
  props = {
    ...props,
    id: 'cardFormality',
    showValidations: true,
    multi: false
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.Formalities" />}
      Component={CodeCheckboxGroup}
      props={props}
    />
  );
});

export const GeoblockingSelect = React.memo((props) => {
  const options = [{
    value: 'switzerland',
    label: <FormattedMessage id={getTranslationKey('allowedRegionMap', 'switzerland')} />
  }, {
    value: 'europe',
    label: <FormattedMessage id={getTranslationKey('allowedRegionMap', 'europe')} />
  }, {
    value: 'world',
    label: <FormattedMessage id={getTranslationKey('allowedRegionMap', 'world')} />
  }];

  props = {
    ...props,
    options,
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.Geoblocking.Region" />}
      Component={Select}
      props={props}
    />
  );
});

export const AuthorizedCustomer = React.memo((props) => {
  const options = get(props, 'authorizations', []).map((a) => ({
    value: get(a, 'attorneyReceiver.id'),
    customer: get(a, 'attorneyReceiver'),
    label: get(a, 'attorneyReceiver.designation')
  }));

  props = {
    ...props,
    id: 'authorizedCustomer',
    name: 'authorizedCustomer',
    options: uniqBy(options, 'value'),
    returnValue: 'customer',
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Client" />}
      Component={Select}
      props={props}
    />
  );
});

export const CardApplication = React.memo((props) => {
  props = {
    ...props,
    id: 'cardApplication',
    showValidations: true,
    multi: false
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.CardApplication" />}
      Component={CodeCheckboxGroup}
      props={props}
    />
  );
});

export const AccountNumberInput = React.memo((props) => {
  props = {
    ...props,
    id: 'accountNumber',
    name: 'accountNumber',
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Field.AccountNumber" />}
      Component={InputField}
      props={props}
    />
  );
});

export const HasSignatureRight = React.memo((props) => {
  props = {
    ...props,
    id: 'hasSignatureRight',
    name: 'hasSignatureRight',
    showValidations: true,
    label: <FormattedMessage id="Card.Field.HasSignatureRight" />
  };

  return (
    <Field
      Component={Checkbox}
      props={props}
    />
  );
});

export const SuspensionReason = React.memo((props) => {
  props = {
    id: 'reason',
    name: 'reason',
    showValidations: true,
    ...props
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Suspension.Reason" />}
      Component={CodeSelect}
      props={props}
    />
  );
});

export const SuspensionStatus = React.memo((props) => {
  props = {
    id: 'status',
    name: 'status',
    showValidations: true,
    ...props
  };

  return (
    <Field
      label={<FormattedMessage id="Card.SuspensionCode" />}
      Component={Select}
      props={props}
    />
  );
});

export const CollectiveApproved = React.memo((props) => {
  props = {
    id: 'collectiveApproved',
    name: 'collectiveApproved',
    showValidations: true,
    label: <FormattedMessage id="Card.CollectiveApproved" />,
    ...props
  };

  return (
    <Field
      Component={Checkbox}
      props={props}
    />
  );
});

export const ValidFrom = React.memo((props) => {
  props = {
    ...props,
    id: 'validFrom',
    name: 'validFrom',
    showValidations: true,
    minDate: moment().startOf('day')
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Limit.ValidFrom" />}
      Component={DateField}
      props={props}
    />
  );
});

export const ValidTo = React.memo((props) => {
  props = {
    ...props,
    id: 'validTo',
    name: 'validTo',
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.Limit.ValidTo" />}
      Component={DateField}
      props={props}
    />
  );
});

export const OwnDailyLimit = React.memo((props) => {
  props = {
    ...props,
    id: 'ownDailyLimit',
    name: 'ownDailyLimit',
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.OwnDailyLimit" />}
      Component={NumberField}
      props={props}
    />
  );
});

export const OwnMonthlyLimit = React.memo((props) => {
  props = {
    ...props,
    id: 'ownMonthlyLimit',
    name: 'ownMonthlyLimit',
    showValidations: true
  };

  return (
    <Field
      label={<FormattedMessage id="Card.OwnMonthlyLimit" />}
      Component={NumberField}
      props={props}
    />
  );
});
