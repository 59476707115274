import { TYPE_NUMBER, TYPE_STRING } from '@metrichor/jmespath-plus';
import { isNumber, isUndefined, isNull, get, head } from 'lodash';

/**
 * Format the given number as currency
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} formatted Formatted number
 */
export function formatCurrency(resolvedArgs = []) {
  const [value, locale] = resolvedArgs;

  if (!isNumber(value)) return value;

  const props = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  const language = get(
    navigator,
    'language',
    get(navigator, 'userLanguage', 'de-CH')
  );

  const localeToUse = !isUndefined(locale) && !isNull(locale)
    ? locale
    : `${head(language.split('-'))}-CH`;

  const formatter = new Intl.NumberFormat(localeToUse, props);
  const parts = formatter.formatToParts(value);
  const currencyPart = parts.find((part) => get(part, 'type') === 'currency');
  const formatted = formatter.format(value).replace(get(currencyPart, 'value'), '').trim();

  return formatted;
}

export default {
  key: 'formatCurrency',
  func: formatCurrency,
  types: [{ types: [TYPE_NUMBER] }, { types: [TYPE_STRING], optional: true }]
};
