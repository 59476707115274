import { DefaultAction } from '@evoja-web/redaction';
import moment from 'moment';

export const LATEST_RELEASE_NOTE_SET_READ_AT = 'map/releaseNote/LATEST_RELEASE_NOTE_SET_READ_AT';

function releaseNotesSetReadAt(time = moment()) {
  return { type: LATEST_RELEASE_NOTE_SET_READ_AT, readAt: moment(time).format() };
}

export const latestReleaseNoteAction = DefaultAction('map/releaseNote', 'LATEST_RELEASE_NOTE');
latestReleaseNoteAction.addFunction('latestReleaseNoteSetReadAt', releaseNotesSetReadAt);

export default latestReleaseNoteAction.get();
