import React from 'react';
import PropTypes from 'prop-types';
import { noop, head } from 'lodash';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './PaymentRules.css';
import { ButtonSelect } from '../../../../General/index';

const PaymentRules = React.memo(({
  onChange,
  validations,
  value
}) => {
  return (
    <React.Fragment>
      <Label>
        <FormattedMessage id="SecuritiesAccount.Opening.Form.PaymentRules" />
      </Label>

      <ButtonSelect
        id="defaultPaymentRule"
        value={[value]}
        onChange={(field, value) => onChange(field, head(value))}
        options={[{
          id: 'yes',
          value: true,
          label: <FormattedMessage id="General.Yes" />
        }, {
          id: 'no',
          value: false,
          label: <FormattedMessage id="General.No" />
        }]}
        showValidations
        validations={validations}
      />
    </React.Fragment>
  );
});

PaymentRules.propTypes = {
  onChange: PropTypes.func,
  validations: PropTypes.object,
  value: PropTypes.bool
};

PaymentRules.defaultProps = {
  onChange: noop,
  validations: {},
  value: undefined
};

export default PaymentRules;
