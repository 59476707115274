import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import kubiRatioAction from '../../actions/Kpi/KubiRatio';

const {
  KUBI_RATIO_PENDING,
  KUBI_RATIO_FULFILLED,
  KUBI_RATIO_REJECTED
} = kubiRatioAction;

export default function* getKubiRatio(request) {
  yield put({ type: KUBI_RATIO_PENDING });

  try {
    const { customerId } = request;
    const url = `/customer/kubiratio/?eq(customerId,string:${customerId})`;
    const data = yield call(handleNextHeaders, url);

    const defaults = {
      id: customerId,
      customerId,
      payment: false,
      saving: false,
      provision: false,
      investment: false,
      financing: false,
      sets: false,
      cards: false,
      ebanking: false,
      focus: false
    };

    const payload = {
      ...defaults,
      ...get(data, '0', {})
    };

    yield put({ type: KUBI_RATIO_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: KUBI_RATIO_REJECTED, error });

    return error;
  }
}
