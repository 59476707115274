import { takeLatest } from 'redux-saga/effects';

import actions from '../actions/Actions';
import getStockPrice from './getStockPrice';

const { STOCK_PRICE_REQUEST } = actions;

export default function* uiNoteRootSaga() {
  yield takeLatest(STOCK_PRICE_REQUEST, getStockPrice);
}
