import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import cancelOpeningAction from '../actions/CancelOpening';

const {
  CANCEL_OPENING_PENDING,
  CANCEL_OPENING_FULFILLED,
  CANCEL_OPENING_REJECTED
} = cancelOpeningAction;

export default function* cancelOpening(request) {
  yield put({ type: CANCEL_OPENING_PENDING });

  try {
    const { processInstanceId } = request;

    const url = '/sif/bpf/public/api/v1/message';
    const payload = yield call(axios, {
      url,
      method: 'post',
      data: {
        messageName: 'valiant-depot-open-cancel',
        processInstanceId,
        resultEnabled: true,
        processVariables: {
          remark: 'Process was manually finished.'
        },
        all: true
      }
    });

    yield put({ type: CANCEL_OPENING_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: CANCEL_OPENING_REJECTED, error });

    return error;
  }
}
