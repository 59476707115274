import { TYPE_ANY, TYPE_NULL } from '@metrichor/jmespath-plus';
import { isArray, isEmpty } from 'lodash';

/**
 * Check if the given array is empty
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {Boolean}
 */
export function isEmptyArray(resolvedArgs) {
  const [value] = resolvedArgs;

  return isArray(value) && isEmpty(value);
}

export default {
  key: 'isEmptyArray',
  func: isEmptyArray,
  types: [{ types: [TYPE_ANY, TYPE_NULL] }]
};
