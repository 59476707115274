import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCodeMapper } from '@evoja-web/entity-code';
import { withAcl } from '@evoja-web/client-acl';
import { chain, get, isEmpty, noop, omit } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import LeadForm from '../../components/Form/LeadForm';
import LeadFormHandler from '../../lib/LeadForm/Handler/LeadCreate';
import withLeadQualification from '../../hocs/withLeadQualification';
import leadActions from '../../actions/Actions';
import { withWorkguideInstanceData, withWorkguideModalToggle } from '../../../Workguide/index';
import calculateScore from '../../lib/Utils/calculateScore';

const postActions = {
  'postAction-1': ({
    config,
    customerId,
    onToggleWorkguideModal,
    toggleRedirectModal
  }) => {
    toggleRedirectModal({
      onFulfilled: () => onToggleWorkguideModal({ customerId, workguideId: get(config, 'postActionWorkguideId') }),
      waitTime: 0
    });
  },
  'postAction-2': ({
    customerId,
    onToggleWorkguideModal,
    toggleRedirectModal
  }) => {
    toggleRedirectModal({
      onFulfilled: () => onToggleWorkguideModal({ customerId, workguideName: 'create-new-activity' }),
      waitTime: 0
    });
  },
  default: ({ data, toggleRedirectModal }) => {
    if (!get(data, 'createFurther', false)) {
      toggleRedirectModal({ waitTime: 1000 });
    }
  }
};

/**
 * Get the post action for the selected customerTaskType.
 * Return the default if no action is configured (just toggle the redirect modal)
 *
 * @param {Object} data              Form data
 * @param {Object} leadQualification Lead qualification data form /work/leadqualification
 *
 * @return  {Function} action Post action to execute
 */
function getPostAction({
  data,
  leadQualification
}) {
  const config = chain(leadQualification)
    .get('data', [])
    .find((q) => q.id === get(data, 'customerTaskType.id'))
    .value();

  const action = get(postActions, get(config, 'postAction.id'), postActions.default);

  return (props = {}) => action({ ...props, config, data });
}

class LeadWorkguideCreateLead extends React.Component {
  constructor(props) {
    super(props);

    const {
      acl,
      customer,
      language,
      leadActions,
      leadQualification,
      mapper,
      session
    } = props;

    this.handler = LeadFormHandler({
      acl,
      customer,
      language,
      leadActions,
      leadQualification,
      mapper,
      session
    });

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { form } = this.props;

    if (!get(form, 'initialized', false)) {
      this.handler.init();
    }
  }

  /**
   * Check if form was saved and execute post action
   *
   * @param   {Object}  prevProps  Previous props
   *
   * @return  void
   */
  componentDidUpdate(prevProps) {
    const {
      customer,
      form,
      leadQualification,
      onToggleWorkguideModal,
      toggleRedirectModal
    } = this.props;
    const data = get(form, 'data', {});
    const saved = get(form, 'saved', false);
    const prevSaved = get(prevProps, 'form.saved');

    if (saved && !prevSaved) {
      const postAction = getPostAction({ data, leadQualification });
      postAction({
        customerId: get(customer, 'id'),
        onToggleWorkguideModal,
        toggleRedirectModal
      });
    }
  }

  /**
   * Submit the form an save the lead
   *
   * @return  void
   */
  onSubmit() {
    const {
      form,
      leadActions,
      leadQualification,
      onScrollToTop
    } = this.props;
    const data = get(form, 'data', {});
    const score = calculateScore({ config: get(leadQualification, 'data', []), lead: data });

    leadActions.leadFormSaveRequest({
      createFurther: get(data, 'createFurther'),
      data: {
        ...omit(data, ['createFurther']),
        score
      }
    });

    onScrollToTop();
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      consultants,
      customer,
      form,
      language,
      onFormCancel,
      session
    } = this.props;

    const validations = this.handler.validate({ data: get(form, 'data', {}) });

    return (
      <div className="lead-workguide-create-lead">
        <LeadForm
          consultants={consultants}
          customer={customer}
          error={get(form, 'error')}
          form={form}
          handler={this.handler}
          initializing={get(form, 'initializing', false)}
          language={language}
          onCancel={onFormCancel}
          saving={get(form, 'saving', false)}
          session={session}
          validations={validations}
        />

        <Row style={{ width: '100%' }}>
          <Col lg={12} md={12}>
            <div style={{ width: '100%' }}>
              <Button
                color="primary"
                disabled={!isEmpty(validations)}
                onClick={this.onSubmit}
              >
                <FormattedMessage id="Lead.Form.Create.Submit" />
              </Button>

              <span style={{ paddingLeft: '0.8em' }} />

              <Button
                color="primary"
                outline
                onClick={onFormCancel}
              >
                <FormattedMessage id="Lead.Form.Create.Cancel" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

LeadWorkguideCreateLead.propTypes = {
  acl: PropTypes.object.isRequired,
  consultants: PropTypes.array,
  customer: PropTypes.object.isRequired,
  form: PropTypes.object,
  language: PropTypes.string,
  leadActions: PropTypes.object.isRequired,
  leadQualification: PropTypes.object.isRequired,
  mapper: PropTypes.object.isRequired,
  onFormCancel: PropTypes.func,
  onScrollToTop: PropTypes.func,
  onToggleWorkguideModal: PropTypes.func,
  session: PropTypes.object.isRequired,
  toggleRedirectModal: PropTypes.func,
};

LeadWorkguideCreateLead.defaultProps = {
  consultants: [],
  form: {},
  language: 'de',
  onFormCancel: noop,
  onScrollToTop: noop,
  onToggleWorkguideModal: noop,
  toggleRedirectModal: noop
};

function mapStateToProps(state) {
  return {
    consultants: state.consultantSearch.activeConsultants,
    form: state.lead.leadForm,
    language: state.login.language,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leadActions: bindActionCreators(leadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withWorkguideInstanceData()(
    withWorkguideModalToggle()(
      withLeadQualification({ deferRendering: true, showLoadIndicator: true })(
        withCodeMapper()(
          withAcl()(
            LeadWorkguideCreateLead
          )
        )
      )
    )
  )
);
