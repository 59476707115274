import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withAcl } from '@evoja-web/client-acl';

import { Checkbox } from '../../packages/General/index';
import roleSwitchAction from '../../actions/Acl/TemporaryUserRoles';

export const MODAL_IDENTIFIER = 'MODAL_PERMISSION_SWITCH';

function refresh() {
  setTimeout(() => window.location.reload(true), 2000);
}

class PermissionSwitchModal extends React.Component {
  constructor(props) {
    super(props);

    const { acl } = this.props;

    this.state = {
      selected: get(props, 'temporaryUserRoles.data', acl.getUserRoles())
    };

    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(id) {
    const { selected } = this.state;

    const updated = selected.includes(id)
      ? selected.filter((s) => s !== id)
      : [...selected, id];

    this.setState({ selected: updated });
  }

  onCancel() {
    const { roleSwitchAction } = this.props;

    roleSwitchAction.temporaryUserRolesReset();

    refresh();
  }

  onSubmit() {
    const { selected } = this.state;
    const { roleSwitchAction } = this.props;

    roleSwitchAction.temporaryUserRolesRequest({ roles: selected });

    refresh();
  }

  renderRoles() {
    const { selected } = this.state;
    const { acl } = this.props;
    const roles = acl.getAvailableRoles();

    return roles.map((role) => {
      const { id, name } = role;
      return (
        <Checkbox
          key={id}
          id={id}
          name={id}
          value={selected.includes(id)}
          onChange={this.onChange}
          disabled={!acl.isAllowed('PermissionSwitch')}
        >
          {name}
        </Checkbox>
      );
    });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { acl } = this.props;

    return (
      <React.Fragment>
        <ModalHeader>
          <FormattedMessage id="PermissionSwitch.Modal.Title" />
        </ModalHeader>

        <ModalBody>
          <p>
            <FormattedMessage id="PermissionSwitch.Modal.Description" />
          </p>

          {this.renderRoles()}
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={this.onSubmit}
            disabled={!acl.isAllowed('PermissionSwitch')}
          >
            <FormattedMessage id="General.Button.Submit" />
          </Button>

          <Button
            color="primary"
            outline
            onClick={this.onCancel}
          >
            <FormattedMessage id="General.Button.Reset" />
          </Button>
        </ModalFooter>
      </React.Fragment>
    );
  }
}

PermissionSwitchModal.propTypes = {
  acl: PropTypes.object.isRequired,
  roleSwitchAction: PropTypes.object.isRequired
};

PermissionSwitchModal.defaultProps = {};

function mapStateToProps(state) {
  return {
    temporaryUserRoles: state.acl.temporaryUserRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    roleSwitchAction: bindActionCreators(roleSwitchAction, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withAcl()(PermissionSwitchModal)
);
