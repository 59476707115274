import { call, put } from 'redux-saga/effects';
import { handleNextHeaders } from '@evoja-web/redux-saga-utils';

import QueryBuilder from '@evoja-web/rql-query-builder';
import customerIdByCustomerNumberAction from '../actions/CustomerIdByCustomerNumber';

const {
  CUSTOMER_ID_BY_CUSTOMER_NUMBER_PENDING,
  CUSTOMER_ID_BY_CUSTOMER_NUMBER_FULFILLED,
  CUSTOMER_ID_BY_CUSTOMER_NUMBER_REJECTED
} = customerIdByCustomerNumberAction;

/**
 * Customer Id by Customer Number
 *
 * @param  {string} customerNumber of consultant
 *
 * @return {Generator}
 */
export default function* getCustomerIdByCustomerNumber(request) {
  yield put({ type: CUSTOMER_ID_BY_CUSTOMER_NUMBER_PENDING });

  try {
    const { customerNumbers } = request;
    const query = QueryBuilder()
      .in('customerNumberAlternate', customerNumbers)
      .select('customerNumberAlternate', 'customerNumber', 'id')
      .getQueryString();

    const url = `/person/customer/${query}`;

    const data = yield call(handleNextHeaders, url);

    yield put({ type: CUSTOMER_ID_BY_CUSTOMER_NUMBER_FULFILLED, data });

    return data;
  } catch (error) {
    yield put({ type: CUSTOMER_ID_BY_CUSTOMER_NUMBER_REJECTED, error });

    return error;
  }
}
