import { call } from 'redux-saga/effects';
import { getCodeMappings, initCodeMappings } from '@evoja-web/entity-code/sagas';
import isNil from 'lodash/isNil';

export default function* bootstrapCodeMappings() {
  const current = yield call(initCodeMappings);

  if (isNil(current)) {
    yield call(getCodeMappings);
  }
}
