import { getReducer } from '@evoja-web/redaction';
import * as actions from '../actions/UserTracking';

export const defaultState = {
  requesting: false,
  error: false,
  data: [],
};

export const methods = {};

methods[actions.USERTRACKING_LOAD_PENDING] = (state) => ({
  ...state,
  requesting: true,
  error: false,
  data: [],
});

methods[actions.USERTRACKING_LOAD_FULFILLED] = (state, action) => ({
  ...state,
  requesting: false,
  error: false,
  data: action.payload,
});

methods[actions.USERTRACKING_LOAD_REJECTED] = (state, action) => ({
  ...state,
  requesting: false,
  error: action.error,
  data: [],
});

export default getReducer(methods, defaultState);
