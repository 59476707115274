import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { get, isEmpty, isNull, upperFirst } from 'lodash';

import searchCustomerAction from '../actions/SearchCustomer';

const {
  SEARCH_CUSTOMER_PENDING,
  SEARCH_CUSTOMER_FULFILLED,
  SEARCH_CUSTOMER_REJECTED
} = searchCustomerAction;

/**
 * Do the search
 *
 * @param  {Object} request Request object from throttle
 *
 * @return {Generator}
 */
export default function* migrationSearchCustomer(request) {
  yield put({ type: SEARCH_CUSTOMER_PENDING });

  const term = {
    city: get(request, 'city', ''),
    customerNumber: get(request, 'customerNumber', ''),
    firstName: get(request, 'firstName', ''),
    lastName: get(request, 'lastName', '')
  };

  try {
    // Create query
    const query = [];
    Object.keys(term).forEach((el) => {
      if (!isEmpty(term[el]) && !isNull(term[el])) {
        // Change search habit, when term is city
        if (el === 'city') {
          query.push(`like(${el},*${upperFirst(term[el])}*)`);
        } else {
          // Check if term is number or sting and capitalize first letter if string
          query.push(`like(${el},${typeof term[el] === 'number' ? term[el] : upperFirst(term[el])}*)`);
        }
      }
    });

    const result = yield call(axios, {
      url: `/person/customer-migration/?${query.length > 1 ? `and(${query.join(',')})` : query.join(',')}`,
      method: 'get'
    });

    result.data = result.data.map((record) => ({
      ...record,
      customerNumberAlternate: get(record, 'customerNumber'),
      designation: get(record, 'lastName'),
      legalAddress: { city: get(record, 'city') },
      birthDate: get(record, 'birthDate'),
    }));

    yield put({ type: SEARCH_CUSTOMER_FULFILLED, payload: result.data });

    return result.data;
  } catch (error) {
    yield put({ type: SEARCH_CUSTOMER_REJECTED, error });
    return error;
  }
}
