import { call } from 'redux-saga/effects';

import { getLevelsConfig, getAchievementsConfig } from '@evoja-web/gamificator/sagas';

export default function* bootstrapGamification() {
  const achievementsConfig = yield call(getAchievementsConfig);
  const levelsConfig = yield call(getLevelsConfig);

  return {
    achievementsConfig,
    levelsConfig
  };
}
