import { TYPE_STRING, TYPE_NULL } from '@metrichor/jmespath-plus';
import { formatDateString } from './formatDateString';

/**
 * Shorthand for formatDateString with local date format (browser)
 *
 * @param  {Array} resolvedArgs Resolved args from jmes path
 *
 * @return {String} date Formatted date
 */
export function toLocalDate(resolvedArgs = []) {
  const [date] = resolvedArgs;
  return formatDateString([date, 'L']);
}

export default {
  key: 'toLocalDate',
  func: toLocalDate,
  types: [{ types: [TYPE_STRING, TYPE_NULL] }]
};
