import { get } from 'lodash';
import moment from 'moment';
import hasProvisionAccounWithdrawalLimit from './hasProvisionAccountWithdrawalLimit';

export default function getWithdrawalValidFormToValidations(form) {
  const { data } = form;

  // check if its a new Account
  if (get(data, 'selectedAccount.accountKey') !== 0) {
    // check whether a notice of termination already exists /if yes validFrom needs to be the start time of the termination or in the future
    if (hasProvisionAccounWithdrawalLimit(get(data, 'selectedAccount'))) {
      const isAfterToday = moment(get(data, 'selectedAccount.withdrawalPeriodStart')).isAfter(moment());
      const validFromMinDate = isAfterToday ? moment(get(data, 'selectedAccount.withdrawalPeriodStart')) : moment();
      return {
        validFrom: {
          isValidDate: [],
          minDate: [validFromMinDate]
        },
        validUntil: {
          isValidDate: [],
          minDate: [validFromMinDate.clone().add(3, 'months').subtract(1, 'days')]
        }
      };
    }

    // if no notice of termination already exists, add 31 days per default to consider the termnation of the account
    const startDate = moment().add(31, 'days');
    return {
      validFrom: {
        isValidDate: [],
        minDate: [startDate]
      },
      validUntil: {
        isValidDate: [],
        minDate: [startDate.clone().add(3, 'months').subtract(1, 'days')]
      }
    };
  }

  // does not have a validFrom - Until restriction
  return {
    validFrom: {
      isValidDate: [],
      minDate: [moment()]
    },
    validUntil: {
      isValidDate: [],
      minDate: [moment().add(3, 'months').subtract(1, 'days')]
    }
  };
}
